import { defaultSocialOptions } from './../common/constants/defaultSocialOptions';
import { socialMediaPatternsObj } from './../common/constants/socialMediaPatterns';

export const getDefaultSocialSelectValue = socialsList => {
    const result = defaultSocialOptions.find(option => !Object.values(socialsList).includes(option.value));

    return result.value;
};

export function transformSocialMediaData(socialMediaData) {
    const transformedData = [];

    if (typeof socialMediaData === 'string') {
        socialMediaData = [socialMediaData];
    }

    socialMediaData.forEach(data => {
        let matched = false;
        Object.entries(socialMediaPatternsObj).forEach(([platform, pattern]) => {
            if (pattern.test(data) && !matched) {
                transformedData.push({ type: platform, url: data });
                matched = true;
            }
        });
    });

    return transformedData;
}

export function updateSocialsList(lead) {
    const socialsList = lead.socialMedia.reduce((acc, { type }, index) => {
        acc[index] = type;
        return acc;
    }, {});

    return socialsList;
}

export function getSocialsOptionsList(socialMediaOptions, socialsList) {
    const newSocials = { ...socialsList };
    const newSocialMediaOptions = socialMediaOptions.map(option => {
        if (Object.values(newSocials).includes(option.value)) {
            return { ...option, disabled: true };
        }

        return { ...option, disabled: false };
    });

    return { newSocialMediaOptions, newSocials };
}
