import React from 'react';
import { iconContainerStyles } from './../theming/iconContainerStyles';
import icon from '../common/styles/Icon.module.scss';

const socialMediaIcons = {
    facebook: 'fi-brands-facebook',
    x: 'fi-brands-twitter-alt',
    twitter: 'fi-brands-twitter-alt',
    instagram: 'fi-brands-instagram',
    youtube: 'fi-brands-youtube',
};

export default function getSocialMediaIcon(url) {
    const iconClass = Object.keys(socialMediaIcons).find(domain => url.includes(domain)) || 'fi-rr-globe';
    return (
        <span style={iconContainerStyles}>
            <i className={`fi ${socialMediaIcons[iconClass]}  ${icon['social-media']}`} />
        </span>
    );
}
