import React from 'react';
import { Flex, Divider, Button, Select } from 'antd';
import SelectFilterTag from '../../common/components/SelectFilterTag/SelectFilterTag';
import { textColor, spacing, primitiveColors, textStandard } from '../../theming';
import CustomSelect from '../../common/components/Select';
import icon from '../../common/styles/Icon.module.scss';
import { useTranslation } from 'react-i18next';

const { Option } = Select;

const RenderFilter = ({
    filteredKey,
    index,
    filteredInfo,
    filterOptions,
    selectedValue,
    setSelectedValue,
    setFilteredInfo,
    setSelectedFilters,
}) => {
    const { t } = useTranslation('leads');
    const values = filteredInfo[filteredKey];

    if (!values || values.length === 0 || !filterOptions[filteredKey]) {
        return null;
    }

    const handleValueChange = value => {
        setSelectedValue(prev => ({ ...prev, [filteredKey]: value }));
    };

    const handleReset = (e, filteredKey) => {
        e.preventDefault();
        setFilteredInfo(prevState => {
            const newState = { ...prevState };
            delete newState[filteredKey];
            return newState;
        });
        setSelectedFilters(prevState => ({
            ...prevState,
            [filteredKey]: [],
        }));
    };

    const handleApply = (e, filteredKey) => {
        e.preventDefault();
        setFilteredInfo(prevState => {
            const newState = { ...prevState };
            newState[filteredKey] = selectedValue[filteredKey];
            return newState;
        });
        setSelectedFilters(prevState => ({
            ...prevState,
            [filteredKey]: selectedValue[filteredKey],
        }));
    };

    const handleDelete = (e, filteredKey) => {
        e.preventDefault();
        setFilteredInfo(prevState => {
            const newState = { ...prevState };
            delete newState[filteredKey];
            return newState;
        });
        setSelectedFilters(prevState => {
            const newState = { ...prevState };
            delete newState[filteredKey];
            return newState;
        });
    };

    return (
        <Flex filteredKey={index}>
            <CustomSelect
                mode="multiple"
                maxTagCount="responsive"
                style={{ width: 140, height: 32 }}
                value={selectedValue[filteredKey]}
                onChange={handleValueChange}
                popupMatchSelectWidth={false}
                dropdownStyle={{ width: 'fit-content', padding: 0, scrollbarWidth: 0 }}
                tagRender={SelectFilterTag}
                colorIcon={textColor.link}
                dropdownRender={menu => (
                    <Flex vertical style={{ paddingTop: spacing[0.5] }}>
                        <Flex vertical style={{ margin: '0px' }}>
                            {menu}
                        </Flex>
                        <Divider style={{ margin: `${spacing[1]} 0` }} />
                        <Flex align="center" justify="space-between" style={{ padding: spacing[2], paddingTop: 0 }}>
                            <Button type="text" onClick={e => handleReset(e, filteredKey)}>
                                {t('actions.reset')}
                            </Button>
                            <Button type="primary" onClick={e => handleApply(e, filteredKey)}>
                                {t('actions.apply')}
                            </Button>
                        </Flex>
                    </Flex>
                )}
            >
                {filterOptions[filteredKey].map((option, optionIndex) => (
                    <Option
                        key={optionIndex}
                        value={option.value}
                        style={{
                            ...textStandard,
                            margin: `${spacing[0.5]} 0px`,
                            borderRadius: '0',
                            padding: `${spacing[1]} ${spacing[3]}`,
                        }}
                    >
                        {option.text}
                    </Option>
                ))}
            </CustomSelect>
            <Button
                type="link"
                style={{
                    marginTop: '1px',
                    padding: `${spacing[0.5]} ${spacing[1]}`,
                }}
                onClick={e => handleDelete(e, filteredKey)}
            >
                <i
                    className={`fi fi-sr-cross-circle ${icon['secondary-hover']}`}
                    style={{ marginTop: spacing[0.5], color: primitiveColors.gray200 }}
                ></i>
            </Button>
        </Flex>
    );
};

export default RenderFilter;
