import { Button, Card, Empty, Flex, List, Modal } from 'antd';
import TextArea from 'antd/es/input/TextArea';
import { useState } from 'react';
import { spacing } from '../../../theming';
import classes from './CommentsSection.module.scss';
import { useTranslation } from 'react-i18next';
import { Comment } from './Comment';
import globalNotification from '../../hooks/globalNotification';
import { notifications } from '../../constants/notifications';
import { useAuth } from '../../../context/AuthContext';
import { notificationType } from '../../constants/notificationType';

export const CommentsList = ({
    comments,
    itemId,
    leaveComment,
    editComment,
    deleteComment,
    setComments,
    isInputCollapsed,
    scrollToTop,
    componentLoading,
    openNotification,
}) => {
    const { t } = useTranslation(['comments', 'general', 'confirmModal']);

    const { currentUser } = useAuth();

    const [message, setMessage] = useState('');

    const [editKey, setEditKey] = useState(null);
    const [editMessage, setEditMessage] = useState('');
    const [editLoading, setEditLoading] = useState(false);

    const [loading, setLoading] = useState(false);

    const handleLeaveComment = async () => {
        setLoading(true);

        try {
            const newComment = await leaveComment(itemId, message);

            setComments(prevComments => [newComment, ...prevComments]);
            setMessage('');
            scrollToTop();
        } catch (error) {
            console.log(error);

            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.COMMENTS,
                action: notifications.action.ADD,
                singular: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const handleDeleteComment = async id => {
        try {
            await deleteComment(id);

            const newComments = comments.filter(comment => comment.id !== id);

            setComments(newComments);
        } catch (error) {
            const errorCode = error.response.data?.code;

            openNotification({
                type: notificationType.ERROR,
                message: t(`apiResponses:${errorCode}.message`),
                description: t(`apiResponses:${errorCode}.description`),
                placement: 'top',
            });
        }
    };

    const handleEditComment = async commentId => {
        setEditLoading(true);

        try {
            const editedComment = await editComment(commentId, editMessage);

            const newComments = comments.map(comment => {
                if (comment.id !== commentId) {
                    return comment;
                }

                return editedComment;
            });

            setComments(newComments);
            setEditKey(null);
            setEditMessage('');
        } catch (error) {
            console.log(error);

            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.COMMENTS,
                action: notifications.action.UPDATE,
                singular: true,
            });
        } finally {
            setEditLoading(false);
        }
    };

    const onClickDelete = id => {
        Modal.confirm({
            title: `${t('confirmModal:actions.delete')} ${t('general:comment')}?`,
            icon: null,
            styles: { body: { padding: spacing[4] } },
            width: 'fit-content',
            okButtonProps: { danger: true },
            onOk: () => handleDeleteComment(id),
            okText: t('confirmModal:buttons.yes'),
            cancelText: t('confirmModal:buttons.cancel'),
        });
    };

    return (
        <>
            <Card
                className={classes['input-wrapper']}
                styles={{ body: { padding: 0 }, position: 'sticky' }}
                bordered={false}
            >
                <div style={{ position: 'relative', background: 'white' }}>
                    <TextArea
                        value={message}
                        onChange={e => setMessage(e.target.value)}
                        placeholder={t('left-comment')}
                        className={classes['text-area']}
                        style={{
                            minHeight: isInputCollapsed && !message ? 48 : 96,
                            maxHeight: isInputCollapsed && !message ? 48 : 'none',
                            height: isInputCollapsed && !message ? 48 : 96,
                            paddingBottom: isInputCollapsed && !message ? spacing[3] : 60,
                            resize: 'none',
                        }}
                        disabled={loading}
                        maxLength={400}
                        autoSize
                    />

                    {(!isInputCollapsed || message) && (
                        <Flex className={classes['buttons-wrapper']} gap={spacing[3]}>
                            <Button
                                className={classes['cancel-button']}
                                onClick={() => setMessage('')}
                                disabled={loading}
                            >
                                {t('cancel')}
                            </Button>
                            <Button
                                type="primary"
                                onClick={handleLeaveComment}
                                className={classes['button-save']}
                                disabled={loading || !message.trim()}
                            >
                                {t('save')}
                            </Button>
                        </Flex>
                    )}
                </div>
            </Card>

            <List
                dataSource={comments}
                renderItem={comment => (
                    <List.Item key={comment.id} style={{ border: 'none' }}>
                        <Comment
                            comment={comment}
                            editKey={editKey}
                            setEditKey={setEditKey}
                            editLoading={editLoading}
                            editMessage={editMessage}
                            setEditMessage={setEditMessage}
                            onClickDelete={onClickDelete}
                            handleEditComment={handleEditComment}
                            currentUser={currentUser}
                        />
                    </List.Item>
                )}
                locale={{
                    emptyText: !componentLoading ? <Empty description={t('general:no-data')} /> : ' ',
                }}
            />
        </>
    );
};
