import { useEffect, useState } from 'react';
import { Button, Dropdown, Empty, Flex, Modal, notification, Table, Tag, Typography } from 'antd';
import { useTeamService } from '../../services/teamService';
import { accountStatus } from '../../common/constants/accountStatus';
import globalNotification from '../../common/hooks/globalNotification';
import { notificationType } from '../../common/constants/notificationType';
import { MembershipForm } from '../../components/team-management/MembershipForm';
import { useAuth } from '../../context/AuthContext';
import { useTranslation } from 'react-i18next';
import { notifications } from '../../common/constants/notifications';
import SideDrawer from '../../common/components/sideDrawer/SideDrawer';
import PhoneInput from 'react-phone-input-2';
import { CustomPagination } from '../../common/components/pagination/Pagination';
import classNames from 'classnames';
import './styles.scss';
import {
    buttonStyle,
    fontSize,
    pageHeading,
    primitiveColors,
    spacing,
    textColor,
    textSmall,
    textStandard,
} from '../../theming';
import CustomAvatar from '../../common/components/Avatar';
import PageContainer from '../../common/components/PageContainer';
import { SuccesModal } from '../../components/team-management/succesModal/SuccesModal';
import { teamRoles } from '../../common/constants/teamRoles';
import { teamStatus } from '../../common/constants/teamStatus';
import icon from '../../common/styles/Icon.module.scss';
import { useMedia } from '../../hooks/useMediaQuery';
import { useMediaQuery } from '@uidotdev/usehooks';
import AddButton from '../../common/components/AddButton';
import TableHeadingButtons from '../../common/components/TableHeadingButtons';
import { teamRolesPriority } from '../../common/constants/TeamRolesPriority';
import ActionsMenu from '../../common/components/ActionsMenu/ActionsMenu';
import TableFilterDropdown from '../../common/components/TableFilterDropdown';
import RenderFilter from '../../components/leads/renderFilter';
import FiltersHeading from '../../common/components/FiltersHeading';
import { ClearInputIcon, FilterIcon, SearchIcon, SorterIcon } from '../../common/components/icons';
import MobileFiltersDrawer from '../../components/leads/mobileFiltersDrawer';
import SearchInput from '../../common/components/GlobalSearch/SearchInput';
import { teamRole } from '../../common/constants/teamRole';

function Team() {
    const { t } = useTranslation(['members', 'invitations', 'general', 'confirmModal', 'apiResponses']);

    const [api, contextHolder] = notification.useNotification();

    const openNotification = ({ type = 'error', message = '', description = '', placement = 'top' }) => {
        api[type]({
            message: message,
            description: description,
            placement: placement,
        });
    };

    const {
        getTeamInfo,
        sendInvitation,
        cancelInvitation,
        cancelMemberships,
        updateMembership,
        getTeamMembers,
        restoreMemberships,
    } = useTeamService();

    const { isExtraSmallDevice, isSmallDevice, isMediumDevice } = useMedia();

    const mobileDevicesBreakpoint = useMediaQuery('only screen and (max-width : 890px)');

    const [team, setTeam] = useState();
    const [members, setMembers] = useState([]);
    const [totalMembers, setTotalMembers] = useState({
        total: 0,
        limit: 0,
    });
    const [loading, setLoading] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [searchText, setSearchText] = useState('');

    const [selectedMembers, setSelectedMembers] = useState([]);
    const [formAction, setFormAction] = useState('create');
    const [defaultFormValues, setDefaultFormValues] = useState(null);

    const [createdUserEmail, setCreatedUserEmail] = useState('');
    const [isSuccesModalOpen, setIsSuccesModalOpen] = useState(false);

    const { currentUser } = useAuth();

    const [searchParams, setSearchParams] = useState({
        limit: 20,
        offset: 1,
        isDeleted: true,
        globalSearch: '',
    });

    const [sortedInfo, setSortedInfo] = useState({});

    const [openMobileFilters, setOpenMobileFilters] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [selectedFilterValue, setSelectedFilterValue] = useState({});
    const [selectedFilters, setSelectedFilters] = useState({
        status: [],
        roles: [],
    });

    const filterOptions = {
        status: teamStatus.map(status => ({
            text: status.label,
            value: status.value,
        })),
        roles: teamRoles.map(role => ({
            text: role.label,
            value: role.value,
        })),
    };
    const filtersRenderCondition =
        Object.keys(filteredInfo).length > 0 &&
        Object.values(filteredInfo).some(value => (Array.isArray(value) ? value.length > 0 : value !== null));

    useEffect(() => {
        setSelectedFilterValue(filteredInfo);
    }, [filteredInfo]); // this is used to update the selectedValue state when filteredInfo changes. This was made due to behavior of antd select component

    useEffect(() => {
        fetchTeamInfo();
    }, [searchParams, filteredInfo]);

    const fetchTeamInfo = async () => {
        setLoading(true);

        try {
            await getTeamInfo()
                .then(res => {
                    setTeam(res.data);
                })
                .catch(err => {
                    globalNotification.open({
                        type: notificationType.ERROR,
                        message: err.response.data.message,
                        description: err.response.data.description,
                    });
                });

            let sortField = '';

            switch (sortedInfo.columnKey) {
                case 'name':
                    sortField = 'firstName';
                    break;
                default:
                    sortField = sortedInfo.columnKey;
                    break;
            }

            const newSearchParams = {
                ...searchParams,
                isDeleted: true,
                sortOrder: sortedInfo.order === 'ascend' ? 'ASC' : 'DESC',
                status: filteredInfo.status || [],
                roles: filteredInfo.roles || [],
                sortField,
            };

            await getTeamMembers({ ...newSearchParams, offset: (searchParams.offset - 1) * searchParams.limit })
                .then(res => {
                    setMembers(res.data.members.map(member => ({ ...member, key: member.membershipId })));
                    setTotalMembers({
                        total: res.data.count,
                        limit: res.data.usersLimit,
                    });
                })
                .catch(err => {
                    globalNotification.open({
                        type: notificationType.ERROR,
                        message: err.response.data.message,
                        description: err.response.data.description,
                    });
                });
        } finally {
            setLoading(false);
        }
    };

    const handleSendInvite = async (event, resetFields) => {
        await sendInvitation(event)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.INVITATIONS,
                    action: notifications.action.SEND,
                    plural: true,
                });

                fetchTeamInfo();

                setDrawerOpen(false);

                resetFields();

                setCreatedUserEmail(event.email);
                setIsSuccesModalOpen(true);
            })
            .catch(error => {
                const errorCode = error.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            });
    };

    const handleUpdateMembership = async (event, resetFields) => {
        await updateMembership(event)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.MEMBERS,
                    action: notifications.action.UPDATE,
                    plural: false,
                });

                setDrawerOpen(false);

                resetFields();
            })
            .catch(error => {
                const errorCode = error.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            })
            .finally(() => {
                fetchTeamInfo();
            });
    };

    const handleCancelInvite = (memberId, membershipId) => {
        cancelInvitation(memberId, membershipId)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.INVITATIONS,
                    action: notifications.action.CANCEL,
                    plural: false,
                });
            })
            .catch(error => {
                const errorCode = error.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            })
            .finally(() => {
                fetchTeamInfo();
            });
    };

    const handleCancelMembership = membershipIds => {
        cancelMemberships(membershipIds)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.MEMBERS,
                    action: notifications.action.DELETE,
                    plural: false,
                });
                fetchTeamInfo();
                setSelectedMembers([]);
            })
            .catch(error => {
                const errorCode = error.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            });
    };

    const handleEditMembership = member => {
        setFormAction('update');
        setDefaultFormValues(member);
        setDrawerOpen(true);
    };

    const handleRestoreMembership = async (membershipIds, teamRole, resetFields) => {
        await restoreMemberships(membershipIds, teamRole)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.MEMBERS,
                    action: notifications.action.RESTORE,
                    plural: false,
                });
                fetchTeamInfo();

                setDrawerOpen(false);
                setSelectedMembers([]);

                if (resetFields) {
                    resetFields();
                }
            })
            .catch(error => {
                const errorCode = error.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            });
    };

    const onClickRestore = async ids => {
        Modal.confirm({
            title: `${t('confirmModal:actions.restore')} ${ids.length > 1 ? t('members:plural-with-article') : t('members:singular-with-article')}?`,
            icon: null,
            styles: { body: { padding: spacing[4] } },
            width: 'fit-content',
            okButtonProps: { danger: true },
            onOk: () => handleRestoreMembership(ids),
            okText: t('confirmModal:buttons.yes'),
            cancelText: t('confirmModal:buttons.cancel'),
        });
    };

    const onClickCancelInvitation = (userId, membershipId) => {
        Modal.confirm({
            title: `${t('confirmModal:actions.delete')} ${userId.length > 1 ? t('members:plural-with-article') : t('members:singular-with-article')}?`,
            icon: null,
            styles: { body: { padding: spacing[4] } },
            width: 'fit-content',
            okButtonProps: { danger: true },
            onOk: () => handleCancelInvite(userId, membershipId),
            okText: t('confirmModal:buttons.yes'),
            cancelText: t('confirmModal:buttons.cancel'),
        });
    };

    const onClickDelete = ids => {
        Modal.confirm({
            title: `${t('confirmModal:actions.delete')} ${ids.length > 1 ? t('members:plural-with-article') : t('members:singular-with-article')}?`,
            icon: null,
            styles: { body: { padding: spacing[4] } },
            width: 'fit-content',
            okButtonProps: { danger: true },
            onOk: () => handleCancelMembership(ids),
            okText: t('confirmModal:buttons.yes'),
            cancelText: t('confirmModal:buttons.cancel'),
        });
    };

    const handleCloseSuccesModal = () => {
        setCreatedUserEmail('');
        setIsSuccesModalOpen(false);
    };

    const getMemberActualStatus = (memberStatus, isActive, isDeleted) => {
        if (isDeleted) {
            return accountStatus.find(status => status.value === 'Deleted');
        }

        if (memberStatus === 'Active') {
            return isActive
                ? accountStatus.find(status => status.value === 'Active')
                : accountStatus.find(status => status.value === 'Inactive');
        }

        return accountStatus.find(status => status.value === memberStatus);
    };

    const onSelectChange = newSelectedRowKeys => {
        setSelectedMembers(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys: selectedMembers,
        onChange: onSelectChange,
    };

    const hasPermissionToOperate = (loggedUser, memberToOperate) => {
        const { teamRole: memberTeamRole, email: memberEmail } = memberToOperate;
        const { teamRole: loggedUserRole, email: loggedUserEmail } = loggedUser;

        return !(
            loggedUserEmail === memberEmail ||
            teamRolesPriority.indexOf(loggedUserRole) > teamRolesPriority.indexOf(memberTeamRole)
        );
    };

    const columns = [
        {
            title: t('fields.name'),
            dataIndex: 'name',
            key: 'name',
            width: mobileDevicesBreakpoint ? 280 : 300,
            sorter: () => {},
            render: (name, record) => {
                return (
                    <Flex gap={mobileDevicesBreakpoint ? spacing[1] : spacing[3]} justify="start" align="center">
                        <CustomAvatar
                            key={record.id}
                            size={isSmallDevice || isExtraSmallDevice ? 32 : 36}
                            photoName={record.photo}
                            firstName={record.firstName}
                            lastName={record.lastName}
                            controller={'businesses'}
                            style={{ flexShrink: 0 }}
                        />
                        <Flex vertical>
                            <Typography.Text
                                style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard), fontWeight: 500 }}
                            >
                                {`${record.firstName || 'Pending'} ${record.lastName || 'User'}`}
                            </Typography.Text>

                            <a
                                href={`mailto:${record.email}`}
                                target="_blank"
                                rel="noreferrer"
                                onClick={e => e.stopPropagation()}
                                style={{
                                    color: textColor.subtle,
                                    ...textStandard,
                                }}
                            >
                                {record.email}
                            </a>
                        </Flex>
                    </Flex>
                );
            },
        },
        {
            title: t('fields.phone'),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: mobileDevicesBreakpoint ? 165 : 200,
            render: phoneNumber => {
                if (!phoneNumber) {
                    return;
                }

                return (
                    <a
                        key={phoneNumber}
                        href={`tel:${phoneNumber}`}
                        onClick={e => e.stopPropagation()}
                        style={{
                            display: 'block',
                            width: '165px',
                            textDecoration: 'none',
                        }}
                    >
                        <PhoneInput
                            value={phoneNumber}
                            buttonStyle={{ display: 'none' }}
                            inputStyle={{
                                width: '100%',
                                border: 'none',
                                padding: 0,
                                cursor: 'pointer',
                                color: textColor.secondary,
                                textDecoration: 'none',
                                pointerEvents: 'none',
                                fontSize: fontSize.sm,
                                backgroundColor: 'inherit',
                                ...(mobileDevicesBreakpoint ? textSmall : textStandard),
                            }}
                            containerStyle={{ width: '165px' }}
                        />
                    </a>
                );
            },
        },
        {
            title: t('fields.status'),
            dataIndex: 'status',
            key: 'status',
            width: mobileDevicesBreakpoint ? 120 : 146,
            filters:
                !mobileDevicesBreakpoint &&
                teamStatus.map(status => ({
                    text: status.label,
                    value: status.value,
                })),
            filteredValue: filteredInfo.status || null,
            render: (status, record) => {
                const userStatus = getMemberActualStatus(status, record.isActive, record.isDeleted);
                return (
                    <Tag
                        style={{
                            color: userStatus.color,
                            backgroundColor: userStatus.backgroundColor,
                            border: `1px solid ${userStatus.borderColor}`,
                            paddingInline: spacing[3],
                            paddingBlock: spacing[0.5],
                            fontWeight: 500,
                            fontSize: mobileDevicesBreakpoint ? fontSize.xxs : 'inherit',
                        }}
                    >
                        {userStatus.label}
                    </Tag>
                );
            },
            filterDropdown: ({ filters, close }) =>
                !mobileDevicesBreakpoint && (
                    <TableFilterDropdown
                        filters={filters}
                        close={close}
                        filterKey="status"
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        setFilteredInfo={setFilteredInfo}
                    />
                ),
        },
        {
            title: t('fields.role'),
            dataIndex: 'teamRole',
            key: 'teamRole',
            showSorterTooltip: { target: 'full-header' },
            filters:
                !mobileDevicesBreakpoint &&
                teamRoles.map(role => ({
                    text: role.label,
                    value: role.value,
                })),
            onFilter: (value, record) => record.teamRole.indexOf(value) === 0,
            filteredValue: filteredInfo.roles || null,
            width: mobileDevicesBreakpoint ? 120 : 146,
            render: teamRole => {
                const userRole = teamRoles.find(role => role.value === teamRole);
                return (
                    <Tag
                        style={{
                            backgroundColor: userRole.backgroundColor,
                            borderColor: userRole.borderColor,
                            color: userRole.color,
                            paddingInline: spacing[3],
                            paddingBlock: spacing[0.5],
                            fontWeight: 500,
                            fontSize: mobileDevicesBreakpoint ? fontSize.xxs : 'inherit',
                        }}
                    >
                        {userRole.label}
                    </Tag>
                );
            },
            filterDropdown: ({ filters, close }) =>
                !mobileDevicesBreakpoint && (
                    <TableFilterDropdown
                        filters={filters}
                        close={close}
                        filterKey="roles"
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        setFilteredInfo={setFilteredInfo}
                    />
                ),
        },
        {
            title: t('fields.actions'),
            dataIndex: 'actions',
            key: 'actions',
            width: 40,
            fixed: 'right',
            render: (item, member) => {
                if (!hasPermissionToOperate(currentUser, member)) {
                    return;
                }

                return member.status === 'Pending' ? (
                    <Button onClick={() => onClickCancelInvitation(member.id, member.membershipId)}>
                        {t('invitations:actions.cancel')}
                    </Button>
                ) : (
                    <Flex justify="center" onClick={e => e.stopPropagation()}>
                        <Dropdown
                            placement="bottom"
                            menu={{
                                items: ActionsMenu({
                                    record: member,
                                    handleEdit: () => handleEditMembership(member),
                                    handleDelete: () => onClickDelete([member.membershipId]),
                                    handleArchive: member.isDeleted
                                        ? () => onClickRestore([member.membershipId])
                                        : null,
                                    isTeamAction: true,
                                    t,
                                }),
                            }}
                        >
                            <span onClick={e => e.preventDefault()} style={{ cursor: 'pointer' }}>
                                <i
                                    className={`fi fi-rr-menu-dots-vertical ${icon.md}`}
                                    style={{ color: textColor.subtle }}
                                />
                            </span>
                        </Dropdown>
                    </Flex>
                );
            },
        },
    ];

    const paginationConfig = {
        pageSize: searchParams.limit,
        current: searchParams.offset,
        total: totalMembers.total,
    };

    const onTableParamsChange = (pagination, filters, sorter) => {
        setSearchParams(prevValue => ({
            ...prevValue,
            currentPage: pagination.current,
            pageSize: pagination.pageSize,
            status: filters.status || '',
            roles: filters.teamRoles || '',
        }));

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const handleAddMember = () => {
        setFormAction('create');
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setDrawerOpen(false);
        setDefaultFormValues(null);
    };

    const clearAll = () => {
        setFilteredInfo({});
        setSelectedFilters({
            status: [],
            roles: [],
        });
    };

    return (
        <PageContainer
            style={{
                backgroundColor: mobileDevicesBreakpoint ? primitiveColors.gray25 : primitiveColors.gray0,
                padding: mobileDevicesBreakpoint ? spacing[1] : spacing[4],
            }}
        >
            {contextHolder}
            <Flex vertical>
                <Flex
                    vertical={mobileDevicesBreakpoint}
                    gap={mobileDevicesBreakpoint ? spacing[1] : spacing[3]}
                    align="center"
                    justify="space-between"
                    style={{
                        marginBottom: mobileDevicesBreakpoint ? spacing[3] : spacing[4],
                    }}
                >
                    <Flex
                        gap={spacing[3]}
                        align="center"
                        justify={mobileDevicesBreakpoint ? 'space-between' : 'start'}
                        style={{
                            width: mobileDevicesBreakpoint && '100%',
                            maxHeight: mobileDevicesBreakpoint && 60,
                        }}
                    >
                        <h1 style={{ ...pageHeading, fontSize: mobileDevicesBreakpoint && fontSize.H4, margin: 0 }}>
                            {team?.name}
                        </h1>
                    </Flex>

                    <Flex
                        gap={spacing[3]}
                        align="center"
                        justify={mobileDevicesBreakpoint ? 'space-between' : 'end'}
                        style={{ width: mobileDevicesBreakpoint ? '100%' : 'auto' }}
                    >
                        {mobileDevicesBreakpoint && (
                            <>
                                <Button
                                    icon={<i className={`fi fi-rr-settings-sliders`} />}
                                    style={{
                                        ...buttonStyle.small,
                                        color: textColor.subtle,
                                        borderColor: textColor.subtle,
                                        backgroundColor: 'inherit',
                                        width: 'clamp(123px, 20vw, 140px)',
                                    }}
                                    onClick={() => setOpenMobileFilters(true)}
                                >
                                    {t('actions.filters')}
                                </Button>

                                <MobileFiltersDrawer
                                    open={openMobileFilters}
                                    closeDrawer={() => setOpenMobileFilters(false)}
                                    filteredInfo={filteredInfo}
                                    filterOptions={filterOptions}
                                    selectedValue={selectedFilterValue}
                                    setSelectedValue={setSelectedFilterValue}
                                    setSelectedFilters={setSelectedFilters}
                                    setFilteredInfo={setFilteredInfo}
                                />
                            </>
                        )}
                        {!mobileDevicesBreakpoint && (
                            <SearchInput
                                placeholder={t('general:search')}
                                value={searchText}
                                style={{ width: isMediumDevice ? 220 : 250 }}
                                onChange={event => setSearchText(event.target.value)}
                                onPressEnter={() => {
                                    setSearchParams(params => {
                                        return {
                                            ...params,
                                            globalSearch: searchText,
                                        };
                                    });
                                }}
                                prefix={
                                    <SearchIcon
                                        onClick={() =>
                                            setSearchParams(params => {
                                                return {
                                                    ...params,
                                                    globalSearch: searchText,
                                                };
                                            })
                                        }
                                    />
                                }
                                suffix={
                                    searchText && (
                                        <ClearInputIcon
                                            setSearchText={setSearchText}
                                            handleReset={() =>
                                                setSearchParams(params => {
                                                    return {
                                                        ...params,
                                                        globalSearch: '',
                                                    };
                                                })
                                            }
                                        />
                                    )
                                }
                            />
                        )}

                        {currentUser.teamRole !== teamRole.VIEWER && (
                            <AddButton
                                size={isMediumDevice && mobileDevicesBreakpoint && 'default'}
                                style={{
                                    ...(mobileDevicesBreakpoint ? buttonStyle.small : buttonStyle.large),
                                }}
                                iconSize={icon.sm}
                                disabled={team?.count >= team?.usersLimit}
                                text={t('actions.add')}
                                onClick={handleAddMember}
                            />
                        )}
                    </Flex>

                    <Flex gap={spacing[4]} align={'center'} style={{ width: '100%' }} justify="flex-end">
                        {selectedMembers.length > 0 && mobileDevicesBreakpoint && (
                            <Flex
                                gap={spacing[0.5]}
                                align="center"
                                justify="end"
                                style={{ width: mobileDevicesBreakpoint && '100%' }}
                            >
                                <TableHeadingButtons
                                    items={selectedMembers}
                                    handleDelete={() => onClickDelete(selectedMembers)}
                                    isMobile
                                    style={{
                                        ...buttonStyle.small,
                                    }}
                                    selectedTab={t('general:statuses.archived')}
                                />
                            </Flex>
                        )}
                    </Flex>
                </Flex>

                {!mobileDevicesBreakpoint && (
                    <Flex
                        align="center"
                        justify={selectedMembers.length > 0 ? 'end' : 'space-between'}
                        style={{ marginBottom: spacing[1], height: 38 }}
                    >
                        {filtersRenderCondition && !selectedMembers.length && (
                            <Flex gap={spacing[2]} align="center">
                                <FiltersHeading />
                                {Object.keys(filteredInfo).map((key, index) => (
                                    <RenderFilter
                                        key={key}
                                        index={index}
                                        filteredKey={key}
                                        filteredInfo={filteredInfo}
                                        filterOptions={filterOptions}
                                        selectedValue={selectedFilterValue}
                                        setSelectedValue={setSelectedFilterValue}
                                        setSelectedFilters={setSelectedFilters}
                                        setFilteredInfo={setFilteredInfo}
                                    />
                                ))}
                                <Button
                                    type="link"
                                    onClick={() => {
                                        clearAll();
                                    }}
                                    size="small"
                                    style={{
                                        color: textColor.subtle,
                                    }}
                                >
                                    {t('actions.reset')}
                                </Button>
                            </Flex>
                        )}
                        {selectedMembers.length > 0 && (
                            <Flex
                                gap={spacing[0.5]}
                                align="center"
                                justify="end"
                                style={{ width: mobileDevicesBreakpoint && '100%' }}
                            >
                                <TableHeadingButtons
                                    items={selectedMembers}
                                    handleDelete={() => onClickDelete(selectedMembers)}
                                    handleRestore={() => onClickRestore(selectedMembers)}
                                    isMobile
                                    style={{
                                        ...buttonStyle.small,
                                    }}
                                    selectedTab={t('general:statuses.archived')}
                                />
                            </Flex>
                        )}
                    </Flex>
                )}
            </Flex>
            <Table
                columns={[
                    ...columns.map(column => {
                        return {
                            ...column,
                            sortOrder: sortedInfo?.columnKey === column.dataIndex && sortedInfo?.order,
                            title: column.title.toUpperCase(),
                            filterIcon: filtered => !mobileDevicesBreakpoint && FilterIcon({ filtered }),
                            sortIcon: () => SorterIcon({ sortOrder: sortedInfo, column: column.dataIndex }),
                        };
                    }),
                ]}
                dataSource={members}
                onChange={onTableParamsChange}
                loading={loading}
                rowSelection={{
                    ...rowSelection,
                    type: 'checkbox',
                    getCheckboxProps: record => {
                        return {
                            disabled: !hasPermissionToOperate(currentUser, record),
                        };
                    },
                    filterIcon: filtered => !mobileDevicesBreakpoint && FilterIcon({ filtered }),
                }}
                footer={() => (
                    <CustomPagination
                        currentPage={paginationConfig.current}
                        handlePageChange={value => {
                            setSearchParams(prev => ({ ...prev, offset: value }));
                        }}
                        perPage={paginationConfig.pageSize}
                        handlePerPageChange={value => {
                            setSearchParams(prev => ({ ...prev, limit: value, offset: 1 }));
                        }}
                        total={paginationConfig.total}
                        title={t('total-members')}
                        totalLimit={totalMembers.limit}
                    />
                )}
                pagination={false}
                scroll={{
                    x: 'max-content',
                }}
                bordered={false}
                size="small"
                defaultSortOrder="descend"
                rowClassName={(record, index) =>
                    classNames({
                        'even-table-cell': index % 2 === 0,
                        'odd-table-cell': index % 2 !== 0,
                    })
                }
                locale={{
                    emptyText: <Empty description={t('general:no-data')} />,
                    triggerDesc: t('general:actions.simple.sort-desc'),
                    triggerAsc: t('general:actions.simple.sort-asc'),
                    cancelSort: t('general:actions.simple.cancel-sort'),
                }}
            />
            <SideDrawer
                title={formAction === 'create' ? t('actions.add-new-member') : t('actions.edit-member')}
                open={drawerOpen}
                closeDrawer={handleDrawerClose}
            >
                {formAction === 'create' ? (
                    <MembershipForm onFinish={handleSendInvite} closeDrawer={handleDrawerClose} />
                ) : (
                    <MembershipForm
                        onFinish={handleUpdateMembership}
                        member={defaultFormValues}
                        closeDrawer={handleDrawerClose}
                    />
                )}
            </SideDrawer>

            <SuccesModal
                userEmail={createdUserEmail}
                isModalOpen={isSuccesModalOpen}
                closeModal={handleCloseSuccesModal}
            />
        </PageContainer>
    );
}

export default Team;
