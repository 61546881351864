import { Button } from 'antd';
import { buttonStyle, primitiveColors, fontSize, lineHeight } from './../../theming';
import icon from '../styles/Icon.module.scss';

export default function AddButton({ text, onClick, style, size, type, iconSize }) {
    return (
        <Button
            type={type || 'primary'}
            onClick={onClick}
            size={size || 'large'}
            style={{
                color: primitiveColors.gray0,
                ...(style || buttonStyle.large),
                fontSize: fontSize.sm,
                lineHeight: lineHeight.md,
                fontWeight: 500,
            }}
        >
            <i className={`fi fi-rr-add ${iconSize || icon.lg}`} style={{ marginTop: 2 }} />
            {text}
        </Button>
    );
}
