import { useEffect, useState } from 'react';
import { useTeamService } from '../../services/teamService';
import CustomSelect from '../../common/components/Select';
import { Button, Form, Modal, Space } from 'antd';
import CustomAvatar from '../../common/components/Avatar';
import { spacing } from '../../theming';
import { useTranslation } from 'react-i18next';
import globalNotification from '../../common/hooks/globalNotification';
import { notificationType } from '../../common/constants/notificationType';
import { notifications } from '../../common/constants/notifications';
import { teamStatusesNames } from '../../common/constants/teamStatusesNames';

export const OwnerTransferForm = ({
    leaveTeam,
    fetchUser,
    fetchUserMemberships,
    logout,
    closeDrawer,
    openNotification,
    userMemberships,
    currentUser,
    teamId,
}) => {
    const { t } = useTranslation(['profile', 'general', 'confirmModal']);

    const [form] = Form.useForm();

    const [membersOptions, setMembersOptions] = useState([]);
    const [membersLoading, setMembersLoading] = useState(false);
    const [selectedMember, setSelectedMember] = useState(null);

    const [formLoading, setFormLoading] = useState(false);

    const { getTeamMembers } = useTeamService();

    const fetchMembers = async () => {
        setMembersLoading(true);

        try {
            const response = await getTeamMembers({
                teamId,
                globalSearch: '',
                limit: 10,
                offset: 0,
                isDeleted: false,
                status: [teamStatusesNames.ACTIVE, teamStatusesNames.INACTIVE],
            });

            const filteredMembers = response.data.members.filter(membership => membership.email !== currentUser.email);

            const membersOptions = filteredMembers.map(member => ({
                value: member.membershipId,
                photo: member.photo,
                firstName: member.firstName,
                lastName: member.lastName,
                label: (
                    <Space gap={spacing[1]}>
                        <CustomAvatar
                            key={member.membershipId}
                            size={32}
                            photoName={member.photo}
                            firstName={member.firstName}
                            lastName={member.lastName}
                            controller={'businesses'}
                        />

                        {`${member.firstName} ${member.lastName}`}
                    </Space>
                ),
            }));

            if (membersOptions.length === 0) {
                globalNotification.open({
                    type: notificationType.INFO,
                    message: t('notification.no-active-members.message'),
                    description: t('notification.no-active-members.description'),
                    placement: 'top',
                });
            }

            setMembersOptions(membersOptions);
        } catch (e) {
            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.MEMBERS,
                action: notifications.action.FETCH,
                plural: true,
            });
        } finally {
            setMembersLoading(false);
        }
    };

    const handleLeaveTeam = async (teamId, newOwnerId) => {
        setFormLoading(true);

        try {
            await leaveTeam(teamId, newOwnerId);

            if (teamId === currentUser.teamId) {
                logout();

                globalNotification.open({
                    type: notificationType.SUCCESS,
                    message: t('notification.leave-success.message'),
                    description: t('notification.leave-success.description'),
                    placement: 'top',
                });
                return;
            }

            fetchUser();
            fetchUserMemberships();
            closeDrawer();
        } catch (err) {
            const errorCode = err.response.data?.code;

            openNotification({
                type: notificationType.ERROR,
                message: t(`apiResponses:${errorCode}.message`),
                description: t(`apiResponses:${errorCode}.description`),
                placement: 'top',
            });
        } finally {
            setFormLoading(false);
        }
    };

    useEffect(() => {
        fetchMembers();
    }, [teamId, form]);

    const openConfirmModal = (teamId, newOwnerId) => {
        Modal.confirm({
            title:
                userMemberships.length === 1
                    ? t('notification.leave-last-team.title')
                    : t('notification.leave-confirm.title'),
            icon: null,
            content: (
                <p>
                    {userMemberships.length === 1
                        ? t('notification.leave-last-team.description')
                        : t('notification.leave-confirm.description')}
                </p>
            ),
            onOk: () => handleLeaveTeam(teamId, newOwnerId),
            styles: { body: { padding: spacing[4] } },
            width: 'clamp(300px, 50%, 500px)',
            okButtonProps: { danger: true },
            okText: t('confirmModal:buttons.yes'),
            cancelText: t('confirmModal:buttons.cancel'),
        });
    };

    const handleSelectKeyDown = event => {
        if (event.key === 'Backspace') {
            form.setFieldsValue({ membershipId: null });
        }
    };

    const filterOption = (input, option) =>
        `${option?.firstName.toLowerCase()} ${option?.lastName.toLowerCase()}`.includes(input.toLowerCase());

    return (
        <Form
            form={form}
            name="transfer-ownership"
            layout="vertical"
            onFinish={values => {
                openConfirmModal(teamId, values.membershipId);
            }}
            autoComplete="off"
        >
            <Form.Item
                label={t('form.label.new-owner')}
                name="membershipId"
                rules={[
                    {
                        required: true,
                        message: t('form.errors.required-new-owner'),
                    },
                ]}
            >
                <CustomSelect
                    showSearch={!selectedMember}
                    filterOption={filterOption}
                    placeholder={t('form.placeholders.new-owner')}
                    allowClear
                    onChange={setSelectedMember}
                    onKeyDown={handleSelectKeyDown}
                    style={{ height: 48 }}
                    options={membersOptions}
                    loading={membersLoading}
                    disabled={membersLoading}
                    optionRender={option => {
                        if (option.value === 0) {
                            return (
                                <Space gap={spacing[1]}>
                                    <CustomAvatar
                                        key={0}
                                        size={32}
                                        firstName={t('general:none')}
                                        lastName={t('general:none')}
                                        controller={'businesses'}
                                        style={{ opacity: 0 }}
                                    />
                                    {t('general:none')}
                                </Space>
                            );
                        }

                        return (
                            <Space gap={spacing[1]}>
                                <CustomAvatar
                                    key={option.value}
                                    size={32}
                                    photoName={option.data.photo}
                                    firstName={option.data.firstName}
                                    lastName={option.data.lastName}
                                    controller={'businesses'}
                                />
                                {`${option.data.firstName} ${option.data.lastName}`}
                            </Space>
                        );
                    }}
                />
            </Form.Item>

            <Form.Item>
                <Button type="primary" htmlType="submit" loading={formLoading}>
                    {t('button.leave')}
                </Button>
            </Form.Item>
        </Form>
    );
};
