export const colorMap = {
    A: '#FF0000',
    B: '#00FF00',
    C: '#0000FF',
    D: '#FFDD1C',
    E: '#FF00FF',
    F: '#008494',
    G: '#FFA500',
    H: '#FFC0CB',
    I: '#800080',
    J: '#008000',
    K: '#000080',
    L: '#808000',
    M: '#800000',
    N: '#808080',
    O: '#008080',
    P: '#A52A2A',
    Q: '#FFD700',
    R: '#008000',
    S: '#800080',
    T: '#000080',
    U: '#808000',
    V: '#800000',
    W: '#808080',
    X: '#008080',
    Y: '#A52A2A',
    Z: '#FFD700',
};
