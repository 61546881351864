import { Button } from 'antd';
import { buttonStyle, primitiveColors } from './../../theming';
import icon from '../styles/Icon.module.scss';
import { useTranslation } from 'react-i18next';

export default function TableHeadingButtons({
    items,
    handleDelete,
    handleArchive,
    handleRestore,
    isMobile,
    style,
    selectedTab,
}) {
    const { t } = useTranslation('general');

    return (
        <>
            <Button
                size={isMobile ? 'small' : 'large'}
                type={isMobile ? 'text' : 'primary'}
                danger
                style={{
                    ...(style || buttonStyle.large),
                }}
                icon={<i className={`fi fi-rr-trash ${icon.md}`} />}
                onClick={() => handleDelete(items.map(lead => lead))}
            >
                {t('actions.simple.delete')}
            </Button>
            {(handleArchive || handleRestore) && (
                <Button
                    type={isMobile ? 'text' : 'default'}
                    size={isMobile ? 'small' : 'large'}
                    style={{
                        ...(style || buttonStyle.large),
                        color: primitiveColors.blue500,
                    }}
                    onClick={() => {
                        if (items.every(lead => lead.isDeleted) || selectedTab === t('general:statuses.archived')) {
                            handleRestore(items);
                        } else {
                            handleArchive(items);
                        }
                    }}
                    icon={<i className={`fi fi-rr-archive ${icon.md}`} />}
                >
                    {items.some(lead => lead.isDeleted || selectedTab === t('general:statuses.archived'))
                        ? t('actions.simple.restore')
                        : t('actions.simple.archive')}
                </Button>
            )}
        </>
    );
}
