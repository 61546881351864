import React from 'react';
import { InboxOutlined } from '@ant-design/icons';
import { Upload } from 'antd';
import { useFileService } from '../../services/fileService';
import { beforeUpload, handleFileUpload, handleChange, handleRemove } from './UploadHelper';
import { useTranslation } from 'react-i18next';

function DragAndDropButton({ title, fileTypes, isMultiple, path, fieldName }) {
    const { t } = useTranslation(['form']);

    const fileService = useFileService();
    const { Dragger } = Upload;

    const props = {
        multiple: isMultiple,
    };

    return (
        <Dragger
            {...props}
            customRequest={fileInfo => handleFileUpload(path, fileInfo, fileService, fieldName)}
            beforeUpload={file => beforeUpload(file, fileTypes, null, t)}
            onChange={fileInfo => handleChange(fileInfo, t)}
            onRemove={fileInfo => handleRemove(fileInfo, path, fileService, t)}
        >
            <p className="ant-upload-drag-icon">
                <InboxOutlined />
            </p>
            <p className="ant-upload-text">
                Click or drag {title} {isMultiple ? 'files' : 'file'} to this area to upload
            </p>
            <p className="ant-upload-hint">
                Support for a single or bulk upload. Strictly prohibited from uploading company data or other banned
                files.
            </p>
        </Dragger>
    );
}

export default DragAndDropButton;
