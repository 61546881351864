import { useAxios } from './axiosService';

export const useGlobalSearchService = () => {
    const { axios } = useAxios();

    const globalSearch = async query => {
        let results = {};
        await Promise.all([
            axios.get(`/leads/search`, { params: { search: query } }).then(response => {
                results.leads = response.data;
            }),
            axios.get(`/businesses/search`, { params: { search: query } }).then(response => {
                results.businesses = response.data;
            }),
            axios.get(`/users/search`, { params: { search: query } }).then(response => {
                results.users = response.data;
            }),
        ]).catch(err => {
            console.error(err); // just log the error. Axios service will show notification.
        });

        return results;
    };

    return {
        globalSearch,
    };
};
