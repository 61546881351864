import { Flex, Pagination, Select } from 'antd';
import classes from './Pagination.module.scss';
import { textStandard, textColor, textSmall, spacing } from '../../../theming';
import classNames from 'classnames';
import { useMedia } from '../../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';

const perPageOptions = [
    {
        value: 10,
        label: '10',
    },
    {
        value: 20,
        label: '20',
    },
    {
        value: 50,
        label: '50',
    },
    {
        value: 100,
        label: '100',
    },
];

export const CustomPagination = ({
    currentPage,
    handlePageChange,
    perPage,
    handlePerPageChange,
    total,
    title,
    totalLimit,
}) => {
    const { isExtraSmallDevice } = useMedia();
    const { t } = useTranslation(['pagination']);

    const handleLeftDoubleArrowClick = () => {
        if (currentPage - 5 < 1) {
            handlePageChange(1);
        } else {
            handlePageChange(currentPage - 5);
        }
    };

    const handleRightDoubleArrowClick = () => {
        if (currentPage + 5 > Math.ceil(total / perPage)) {
            handlePageChange(Math.ceil(total / perPage));
        } else {
            handlePageChange(currentPage + 5);
        }
    };

    return (
        <Flex vertical={isExtraSmallDevice} justify="space-between" align="center">
            {!isExtraSmallDevice ? (
                <p style={{ ...textStandard, color: textColor.subtle }}>
                    {title}:&nbsp;
                    <strong style={{ color: textColor.primary }}>
                        {totalLimit ? `${total} / ${totalLimit}` : total}
                    </strong>
                </p>
            ) : (
                <Flex
                    align="center"
                    justify="space-between"
                    gap={spacing[1]}
                    style={{
                        width: '100%',
                    }}
                >
                    <div>
                        <p style={{ ...textSmall, color: textColor.subtle }}>
                            {title}: <strong style={{ color: textColor.primary }}>{total}</strong>
                        </p>
                    </div>
                    <Flex align="center" gap={spacing[1]}>
                        <p className={classes.selectText}>{t('show')}</p>
                        <Select
                            title={t('show')}
                            defaultValue={20}
                            style={{
                                width: 70,
                                height: 36,
                                ...textSmall,
                                color: textColor.subtle,
                            }}
                            value={perPage}
                            onChange={handlePerPageChange}
                            options={perPageOptions}
                        />
                    </Flex>
                </Flex>
            )}
            <Flex
                align="center"
                justify="center"
                style={{
                    width: isExtraSmallDevice && '100%',
                }}
            >
                {currentPage !== 1 && (
                    <button className={classes['arrow-button']} onClick={handleLeftDoubleArrowClick}>
                        <i className={classNames('fi-rr-angle-double-left', classes['icon-styles'])} />
                    </button>
                )}

                {Math.ceil(total / perPage) > 1 ? (
                    <Pagination
                        defaultCurrent={1}
                        current={currentPage}
                        onChange={handlePageChange}
                        total={total}
                        showSizeChanger={false}
                        pageSize={perPage}
                        itemRender={(_, type, originalElement) => {
                            switch (type) {
                                case 'prev':
                                    return (
                                        <i
                                            className={classNames('fi-rr-angle-left', classes['icon-styles'], {
                                                [classes['icon-styles-disabled']]: currentPage === 1,
                                            })}
                                        />
                                    );
                                case 'next':
                                    return (
                                        <i
                                            className={classNames('fi-rr-angle-right', classes['icon-styles'], {
                                                [classes['icon-styles-disabled']]:
                                                    currentPage === Math.ceil(total / perPage),
                                            })}
                                        />
                                    );
                                case 'page':
                                    return <div className={classes.page}>{originalElement}</div>;
                                default:
                                    return originalElement;
                            }
                        }}
                    />
                ) : (
                    <Flex align="center" gap={spacing[1]}>
                        <i
                            className={classNames('fi-rr-angle-left', classes['icon-styles'], {
                                [classes['icon-styles-disabled']]: currentPage === 1,
                            })}
                        />

                        <div className={classes['page-block']}>1</div>
                        <p className={classes.text}>{t('of')}</p>
                        <p className={classes.text}>1</p>

                        <i
                            className={classNames('fi-rr-angle-right', classes['icon-styles'], {
                                [classes['icon-styles-disabled']]:
                                    total === 0 || currentPage === Math.ceil(total / perPage),
                            })}
                        />
                    </Flex>
                )}

                {total > 0 && currentPage !== Math.ceil(total / perPage) && (
                    <button className={classes['arrow-button']} onClick={handleRightDoubleArrowClick}>
                        <i className={classNames('fi-rr-angle-double-right', classes['icon-styles'])} />
                    </button>
                )}
            </Flex>

            {!isExtraSmallDevice && (
                <Flex align="center" gap={spacing[1]}>
                    <p className={classes['select-text']}>{t('show')}</p>
                    <Select
                        title={t('show')}
                        defaultValue={20}
                        style={{
                            width: 70,
                            height: 36,
                            ...textStandard,
                            color: textColor.subtle,
                        }}
                        value={perPage}
                        onChange={handlePerPageChange}
                        options={perPageOptions}
                    />
                </Flex>
            )}
        </Flex>
    );
};
