import { Flex, Checkbox, Button } from 'antd';
import ButtonIcon from '../../common/components/buttonIcon/ButtonIcon';
import dayjs from 'dayjs';
import { spacing, coreBrandColors, primitiveColors } from '../../theming';
import 'dayjs/locale/en';
import 'dayjs/locale/uk';
import capitalize from '../../utils/capitalize';
import { datesFormat } from '../../common/constants/datesFormat';

export default function StatusModalContent({
    Modal,
    isCanceled,
    subscription,
    cancelImmediately,
    manageSubscriptionPeriod,
    isFree,
    modalButtonStyle,
    currentLanguage,
    t,
}) {
    return (
        <Flex vertical gap={spacing[2]} align="start">
            <ButtonIcon
                icon="fi-rr-cross"
                onClick={() => Modal.destroyAll()}
                style={{
                    top: spacing[4],
                    right: spacing[4],
                }}
            />
            <p>
                {t('status-modal.description')}
                {isCanceled ? t('status-modal.is-cancelled') : t('status-modal.is-not-cancelled')}&nbsp;
                {!isFree &&
                    capitalize(
                        dayjs(subscription.endDate)
                            .locale(currentLanguage)
                            .format(currentLanguage === 'uk' ? datesFormat.uk.long : datesFormat.en.short),
                        currentLanguage === 'uk' ? 3 : 0
                    )}
            </p>
            {!isCanceled && (
                <span style={{ display: 'flex', alignItems: 'center', gap: spacing[1] }}>
                    <Checkbox onChange={e => (cancelImmediately = e.target.checked)} />
                    {t('status-modal.buttons.cancel-immediately')}
                </span>
            )}
            <Button
                type="primary"
                onClick={() => manageSubscriptionPeriod(cancelImmediately, isFree).then(() => Modal.destroyAll())}
                style={{
                    ...modalButtonStyle,
                    backgroundColor: isCanceled ? coreBrandColors.blueRibbon : primitiveColors.red500,
                }}
            >
                {isCanceled ? t('status-modal.buttons.renew') : t('status-modal.buttons.cancel')}
            </Button>
        </Flex>
    );
}
