import { Button, Flex, Form } from 'antd';
import StatusForm from './statusForm';
import classes from './style.module.scss';
import { buttonStyle, spacing } from '../../theming';

export const EditSingleStatusForm = ({
    status,
    index,
    handleChange,
    handleUpdateStatus,
    updateLoading,
    toggleFormVisibility,
    statusesToChange,
    colors,
    t
}) => {
    const [form] = Form.useForm();

    return (
        <Form
            form={form}
            initialValues={{
                name: status.name,
                primaryColor: status.primaryColor,
            }}
            layout="vertical"
            name="edit_status"
            className={classes.form}
            onFinish={() => {
                handleUpdateStatus(status.id, statusesToChange[index]);
            }}
        >
            <StatusForm status={status} index={index} colors={colors} t={t} handleChange={handleChange} />
            <Form.Item>
                <Flex gap={spacing[3]}>
                    <Button
                        type="primary"
                        htmlType="submit"
                        style={{
                            ...buttonStyle.medium,
                        }}
                        loading={updateLoading === status.id}
                    >
                        {t('edit-status.save')}
                    </Button>
                    <Button
                        type="link"
                        style={{
                            ...buttonStyle.medium,
                        }}
                        onClick={() => {
                            toggleFormVisibility(index);
                        }}
                    >
                        {t('edit-status.cancel')}
                    </Button>
                </Flex>
            </Form.Item>
        </Form>
    );
};
