import { useAxios } from './axiosService';

export const useFileService = () => {
    const { axios } = useAxios();

    const uploadFile = async (path, file, config) => {
        return await axios.post(path, file, config);
    };

    const deleteFile = async (path, fileName) => {
        return await axios.delete(`${path}/${fileName}`);
    };

    const getFile = async path => {
        const res = await axios.get(`${path}`);
        return res.data;
    };

    return { uploadFile, deleteFile, getFile };
};
