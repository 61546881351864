import { Popover, List, Typography, Divider, Flex, Button } from 'antd';
import { spacing, primitiveColors, textStandard } from './../../theming';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

export default function TableFilterDropdown({
    filters,
    selectedFilters,
    setSelectedFilters,
    setFilteredInfo,
    filterKey,
    close,
}) {
    const { t } = useTranslation();

    if (!selectedFilters[filterKey]) {
        setSelectedFilters(prevState => ({
            ...prevState,
            [filterKey]: [],
        }));
    }

    const menu = filters.map((filter, index) => ({
        id: index,
        name: filter.text,
        value: filter.value,
    }));

    const handleItemClick = (e, value) => {
        e.preventDefault();
        setSelectedFilters(prevState => {
            const newState = { ...prevState };

            if (newState[filterKey].includes(value)) {
                newState[filterKey] = newState[filterKey].filter(state => state !== value);
            } else {
                newState[filterKey].push(value);
            }
            return newState;
        });
    };

    const handleReset = e => {
        e.preventDefault();
        setSelectedFilters(prevState => ({
            ...prevState,
            [filterKey]: [],
        }));
        setFilteredInfo(prevState => {
            const newState = { ...prevState };
            delete newState[filterKey];
            return newState;
        });
    };

    const handleApply = e => {
        e.preventDefault();
        setFilteredInfo(prevState => {
            const newState = { ...prevState };
            if (selectedFilters[filterKey] && selectedFilters[filterKey].length > 0) {
                newState[filterKey] = selectedFilters[filterKey];
            }
            return newState;
        });
        setSelectedFilters(prevState => ({
            ...prevState,
            [filterKey]: selectedFilters[filterKey],
        }));
    };

    return (
        <Popover
            style={{
                width: 'fit-content',
            }}
        >
            <List bordered={false}>
                {menu.map(item => (
                    <List.Item
                        key={item.id}
                        onClick={e => handleItemClick(e, item.value)}
                        className={classNames('filter-item', {
                            'filter-item-selected': selectedFilters[filterKey]?.includes(item.value),
                        })}
                        style={{
                            padding: `${spacing[1]} ${spacing[2]}`,
                            margin: `${spacing[1]} 0px`,
                            cursor: 'pointer',
                            borderBlockEnd: 'none',
                        }}
                    >
                        <Typography.Text style={{ ...textStandard }}>{item.name}</Typography.Text>
                        <span>
                            {selectedFilters[filterKey]?.includes(item.value) && (
                                <i className="fi fi-sr-check" style={{ color: primitiveColors.blue500 }}></i>
                            )}
                        </span>
                    </List.Item>
                ))}
            </List>
            <Divider className="filter-divider" />
            <Flex
                justify="space-between"
                style={{
                    padding: spacing[2],
                    paddingTop: 0,
                }}
                gap={spacing[2]}
            >
                <Button
                    type="text"
                    onClick={e => {
                        handleReset(e);
                        close();
                    }}
                >
                    {t('leads:actions.reset')}
                </Button>
                <Button
                    type="primary"
                    onClick={e => {
                        handleApply(e);
                        close();
                    }}
                >
                    {t('leads:actions.apply')}
                </Button>
            </Flex>
        </Popover>
    );
}
