import { primitiveColors } from '../../theming';

export const accountStatus = [
    {
        value: 'Active',
        label: 'Active',
        color: primitiveColors.vitalize500,
        borderColor: primitiveColors.vitalize100,
        backgroundColor: primitiveColors.vitalize50,
    },
    {
        value: 'Deleted',
        label: 'Archived',
        color: primitiveColors.gray500,
        borderColor: primitiveColors.gray300,
        backgroundColor: primitiveColors.gray0,
    },
    {
        value: 'Pending',
        label: 'Pending',
        color: primitiveColors.magenta500,
        borderColor: primitiveColors.magenta100,
        backgroundColor: primitiveColors.magenta50,
    },
    {
        value: 'Inactive',
        label: 'Inactive',
        color: primitiveColors.gray400,
        borderColor: primitiveColors.gray100,
        backgroundColor: primitiveColors.gray50,
    },
];
