import { Flex, Button } from 'antd';
import ButtonIcon from './../../common/components/buttonIcon/ButtonIcon';
import { spacing, coreBrandColors } from './../../theming';

export default function ConfirmModalContent({ Modal, subscription, handler, priceId, modalButtonStyle, t }) {
    return (
        <Flex vertical gap={spacing[2]} align="start">
            <ButtonIcon
                icon="fi-rr-cross"
                onClick={() => Modal.destroyAll()}
                style={{
                    top: spacing[4],
                    right: spacing[4],
                }}
            />
            <p>
                {t('confirmation-modal.description')}&nbsp;
                {subscription.price === 0 ? t('confirmation-modal.to-free') : t('confirmation-modal.to-payed')}.
            </p>
            <Button
                type="primary"
                onClick={() => handler(priceId)}
                style={{
                    ...modalButtonStyle,
                    backgroundColor: coreBrandColors.blueRibbon,
                }}
            >
                {t('confirmation-modal.button')}
            </Button>
        </Flex>
    );
}
