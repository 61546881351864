import { useState, useEffect } from 'react';
import { Flex, Button, Modal, Tooltip } from 'antd';
import SideDrawer from '../../common/components/sideDrawer/SideDrawer';
import AddStatus from './addStatus';
import EditStatus from './editStatus';
import StatusesList from './statusesList';
import { SubTitle, getStatusPosition, Skeletons } from './helper';
import { useLeadService } from '../../services/leadService';
import { useTranslation } from 'react-i18next';
import { useMedia } from '../../hooks/useMediaQuery';
import globalNotification from '../../common/hooks/globalNotification';
import { notificationType } from '../../common/constants/notificationType';
import { spacing, buttonStyle, fontSize, primitiveColors } from '../../theming';
import { DndContext, PointerSensor, TouchSensor, closestCorners, useSensor, useSensors } from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import classes from './style.module.scss';
import icon from '../../common/styles/Icon.module.scss';
import AddButton from '../../common/components/AddButton';

export default function Statuses() {
    const [statuses, setStatuses] = useState([]);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [drawerMode, setDrawerMode] = useState('add');
    const [colors, setColors] = useState([]);
    const [loading, setLoading] = useState({
        create: false,
        update: false,
        delete: false,
        fetch: false,
    });

    const { getStatuses, deleteStatus, createStatus, updateStatus, updateStatusOrder } = useLeadService();
    const { t } = useTranslation(['settings', 'apiResponses']);
    const sensors = useSensors(useSensor(PointerSensor), useSensor(TouchSensor));

    const { isSmallDevice } = useMedia();

    // #region fetchStatuses
    async function fetchStatuses() {
        setLoading(prev => ({ ...prev, fetch: true }));
        try {
            const res = await getStatuses();
            setStatuses(res.data.statuses);
            setColors(res.data.colors);
        } catch (err) {
            console.log(err); // just to see the error. all other axios will handle
        } finally {
            setLoading(prev => ({ ...prev, fetch: false }));
        }
    }

    useEffect(() => {
        fetchStatuses();
    }, []);
    // #endregion

    // #region handlers
    const handleDrawer = () => {
        setOpenDrawer(prev => !prev);
    };

    const handleCreateStatus = async statusList => {
        setLoading(prev => ({ ...prev, create: true }));
        try {
            for (const status of statusList) {
                await createStatus({
                    name: status.name,
                    primaryColor: status.primaryColor,
                });
            }

            globalNotification.open({
                type: notificationType.SUCCESS,
                message: t('statuses.notification.created'),
            });

            handleDrawer();
            fetchStatuses();
        } catch (err) {
            const errCode = err?.response?.data?.code;

            globalNotification.open({
                type: 'error',
                message: errCode ? t(`apiResponses:${errCode}.message`) : err?.response.data.message,
                description: errCode ? t(`apiResponses:${errCode}.description`) : err?.response.data.description,
            });
        } finally {
            setLoading(prev => ({ ...prev, create: false }));
        }
    };

    const handleUpdateStatus = async (id, data) => {
        setLoading(prev => ({ ...prev, update: id }));
        try {
            await updateStatus(id, {
                name: data.name,
                primaryColor: data.primaryColor,
            });

            globalNotification.open({
                type: notificationType.SUCCESS,
                message: t('statuses.notification.updated'),
            });

            fetchStatuses();
        } catch (err) {
            const errCode = err?.response?.data?.code;

            globalNotification.open({
                type: notificationType.ERROR,
                message: errCode ? t(`apiResponses:${errCode}.message`) : err?.response.data.message,
                description: errCode ? t(`apiResponses:${errCode}.description`) : err?.response.data.description,
            });
        } finally {
            setLoading(prev => ({ ...prev, update: false }));
        }
    };

    const handleOpenDeleteModal = id => {
        Modal.confirm({
            danger: true,
            title: t('statuses.modal.title'),
            onOk: () => {
                handleDeleteStatus(id);
            },
            okText: t('statuses.modal.ok'),
            okType: 'danger',
            okButtonProps: {
                type: 'primary',
            },
            cancelText: t('statuses.modal.cancel'),
            className: classes['delete-modal'],
            centered: true,
        });
    };

    const handleDeleteStatus = async id => {
        setLoading(prev => ({ ...prev, delete: id }));
        try {
            await deleteStatus(id);

            globalNotification.open({
                type: notificationType.SUCCESS,
                message: t('statuses.notification.deleted'),
            });

            fetchStatuses();
        } catch (err) {
            const errCode = err?.response?.data?.code;

            globalNotification.open({
                type: notificationType.ERROR,
                message: t(`apiResponses:${errCode}.message`),
                description: t(`apiResponses:${errCode}.description`),
            });
        } finally {
            setLoading(prev => ({ ...prev, delete: false }));
        }
    };

    const handleDragEnd = async event => {
        const { active, over } = event;

        if (active.id === over.id) {
            return;
        }

        const activeIndex = getStatusPosition(active.id, statuses);
        const overIndex = getStatusPosition(over.id, statuses);

        if (activeIndex !== overIndex) {
            const newStatuses = arrayMove(statuses, activeIndex, overIndex);

            const updatedOrder = newStatuses.map((status, index) => ({
                ...status,
                statusOrder: index + 1,
            }));

            try {
                await updateStatusOrder(updatedOrder);
                setStatuses(updatedOrder);
            } catch (error) {
                console.error('Failed to update status order:', error);
            }
        }
    };
    // #endregion

    return (
        <Flex vertical gap={isSmallDevice ? spacing[2] : spacing[4]}>
            <Flex justify="space-between" align={isSmallDevice && 'center'} gap={isSmallDevice ? 0 : spacing[3]}>
                <Flex gap={spacing[3]} align="center">
                    <div className={classes['status-icon']}>
                        <i className="fi-rr-ballot" />
                    </div>
                    <Flex vertical>
                        <Flex
                            align="center"
                            gap={isSmallDevice ? 0 : spacing[2]}
                            style={{
                                width: 'fit-content',
                            }}
                        >
                            <h5 className={classes.title}>{t('statuses.title')}</h5>
                            <Button
                                onClick={() => {
                                    handleDrawer();
                                    setDrawerMode('edit');
                                }}
                                type="link"
                                className={classes['edit-button']}
                            >
                                <i className="fi-rr-edit" style={{ marginTop: spacing[0.5] }} />
                            </Button>
                        </Flex>
                        {!isSmallDevice && <SubTitle classes={classes} t={t} />}
                    </Flex>
                </Flex>
                <Tooltip title={statuses.length === 12 ? t('statuses.tooltip.maxLimitReached') : ''}>
                    <AddButton
                        style={{
                            ...(isSmallDevice ? buttonStyle.medium : buttonStyle.large),
                        }}
                        iconSize={icon.sm}
                        text={t('statuses.button')}
                        onClick={() => {
                            handleDrawer();
                            setDrawerMode('add');
                        }}
                        disabled={statuses.length === 12}
                    />
                </Tooltip>
            </Flex>
            {isSmallDevice && <SubTitle classes={classes} t={t} />}
            <DndContext sensors={sensors} collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
                {!loading.fetch ? <StatusesList statuses={statuses} /> : <Skeletons />}
            </DndContext>
            <SideDrawer
                open={openDrawer}
                closeDrawer={handleDrawer}
                title={drawerMode === 'add' ? t('add-status.title') : t('edit-status.title')}
                children={
                    drawerMode === 'add' ? (
                        <AddStatus t={t} colors={colors} handleCreateStatus={handleCreateStatus} loading={loading} />
                    ) : (
                        <EditStatus
                            t={t}
                            statuses={statuses}
                            colors={colors}
                            handleUpdateStatus={handleUpdateStatus}
                            handleOpenDeleteModal={handleOpenDeleteModal}
                            loading={loading}
                        />
                    )
                }
            />
        </Flex>
    );
}
