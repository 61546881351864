// edit status form:
import { Flex, Skeleton } from 'antd';
import { useSortable } from '@dnd-kit/sortable';
import { spacing } from './../../theming/spacing';
import StatusTag from '../../common/components/StatusTag';
import { CSS } from '@dnd-kit/utilities';
import classes from './style.module.scss';

export function getInitialValues(statusList) {
    return statusList?.reduce((acc, status, index) => {
        acc[`name_${index}`] = status.name;
        acc[`primaryColor_${index}`] = status.primaryColor;
        return acc;
    }, {});
}

export const SubTitle = ({ classes, t }) => {
    return <p className={classes.subtitle}>{t('statuses.subtitle')}</p>;
};

export const getStatusPosition = (id, statuses) => statuses.findIndex(status => status.statusOrder === id);

export function SortableItem({ status }) {
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({
        id: status.statusOrder,
    });

    const style = {
        transition,
        transform: CSS.Transform.toString(transform),
        touchAction: 'none',
        cursor: 'pointer',
    };

    return (
        <div ref={setNodeRef} {...attributes} {...listeners} style={style} className={classes['sort-tag']}>
            <StatusTag
                status={status}
                style={{
                    padding: spacing[3],
                    marginRight: 0,
                    boxSizing: 'border-box',
                }}
            />
        </div>
    );
}

export function Skeletons() {
    // length 4 is the minimum number of statuses
    return (
        <Flex
            gap={spacing[3]}
            style={{
                flexWrap: 'wrap',
            }}
        >
            {Array.from({ length: 4 }).map((_, index) => (
                <Skeleton.Button key={index} active size="large" />
            ))}
        </Flex>
    );
}
