import React, { createContext, useContext, useState } from 'react';
import { useTeamService } from '../services/teamService';
import globalNotification from './../common/hooks/globalNotification';
import { notifications } from './../common/constants/notifications';

const TeamsContext = createContext();

export const useTeamsContext = () => useContext(TeamsContext);

export const TeamsProvider = ({ children }) => {
    const [teams, setTeams] = useState([]);
    const { getUserMemberships } = useTeamService();

    const fetchTeams = async () => {
        try {
            const { data } = await getUserMemberships();
            const teams = data.map(membership => ({
                value: membership.team.id,
                label: membership.team.name,
            }));
            setTeams(teams);
        } catch (error) {
            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.MEMBERS,
                action: notifications.action.FETCH,
                plural: true,
            });
        }
    };

    return <TeamsContext.Provider value={{ teams, fetchTeams }}>{children}</TeamsContext.Provider>;
};
