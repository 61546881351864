import { Button, Flex, Typography } from 'antd';
import { useMedia } from '../../hooks/useMediaQuery';
import logo from '../../assets/Main_logo.png';
import { buttonStyle, spacing } from '../../theming';
import { useMediaQuery } from '@uidotdev/usehooks';

const { Text, Title } = Typography;

function CreateTeamMain({ setActiveTabKey, t, currentUser }) {
    const { isSmallDevice } = useMedia();
    const mobileDevicesBreakpoint = useMediaQuery('only screen and (max-width : 840px)');

    return (
        <Flex
            vertical
            gap={isSmallDevice ? spacing[1] : spacing[4]}
            style={{ width: 'clamp(300px, 85vw, 400px)', margin: `${spacing[2]} 0` }}
            align={mobileDevicesBreakpoint && 'center'}
        >
            {!currentUser?.teamId && (
                <img
                    src={logo}
                    alt="logo"
                    style={{
                        width: 60,
                        placeSelf: mobileDevicesBreakpoint && 'center',
                    }}
                />
            )}
            <Title level={isSmallDevice ? 3 : 2} style={{ margin: isSmallDevice && `${spacing[1]} 0` }}>
                {t('main-title')}
            </Title>
            <Text style={{ textAlign: mobileDevicesBreakpoint && 'center' }}>{t('main-sub')}</Text>

            <Button
                type="primary"
                onClick={() => setActiveTabKey('1')}
                style={{ ...(isSmallDevice ? buttonStyle.medium : buttonStyle.large), width: '100%' }}
            >
                {t('buttons.create')}
            </Button>
        </Flex>
    );
}

export default CreateTeamMain;
