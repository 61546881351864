export const socialMediaPatterns = [
    /^(https?:\/\/)?(www\.)?facebook\.com\/(profile\.php\?id=[0-9]+|[A-Za-z0-9._-]+)\/?$/,
    /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/[A-Za-z0-9_]+\/?$/,
    /^(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9._-]+\/?$/,
    /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
]; // Array of regex patterns for social media links if it in form of an array

export const socialMediaPatternsObj = {
    facebook: /^(https?:\/\/)?(www\.)?facebook\.com\/(profile\.php\?id=[0-9]+|[A-Za-z0-9._-]+)\/?$/,
    twitter: /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/[A-Za-z0-9_]+\/?$/,
    instagram: /^(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9._-]+\/?$/,
    youtube: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
}; // Object of regex patterns for social media links if it in form of an object
