import { primitiveColors } from '../../theming';

export const teamRoles = [
    {
        label: 'Owner',
        value: 'Owner',
        color: primitiveColors.geekblue500,
        backgroundColor: primitiveColors.geekblue50,
        borderColor: primitiveColors.geekblue100,
    },

    {
        label: 'Manager',
        value: 'Manager',
        color: primitiveColors.cyan500,
        backgroundColor: primitiveColors.cyan50,
        borderColor: primitiveColors.cyan100,
    },

    {
        label: 'Viewer',
        value: 'Viewer',
        color: primitiveColors.nectarine500,
        backgroundColor: primitiveColors.nectarine50,
        borderColor: primitiveColors.nectarine100,
    },
];
