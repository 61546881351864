export const languages = {
    ENGLISH: {
        value: 'en',
        label: 'English',
    },
    UKRAINIAN: {
        value: 'uk',
        label: 'Ukrainian',
    },
};
