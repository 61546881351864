const globalNotification = { open: null, request: null };

export default globalNotification;

// NOTE:
//        There is a const in common/constants/notificationType with possible notification types;
//        Use one of this options for placement: top | bottom | topLeft | topRight | bottomLeft | bottomRight;
//        Please refer to the usage example below:
//
//        globalNotification.open({
//        type: notificationType.ERROR,
//        message: err?.response?.status + ' ' + err?.response?.statusText,
//        description: 'Sorry, something went wrong.',
//        placement: 'bottomRight'
//        });
