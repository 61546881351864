import { Button, Card, Divider, Flex, Skeleton, Typography } from 'antd';
import { spacing } from '../../theming';
import { useEffect, useRef, useState } from 'react';
import { BusinessesService } from '../../services/businessesService';
import { PreviewMainInfo } from '../../common/components/preview/PreviewMainInfo';
import { PreviewAdditionalInfo } from '../../common/components/preview/PreviewAdditionalInfo';
import { useTranslation } from 'react-i18next';
import { PreviewTabs } from '../../common/components/previewTabs';
import InfiniteScroll from 'react-infinite-scroll-component';
import { CommentsList } from '../../common/components/commentsList/CommentsList';
import { LogsList } from '../../common/components/logsList/LogsList';
import { Journal } from '../../common/components/Journal';
import globalNotification from '../../common/hooks/globalNotification';
import { notifications } from '../../common/constants/notifications';
import { teamRole } from '../../common/constants/teamRole';

const RECORD_LIMIT_PER_REQUEST = 10;

export const BusinessPreview = ({ business, openEditMode, openNotification, currentUser }) => {
    const { t } = useTranslation(['businessPreview']);

    const { id, name, email, photo, phones, socialMedia, description } = business;

    const [selectedTab, setSelectedTab] = useState('1');

    const [businessComments, setBusinessComments] = useState([]);
    const [isCommentInputCollapsed, setIsCommentInputCollapsed] = useState(false);

    const [businessLogs, setBusinessLogs] = useState([]);

    const [businessLogsAndComments, setBusinessLogsAndComments] = useState({
        logsAndComments: [],
        logsCount: 0,
        commentsCount: 0,
    });

    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const infiniteScrollRef = useRef(null);

    const {
        getBusinessComments,
        leaveBusinessComment,
        deleteBusinessComment,
        editBusinessComment,
        getBusinessLogs,
        getBusinessLogsAndComments,
    } = BusinessesService();

    const fetchComments = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            const response = await getBusinessComments(id, businessComments.length, RECORD_LIMIT_PER_REQUEST);

            if (response.comments.length === 0 || response.comments.length < RECORD_LIMIT_PER_REQUEST) {
                setHasMore(false);
            }

            setBusinessComments(prevComments => [...prevComments, ...response.comments]);
        } catch {
            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.COMMENTS,
                action: notifications.action.FETCH,
                plural: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchLogs = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            const response = await getBusinessLogs(id, businessLogs.length, RECORD_LIMIT_PER_REQUEST);

            if (response.logs.length === 0 || response.logs.length < RECORD_LIMIT_PER_REQUEST) {
                setHasMore(false);
            }

            setBusinessLogs(prevLogs => [...prevLogs, ...response.logs]);
        } catch {
            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.LOGS,
                action: notifications.action.FETCH,
                plural: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchLogsAndComments = async () => {
        if (loading) {
            return;
        }

        setLoading(true);

        try {
            const response = await getBusinessLogsAndComments(
                id,
                businessLogsAndComments.logsCount,
                RECORD_LIMIT_PER_REQUEST,
                businessLogsAndComments.commentsCount,
                RECORD_LIMIT_PER_REQUEST
            );

            if (response.logsAndComments.length === 0 || response.logsAndComments.length < RECORD_LIMIT_PER_REQUEST) {
                setHasMore(false);
            }

            const { logsAndComments, logsCount, commentsCount } = response;

            setBusinessLogsAndComments(prevLogsAndComments => {
                return {
                    logsAndComments: [...prevLogsAndComments.logsAndComments, ...logsAndComments],
                    logsCount: prevLogsAndComments.logsCount + logsCount,
                    commentsCount: prevLogsAndComments.commentsCount + commentsCount,
                };
            });
        } catch {
            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.ACTIVITY,
                action: notifications.action.FETCH,
                plural: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setHasMore(true);
        setBusinessLogs([]);
        setBusinessComments([]);
        setBusinessLogsAndComments({
            logsAndComments: [],
            logsCount: 0,
            commentsCount: 0,
        });

        switch (selectedTab) {
            case '3':
                fetchLogs();
                break;
            case '2':
                fetchComments();
                break;
            default:
                fetchLogsAndComments();
                break;
        }
    }, [selectedTab]);

    const handleScroll = event => {
        if (event.target.scrollTop > 50) {
            setIsCommentInputCollapsed(true);
        } else if (event.target.scrollTop === 0) {
            setIsCommentInputCollapsed(false);
        }
    };

    const handleScrollToTop = () => {
        infiniteScrollRef.current.el.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Card style={{ boxShadow: 'none' }} styles={{ body: { padding: 0 } }} bordered={false}>
            <Flex vertical>
                <Flex justify="space-between" align="center">
                    <Typography.Title style={{ margin: 0 }}>{t('title')}</Typography.Title>

                    {currentUser.teamRole === teamRole.OWNER && (
                        <Button onClick={() => openEditMode(business)}>{t('edit-info')}</Button>
                    )}
                </Flex>

                <Divider />

                <PreviewMainInfo
                    id={id}
                    name={name}
                    photo={photo}
                    email={email}
                    phone={phones[0]}
                    socialMedia={socialMedia}
                />

                <Divider />

                <PreviewAdditionalInfo description={description} phones={phones} />

                {(description || phones.length > 1) && <Divider />}

                <Flex gap={spacing[1]} vertical>
                    <Typography.Text>{t('activity')}</Typography.Text>
                    <PreviewTabs selectedTab={selectedTab} onChange={setSelectedTab} />
                </Flex>

                <div
                    id="scrollableDiv"
                    style={{
                        height: 408,
                        overflow: 'auto',
                    }}
                >
                    <InfiniteScroll
                        ref={infiniteScrollRef}
                        dataLength={
                            selectedTab === '2'
                                ? businessComments.length
                                : selectedTab === '3'
                                  ? businessLogs.length
                                  : businessLogsAndComments.logsAndComments.length
                        }
                        next={
                            selectedTab === '2' ? fetchComments : selectedTab === '3' ? fetchLogs : fetchLogsAndComments
                        }
                        hasMore={hasMore}
                        height={408}
                        loader={
                            <Skeleton
                                avatar
                                paragraph={{
                                    rows: 1,
                                }}
                                active
                            />
                        }
                        onScroll={handleScroll}
                        style={{ scrollbarWidth: 'thin', scrollbarColor: '#888 #f1f1f1' }}
                    >
                        {selectedTab === '2' ? (
                            <CommentsList
                                comments={businessComments}
                                itemId={id}
                                leaveComment={leaveBusinessComment}
                                editComment={editBusinessComment}
                                deleteComment={deleteBusinessComment}
                                setComments={setBusinessComments}
                                isInputCollapsed={isCommentInputCollapsed}
                                scrollToTop={handleScrollToTop}
                                componentLoading={loading}
                                openNotification={openNotification}
                            />
                        ) : selectedTab === '3' ? (
                            <LogsList logs={businessLogs} entityName={'businesses'} loading={loading} />
                        ) : (
                            <Journal
                                logsAndComments={businessLogsAndComments.logsAndComments}
                                entityName={'businesses'}
                                loading={loading}
                            />
                        )}
                    </InfiniteScroll>
                </div>
            </Flex>
        </Card>
    );
};
