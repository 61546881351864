import { useState, useMemo, useCallback } from 'react';
import { Collapse, Drawer, Flex, Typography, Checkbox, Select, Button, Tag } from 'antd';
import ButtonIcon from '../../common/components/buttonIcon/ButtonIcon';
import icon from '../../common/styles/Icon.module.scss';
import {
    buttonStyle,
    coreBrandColors,
    fontSize,
    lineHeight,
    primitiveColors,
    spacing,
    radius,
    textStandard,
} from '../../theming';
import classes from './styles.module.scss';
import CustomSelect from '../../common/components/Select';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import { useMedia } from '../../hooks/useMediaQuery';

export default function MobileFiltersDrawer({
    open,
    closeDrawer,
    filterOptions,
    selectedValue,
    setSelectedValue,
    setSelectedFilters,
    setFilteredInfo,
}) {
    const [checkedList, setCheckedList] = useState({
        state: selectedValue.state ?? [],
        status: selectedValue.status ?? [],
    });

    const { t } = useTranslation(['leads', 'general', 'form', 'membership', 'members']);
    const { isExtraSmallDevice } = useMedia();

    const getExtra = useCallback((selectedValue, key) => {
        if (!selectedValue || selectedValue.length === 0) {
            return null;
        }
        return (
            <Tag
                color={coreBrandColors.blueRibbon}
                style={{
                    padding: `2px ${spacing[2]}`,
                    borderRadius: radius.huge,
                }}
                key={key}
            >
                {selectedValue.length}
            </Tag>
        );
    }, []);

    const onChange = useCallback(
        (checkedValues, key) => {
            setCheckedList(prev => ({
                ...prev,
                [key]: checkedValues,
            }));
            setSelectedValue(prev => ({
                ...prev,
                [key]: checkedValues,
            }));
            setFilteredInfo(prevState => ({
                ...prevState,
                [key]: checkedValues,
            }));
        },
        [setSelectedValue, setFilteredInfo]
    );

    const items = useMemo(() => {
        const menuItems = [];

        if (filterOptions.state) {
            menuItems.push({
                label: <Typography.Text style={{ ...textStandard }}>{t('fields.state')}</Typography.Text>,
                key: 'state',
                children: (
                    <Checkbox.Group
                        style={{ width: '100%' }}
                        onChange={checkedValues => onChange(checkedValues, 'state')}
                        defaultValue={selectedValue.state}
                        value={checkedList.state}
                    >
                        {filterOptions.state.map((option, index) => (
                            <Checkbox key={index} value={option.value}>
                                {option.text}
                            </Checkbox>
                        ))}
                    </Checkbox.Group>
                ),
                extra: selectedValue.state && getExtra(selectedValue.state, 'state'),
            });
        }

        if (filterOptions.status) {
            menuItems.push({
                label: <Typography.Text style={{ ...textStandard }}>{t('fields.status')}</Typography.Text>,
                key: 'status',
                children: (
                    <Checkbox.Group
                        style={{ width: '100%' }}
                        onChange={checkedValues => onChange(checkedValues, 'status')}
                        defaultValue={selectedValue.status}
                        value={checkedList.status}
                    >
                        {filterOptions.status.map((option, index) => (
                            <Checkbox key={index} value={option.value}>
                                {option.text}
                            </Checkbox>
                        ))}
                    </Checkbox.Group>
                ),
                extra: selectedValue.status && getExtra(selectedValue.status, 'status'),
            });
        }

        if (filterOptions.roles) {
            menuItems.push({
                label: <Typography.Text style={{ ...textStandard }}>{t('members:fields.role')}</Typography.Text>,
                key: 'roles',
                children: (
                    <Checkbox.Group
                        style={{ width: '100%' }}
                        onChange={checkedValues => onChange(checkedValues, 'roles')}
                        defaultValue={selectedValue.roles}
                        value={checkedList.roles}
                    >
                        {filterOptions.roles.map((option, index) => (
                            <Checkbox key={index} value={option.value}>
                                {option.text}
                            </Checkbox>
                        ))}
                    </Checkbox.Group>
                ),
                extra: selectedValue.roles && getExtra(selectedValue.roles, 'roles'),
            });
        }

        if (filterOptions.business) {
            menuItems.push({
                label: <Typography.Text style={{ ...textStandard }}>{t('fields.business')}</Typography.Text>,
                key: 'business',
                children: (
                    <CustomSelect
                        mode="multiple"
                        maxTagCount="responsive"
                        style={{ width: isExtraSmallDevice ? '100%' : 'clamp(250px, 70vw, 450px)' }}
                        onChange={value => {
                            setSelectedValue(prev => ({
                                ...prev,
                                business: value,
                            }));
                            setFilteredInfo(prevState => ({
                                ...prevState,
                                business: value,
                            }));
                        }}
                        value={selectedValue.business}
                        size="large"
                        placeholder={t('form:placeholder.business')}
                    >
                        {filterOptions.business.map((option, index) => (
                            <Select.Option key={index} value={option.value}>
                                {option.text}
                            </Select.Option>
                        ))}
                    </CustomSelect>
                ),
                extra: selectedValue.business && getExtra(selectedValue.business, 'business'),
            });
        }

        return menuItems;
    }, [checkedList, filterOptions, getExtra, onChange, selectedValue]);

    const handleClearAll = () => {
        setSelectedValue({});
        setSelectedFilters({});
        setCheckedList({
            state: [],
            status: [],
        });
    };

    const handleApplyFilters = () => {
        setFilteredInfo(selectedValue);
        closeDrawer();
    };

    return (
        <Drawer
            placement="bottom"
            width={555}
            onClose={closeDrawer}
            closable={false}
            open={open}
            styles={{
                wrapper: {
                    height: '82vh',
                    width: '100vw',
                },
                content: {
                    borderRadius: '20px 20px 0 0 ',
                },
                body: {
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    padding: `${spacing[4]} ${spacing[3]}`,
                    backgroundColor: primitiveColors.gray25,
                },
            }}
            className={`${classes['mobile-filters']}`}
        >
            <Flex vertical>
                <Flex align="center" justify="space-between" gap={spacing[1]}>
                    <Typography.Text
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            gap: spacing[1],
                            fontSize: fontSize.lg,
                            fontWeight: 600,
                            lineHeight: lineHeight.xs,
                        }}
                    >
                        <i className={`fi fi-rr-settings-sliders ${icon.sm}`} style={{ marginTop: 3 }} />
                        {t('actions.filters')}
                    </Typography.Text>
                    <ButtonIcon
                        onClick={closeDrawer}
                        icon="fi-rr-cross"
                        style={{
                            position: 'static',
                        }}
                    />
                </Flex>
                <Collapse
                    defaultActiveKey={['state', 'status', 'business']}
                    bordered={false}
                    size="small"
                    expandIconPosition="end"
                    ghost
                    style={{ marginTop: spacing[5], backgroundColor: 'inherit' }}
                    expandIcon={({ isActive }) => {
                        return (
                            <Flex justify="end" align="center" style={{ marginRight: spacing[1] }}>
                                <i
                                    className={classNames({
                                        'fi fi-rr-angle-small-up': isActive,
                                        'fi fi-rr-angle-small-down': !isActive,
                                        [icon.md]: true,
                                    })}
                                    style={{
                                        fontSize: '1rem',
                                        color: isActive ? coreBrandColors.blueRibbon : primitiveColors.gray900,
                                    }}
                                />
                            </Flex>
                        );
                    }}
                    items={items}
                />
            </Flex>
            <Flex justify="space-between" style={{ paddingTop: `${spacing[3]}` }}>
                <Button
                    type="text"
                    style={{
                        ...buttonStyle.small,
                    }}
                    onClick={handleClearAll}
                >
                    {t('actions.clear-all')}
                </Button>
                <Button
                    type="primary"
                    style={{
                        ...buttonStyle.small,
                    }}
                    onClick={handleApplyFilters}
                >
                    {t('actions.apply-filters')}
                </Button>
            </Flex>
        </Drawer>
    );
}
