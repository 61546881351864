import { Empty, List } from 'antd';
import { Log } from './Log';
import { useTranslation } from 'react-i18next';

export const LogsList = ({ logs, entityName, loading }) => {
    const { t } = useTranslation(['general']);

    return (
        <div>
            <List
                bordered={false}
                dataSource={logs}
                renderItem={log => (
                    <List.Item key={log.id} style={{ border: 'none' }}>
                        <Log log={log} entityName={entityName} />
                    </List.Item>
                )}
                locale={{
                    emptyText: !loading ? <Empty description={t('general:no-data')} /> : ' ',
                }}
            />
        </div>
    );
};
