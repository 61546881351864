import dayjs from 'dayjs';

export const sortDates = (a, b) => {
    const dateA = dayjs(a);
    const dateB = dayjs(b);

    if (dateA.isBefore(dateB)) return -1;
    if (dateA.isAfter(dateB)) return 1;
    return 0;
};
