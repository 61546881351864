import { Modal, Flex, Typography, Button } from 'antd';
import { spacing, buttonStyle, coreBrandColors } from './../../theming';
import classes from './style.module.scss';

const { Text, Title } = Typography;

function SkipInvitationModal({ isModalOpen, modalHandler, navigationHandler, t }) {
    return (
        <Modal
            open={isModalOpen}
            closable={false}
            okButtonProps={{
                style: { display: 'none' },
            }}
            cancelButtonProps={{
                style: { display: 'none' },
            }}
            centered
            styles={{
                content: {
                    margin: spacing[2],
                },
                body: {
                    padding: `${spacing[5]} ${spacing[4]}`,
                },
            }}
            className={classes.modal}
        >
            <Flex vertical gap={spacing[2]} align="start">
                <Title className={classes['modal-title']}>{t('skip-modal-title')}</Title>
                <Text className={classes['modal-text']}>{t('skip-modal-sub')}</Text>
                <Flex
                    gap={spacing[2]}
                    justify="end"
                    style={{
                        width: '100%',
                    }}
                >
                    <Button
                        onClick={modalHandler}
                        style={{
                            ...buttonStyle.medium,
                            borderColor: coreBrandColors.blueRibbon,
                            color: coreBrandColors.blueRibbon,
                        }}
                    >
                        {t('buttons.cancel')}
                    </Button>
                    <Button
                        type="primary"
                        danger
                        onClick={() => {
                            navigationHandler();
                            modalHandler();
                        }}
                        style={{
                            ...buttonStyle.medium,
                        }}
                    >
                        {t('buttons.skip-step')}
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    );
}

export default SkipInvitationModal;
