import React from 'react';
import { Line } from 'react-chartjs-2';
import { chartModesConstants } from '../constants/chartModes';
import 'chart.js/auto';
import { chartColors } from '../constants/chartColors';
import dayjs from 'dayjs';
import { primitiveColors } from '../../theming';
import classes from './LineChart.module.scss';
import { useTranslation } from 'react-i18next';
import { useMedia } from '../../hooks/useMediaQuery';

export const LineChart = ({ chartData, mode }) => {
    const { i18n } = useTranslation();

    const { isExtraSmallDevice } = useMedia();

    const currentLanguage = i18n.language;

    let maxDataValue = 0;

    // calculating max value in the datasets
    chartData.datasets.forEach(dataset => {
        dataset.data.forEach(value => {
            if (value > maxDataValue) {
                maxDataValue = value;
            }
        });
    });

    function calculateOptimalStepSize(maxDataValue) {
        const potentialSteps = [1, 2, 5, 10, 20, 25, 50, 100, 200, 500, 1000, 2000, 3000, 4000, 5000];
        const targetSteps = 6;

        for (let i = 0; i < potentialSteps.length; i++) {
            const step = potentialSteps[i];
            if (maxDataValue / step <= targetSteps) {
                return step;
            }
        }

        return potentialSteps[potentialSteps.length - 1];
    }

    // Function to create or get the tooltip element
    function getOrCreateTooltip(chart) {
        let tooltipEl = chart.canvas.parentNode.querySelector('div.chartjs-tooltip');

        if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.classList.add('chartjs-tooltip');
            tooltipEl.innerHTML =
                mode === chartModesConstants.Analysis
                    ? `<div id='tooltip-root'></div>`
                    : `<div class=${classes['tooltip-root']} id='tooltip-root'></div>`;
            tooltipEl.style.backgroundColor = 'rgba(255, 255, 255, 0.8)';
            tooltipEl.style.borderRadius = '10px';
            tooltipEl.style.boxShadow = '0 3px 6px rgba(0, 0, 0, 0.1)';
            tooltipEl.style.opacity = 1;
            tooltipEl.style.pointerEvents = 'none';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.transform = 'translate(-50%, 0)';
            tooltipEl.style.transition = 'all .1s ease';
            tooltipEl.style.zIndex = 1;
            tooltipEl.style.font = 'Inter';
            tooltipEl.style.padding = '12px';

            chart.canvas.parentNode.appendChild(tooltipEl);
        }

        return tooltipEl;
    }

    const createSpan = color => {
        const span = document.createElement('span');
        span.classList.add(classes['tooltip-span']);
        span.style.backgroundColor = color;
        return span;
    };

    const externalTooltipHandler = context => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set Text
        if (tooltip.body) {
            const titleLines = tooltip.title || [];
            const bodyLines = tooltip.body.map(b => b.lines);

            const titleWrapper = document.createElement('div');
            titleWrapper.classList.add(classes['tooltip-title-wrapper']);
            titleWrapper.appendChild(
                document.createTextNode(
                    titleLines[0].split('-').length === 2
                        ? `${dayjs(titleLines[0]).format('MMM')} ${titleLines[0].split('-')[0]}`
                        : titleLines[0]
                )
            );

            const wrapper = document.createElement('div');
            wrapper.classList.add(classes['tooltip-fit-content']);

            wrapper.appendChild(titleWrapper);

            bodyLines.forEach((body, i) => {
                const labelText = body[0].split(/:\s*/);

                const textWrapper = document.createElement('div');
                textWrapper.classList.add(classes['tooltip-text-wrapper']);

                const labelWrapper = document.createElement('div');
                labelWrapper.classList.add(classes['tooltip-wrapper']);

                const textElement = document.createElement('span');
                textElement.classList.add(classes['tooltip-text-element']);
                textElement.appendChild(document.createTextNode(`${labelText[0]}:`));

                const elementValue = document.createElement('span');
                elementValue.classList.add(classes['tooltip-element-value']);
                elementValue.textContent = labelText[1];

                labelWrapper.appendChild(createSpan(chartColors[i]));

                labelWrapper.appendChild(textElement);
                textWrapper.appendChild(labelWrapper);
                textWrapper.appendChild(elementValue);

                wrapper.appendChild(textWrapper);
            });

            // Append the table head and body to your tooltip container as needed

            const tableRoot = tooltipEl.querySelector('#tooltip-root');

            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.appendChild(wrapper);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position, and set styles for font
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    };

    const externalTooltipHandlerComparison = context => {
        // Tooltip Element
        const { chart, tooltip } = context;
        const tooltipEl = getOrCreateTooltip(chart);

        // Hide if no tooltip
        if (tooltip.opacity === 0) {
            tooltipEl.style.opacity = 0;
            return;
        }

        // Set Text
        if (tooltip.body) {
            const titles = tooltip.title[0].split('#') || [];
            const bodyLines = tooltip.body.map(b => b.lines);

            const firstTitleWrapper = document.createElement('div');
            firstTitleWrapper.classList.add(classes['tooltip-title-wrapper']);
            firstTitleWrapper.appendChild(
                document.createTextNode(
                    titles[0].split('-').length === 2
                        ? `${dayjs(titles[0]).format('MMM')} ${titles[0].split('-')[0]}`
                        : titles[0]
                )
            );

            const secondTitleWrapper = document.createElement('div');
            secondTitleWrapper.classList.add(classes['tooltip-title-wrapper']);
            secondTitleWrapper.appendChild(
                document.createTextNode(
                    titles[1].split('-').length === 2
                        ? `${dayjs(titles[1]).format('MMM')} ${titles[1].split('-')[0]}`
                        : titles[1]
                )
            );

            const leftWrapper = document.createElement('div');
            leftWrapper.classList.add(classes['tooltip-fit-content']);

            const rightWrapper = document.createElement('div');
            rightWrapper.classList.add(classes['tooltip-fit-content']);

            leftWrapper.appendChild(firstTitleWrapper);
            rightWrapper.appendChild(secondTitleWrapper);
            bodyLines.sort((a, b) => a[0].localeCompare(b[0]));

            bodyLines.forEach((body, i) => {
                const labelText = body[0].split(/:\s*/);

                const textWrapper = document.createElement('div');
                textWrapper.classList.add(classes['tooltip-text-wrapper']);

                const wrapper = document.createElement('div');
                wrapper.classList.add(classes['tooltip-wrapper']);

                const contentWrapper = document.createElement('div');
                contentWrapper.classList.add(classes['tooltip-content-wrapper']);

                const textElement = document.createElement('span');
                textElement.classList.add(classes['tooltip-text-element']);
                textElement.appendChild(document.createTextNode(`${labelText[0]}:`));

                const elementValue = document.createElement('span');
                elementValue.classList.add(classes['tooltip-element-value']);
                elementValue.textContent = labelText[1];

                if (i % 2 === 0) {
                    wrapper.appendChild(createSpan(chartColors[Math.floor(i / 2)]));
                } else {
                    const dashedLine = document.createElement('div');
                    dashedLine.classList.add(classes['tooltip-dashed-line']);
                    dashedLine.appendChild(createSpan(chartColors[Math.floor(i / 2)]));
                    dashedLine.appendChild(createSpan(chartColors[Math.floor(i / 2)]));
                    wrapper.appendChild(dashedLine);
                }

                wrapper.appendChild(textElement);
                textWrapper.appendChild(wrapper);
                textWrapper.appendChild(elementValue);

                if (i % 2 === 0) {
                    leftWrapper.appendChild(textWrapper, contentWrapper);
                } else {
                    rightWrapper.appendChild(textWrapper, contentWrapper);
                }
            });

            const tableRoot = tooltipEl.querySelector('#tooltip-root');

            // Remove old children
            while (tableRoot.firstChild) {
                tableRoot.firstChild.remove();
            }

            // Add new children
            tableRoot.appendChild(leftWrapper);
            tableRoot.appendChild(rightWrapper);
        }

        const { offsetLeft: positionX, offsetTop: positionY } = chart.canvas;

        // Display, position
        tooltipEl.style.opacity = 1;
        tooltipEl.style.left = positionX + tooltip.caretX + 'px';
        tooltipEl.style.top = positionY + tooltip.caretY + 'px';
    };

    const quartalsLabels = [];

    function formatDateToNumber(label) {
        const date = dayjs(label);
        const parts = label.split('-');

        if (parts.length === 2 && chartData.labels.length <= 9) {
            // this will work if passes date is a month date (2024-05)
            return date.format('MMM');
        } else if (parts.length === 2 && chartData.labels.length > 9) {
            const month = parseInt(parts[1], 10);

            let quartal = '';

            if (month >= 1 && month <= 3) {
                quartal = `Q1/${parts[0]}`;
            } else if (month >= 4 && month <= 6) {
                quartal = `Q2/${parts[0]}`;
            } else if (month >= 7 && month <= 9) {
                quartal = `Q3/${parts[0]}`;
            } else if (month >= 10 && month <= 12) {
                quartal = `Q4/${parts[0]}`;
            }

            if (quartalsLabels.includes(quartal)) {
                return '';
            }

            quartalsLabels.push(quartal);

            return quartal.split('/');
        } else if (parts.length === 3) {
            // day date contains 3 parts (19-07-2024)
            const day = parseInt(parts[0], 10);
            return day.toString();
        }

        return label; // this will work if we pass week date to func (15/06)
    }

    const rangeChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: isExtraSmallDevice ? 'start' : 'center',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    boxHeight: 0.5,
                    pointStyleWidth: 16,
                    padding: isExtraSmallDevice ? 12 : 24,
                    color: primitiveColors.gray500,
                    font: {
                        family: 'Inter',
                        size: 12,
                        weight: 400,
                    },
                },
            },
            title: {
                display: false,
            },
            tooltip: {
                enabled: false,
                position: 'average',
                external: externalTooltipHandler,
            },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                beginAtZero: true,
                ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    color: primitiveColors.gray500,
                    font: {
                        size: 12,
                        family: 'Inter',
                        weight: 400,
                    },
                    callback: function (value, index, values) {
                        return formatDateToNumber(chartData.labels[index]);
                    },
                },
                border: {
                    display: false,
                },
            },
            y: {
                border: { dash: [5, 5], display: false },
                grid: {
                    color: primitiveColors.gray50,
                    tickColor: 'transparent',
                    tickBorderDash: [5, 1],
                    tickLength: 8,
                    tickWidth: 1,
                    drawTicks: true,
                },
                beginAtZero: true,
                ticks: {
                    color: primitiveColors.gray400,
                    font: {
                        size: 12,
                        family: 'Inter',
                        weight: 500,
                    },
                    precision: 0,
                    stepSize: calculateOptimalStepSize(maxDataValue),
                    callback: function (value) {
                        if (Number.isInteger(value)) {
                            return value;
                        }
                    },
                },
                max: calculateOptimalStepSize(maxDataValue) * 6,
                borderColor: 'transparent',
                borderWidth: 0,
            },
        },
        elements: {
            point: {
                radius: 0,
                hoverRadius: 10,
                hoverBorderWidth: 3.67,
            },
            line: {
                tension: 0.4,
                borderWidth: 1,
            },
        },
    };

    const comparisonChartOptions = {
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
            legend: {
                display: true,
                position: 'bottom',
                align: 'center',
                labels: {
                    usePointStyle: true,
                    pointStyle: 'rectRounded',
                    boxHeight: 0.5,
                    pointStyleWidth: 16,
                    padding: 24,
                    color: primitiveColors.gray500,
                    font: {
                        family: 'Inter',
                        size: 12,
                        weight: 400,
                    },
                    generateLabels: function (chart) {
                        const data = chart.data;
                        if (data.datasets.length) {
                            return data.datasets.map((dataset, i) => {
                                const pointStyle =
                                    dataset.borderDash && dataset.borderDash.length ? 'line' : 'rectRounded';
                                return {
                                    text: dataset.label,
                                    fillStyle: dataset.borderColor,
                                    hidden: !chart.isDatasetVisible(i),
                                    lineCap: 'round',
                                    lineDash: pointStyle === 'line' ? [4, 4] : [],
                                    lineDashOffset: dataset.borderDashOffset,
                                    lineJoin: dataset.borderJoinStyle,
                                    lineWidth: dataset.borderWidth,
                                    strokeStyle: dataset.borderColor,
                                    pointStyle: pointStyle,
                                    datasetIndex: i,
                                };
                            });
                        }
                        return [];
                    },
                },
            },
            title: {
                display: false,
            },
            tooltip: {
                enabled: false,
                position: 'nearest',
                external: externalTooltipHandlerComparison,
            },
        },
        interaction: {
            mode: 'index',
            intersect: false,
        },
        scales: {
            x: {
                grid: {
                    display: false,
                },
                beginAtZero: true,
                ticks: {
                    autoSkip: true,
                    maxRotation: 0,
                    color: primitiveColors.gray50,
                    font: {
                        size: 12,
                        fontWeight: 500,
                    },
                    display: false,
                },
                border: {
                    display: false,
                },
            },
            y: {
                border: { dash: [5, 5], display: false },
                grid: {
                    color: primitiveColors.gray50,
                    tickColor: 'transparent',
                    tickBorderDash: [5, 1],
                    tickLength: 8,
                    tickWidth: 1,
                    drawTicks: true,
                },
                beginAtZero: true,
                ticks: {
                    color: primitiveColors.gray400,
                    font: {
                        size: 12,
                        fontWeight: 400,
                    },
                    precision: 0,

                    stepSize: calculateOptimalStepSize(maxDataValue),
                    callback: function (value) {
                        if (Number.isInteger(value)) {
                            return value;
                        }
                    },
                },
                max: calculateOptimalStepSize(maxDataValue) * 6,
                borderColor: 'transparent',
                borderWidth: 0,
            },
        },
        elements: {
            point: {
                radius: 0,
                hoverRadius: 10,
                hoverBorderWidth: 3.67,
            },
            line: {
                tension: 0.4,
                borderWidth: 1,
            },
        },
    };

    return (
        <div style={{ width: '100%', height: '100%', maxWidth: '100%', maxHeight: '100%' }}>
            <Line
                key={currentLanguage}
                data={chartData}
                options={mode === chartModesConstants.Analysis ? rangeChartOptions : comparisonChartOptions}
                plugins={[
                    {
                        afterDraw: chart => {
                            if (chart.tooltip?._active?.length) {
                                let x = chart.tooltip._active[0].element.x;
                                let yAxis = chart.scales.y;
                                let ctx = chart.ctx;

                                ctx.save();
                                ctx.setLineDash([8, 7]);
                                ctx.beginPath();
                                ctx.moveTo(x, yAxis.top);
                                ctx.lineTo(x, yAxis.bottom);
                                ctx.lineWidth = 1;
                                ctx.strokeStyle = primitiveColors.gray100;
                                ctx.stroke();
                                ctx.restore();
                            }
                        },
                    },
                ]}
            />
        </div>
    );
};
