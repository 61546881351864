import { Flex, Typography } from 'antd';
import { fontSize, primitiveColors, spacing, textStandard } from '../../../theming';
import dayjs from 'dayjs';
import { auditLogActions } from '../../constants/auditLogActions';
import CustomAvatar from '../Avatar';
import { camelCaseToDashed } from '../../../utils/camelCaseToDashed';
import { useTranslation } from 'react-i18next';
import classes from './Log.module.scss';
import 'dayjs/locale/en';
import 'dayjs/locale/uk';

export const Log = ({ log, entityName }) => {
    const { t, i18n } = useTranslation(['general', 'businesses', 'leads', 'form', 'log']);

    const getDefaultLogMessage = (action, log) => {
        switch (action) {
            case auditLogActions.CREATE_BUSINESS:
            case auditLogActions.CREATE_LEAD:
                return `${t('actions.thirdPersonPast.create')} ${t(`${entityName}:singular-with-article`)}`;
            case auditLogActions.DELETE_BUSINESS:
            case auditLogActions.DELETE_LEAD:
                return `${t('actions.thirdPersonPast.delete')} ${t(`${entityName}:singular-with-article`)}`;
            case auditLogActions.RESTORE_BUSINESS:
            case auditLogActions.RESTORE_LEAD:
                return `${t('actions.thirdPersonPast.restore')} ${t(`${entityName}:singular-with-article`)}`;
            case auditLogActions.ARCHIVE_BUSINESS:
            case auditLogActions.ARCHIVE_LEAD:
                return `${t('actions.thirdPersonPast.archive')} ${t(`${entityName}:singular-with-article`)}`;
            case auditLogActions.DELETE_BUSINESS_COMMENT:
            case auditLogActions.DELETE_LEAD_COMMENT:
                return `${t('actions.thirdPersonPast.delete')} ${t('comment')}: '${log.changedObjects.previous.content}'`;
            case auditLogActions.ASSIGN_LEAD_TO_BUSINESS:
                return `${t('actions.thirdPersonPast.assign')} ${t('log:lead.genitive')} - ${log.changedObjects.current.firstName} ${log.changedObjects.current.lastName}`;
            case auditLogActions.UNASSIGN_LEAD_TO_BUSINESS:
                return `${t('actions.thirdPersonPast.unassign')} ${t('log:lead.genitive')} - ${log.changedObjects.previous.firstName} ${log.changedObjects.previous.lastName}`;

            default:
                return;
        }
    };

    const getUpdateLogMessage = (logObject, parentKey, childKey) => {
        const prevValue = childKey
            ? logObject.changedObjects.previous[parentKey]?.[childKey]
            : logObject.changedObjects.previous[parentKey];

        const currValue = childKey
            ? logObject.changedObjects.current[parentKey]?.[childKey]
            : logObject.changedObjects.current[parentKey];

        const currentLog = childKey
            ? logObject.changedObjects.current[parentKey][childKey]
            : logObject.changedObjects.current[parentKey];

        const previousLog = childKey
            ? logObject.changedObjects.previous[parentKey][childKey]
            : logObject.changedObjects.previous[parentKey];

        if (childKey) {
            if (!prevValue) {
                return `${t('actions.thirdPersonPast.add')} ${i18n.language === 'uk' ? t('field') : t(`form:${camelCaseToDashed(childKey)}`)} ${i18n.language === 'uk' ? t(`form:${camelCaseToDashed(childKey)}`) : t('field')} - '${currentLog}'`;
            }
            if (!currValue) {
                return `${t('actions.thirdPersonPast.remove')} '${previousLog}' ${t('prepositions.from').toLowerCase()} ${t(`form:${camelCaseToDashed(childKey)}`)}`;
            }
            return `${t('actions.thirdPersonPast.change')} ${i18n.language === 'uk' ? t('field') : t(`form:${camelCaseToDashed(childKey)}`)} ${i18n.language === 'uk' ? t(`form:${camelCaseToDashed(childKey)}`) : t('field')} ${t('prepositions.from').toLowerCase()} '${previousLog}' ${t('prepositions.to-as-on')} '${currentLog}'`;
        }

        if (!prevValue) {
            if (parentKey === 'photo') {
                return `${t('actions.thirdPersonPast.add')} ${i18n.language === 'uk' ? t('log:photo') : t(`${entityName}:singular-dative`)} ${i18n.language === 'uk' ? t(`${entityName}:singular-dative`) : t('log:photo')}`;
            }
            if (parentKey === 'contactPerson') {
                const { firstName, lastName } = logObject.changedObjects.current[parentKey];
                return `${t('actions.thirdPersonPast.add')} ${firstName} ${lastName} ${t('prepositions.as')} ${t('log:contact-person.accusative')}`;
            }
            return `${t('actions.thirdPersonPast.add')} ${i18n.language === 'uk' ? t('field') : t(`form:${camelCaseToDashed(parentKey)}`)} ${i18n.language === 'uk' ? t(`form:${camelCaseToDashed(parentKey)}`) : t('field')} - '${currentLog}'`;
        }

        if (!currValue) {
            if (parentKey === 'photo') {
                return `${t('actions.thirdPersonPast.remove')} ${i18n.language === 'uk' ? t('log:photo') : t(`${entityName}:singular-dative`)} ${i18n.language === 'uk' ? t(`${entityName}:singular-dative`) : t('log:photo')}`;
            }
            if (parentKey === 'contactPerson') {
                const { firstName, lastName } = logObject.changedObjects.previous[parentKey];
                return `${t('actions.thirdPersonPast.remove')} ${firstName} ${lastName} ${t('prepositions.as')} ${t('log:contact-person.accusative')}`;
            }
            return `${t('actions.thirdPersonPast.remove')} '${previousLog}' ${t('prepositions.from').toLowerCase()} ${t(`form:${camelCaseToDashed(parentKey)}`)}`;
        }

        if (parentKey === 'content') {
            return `${t('actions.thirdPersonPast.change')} ${i18n.language === 'uk' ? t(`form:${camelCaseToDashed(parentKey)}`) : t('log:comment.genitive')} ${i18n.language === 'uk' ? t('log:comment.genitive') : t(`form:${camelCaseToDashed(parentKey)}`)} ${t('prepositions.from').toLowerCase()} '${previousLog}' ${t('prepositions.to-as-on')} '${currentLog}'`;
        }

        return `${t('actions.thirdPersonPast.change')} ${i18n.language === 'uk' ? t('field') : t(`form:${camelCaseToDashed(parentKey)}`)} ${i18n.language === 'uk' ? t(`form:${camelCaseToDashed(parentKey)}`) : t('field')} ${t('prepositions.from').toLowerCase()} '${previousLog}' ${t('prepositions.to-as-on')} '${currentLog}'`;
    };

    const isUpdateLog =
        log.action === auditLogActions.UPDATE_BUSINESS ||
        log.action === auditLogActions.UPDATE_LEAD ||
        log.action === auditLogActions.EDIT_BUSINESS_COMMENT ||
        log.action === auditLogActions.EDIT_LEAD_COMMENT;

    return (
        <Flex vertical key={log.id} style={{ width: '100%' }}>
            <Typography.Text
                style={{
                    color: primitiveColors.gray400,
                    fontSize: fontSize.xxs,
                    fontWeight: 500,
                }}
            >
                {dayjs(log.createdAt)
                    .locale(i18n.language)
                    .format(`MMM D YYYY [${t('general:prepositions.at')}] HH:mm`)}
            </Typography.Text>
            <Flex gap={spacing[1]} vertical>
                {isUpdateLog ? (
                    <ul style={{ listStyle: 'none', paddingLeft: spacing[1] }}>
                        {Object.keys(log.changedObjects.difference).map(key => {
                            if (typeof log.changedObjects.difference[key] === 'object') {
                                return Object.keys(log.changedObjects.difference[key]).map(childKey => (
                                    <li key={childKey} className={classes['list-item']}>
                                        <Typography.Text
                                            style={{
                                                ...textStandard,
                                                fontSize: fontSize.xs,
                                                color: primitiveColors.gray700,
                                            }}
                                        >
                                            {getUpdateLogMessage(log, key, childKey)}
                                        </Typography.Text>
                                    </li>
                                ));
                            } else {
                                return (
                                    <li key={key} className={classes['list-item']}>
                                        <Typography.Text
                                            style={{
                                                ...textStandard,
                                                fontSize: fontSize.xs,
                                                color: primitiveColors.gray700,
                                            }}
                                        >
                                            {getUpdateLogMessage(log, key)}
                                        </Typography.Text>
                                    </li>
                                );
                            }
                        })}
                    </ul>
                ) : (
                    <Typography.Text
                        style={{
                            fontSize: fontSize.xs,
                            fontWeight: 500,
                            color: primitiveColors.gray700,
                        }}
                    >
                        {getDefaultLogMessage(log.action, log)}
                    </Typography.Text>
                )}

                <Flex align="center" gap={spacing[1]}>
                    <CustomAvatar
                        key={log.id}
                        size={24}
                        photoName={log.user?.photo}
                        firstName={log.user?.firstName}
                        lastName={log.user?.lastName}
                        controller={'users'}
                        style={{ fontSize: fontSize.xxs }}
                    />

                    <Typography.Text
                        style={{
                            fontSize: fontSize.xxs,
                            fontWeight: 500,
                            color: primitiveColors.gray700,
                        }}
                    >
                        {`${log.user ? log.user.firstName : log.userEmail} ${log.user ? log.user.lastName : ''}`}
                    </Typography.Text>

                    {!log.user && (
                        <Typography.Text
                            style={{ ...textStandard, fontSize: fontSize.xxs, color: primitiveColors.gray200 }}
                        >
                            {`(${t('deleted')})`}
                        </Typography.Text>
                    )}
                </Flex>
            </Flex>
        </Flex>
    );
};
