import { useAxios } from './axiosService';

export const BusinessesService = () => {
    const { axios } = useAxios();

    const getAllActiveBusinesses = async () => {
        const response = await axios.get('/businesses');

        return response.data;
    };

    const getAllArchivedBusinesses = async () => {
        const response = await axios.get('/businesses/archived');

        return response.data;
    };

    const getBusinessById = async id => {
        const response = await axios.get(`/businesses/${id}`, { id });

        return response.data;
    };

    const getMembersWithoutBusiness = async () => {
        const response = await axios.get('/businesses/members-without-business');

        return response.data;
    };

    const createBusiness = async body => {
        const response = await axios.post(`/businesses`, body);

        return response.data;
    };

    const updateBusiness = async (businessId, body) => {
        const response = await axios.put(`/businesses/${businessId}`, body);

        return response.data;
    };

    const deleteBusinesses = async ids => {
        await axios.delete('/businesses/', {
            data: { ids },
        });
    };

    const archiveBusinesses = async ids => {
        await axios.patch('/businesses/archive', { ids });
    };

    const unarchiveBusinesses = async ids => {
        await axios.patch('/businesses/restore', { ids });
    };

    const getBusinessComments = async (id, skip, take) => {
        const response = await axios.get(`/businesses/comments/${id}?skip=${skip}&take=${take}`);

        return response.data;
    };

    const leaveBusinessComment = async (id, content) => {
        const response = await axios.post(`/businesses/comments/${id}`, { content });

        return response.data;
    };

    const deleteBusinessComment = async id => {
        await axios.delete(`/businesses/comments/${id}`);
    };

    const editBusinessComment = async (id, content) => {
        const response = await axios.put(`/businesses/comments/${id}`, { content });

        return response.data;
    };

    const getBusinessLogs = async (id, skip, take) => {
        const response = await axios.get(`/businesses/logs/${id}?skip=${skip}&take=${take}`);

        return response.data;
    };

    const getBusinessLogsAndComments = async (id, logsSkip, logsTake, commentsSkip, commentsTake) => {
        const response = await axios.get(
            `/businesses/logs-and-comments/${id}?logsSkip=${logsSkip}&logsTake=${logsTake}&commentsSkip=${commentsSkip}&commentsTake=${commentsTake}`
        );

        return response.data;
    };

    return {
        getAllActiveBusinesses,
        getAllArchivedBusinesses,
        getBusinessById,
        getMembersWithoutBusiness,
        deleteBusinesses,
        archiveBusinesses,
        unarchiveBusinesses,
        updateBusiness,
        createBusiness,
        getBusinessComments,
        leaveBusinessComment,
        deleteBusinessComment,
        editBusinessComment,
        getBusinessLogs,
        getBusinessLogsAndComments,
    };
};
