export const lineChartGradients = [
    {
        startColor: "rgba(0, 101, 255, 0.4)",
        stopColor: "rgba(0, 101, 255, 0)",
    },

    {
        startColor: "rgba(0, 214, 187, 0.4)",
        stopColor: "rgba(0, 214, 187, 0)",
    },

    {
        startColor: "rgba(168, 118, 250, 0.4)",
        stopColor: "rgba(168, 118, 250, 0)",
    },

    {
        startColor: "rgba(253, 126, 20, 0.4)",
        stopColor: "rgba(253, 126, 20, 0)",
    },

    {
        startColor: "rgba(232, 62, 140, 0.4)",
        stopColor: "rgba(232, 62, 140, 0)",
    },
]