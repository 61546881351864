import { spacing } from '../../../theming';
import icon from '../../styles/Icon.module.scss';

export default function SearchIcon({ onClick }) {
    return (
        <span style={{ marginRight: spacing[2], paddingTop: spacing[0.5] }} onClick={onClick}>
            <i className={`fi fi-rr-search ${icon['secondary-hover']}`} />
        </span>
    );
}
