import { Drawer, Flex } from 'antd';
import Title from 'antd/es/typography/Title';
import ButtonIcon from '../buttonIcon/ButtonIcon';
import classes from './SideDrawer.module.scss';
import { useMedia } from '../../../hooks/useMediaQuery';
import { fontSize, spacing } from '../../../theming';

export default function SideDrawer({ title, open, closeDrawer, children, width, padding }) {
    const { isSmallDevice } = useMedia();
    return (
        <Drawer
            width={isSmallDevice ? 'clamp(250px, 95vw, 373px)' : width ? width : '555px'}
            onClose={closeDrawer}
            closable={false}
            open={open}
            styles={{
                body: {
                    padding: isSmallDevice ? spacing[4] : padding ? padding : spacing[8],
                    position: 'relative',
                },
                content: {
                    borderRadius: '20px 0 0 20px',
                },
            }}
        >
            <Flex vertical>
                <ButtonIcon
                    onClick={closeDrawer}
                    icon="fi-rr-cross"
                    style={{ position: isSmallDevice && 'static', placeSelf: 'end' }}
                />
                {title && (
                    <Title
                        className={classes.title}
                        style={{ fontSize: isSmallDevice && fontSize.H4, marginBottom: spacing[5] }}
                    >
                        {title}
                    </Title>
                )}
                {children}
            </Flex>
        </Drawer>
    );
}
