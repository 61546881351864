import { Button, Flex, Form, Input, Radio } from 'antd';
import PhoneInput from 'react-phone-input-2';
import { teamRole } from '../../common/constants/teamRole';
import { useTranslation } from 'react-i18next';
import { spacing } from '../../theming';
import { useEffect, useState } from 'react';
import classes from '../../common/styles/FormStyles.module.scss';
import classNames from 'classnames';
import globalNotification from '../../common/hooks/globalNotification';
import { notifications } from '../../common/constants/notifications';
import { teamRolesPriority } from '../../common/constants/TeamRolesPriority';
import getInputRules, { inputType } from '../../utils/getInputRules';

export const MembershipForm = ({ onFinish, member, closeDrawer }) => {
    const { t, i18n } = useTranslation(['members', 'invitations', 'general', 'membersForm']);
    const [form] = Form.useForm();
    const [loading, setLoading] = useState(false);
    const [phoneError, setPhoneError] = useState(false);

    useEffect(() => {
        if (member) {
            form.setFieldsValue({
                firstName: member.firstName,
                lastName: member.lastName,
                email: member.email,
                phone: member.phoneNumber,
                role: member.teamRole,
            });
        }
    }, [member, form]);

    const handleFormSubmit = async data => {
        const payload = data;
        if (member) {
            payload.id = member.membershipId;
        }

        try {
            await onFinish(payload, () => form.resetFields());

            closeDrawer();
        } catch {
            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.MEMBERS,
                action: member ? notifications.action.UPDATE : notifications.action.ADD,
                plural: false,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <div
            style={{
                height: 240,
                display: 'flex',
                justifyContent: 'space-around',
                flexDirection: 'column',
            }}
        >
            <Form
                form={form}
                labelCol={{ span: 24 }}
                wrapperCol={{ span: 24 }}
                style={{ width: '100%' }}
                onFinish={event => handleFormSubmit(event)}
            >
                <Flex gap={spacing[5]}>
                    <Form.Item
                        label={t('membersForm:labels.name')}
                        name="firstName"
                        style={{ flex: 1 }}
                        rules={getInputRules(
                            t,
                            inputType.FIRST_NAME,
                            t('membersForm:errors.required-name'),
                            t('membersForm:errors.invalid-name')
                        )}
                    >
                        <Input placeholder={t('membersForm:placeholders.name')} />
                    </Form.Item>

                    <Form.Item
                        label={t('membersForm:labels.surname')}
                        name="lastName"
                        style={{ flex: 1 }}
                        rules={getInputRules(
                            t,
                            inputType.LAST_NAME,
                            t('membersForm:errors.required-surname'),
                            t('membersForm:errors.invalid-surname')
                        )}
                    >
                        <Input placeholder={t('membersForm:placeholders.surname')} />
                    </Form.Item>
                </Flex>
                <Form.Item
                    name="email"
                    label={t('membersForm:labels.email')}
                    rules={getInputRules(
                        t,
                        inputType.EMAIL,
                        t('membersForm:errors.required-email'),
                        t('membersForm:errors.invalid-email')
                    )}
                >
                    <Input disabled={!!member} placeholder={t('membersForm:placeholders.email')} />
                </Form.Item>

                <Form.Item
                    name={'phone'}
                    label={t('membersForm:labels.primary-phone')}
                    required
                    rules={[
                        {
                            validator: async (_, phone) => {
                                if (!phone) {
                                    setPhoneError(true);
                                    return Promise.reject(new Error(t('membersForm:errors.required-primary-phone')));
                                }

                                if (phone.trim().length < 10) {
                                    setPhoneError(true);
                                    return Promise.reject(
                                        new Error(t('membersForm:errors.min-10-characters-primary-phone'))
                                    );
                                }

                                setPhoneError(false);
                                return Promise.resolve();
                            },
                        },
                    ]}
                >
                    <PhoneInput
                        country={'us'}
                        containerClass={classes['phone-input-container']}
                        containerStyle={{ justifyContent: 'flex-end' }}
                        inputClass={classNames(classes['phone-input'], {
                            [classes['phone-input-error']]: phoneError,
                        })}
                        buttonClass={classNames(classes['phone-input-button'], {
                            [classes['phone-input-error']]: phoneError,
                        })}
                    />
                </Form.Item>
                <Form.Item
                    name={'role'}
                    label={t('membersForm:labels.role')}
                    rules={[
                        {
                            required: true,
                            message: t('membersForm:errors.required-role'),
                        },
                    ]}
                >
                    <Radio.Group
                        style={{ display: 'flex', flexDirection: 'column', gap: spacing[2], width: 'fit-content' }}
                    >
                        {teamRolesPriority
                            .filter(role => role !== teamRole.OWNER)
                            .map((role, index) => (
                                <Radio value={role} key={index}>
                                    {role}
                                </Radio>
                            ))}
                    </Radio.Group>
                </Form.Item>
                <Button htmlType="submit" type="primary" size={'large'} loading={loading}>
                    {!!member ? t('membersForm:buttons.save') : t('membersForm:buttons.invite-member')}
                </Button>
            </Form>
        </div>
    );
};
