import { DatePicker } from 'antd';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import uk from 'antd/es/date-picker/locale/uk_UA';
import en from 'antd/es/date-picker/locale/en_US';
import { useTranslation } from 'react-i18next';
import { useEffect } from 'react';
import 'dayjs/locale/uk';
import 'dayjs/locale/en';

dayjs.extend(customParseFormat);
dayjs.extend(weekOfYear);

const CustomDatePicker = ({ type, suffixIcon, children, ...rest }) => {
    const { i18n } = useTranslation();

    const getFormat = () => {
        switch (type) {
            case 'week':
                return date => `${date.year()}-${date.week()}th`;
            case 'month':
                return 'MMM';
            case 'quarter':
                return '[Q]Q';
            case 'year':
                return 'YYYY';
            default:
                return 'DD.MM.YYYY';
        }
    };

    useEffect(() => {
        if (i18n.language === 'uk') {
            dayjs.locale('uk');
        } else {
            dayjs.locale('en');
        }
    });

    return (
        <DatePicker
            locale={i18n.language === 'uk' ? uk : en}
            format={getFormat()}
            suffixIcon={suffixIcon || <i className="fi-rr-calendar" />}
            {...rest}
        >
            {children}
        </DatePicker>
    );
};

export default CustomDatePicker;
