import { createContext, useCallback, useContext, useState } from 'react';
import { useLocalStorage } from '../common/hooks/useLocalStorage';
import { useNavigate } from 'react-router-dom';
import { useUserService } from '../services/userService';
import { REFRESH_TOKEN, TOKEN, USER_ROLES } from '../common/constants/localStorage';
import { updateToken } from '../services/tokenService';
import { useAuthService } from '../services/authService';
import globalNotification from '../common/hooks/globalNotification';
import { useTeamService } from '../services/teamService';

export const AuthContext = createContext(null);

export const AuthProvider = ({ children }) => {
    const [token, setToken] = useLocalStorage(TOKEN, null);
    const [refreshToken, setRefreshToken] = useLocalStorage(REFRESH_TOKEN, null);
    const [currentUser, setCurrentUser] = useState();
    const [isUserLoading, setIsUserLoading] = useState(true);

    const navigate = useNavigate();
    const userService = useUserService();
    const authService = useAuthService();
    const { updateMembership, findUserMembership } = useTeamService();

    const fetchUser = useCallback(async () => {
        try {
            if (token) {
                setIsUserLoading(true);
                const { data } = await userService.getUser();

                setCurrentUser(data);
                updateToken(USER_ROLES, data.userRoleNames);
                setTimeout(() => {
                    setIsUserLoading(false);
                }, 1000);

                if (!data.teamId) {
                    navigate('/create-team');
                }
            }
        } catch (e) {
            logout();
            console.error(e);
        }
    }, []);

    const setAuth = (token, refreshToken) => {
        setToken(token);
        setRefreshToken(refreshToken);
    };

    const logout = async () => {
        try {
            if (!currentUser) {
                throw new Error();
            }

            const { data } = await findUserMembership(currentUser.id);

            if (!data) {
                throw new Error();
            }

            await updateMembership({ id: data.id, isActive: false });
        } catch (e) {
            console.log(e);
        } finally {
            updateToken(TOKEN, null);
            updateToken(REFRESH_TOKEN, null);
            updateToken(USER_ROLES, null);
            navigate('/auth/sign-in');
        }
    };

    const changeTeam = async teamId => {
        try {
            const { data } = await authService.changeTeam(teamId);

            setAuth(data.accessToken, data.refreshToken);
        } catch (error) {
            globalNotification.open({ message: 'This membership is not valid' });
        } finally {
            await fetchUser();
        }
    };

    return (
        <AuthContext.Provider
            value={{
                token,
                setAuth,
                logout,
                fetchUser,
                currentUser,
                isUserLoading,
                changeTeam,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => {
    return useContext(AuthContext);
};
