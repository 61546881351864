import { useState, useRef, useEffect } from 'react';
import { Flex, Button, Tooltip } from 'antd';
import StatusTag from '../../common/components/StatusTag';
import { motion, AnimatePresence } from 'framer-motion';
import { spacing, primitiveColors, buttonStyle } from '../../theming';
import { EditSingleStatusForm } from './editSingleStatusForm';

export default function EditStatus({ t, statuses, colors, handleOpenDeleteModal, handleUpdateStatus, loading }) {
    const [visibleForms, setVisibleForms] = useState({});
    const [buttonVisibility, setButtonVisibility] = useState({});
    const [statusesToChange, setStatusesToChange] = useState(statuses);

    const timeoutRefs = useRef({});
    const editButtonRefs = useRef([]);

    const iconButtonStyle = {
        backgroundColor: primitiveColors.gray100,
        color: primitiveColors.gray700,
        width: 30,
        height: 30,
    };

    useEffect(() => {
        setStatusesToChange(statuses);
    }, [statuses]);

    // #region handlers
    const toggleFormVisibility = index => {
        setVisibleForms(prev => ({
            ...prev,
            [index]: !prev[index],
        }));
    };

    const handleChange = (index, field, value) => {
        const newStatusList = statusesToChange.map((status, idx) => {
            if (idx === index) {
                return { ...status, [field]: value };
            }
            return status;
        });

        setStatusesToChange(newStatusList);
    };
    // #endregion

    // #region Animation handling
    const handleVisibilityChange = (index, isVisible) => {
        if (!isVisible) {
            timeoutRefs.current[index] = setTimeout(() => {
                setButtonVisibility(prev => ({ ...prev, [index]: true }));
            }, 300);
        } else {
            setButtonVisibility(prev => ({ ...prev, [index]: false }));
            if (timeoutRefs.current[index]) {
                clearTimeout(timeoutRefs.current[index]);
            }
        }
    };

    useEffect(() => {
        editButtonRefs.current = editButtonRefs.current.slice(0, statuses.length);

        return () => {
            Object.values(timeoutRefs.current).forEach(clearTimeout);
        };
    }, [statuses]);

    useEffect(() => {
        statusesToChange.forEach((_, index) => {
            handleVisibilityChange(index, visibleForms[index]);
        });
    }, [visibleForms, statusesToChange]);
    // #endregion

    return (
        <Flex vertical gap={spacing[4]}>
            {statusesToChange?.map((status, index) => {
                return (
                    <Flex justify="space-between" gap={spacing[3]} key={index}>
                        {!visibleForms[index] && buttonVisibility[index] && (
                            <StatusTag
                                status={status}
                                style={{
                                    padding: spacing[3],
                                }}
                            />
                        )}
                        <AnimatePresence>
                            {visibleForms[index] && (
                                <motion.div
                                    initial={{
                                        opacity: 0,
                                        scale: 0,
                                        transformOrigin: 'top right',
                                    }}
                                    animate={{ opacity: 1, scale: 1 }}
                                    exit={{
                                        opacity: 0,
                                        scale: 0,
                                        transformOrigin: 'top right',
                                    }}
                                    transition={{ duration: 0.3 }}
                                    style={{
                                        top: editButtonRefs.current[index]?.offsetTop || 0,
                                        right: editButtonRefs.current[index]?.offsetRight || 0,
                                    }}
                                >
                                    <EditSingleStatusForm
                                        status={status}
                                        index={index}
                                        handleChange={handleChange}
                                        handleUpdateStatus={handleUpdateStatus}
                                        updateLoading={loading.update}
                                        toggleFormVisibility={toggleFormVisibility}
                                        colors={colors}
                                        statusesToChange={statusesToChange}
                                        t={t}
                                    />
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <Flex gap={spacing[3]}>
                            {!visibleForms[index] && buttonVisibility[index] && (
                                <Button
                                    icon={<i className="fi-rr-edit" />}
                                    type="link"
                                    style={{ ...iconButtonStyle }}
                                    onClick={() => {
                                        toggleFormVisibility(index);
                                    }}
                                    ref={el => (editButtonRefs.current[index] = el)}
                                />
                            )}
                            <Tooltip title={statuses.length === 4 ? t('statuses.tooltip.minLimitReached') : ''}>
                                <Button
                                    icon={<i className="fi-rr-trash" />}
                                    type="link"
                                    style={{
                                        ...iconButtonStyle,
                                        marginTop: visibleForms[index] ? '34px' : '0px',
                                    }}
                                    onClick={() => {
                                        statusesToChange.length > 4 && handleOpenDeleteModal(status.id);
                                    }}
                                    loading={loading.delete === status.id}
                                />
                            </Tooltip>
                        </Flex>
                    </Flex>
                );
            })}
        </Flex>
    );
}
