import { useAxios } from './axiosService';
import globalNotification from '../common/hooks/globalNotification';
import { notificationType } from '../common/constants/notificationType';

export const useExportPdfService = () => {
    const { axios } = useAxios();

    const downloadPdfDocument = async (id, fileName) => {
        return new Promise((resolve, reject) => {
            axios
                .get(`/exportDocument/pdf/${id}`, { responseType: 'blob' })
                .then(response => {
                    const href = URL.createObjectURL(response.data);

                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `${fileName}.pdf`); //or any other extension
                    document.body.appendChild(link);
                    link.click();

                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);

                    globalNotification.open({
                        type: notificationType.SUCCESS,
                        message: 'Success',
                        description: 'Download completed successfully',
                    });

                    resolve();
                })
                .catch(error => {
                    reject(error);
                });
        });
    };

    return { downloadPdfDocument };
};
