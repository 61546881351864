import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Divider, Card, Flex, Button, List, Modal } from 'antd';
import { MinusOutlined, PlusOutlined } from '@ant-design/icons';
import { useOrderService } from '../../services/orderService';

function Cart() {
    const orderService = useOrderService();
    const navigate = useNavigate();

    const [cart, setCart] = useState([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        getDemoData();
    }, []);

    async function getDemoData() {
        const { data } = await orderService.getDemoCart();
        setCart(data);
    }

    function handleQuantity(method, itemId) {
        if (method === 'increase') {
            setCart(prevCart => {
                const newCart = prevCart.map(item => {
                    if (item.id === itemId) {
                        return { ...item, quantity: item.quantity + 1 };
                    }
                    return item;
                });
                return newCart;
            });
        }
        if (method === 'decrease') {
            setCart(prevCart => {
                const newCart = prevCart.map(item => {
                    if (item.id === itemId) {
                        return { ...item, quantity: item.quantity - 1 };
                    }
                    return item;
                });

                return newCart;
            });
        }
    }

    function handleDelete(itemId) {
        Modal.confirm({
            title: 'Are you sure you want to delete this item?',
            okText: 'Yes',
            width: 'fit-content',
            centered: true,
            maskClosable: true,
            onOk: () =>
                setCart(prevCart => {
                    const newCart = prevCart.filter(item => item.id !== itemId);
                    return newCart;
                }),
        });
    }

    function handleTotalCost() {
        return cart.reduce((acc, item) => acc + item.quantity * (item.priceInCents / 100), 0);
    }

    async function handleCompleteOrder() {
        Modal.confirm({
            title: 'Confirm Order',
            okText: 'Yes',
            width: 'fit-content',
            content: 'After confirmation, you will be redirected to the payment page.',
            centered: true,
            maskClosable: true,
            onOk: createOrder,
        });
    }

    async function createOrder() {
        const order = cart.map(item => ({ productId: item.id, quantity: item.quantity }));
        try {
            setLoading(true);
            const response = await orderService.newOrder(order);
            if (response?.data) {
                navigate('/payments', { state: { orderData: response.data } });
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
        }
    }

    return (
        <>
            <Divider orientation="left">
                <b>Cart Demo</b>
            </Divider>
            <p>This page is designed to demonstrate the functionality of the Stripe payment system.</p>

            <Card title="Shopping Cart">
                {cart.length > 0 ? (
                    <>
                        <List
                            footer={<h3 style={{ textAlign: 'end' }}>Total amount: {handleTotalCost()} $</h3>}
                            bordered
                            dataSource={cart}
                            renderItem={item => (
                                <List.Item key={item.id}>
                                    <List.Item.Meta
                                        avatar={<img src={item.image} alt={item.title} width="100" height="100" />}
                                        title={item.title}
                                        description={item.description}
                                    />

                                    <Flex gap="middle" wrap="wrap" vertical={false} align="center">
                                        <p style={{ padding: '0 30px' }}> {item.priceInCents / 100} $</p>
                                        <Button
                                            type="primary"
                                            shape="circle"
                                            icon={<MinusOutlined />}
                                            onClick={() => {
                                                if (item.quantity > 1) {
                                                    handleQuantity('decrease', item.id);
                                                } else {
                                                    handleDelete(item.id);
                                                }
                                            }}
                                        />
                                        <p style={{ width: '20px', textAlign: 'center' }}>{item.quantity}</p>
                                        <Button
                                            type="primary"
                                            shape="circle"
                                            icon={<PlusOutlined />}
                                            onClick={() => handleQuantity('increase', item.id)}
                                        />
                                        <p style={{ paddingLeft: '30px', width: '100px' }}>
                                            {item.quantity * (item.priceInCents / 100)} $
                                        </p>
                                    </Flex>
                                </List.Item>
                            )}
                        />
                        <div style={{ textAlign: 'end' }}>
                            <Button
                                style={{ width: '200px', margin: '30px' }}
                                loading={loading}
                                type="primary"
                                shape="round"
                                size="large"
                                onClick={handleCompleteOrder}
                            >
                                Complete Order
                            </Button>
                        </div>
                    </>
                ) : (
                    <div style={{ textAlign: 'center' }}>
                        <h3>Cart is empty!</h3>
                        <div>
                            <Button
                                style={{ width: '200px', margin: '30px' }}
                                shape="round"
                                size="large"
                                onClick={getDemoData}
                            >
                                Get Demo Cart
                            </Button>
                        </div>
                    </div>
                )}
            </Card>
        </>
    );
}

export default Cart;
