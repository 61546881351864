import React from 'react';
import { Outlet, redirect, createBrowserRouter, RouterProvider } from 'react-router-dom';
import ReactDOM from 'react-dom/client';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import Auth from './pages/Auth/Auth';
import TwoFactorAuth from './pages/TwoFactorAuth/TwoFactorAuth';
import UserSettings from './pages/UserSettings/UserSettings';
import UsersList from './pages/UsersList/UsersList';
import RecentUpdates from './pages/RecentUpdates/RecentUpdates';
import FileActionsDemo from './pages/FileActions/FileActionsDemo';
import ExportPdf from './pages/ExportPdf/ExportPdf';
import ErrorPage from './pages/ErrorPage/ErrorPage';
import Orders from './pages/Orders/Orders';
import Cart from './pages/Cart/Cart';
import Payments from './pages/Payments/Payments';
import { ForgotPassword } from './pages/ForgotPassword/ForgotPassword';
import { RestorePassword } from './pages/RestorePassword/RestorePassword';
import { AuthLayout } from './components/auth/AuthLayout';
import { RestorePasswordGuard } from './components/guards/RestorePasswordGuard';
import { NotificationLayer } from './components/auth/NotificationLayer';
import Callback from './components/auth/Callback';
import AuthGuard from './components/guards/AuthGuard';
import { routesPath } from './common/constants/routesPath';
import { Subscriptions } from './pages/Subscriptions/Subscriptions';
import { SubscriptionGuard } from './components/guards/SubscriptionGuard';
import Team from './pages/Team/Team';
import Invitation from './pages/Invitation/Invitation';
import { Businesses } from './pages/Businesses/Businesses';
import Leads from './pages/Leads/Leads';
import { InvitationGuard } from './components/guards/InvitationGuard';
import CreateTeam from './pages/CreateTeam/CreateTeam';
import './i18n';
import dayjs from 'dayjs';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import localeData from 'dayjs/plugin/localeData';
import weekday from 'dayjs/plugin/weekday';
import weekOfYear from 'dayjs/plugin/weekOfYear';
import weekYear from 'dayjs/plugin/weekYear';
import { Dashboard } from './pages/Dashboard/Dashboard';
import Profile from './pages/Profile/Profile';
import { Demo } from './pages/Demo/Demo';
import { TeamRoleGuard } from './components/guards/TeamRoleGuard';
import { SuperAdminGuard } from './components/guards/SuperAdminGuard';
import { teamRole } from './common/constants/teamRole';
import { TeamsProvider } from './context/TeamsContext';
import Settings from './pages/Settings/Settings';

dayjs.extend(customParseFormat);
dayjs.extend(advancedFormat);
dayjs.extend(weekday);
dayjs.extend(localeData);
dayjs.extend(weekOfYear);
dayjs.extend(weekYear);

const router = createBrowserRouter([
    {
        path: routesPath.ROOT.PATH,
        element: <NotificationLayer />,
        children: [
            {
                path: routesPath.ROOT.PATH,
                element: (
                    <AuthLayout>
                        <AuthGuard>
                            <App />
                        </AuthGuard>
                    </AuthLayout>
                ),
                children: [
                    {
                        path: routesPath.ROOT.RECENT_UPDATES,
                        element: <RecentUpdates />,
                    },
                    {
                        path: routesPath.ROOT.USERS_LIST,
                        element: (
                            <SuperAdminGuard>
                                <UsersList />
                            </SuperAdminGuard>
                        ),
                    },
                    {
                        path: routesPath.ROOT.USER_SETTINGS,
                        element: <UserSettings />,
                    },
                    {
                        path: routesPath.ROOT.SETTINGS,
                        element: (
                            <TeamRoleGuard role={teamRole.MANAGER}>
                                <Settings />
                            </TeamRoleGuard>
                        ),
                    },
                    {
                        path: routesPath.ROOT.EXPORT_PDF,
                        element: (
                            <SubscriptionGuard>
                                <ExportPdf />
                            </SubscriptionGuard>
                        ),
                    },
                    {
                        path: routesPath.ROOT.FILE_UPLOAD,
                        element: (
                            <SuperAdminGuard>
                                <FileActionsDemo />
                            </SuperAdminGuard>
                        ),
                    },
                    {
                        path: routesPath.ROOT.ORDERS,
                        element: (
                            <SuperAdminGuard>
                                <Orders />
                            </SuperAdminGuard>
                        ),
                    },
                    {
                        path: routesPath.ROOT.CART,
                        element: (
                            <SuperAdminGuard>
                                <Cart />
                            </SuperAdminGuard>
                        ),
                    },
                    {
                        path: routesPath.ROOT.PAYMENTS,
                        element: (
                            <SuperAdminGuard>
                                <Payments />
                            </SuperAdminGuard>
                        ),
                    },
                    {
                        path: routesPath.ROOT.SUBSCRIPTIONS,
                        element: (
                            <TeamRoleGuard role={teamRole.OWNER}>
                                <Subscriptions />
                            </TeamRoleGuard>
                        ),
                    },
                    {
                        path: routesPath.ROOT.BUSINESSES,
                        element: <Businesses />,
                    },
                    {
                        path: routesPath.ROOT.TEAM,
                        element: <Team />,
                    },
                    {
                        path: routesPath.ROOT.LEADS,
                        element: <Leads />,
                    },
                    {
                        path: routesPath.ROOT.CREATE_TEAM,
                        element: <CreateTeam />,
                    },
                    {
                        path: routesPath.ROOT.DASHBOARD,
                        element: (
                            <TeamRoleGuard role={teamRole.VIEWER}>
                                <Dashboard />
                            </TeamRoleGuard>
                        ),
                    },
                    {
                        path: 'demo',
                        element: (
                            <SuperAdminGuard>
                                <Demo />
                            </SuperAdminGuard>
                        ),
                    },
                    {
                        path: routesPath.ROOT.PROFILE,
                        element: <Profile />,
                    },
                ],
            },
            {
                path: routesPath.ROOT.CREATE_TEAM_AUTH,
                element: (
                    <AuthLayout>
                        <AuthGuard>
                            <TeamsProvider>
                                <CreateTeam />
                            </TeamsProvider>
                        </AuthGuard>
                    </AuthLayout>
                ),
            },
            {
                path: routesPath.AUTH.PATH,
                element: (
                    <AuthGuard>
                        <AuthLayout>
                            <Outlet />
                        </AuthLayout>
                    </AuthGuard>
                ),
                children: [
                    // redirecting to sign-in when opening /auth
                    {
                        index: true,
                        loader: async () => redirect('sign-in'),
                    },
                    {
                        path: routesPath.AUTH.SIGN_IN,
                        element: <Auth />,
                    },
                    {
                        path: routesPath.AUTH.TWO_FACTOR_AUTH,
                        element: <TwoFactorAuth />,
                    },
                    {
                        path: routesPath.AUTH.FORGOT_PASSWORD,
                        element: <ForgotPassword />,
                    },
                    {
                        path: routesPath.AUTH.RESTORE_PASSWORD,
                        element: (
                            <RestorePasswordGuard>
                                <RestorePassword />
                            </RestorePasswordGuard>
                        ),
                    },
                    {
                        path: routesPath.AUTH.INVITATION,
                        element: (
                            <InvitationGuard>
                                <Invitation />
                            </InvitationGuard>
                        ),
                    },
                    {
                        path: routesPath.AUTH.CALLBACK,
                        element: <Callback />,
                    },
                ],
            },
            {
                path: routesPath.ERROR.PATH,
                element: <ErrorPage />,
            },
        ],
    },
]);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <React.Suspense>
        <RouterProvider router={router} />
    </React.Suspense>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
