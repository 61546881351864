import { Divider, Row, Tabs, Flex, Button } from 'antd';
import classes from '../../common/styles/FormStyles.module.scss';
import GoogleSignInButton from '../../components/auth/GoogleSignInButton';
import FacebookSignInButton from '../../components/auth/FacebookSignInButton';
import SignUpForm from '../../components/auth/SignUpForm';
import SignInForm from '../../components/auth/SignInForm';
import { useEffect, useState } from 'react';
import { notification } from 'antd';
import { fontSize, lineHeight, spacing, textColor, textStandard } from '../../theming';
import logo from '../../assets/Main_logo.png';
import { useTranslation } from 'react-i18next';
import { useMedia } from './../../hooks/useMediaQuery';
import { useSearchParams } from 'react-router-dom';
import { useAuthService } from '../../services/authService';

function Auth() {
    const [api, contextHolder] = notification.useNotification();

    const openNotification = ({ type = 'error', message = '', description = '', placement = 'top' }) => {
        api[type]({
            message: message,
            description: description,
            placement: placement,
        });
    };
    const { t } = useTranslation(['auth', 'form', 'apiResponses']);
    const { isSmallDevice } = useMedia();

    const [activeTabKey, setActiveTabKey] = useState('0');

    const [params] = useSearchParams();

    const signInChild = {
        label: `Sign In`,
        key: '0',
        children: (
            <SignInForm
                preview={params.get('preview')}
                setActiveTabKey={setActiveTabKey}
                openNotification={openNotification}
                t={t}
            />
        ),
    };

    const signUpChild = {
        label: `Sign Up`,
        key: '1',
        children: <SignUpForm setActiveTabKey={setActiveTabKey} openNotification={openNotification} t={t} />,
    };

    const authType = {
        SIGN_IN: 'Sign-in',
        SIGN_UP: 'Sign-up',
    };

    return (
        <div
            className={classes['auth-wrapper']}
            style={{
                gap: 0,
            }}
        >
            {contextHolder}
            <img src={logo} alt="InterCode_logo" className={classes.logo} />
            <h3
                style={{
                    marginBottom: spacing[2],
                    fontSize: isSmallDevice && 26,
                }}
            >
                {activeTabKey === '0' ? t('auth:login-title') : t('auth:register-title')}
            </h3>
            <Tabs activeKey={activeTabKey} centered items={[signInChild, signUpChild]} />
            <div className={classes.content} style={{ gap: isSmallDevice && 0 }}>
                <Flex justify="center" align="center" gap={spacing[0.5]}>
                    <p
                        style={{
                            color: textColor.secondaryAlt,
                            fontSize: isSmallDevice && fontSize.xs,
                            textAlign: 'start',
                            textWrap: 'nowrap',
                        }}
                    >
                        {activeTabKey === '0' ? t('auth:login-subtitle') : t('auth:register-subtitle')}
                    </p>
                    <Button
                        type="link"
                        style={{
                            ...textStandard,
                            padding: 0,
                            fontWeight: 500,
                            lineHeight: lineHeight.lg,
                            fontSize: isSmallDevice && fontSize.xs,
                        }}
                        onClick={() => setActiveTabKey(activeTabKey === '0' ? '1' : '0')}
                    >
                        {activeTabKey === '0' ? t('auth:buttons.register') : t('auth:buttons.login')}
                    </Button>
                </Flex>

                <Flex vertical>
                    <Divider className={classes.divider} orientation="center" plain>
                        {t('auth:or')}
                    </Divider>

                    <Row justify="center" align="center" style={{ marginBottom: spacing[1] }}>
                        <GoogleSignInButton
                            authType={activeTabKey === '1' ? authType.SIGN_UP : authType.SIGN_IN}
                            setActiveTabKey={setActiveTabKey}
                            openNotification={openNotification}
                            t={t}
                        />
                    </Row>
                    <Row justify="center" align="center">
                        <FacebookSignInButton
                            authType={activeTabKey === '1' ? authType.SIGN_UP : authType.SIGN_IN}
                            setActiveTabKey={setActiveTabKey}
                            openNotification={openNotification}
                            t={t}
                        />
                    </Row>
                </Flex>
            </div>
        </div>
    );
}

export default Auth;
