import React from 'react';
import { Empty } from 'antd';
import { RenderBusinesses, RenderLeads, RenderUsers } from './SearchRenders';

export default function SearchResultsList({ options, results, noResults, showModal, t }) {
    const isOptionChecked = label => options.find(item => item.value === label.toLowerCase())?.checked;

    const isLeadsChecked = isOptionChecked('leads');
    const isBusinessesChecked = isOptionChecked('businesses');
    const isUsersChecked = isOptionChecked('users');

    const hasResults = type => results[type] && results[type].length > 0;

    const renderEmpty = () => (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} style={{ width: '140px' }} description={t('general:no-data')} />
    );

    const renderResults = (type, Component, label) => (
        <div key={type}>
            <h3>{label}:</h3>
            {hasResults(type) ? <Component {...{ [type]: results[type], showModal, t }} /> : renderEmpty()}
        </div>
    );

    if (noResults) {
        return renderEmpty();
    }

    return (
        <div>
            {isLeadsChecked && renderResults('leads', RenderLeads, t('leads'))}
            {isBusinessesChecked && renderResults('businesses', RenderBusinesses, t('businesses'))}
            {isUsersChecked && renderResults('users', RenderUsers, t('users'))}
            {options.every(item => !item.checked) && (
                <>
                    {renderResults('leads', RenderLeads, t('leads'))}
                    {renderResults('businesses', RenderBusinesses, t('businesses'))}
                    {renderResults('users', RenderUsers, t('users'))}
                </>
            )}
        </div>
    );
}
