import { useCallback } from 'react';
import { Button, Flex, List, Modal, Typography } from 'antd';
import { useSubscriptionService } from '../../services/subscriptionService';
import globalNotification from '../../common/hooks/globalNotification';
import { motion } from 'framer-motion';
import { buttonStyle, coreBrandColors, fontSize, primitiveColors, spacing } from '../../theming';
import classes from './subscription-card.module.scss';
import { useTranslation } from 'react-i18next';
import StatusModalContent from './status-modal-content';
import ConfirmModalContent from './confirm-modal-content';
import { getStatusModalTitle, getButtonText } from './helpers';
import { notificationType } from '../../common/constants/notificationType';
import { useMedia } from '../../hooks/useMediaQuery';
import classNames from 'classnames';
import { extractFeatureName } from './helpers';

const { Title } = Typography;

export default function SubscriptionCard({
    subscription,
    userPriority: userSubscription,
    loading,
    setLoading,
    handleSubscriptionChange,
    isTeamCreation,
}) {
    const { registerSubscription, cancelSubscription, renewSubscription, cancelSubscriptionImmediately } =
        useSubscriptionService();
    const isCanceled = subscription?.title === userSubscription?.tier && userSubscription?.status === 'Cancelled';
    const isFree = subscription?.price === 0;
    const modalButtonStyle = {
        ...buttonStyle.large,
        placeSelf: 'flex-end',
        width: 'fit-content',
    };
    const { priority: userPriority, subscriptionId } = userSubscription;
    const { t, i18n } = useTranslation(['subscriptions']);
    const { isSmallDevice, isMediumDevice } = useMedia();
    const mobileDeviceBreakpoint = isSmallDevice || isMediumDevice;

    // #region handlers
    const handleSubscriptionRegister = useCallback(
        async priceId => {
            try {
                const res = await registerSubscription(priceId, 1, localStorage.getItem('teamId'), isTeamCreation);
                if (res.data.redirect) {
                    window.open(res.data.url, '_self');
                } else {
                    window.location.reload();
                }
                isTeamCreation && localStorage.setItem('isSubscriptionChanged', 'true');
            } catch {
                globalNotification.open({
                    type: notificationType.ERROR,
                    message: t('notification.error'),
                    description: t('notification.register-error'),
                });
            }
        },
        [registerSubscription, isTeamCreation]
    );

    const openConfirmationModal = useCallback(
        priceId => {
            Modal.confirm({
                centered: true,
                title: `${t('confirmation-modal.title')} ${subscription.title}?`,
                icon: null,
                content: (
                    <ConfirmModalContent
                        Modal={Modal}
                        subscription={subscription}
                        handler={handleSubscriptionRegister}
                        priceId={priceId}
                        modalButtonStyle={modalButtonStyle}
                        t={t}
                    />
                ),
                className: classes.modal,
            });
        },
        [handleSubscriptionRegister, subscription]
    );

    const manageSubscriptionPeriod = useCallback(
        async (now, isFree) => {
            if (isFree) {
                globalNotification.open({
                    type: notificationType.ERROR,
                    message: t('notification.error'),
                    description: t('notification.free-subscription'),
                });
                return;
            }

            setLoading(true);
            try {
                if (isCanceled) {
                    await renewSubscription(subscriptionId);
                } else if (now) {
                    await cancelSubscriptionImmediately(subscriptionId);
                } else {
                    await cancelSubscription(subscriptionId);
                }
                handleSubscriptionChange();
            } catch {
                globalNotification.open({
                    type: notificationType.ERROR,
                    message: t('notification.error'),
                    description: t('notification.managing-error'),
                });
            } finally {
                setLoading(false);
            }
        },
        [
            setLoading,
            isCanceled,
            renewSubscription,
            cancelSubscriptionImmediately,
            cancelSubscription,
            subscriptionId,
            handleSubscriptionChange,
            isFree,
        ]
    );

    const openSubscriptionStatusModal = useCallback(
        isFree => {
            let cancelImmediately = false;

            Modal.confirm({
                centered: true,
                title: `${getStatusModalTitle(isCanceled, i18n.language, t)}`,
                icon: null,
                content: (
                    <StatusModalContent
                        Modal={Modal}
                        isCanceled={isCanceled}
                        subscription={subscription}
                        cancelImmediately={cancelImmediately}
                        manageSubscriptionPeriod={manageSubscriptionPeriod}
                        isFree={isFree}
                        modalButtonStyle={modalButtonStyle}
                        currentLanguage={i18n.language}
                        t={t}
                    />
                ),
                className: classes.modal,
            });
        },
        [isCanceled, manageSubscriptionPeriod, subscription]
    );
    // #endregion

    return (
        <motion.div
            whileHover={{
                height: 750,
                borderColor: coreBrandColors.blueRibbon,
                transition: { duration: 0.3 },
            }}
            style={{
                height: 720, // wont work form scss file coz of the hover effect from motion framer
            }}
            className={classes.card}
        >
            <Flex
                vertical
                align="center"
                justify="start"
                style={{
                    marginBottom: mobileDeviceBreakpoint ? spacing[2] : spacing[4],
                }}
            >
                {subscription.isPopular && (
                    <span
                        className={classNames(classes.tag, {
                            [classes['tag-selected']]: userPriority === subscription?.priority,
                            [classes['tag-unselected']]: userPriority !== subscription?.priority,
                        })}
                    >
                        <i className="fi fi-rs-check-circle" />
                        Most popular plan
                    </span>
                )}
            </Flex>
            <Flex vertical align="center" justify="center" gap={mobileDeviceBreakpoint ? spacing[4] : spacing[6]}>
                <Title level={3} style={{ color: coreBrandColors.blueRibbon, margin: 0 }}>
                    {subscription.title}
                </Title>
                <Title level={4} style={{ color: primitiveColors.gray900, margin: 0, fontSize: fontSize.H1 }}>
                    {`$ ${subscription?.price / 100}`}
                    <span
                        style={{
                            fontSize: fontSize.md,
                            fontWeight: 400,
                            color: primitiveColors.gray400,
                        }}
                    >
                        / month
                    </span>
                </Title>
                <Button
                    type={userPriority === subscription?.priority ? 'default' : 'primary'}
                    style={{
                        ...(mobileDeviceBreakpoint ? buttonStyle.medium : buttonStyle.large),
                        width: '100%',
                        borderColor: userPriority === subscription?.priority && coreBrandColors.blueRibbon,
                        color: userPriority === subscription?.priority && coreBrandColors.blueRibbon,
                    }}
                    onClick={() => {
                        userPriority === subscription.priority
                            ? openSubscriptionStatusModal(isFree)
                            : openConfirmationModal(subscription.stripePriceId);
                    }}
                    loading={loading}
                >
                    {getButtonText(isCanceled, userPriority, subscription, t)}
                </Button>
                <div className={classes.description}>
                    <p style={{ textAlign: 'center' }}>{subscription.description}</p>
                    <List className={classes['features-list']}>
                        {subscription.features.map(feature => {
                            const featureName = extractFeatureName(feature);
                            const isTeamSize = featureName.includes('Team size');
                            return (
                                <List.Item key={featureName} className={isTeamSize ? classes.bold : classes.normal}>
                                    <i
                                        className="fi-sr-check"
                                        style={{ color: coreBrandColors.blueRibbon, marginRight: spacing[2] }}
                                    />
                                    {featureName}
                                </List.Item>
                            );
                        })}
                    </List>
                </div>
            </Flex>
        </motion.div>
    );
}
