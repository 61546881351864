import { Dropdown, Empty, Flex, List, Modal, notification, Table, Typography } from 'antd';
import { useState, useEffect, useMemo } from 'react';
import { BusinessesService } from '../../services/businessesService';
import globalNotification from '../../common/hooks/globalNotification';
import SideDrawer from '../../common/components/sideDrawer/SideDrawer';
import BusinessesForm from '../../components/businesses/BusinessesForm';
import PhoneInput from 'react-phone-input-2';
import { useAuth } from '../../context/AuthContext';
import PageContainer from '../../common/components/PageContainer';
import classNames from 'classnames';
import { CustomPagination } from '../../common/components/pagination/Pagination';
import Avatar from '../../common/components/Avatar';
import {
    fontSize,
    textStandard,
    spacing,
    textColor,
    buttonStyle,
    primitiveColors,
    textSmall,
    pageHeading,
} from '../../theming';
import getSocialMediaIcon from '../../utils/getSocialMediaIcon';
import { socialMediaPatterns } from '../../common/constants/socialMediaPatterns';
import SorterIcon from '../../common/customIcons/SorterIcon';
import './styles.scss';
import { useTranslation } from 'react-i18next';
import { notifications } from '../../common/constants/notifications';
import StatusControl from '../../common/components/StatusSegmentController';
import AddButton from '../../common/components/AddButton';
import SearchInput from '../../common/components/GlobalSearch/SearchInput';
import { ClearInputIcon, SearchIcon } from '../../common/components/icons';
import icon from '../../common/styles/Icon.module.scss';
import ActionsMenu from '../../common/components/ActionsMenu/ActionsMenu';
import TableRowDescription from '../../common/components/TableRowDescription';
import { BusinessPreview } from '../../components/businesses/BusinessPreview';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useMedia } from '../../hooks/useMediaQuery';
import TableHeadingButtons from '../../common/components/TableHeadingButtons';
import { teamRole } from '../../common/constants/teamRole';
import { notificationType } from '../../common/constants/notificationType';

export const Businesses = () => {
    const { t, i18n } = useTranslation(['businesses', 'general', 'confirmModal']);

    const [api, contextHolder] = notification.useNotification();

    const openNotification = ({ type = 'error', message = '', description = '', placement = 'top' }) => {
        api[type]({
            message: message,
            description: description,
            placement: placement,
        });
    };

    const currentLanguage = i18n.language;

    const [sortedInfo, setSortedInfo] = useState({});
    const handleSortCriteriaChange = (pagination, filters, sorter) => {
        setSortedInfo(sorter);
    };

    const { currentUser } = useAuth();

    const isUserOwner = useMemo(() => {
        return currentUser.teamRole === teamRole.OWNER;
    }, [currentUser]);

    const [data, setData] = useState([]);
    const [selectedBusinesses, setSelectedBusinesses] = useState([]);
    const [selectedTab, setSelectedTab] = useState(t('general:statuses.active'));
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);

    const {
        getAllActiveBusinesses,
        getAllArchivedBusinesses,
        archiveBusinesses,
        unarchiveBusinesses,
        deleteBusinesses,
    } = BusinessesService();

    const { isExtraSmallDevice, isSmallDevice, isMediumDevice } = useMedia();

    const mobileDevicesBreakpoint = useMediaQuery('only screen and (max-width : 880px)');

    const [searchQuery, setSearchQuery] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(20);

    const [isDrawerOpened, setIsDrawerOpened] = useState(false);
    const [isDataLoading, setIsDataLoading] = useState(true);

    const [formAction, setFormAction] = useState('create');
    const [defaultFormValues, setDefaultFormValues] = useState(null);

    const [isClickFromView, setIsClickFromView] = useState(false);

    const handleRowClick = record => {
        if (selectedTab === t('general:statuses.archived')) {
            return;
        }

        handleOpenPreviewDrawer(record);
    };

    const handlePageChange = page => {
        setCurrentPage(page);
    };

    const handleExpand = (expanded, record) => {
        setExpandedRowKeys(expanded ? [record.key] : []);
    };

    const onSelectChange = newSelectedRowKeys => {
        setSelectedBusinesses(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys: selectedBusinesses,
        onChange: onSelectChange,
    };

    const fetchData = async () => {
        setIsDataLoading(true);

        try {
            const result =
                selectedTab === t('general:statuses.active')
                    ? await getAllActiveBusinesses()
                    : await getAllArchivedBusinesses();

            setData(
                result.businesses.map(business => ({
                    ...business,
                    key: business.id,
                }))
            );
        } catch (err) {
            console.log(err);
            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.BUSINESSES,
                action: notifications.action.FETCH,
                plural: true,
            });
        } finally {
            setIsDataLoading(false);
        }
    };

    useEffect(() => {
        fetchData();
    }, [selectedTab]);

    useEffect(() => {
        setSelectedTab(t('general:statuses.active'));
    }, [currentLanguage]);

    const filletedBusinesses = data.filter(
        business =>
            business.name.includes(searchQuery) ||
            business.email.includes(searchQuery) ||
            `${business.address.addressTwo ? business.address.addressTwo : ''} ${business.address.addressOne} ${business.address.city} ${business.address.country}, ${business.address.state} ${business.address.zipCode}`.includes(
                searchQuery
            ) ||
            business.phones.some(phone => phone.includes(searchQuery)) ||
            `${business.contactPerson?.firstName} ${business.contactPerson?.lastName}`.includes(searchQuery)
    );

    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;

    const currentItems = filletedBusinesses.slice(indexOfFirstItem, indexOfLastItem);

    const handleSearchQueryChange = value => {
        setSearchQuery(value);
    };

    const handleDrawerClose = () => {
        setIsDrawerOpened(false);
        setTimeout(() => {
            setDefaultFormValues(() => null);
            setFormAction('');
        }, 500);
    };

    const openEditMode = record => {
        handleDrawerClose();
        setIsClickFromView(true);

        setTimeout(() => {
            handleOpenEditDrawer(record, true);
        }, 500);
    };

    const handleSubmitArchiveBusinesses = async ids => {
        await archiveBusinesses(ids)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.BUSINESSES,
                    action: notifications.action.ARCHIVE,
                    plural: ids.length > 1,
                    single: ids.length === 1,
                });
                setSelectedBusinesses([]);
            })
            .catch(err => {
                const errorCode = err.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const handleSubmitUnarchiveBusinesses = async ids => {
        await unarchiveBusinesses(ids)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.BUSINESSES,
                    action: notifications.action.RESTORE,
                    plural: ids.length > 1,
                    single: ids.length === 1,
                });
                setSelectedBusinesses([]);
            })
            .catch(err => {
                const errorCode = err.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const handleSubmitDeleteBusinesses = async ids => {
        await deleteBusinesses(ids)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.BUSINESSES,
                    action: notifications.action.DELETE,
                    plural: ids.length > 1,
                    single: ids.length === 1,
                });
                setSelectedBusinesses([]);
            })
            .catch(err => {
                const errorCode = err.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            })
            .finally(() => {
                fetchData();
            });
    };

    const onClickArchive = async ids => {
        Modal.confirm({
            title: `${t('confirmModal:actions.archive')} ${ids.length > 1 ? t('businesses:plural-with-article') : t('businesses:singular-with-article')}?`,
            icon: null,
            styles: { body: { padding: spacing[4] } },
            width: 'fit-content',
            okButtonProps: { danger: true },
            onOk: () => handleSubmitArchiveBusinesses(ids),
            okText: t('confirmModal:buttons.yes'),
            cancelText: t('confirmModal:buttons.cancel'),
        });
    };

    const onClickUnarchive = async ids => {
        Modal.confirm({
            title: `${t('confirmModal:actions.restore')} ${ids.length > 1 ? t('businesses:plural-with-article') : t('businesses:singular-with-article')}?`,
            icon: null,
            styles: { body: { padding: spacing[4] } },
            width: 'fit-content',
            okButtonProps: { danger: true },
            onOk: () => handleSubmitUnarchiveBusinesses(ids),
            okText: t('confirmModal:buttons.yes'),
            cancelText: t('confirmModal:buttons.cancel'),
        });
    };

    const onClickDelete = ids => {
        Modal.confirm({
            title: `${t('confirmModal:actions.delete')} ${ids.length > 1 ? t('businesses:plural-with-article') : t('businesses:singular-with-article')}?`,
            icon: null,
            styles: { body: { padding: spacing[4] } },
            width: 'fit-content',
            okButtonProps: { danger: true },
            onOk: () => handleSubmitDeleteBusinesses(ids),
            okText: t('confirmModal:buttons.yes'),
            cancelText: t('confirmModal:buttons.cancel'),
        });
    };

    const handleOpenEditDrawer = (record, isClickFromView = false) => {
        if (!isClickFromView) {
            setIsClickFromView(false);
        }

        setFormAction('update');
        setDefaultFormValues(record);
        setIsDrawerOpened(true);
    };

    const handleOpenPreviewDrawer = record => {
        setFormAction('preview');
        setDefaultFormValues(record);
        setIsDrawerOpened(true);
    };

    const columns = [
        {
            title: t('fields.name'),
            dataIndex: 'name',
            key: 'name',
            sorter: (a, b) => a.name.localeCompare(b.name),
            width: mobileDevicesBreakpoint ? 280 : 300,
            sortOrder: sortedInfo.columnKey === 'name' ? sortedInfo.order : null,
            render: (name, record) => {
                return (
                    <Flex
                        gap={mobileDevicesBreakpoint ? spacing[1] : spacing[3]}
                        justify="start"
                        align="center"
                        style={{
                            cursor: 'pointer',
                        }}
                    >
                        <Avatar
                            key={record.id}
                            size={isSmallDevice || isExtraSmallDevice ? 32 : 36}
                            photoName={record.photo}
                            firstName={record.name.split(' ')[0]}
                            lastName={record.name.split(' ')[1]}
                            controller={'businesses'}
                            style={{ backgroundColor: primitiveColors.gray100, flexShrink: 0 }}
                        />
                        <Flex vertical>
                            <Typography.Text style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard) }}>
                                {name}
                            </Typography.Text>

                            <Typography.Text
                                style={{
                                    color: textColor.subtle,
                                    ...textStandard,
                                }}
                            >
                                {record.email}
                            </Typography.Text>
                        </Flex>
                    </Flex>
                );
            },
        },
        {
            title: t('fields.address'),
            dataIndex: 'address',
            key: 'address',
            ellipsis: true,
            render: address => (
                <Typography.Text style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard) }}>
                    {`${address.addressTwo ? address.addressTwo : ''} ${address.addressOne} ${address.city} ${address.country}, ${address.state} ${address.zipCode}`}
                </Typography.Text>
            ),
        },
        {
            title: t('fields.phone'),
            dataIndex: 'phones',
            key: 'phones',
            width: mobileDevicesBreakpoint ? 165 : 200,
            render: phones => (
                <List
                    dataSource={mobileDevicesBreakpoint ? phones.slice(0, 1) : phones.slice(0, 2)}
                    renderItem={(item, index) => (
                        <a
                            key={item}
                            href={`tel:${item}`}
                            onClick={e => e.stopPropagation()}
                            style={{
                                display: 'block',
                                width: '165px',
                                textDecoration: 'none',
                            }}
                        >
                            <PhoneInput
                                value={item}
                                buttonStyle={{ display: 'none' }}
                                inputStyle={{
                                    width: '100%',
                                    border: 'none',
                                    padding: 0,
                                    cursor: 'pointer',
                                    color: index === 0 ? textColor.secondary : textColor.secondaryAlt,
                                    textDecoration: 'none',
                                    pointerEvents: 'none',
                                    fontSize: fontSize.sm,
                                    backgroundColor: 'inherit',
                                    ...(mobileDevicesBreakpoint ? textSmall : textStandard),
                                }}
                                containerStyle={{ width: '165px' }}
                            />
                        </a>
                    )}
                />
            ),
        },
        {
            title: t('fields.contact-person'),
            dataIndex: 'contactPerson',
            key: 'contactPerson',
            ellipsis: true,
            render: contactPerson => {
                return contactPerson ? (
                    <Flex gap={spacing[1]} justify="start" align="center">
                        <Avatar
                            key={contactPerson.id}
                            size={isSmallDevice || isExtraSmallDevice ? 32 : 36}
                            photoName={contactPerson.photo}
                            firstName={contactPerson.firstName}
                            lastName={contactPerson.lastName}
                            controller={'businesses'}
                        />
                        <Typography.Text style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard) }}>
                            {`${contactPerson.firstName} ${contactPerson.lastName}`}
                        </Typography.Text>
                    </Flex>
                ) : (
                    <Typography.Text style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard) }}>
                        {t('general:none')}
                    </Typography.Text>
                );
            },
        },
        {
            title: t('fields.social-media'),
            dataIndex: 'socialMedia',
            key: 'socialMedia',
            width: 'fit-content',
            render: socialMedia => (
                <Flex gap={spacing[1]}>
                    {Object.values(socialMedia).map((item, index) => {
                        return (
                            item &&
                            socialMediaPatterns.some(pattern => new RegExp(pattern).test(item)) && (
                                <a
                                    key={index}
                                    href={item.startsWith('http') ? item : `https://${item}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ textDecoration: 'none', color: textColor.secondary }}
                                >
                                    {getSocialMediaIcon(item)}
                                </a>
                            )
                        );
                    })}
                    {Object.values(socialMedia).every(item => item === '') && (
                        <Typography.Text style={{ ...textStandard }}>{t('table-info.no-social-media')}</Typography.Text>
                    )}
                </Flex>
            ),
        },
    ];

    if (isUserOwner) {
        columns.push({
            title: '',
            dataIndex: 'actions',
            width: 40,
            fixed: 'right',
            key: 'operation',
            render: (_, record) => (
                <Flex justify="center" onClick={e => e.stopPropagation()}>
                    <Dropdown
                        placement="bottom"
                        menu={{
                            items: ActionsMenu({
                                record,
                                handleEdit: () => handleOpenEditDrawer(record),
                                handleDelete: () => onClickDelete([record.id]),
                                handleArchive: () => {
                                    if (record.isDeleted) {
                                        onClickUnarchive([record.id]);
                                    } else {
                                        onClickArchive([record.id]);
                                    }
                                },
                                handleView: () => handleOpenPreviewDrawer(record),
                                t,
                            }),
                        }}
                    >
                        <span onClick={e => e.preventDefault()} style={{ cursor: 'pointer' }}>
                            <i
                                className={`fi fi-rr-menu-dots-vertical ${icon.md}`}
                                style={{ color: textColor.subtle }}
                            />
                        </span>
                    </Dropdown>
                </Flex>
            ),
        });
    }

    return (
        <>
            <PageContainer
                style={{
                    backgroundColor: mobileDevicesBreakpoint ? primitiveColors.gray25 : primitiveColors.gray0,
                    padding: mobileDevicesBreakpoint ? spacing[1] : spacing[4],
                }}
            >
                {contextHolder}
                <Flex vertical>
                    <Flex
                        vertical={mobileDevicesBreakpoint}
                        gap={mobileDevicesBreakpoint ? spacing[1] : spacing[3]}
                        align="center"
                        justify="space-between"
                        style={{
                            marginBottom: mobileDevicesBreakpoint ? spacing[3] : spacing[4],
                        }}
                    >
                        <Flex
                            gap={spacing[3]}
                            align="center"
                            justify={mobileDevicesBreakpoint ? 'space-between' : 'start'}
                            style={{
                                width: mobileDevicesBreakpoint && '100%',
                                maxHeight: mobileDevicesBreakpoint && 60,
                            }}
                        >
                            <h1 style={{ ...pageHeading, fontSize: mobileDevicesBreakpoint && fontSize.H4, margin: 0 }}>
                                {t('plural')}
                            </h1>
                            <StatusControl
                                value={selectedTab}
                                onChange={value => {
                                    setSelectedTab(value);
                                    setCurrentPage(1);
                                    setSelectedBusinesses([]);
                                }}
                            />
                        </Flex>

                        <Flex
                            gap={spacing[3]}
                            align="center"
                            justify={mobileDevicesBreakpoint ? 'end' : 'end'}
                            style={{ width: mobileDevicesBreakpoint && '100%' }}
                        >
                            {!mobileDevicesBreakpoint && (
                                <SearchInput
                                    placeholder={t('general:search')}
                                    value={searchQuery}
                                    style={{
                                        width: isMediumDevice ? 220 : 250,
                                    }}
                                    onChange={e => handleSearchQueryChange(e.target.value)}
                                    onPressEnter={e => {
                                        e.preventDefault();
                                    }}
                                    prefix={<SearchIcon onClick={() => null} />}
                                    suffix={
                                        searchQuery && (
                                            <ClearInputIcon
                                                setSearchText={setSearchQuery}
                                                handleReset={() => handleSearchQueryChange('')}
                                            />
                                        )
                                    }
                                />
                            )}

                            {isUserOwner && selectedTab === t('general:statuses.active') && (
                                <AddButton
                                    text={t('actions.add')}
                                    size={isMediumDevice && mobileDevicesBreakpoint && 'default'}
                                    style={{
                                        ...(mobileDevicesBreakpoint ? buttonStyle.small : buttonStyle.large),
                                    }}
                                    iconSize={icon.sm}
                                    onClick={() => {
                                        setIsClickFromView(false);
                                        setIsDrawerOpened(true);
                                        setFormAction('create');
                                    }}
                                />
                            )}
                        </Flex>

                        {selectedBusinesses.length > 0 && mobileDevicesBreakpoint && isUserOwner && (
                            <Flex
                                gap={spacing[0.5]}
                                align="center"
                                justify="end"
                                style={{ width: mobileDevicesBreakpoint && '100%' }}
                            >
                                <TableHeadingButtons
                                    items={selectedBusinesses}
                                    handleDelete={() => onClickDelete(selectedBusinesses)}
                                    handleArchive={() => onClickArchive(selectedBusinesses)}
                                    handleRestore={() => onClickUnarchive(selectedBusinesses)}
                                    isMobile
                                    style={{
                                        ...buttonStyle.small,
                                    }}
                                    selectedTab={selectedTab}
                                />
                            </Flex>
                        )}
                    </Flex>

                    {!mobileDevicesBreakpoint && (
                        <Flex
                            align="center"
                            justify={selectedBusinesses.length > 0 ? 'end' : 'space-between'}
                            style={{ marginBottom: spacing[1], height: 38 }}
                        >
                            {selectedBusinesses.length > 0 && (
                                <Flex
                                    gap={spacing[0.5]}
                                    align="center"
                                    justify="end"
                                    style={{ width: mobileDevicesBreakpoint && '100%' }}
                                >
                                    <TableHeadingButtons
                                        items={selectedBusinesses}
                                        handleDelete={() => onClickDelete(selectedBusinesses)}
                                        handleArchive={() => onClickArchive(selectedBusinesses)}
                                        handleRestore={() => onClickUnarchive(selectedBusinesses)}
                                        isMobile
                                        style={{
                                            ...buttonStyle.small,
                                        }}
                                        selectedTab={selectedTab}
                                    />
                                </Flex>
                            )}
                        </Flex>
                    )}

                    <SideDrawer
                        open={isDrawerOpened}
                        closeDrawer={handleDrawerClose}
                        title={
                            formAction === 'create'
                                ? t('actions.add-new')
                                : formAction === 'preview'
                                  ? ''
                                  : t('actions.update')
                        }
                        width={formAction === 'preview' && 700}
                        padding={formAction === 'preview' ? `${spacing[10]} ${spacing[6]}` : ''}
                        children={
                            formAction === 'preview' ? (
                                <BusinessPreview
                                    business={defaultFormValues || {}}
                                    openEditMode={openEditMode}
                                    openNotification={openNotification}
                                    currentUser={currentUser}
                                />
                            ) : (
                                <BusinessesForm
                                    defaultValues={defaultFormValues}
                                    openPreviewDrawer={handleOpenPreviewDrawer}
                                    closeDrawer={handleDrawerClose}
                                    fetchData={fetchData}
                                    type={formAction}
                                    isDrawerOpened={isDrawerOpened}
                                    isClickFromView={isClickFromView}
                                    openNotification={openNotification}
                                />
                            )
                        }
                    />
                </Flex>

                <Table
                    columns={[
                        Table.SELECTION_COLUMN,
                        Table.EXPAND_COLUMN,
                        ...columns.map(column => ({
                            ...column,
                            sortOrder: sortedInfo?.columnKey === column.dataIndex && sortedInfo?.order,
                            title: column.title.toUpperCase(),
                            sortIcon: () => SorterIcon({ sortOrder: sortedInfo, column: column.dataIndex }),
                        })),
                    ]}
                    rowSelection={{
                        ...rowSelection,
                        type: 'checkbox',
                        getCheckboxProps: record => {
                            return {
                                disabled: !isUserOwner,
                            };
                        },
                    }}
                    scroll={{
                        x: 'max-content',
                    }}
                    bordered={false}
                    expandable={{
                        fixed: true,
                        onExpand: handleExpand,
                        expandedRowKeys: expandedRowKeys,
                        expandedRowClassName: () => 'expanded-row',
                        expandedRowRender: record => <TableRowDescription record={record} />,
                        expandIcon: ({ expanded, record, onExpand }) => {
                            return expanded ? (
                                <i
                                    className={`fi fi-rr-angle-small-down ${icon.md}`}
                                    onClick={e => {
                                        e.stopPropagation();
                                        onExpand(record, e);
                                        setExpandedRowKeys(prev => [...prev.filter(key => key !== record.key)]);
                                    }}
                                    style={{ color: primitiveColors.blue500, cursor: 'pointer', display: 'flex' }}
                                />
                            ) : (
                                <i
                                    className={`fi fi-rr-angle-small-right ${icon.md}`}
                                    onClick={e => {
                                        e.stopPropagation();
                                        onExpand(record, e);
                                        setExpandedRowKeys(prev => [...prev, record.key]);
                                    }}
                                    style={{
                                        color: primitiveColors.gray300,
                                        cursor: 'pointer',
                                        display: 'flex',
                                    }}
                                />
                            );
                        },
                    }}
                    dataSource={currentItems}
                    onChange={handleSortCriteriaChange}
                    loading={isDataLoading}
                    showHeader
                    size="small"
                    footer={() => (
                        <CustomPagination
                            currentPage={currentPage}
                            handlePageChange={handlePageChange}
                            perPage={itemsPerPage}
                            handlePerPageChange={setItemsPerPage}
                            total={filletedBusinesses.length}
                            title={t('pagination.total-businesses')}
                        />
                    )}
                    pagination={false}
                    onRow={record => {
                        return {
                            onClick: event => handleRowClick(record),
                        };
                    }}
                    rowClassName={(record, index) =>
                        classNames({
                            'even-table-cell': index % 2 === 0,
                            'odd-table-cell': index % 2 !== 0,
                            'expanded-cell': expandedRowKeys.includes(record.key),
                        })
                    }
                    defaultSortOrder="descend"
                    locale={{
                        emptyText: <Empty description={t('general:no-data')} />,
                        triggerDesc: t('general:actions.simple.sort-desc'),
                        triggerAsc: t('general:actions.simple.sort-asc'),
                        cancelSort: t('general:actions.simple.cancel-sort'),
                    }}
                />
            </PageContainer>
        </>
    );
};
