/**
 * This function capitalizes the first letter of a string at a given index.
 * @param {*} string The string to capitalize
 * @param {*} charAt The index of the character to capitalize
 * @returns The string with the first letter capitalized
 * @example capitalize('hello', 0) // Hello
 * @example 
 * capitalize(
    dayjs(lead.updatedAt)
    .locale(currentLanguage)
    .format(currentLanguage === 'uk' ? datesFormat.uk.long : datesFormat.en.short),
    currentLanguage === 'uk' ? 3 : 0 // 29 Сер 2021 or Aug 29 2021
 */
export default function capitalize(string, charAt) {
    return string.substring(0, charAt) + string.charAt(charAt).toUpperCase() + string.substring(charAt + 1);
}
