import { Button, Divider } from 'antd';
import { FilePdfOutlined } from '@ant-design/icons';
import { useExportPdfService } from '../../services/exportPdfService';
import globalNotification from '../../common/hooks/globalNotification';
import { notificationType } from '../../common/constants/notificationType';

function ExportPdf() {
    const exportPdfService = useExportPdfService();

    const downloadDocument = async (id, fileName) => {
        try {
            await exportPdfService.downloadPdfDocument(id, fileName);
        } catch (error) {
            globalNotification.open({
                type: notificationType.ERROR,
                message: 'Error',
                description: error.message,
            });
        }
    };

    return (
        <>
            <Divider orientation="left">
                <b>Export Document Template</b>
            </Divider>
            <p style={{ lineHeight: '1.3rem' }}>
                This template illustrates the process of exporting documents by fetching data from a database and
                managing responses.
                <br /> For more detailed instructions, please refer to the README file.
            </p>
            <div>
                <Button
                    style={{ fontWeight: 600, width: 160 }}
                    type="primary"
                    icon={<FilePdfOutlined />}
                    onClick={() => {
                        downloadDocument((document.id = 123), (document.name = 'exportExample'));
                    }}
                >
                    Save PDF
                </Button>
            </div>
        </>
    );
}
export default ExportPdf;
