import { Button, Flex, Form, Input, Typography } from 'antd';
import { useEffect, useState } from 'react';
import { useTeamService } from '../../services/teamService';
import classes from './style.module.scss';
import globalNotification from '../../common/hooks/globalNotification';
import { notificationType } from '../../common/constants/notificationType';
import { spacing, buttonStyle, primitiveColors, fontSize } from '../../theming';
import logo from '../../assets/Main_logo.png';
import { useMediaQuery } from '@uidotdev/usehooks';
import getInputRules, { inputType } from '../../utils/getInputRules';
import ModalWarning from '../../common/components/ModalWarning';
import { useTeamsContext } from '../../context/TeamsContext';

const { Text, Title } = Typography;

function CreateTeamForm({ activeTabKey, setActiveTabKey, t, currentUser }) {
    const { fetchTeams } = useTeamsContext();
    const [form] = Form.useForm();
    const [formValid, setFormValid] = useState(false);
    const [isTeamsLimitReached, setIsTeamsLimitReached] = useState();
    const mobileDevicesBreakpoint = useMediaQuery('only screen and (max-width : 840px)');
    const flexStyle = {
        width: mobileDevicesBreakpoint ? '100%' : 'clamp(300px, 85vw, 400px)',
        margin: mobileDevicesBreakpoint ? 0 : `${spacing[2]} 0`,
    };
    const { createTeam, checkTeamsLimit } = useTeamService();

    const handleCheckTeamsLimit = async () => {
        try {
            const isLimitExceeded = await checkTeamsLimit();
            setIsTeamsLimitReached(isLimitExceeded);
        } catch (error) {
            console.error('Error checking teams limit', error);
        }
    };

    useEffect(() => {
        form.setFieldsValue({ name: '' });
        handleCheckTeamsLimit();
    }, [activeTabKey]);

    const handleFormSubmit = async form => {
        if (isTeamsLimitReached) {
            return ModalWarning({
                title: t('teamCreation:modal.warning.title'),
                text: t('teamCreation:modal.warning.content'),
                okText: t('teamCreation:buttons.close'),
            });
        }

        await createTeam(form.name)
            .then(res => {
                globalNotification.open({
                    type: notificationType.SUCCESS,
                    message: t('notification.team-success.message'),
                    description: t('notification.team-success.description'),
                    placement: 'top',
                });
                setActiveTabKey('2');
                localStorage.setItem('teamId', res.data.id);

                if (currentUser.teamId) {
                    fetchTeams();
                }
            })
            .catch(error => {
                const errorCode = error.response.data?.code;
                globalNotification.open({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            });
    };

    const handleFormChange = async () => {
        try {
            await form.validateFields();
            setFormValid(true);
        } catch {
            setFormValid(false);
        }
    };

    return (
        <Flex vertical gap={spacing[4]} style={{ ...flexStyle }}>
            {!currentUser.teamId && (
                <img
                    src={logo}
                    alt="logo"
                    style={{
                        width: 60,
                        placeSelf: 'center',
                    }}
                />
            )}
            <Flex vertical gap={spacing[1]} style={{ ...flexStyle }}>
                <Text>{t('step-1')}</Text>
                <Title
                    level={2}
                    style={{
                        margin: 0,
                    }}
                >
                    {t('name-title')}
                </Title>
                <Text
                    style={{
                        marginBottom: spacing[4],
                    }}
                >
                    {t('name-sub')}
                </Text>
                <Form
                    className={classes.form}
                    form={form}
                    onFinish={event => handleFormSubmit(event)}
                    onChange={() => handleFormChange()}
                >
                    <Form.Item
                        style={{ width: '100%' }}
                        name="name"
                        rules={getInputRules(t, inputType.TEAM_NAME, t('form.team-req'))}
                    >
                        <Input
                            placeholder={t('name-example')}
                            style={{
                                backgroundColor: 'inherit',
                                padding: `${spacing[1]} ${spacing[3]}`,
                            }}
                            size="large"
                            prefix={
                                <i
                                    className="fi-rr-users"
                                    style={{
                                        color: primitiveColors.gray200,
                                        fontSize: fontSize.lg,
                                        margin: '5px 10px 0 0',
                                    }}
                                />
                            }
                        />
                    </Form.Item>
                    <Button
                        type="primary"
                        disabled={!formValid}
                        htmlType="submit"
                        style={{
                            ...buttonStyle.large,
                            placeSelf: !mobileDevicesBreakpoint && 'start',
                            width: mobileDevicesBreakpoint ? '100%' : 150,
                            border: 0,
                        }}
                    >
                        {t('buttons.next')}
                    </Button>
                </Form>
                {!currentUser.teamId && (
                    <Button
                        type="link"
                        onClick={() => setActiveTabKey('0')}
                        style={{
                            placeSelf: 'start',
                            padding: `${spacing[0.5]} ${spacing[2]}`,
                            marginTop: spacing[2],
                        }}
                        icon={
                            <i
                                className="fi-rr-arrow-left"
                                style={{ position: 'relative', top: 1, right: 5, fontSize: fontSize.lg }}
                            />
                        }
                    >
                        {t('buttons.back')}
                    </Button>
                )}
            </Flex>
        </Flex>
    );
}

export default CreateTeamForm;
