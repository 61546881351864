export const notifications = {
    type: {
        SUCCESS: 'success',
        INFO: 'info',
        WARNING: 'warning',
        ERROR: 'error',
    },
    resource: {
        USERS: 'users',
        LEADS: 'leads',
        BUSINESSES: 'businesses',
        MEMBERS: 'members',
        INVITATIONS: 'invitations',
        SUBSCRIPTIONS: 'subscriptions',
        COMMENTS: 'comments',
        LOGS: 'logs',
        ACTIVITY: 'activity',
        TEAMS: 'teams',
    },
    action: {
        ADD: 'add',
        UPDATE: 'update',
        LEAVE: 'leave',
        DELETE: 'delete',
        FIND: 'find',
        FETCH: 'fetch',
        VIEW: 'view',
        RESTORE: 'restore',
        ARCHIVE: 'archive',
        SAVE: 'save',
        CANCEL: 'cancel',
        CONFIRM: 'confirm',
        BACK: 'back',
        SEND: 'send',
        CREATE: 'create',
        RESET: 'reset',
    },
};
