import React from 'react';
import classes from './FileActionsDemo.module.scss';
import ClickToUploadFileButton from '../../components/fileUploadButtons/ClickToUploadFileButton';
import DragAndDropButton from '../../components/fileUploadButtons/DragAndDropButton';
import PicturesWallUpload from '../../components/fileUploadButtons/PicturesWallUpload';
import CropImageBeforeUpload from '../../components/fileUploadButtons/CropImageBeforeUpload';
import { Divider, Card, Flex } from 'antd';

function FileActionsDemo() {
    const baseApiUrl = '/action-demo-files';

    return (
        <>
            <Divider orientation="left">
                <b>File Actions Demo</b>
            </Divider>
            <p>
                Welcome to our file actions demo page! Here, you can easily upload, get or delete files from server.
                <br />
                We use AWS services for secure storage and management of your uploaded files. Simply choose the method
                that suits you best. It's that easy!
            </p>

            <Flex wrap="wrap" gap="middle" vertical={false} justify="space-around">
                <Card className={classes['upload-card']} title="Upload by clicking" size="small">
                    <p>
                        This example demonstrates a <b>single PDF file uploading.</b>
                    </p>
                    <ul>
                        <p className={classes.props}>Component props: </p>
                        <li>title(PDF),</li>
                        <li>multiple(false),</li>
                        <li>fieldName(file)</li>
                        <li>path(baseApiUrl='/action-demo-files')</li>
                    </ul>
                    <p>You can change them depending on the specific requirements.</p>
                    <p>Set fieldName and path values to specify a required endpoint</p>
                    <Divider />
                    <ClickToUploadFileButton
                        title={'PDF'}
                        fieldName={'file'}
                        fileType={'.pdf'}
                        isMultiple={false}
                        path={baseApiUrl}
                    ></ClickToUploadFileButton>
                </Card>

                <Card className={classes['upload-card']} title="Drag and Drop" size="small">
                    <p>
                        This example demonstrates a <b>TXT files uploading.</b>
                    </p>
                    <ul>
                        <p className={classes.props}>Component props: </p>
                        <li>title(TXT),</li>
                        <li>multiple(true),</li>
                        <li>beforeUpload(file type .txt only)</li>
                        <li>path(baseApiUrl='/action-demo-files')</li>
                        <li>fieldName(file)</li>
                    </ul>
                    <p>You can change them depending on the specific requirements.</p>
                    <p>Set fieldName and path values to specify a required endpoint</p>
                    <p>Warning：beforeUpload function is not supported in IE9</p>
                    <p>
                        You can drag files to a specific area, to upload. Alternatively, you can also upload by
                        selecting.
                    </p>
                    <Divider />
                    <DragAndDropButton
                        title={'TXT'}
                        fileTypes={['text/plain']}
                        isMultiple={true}
                        path={baseApiUrl}
                        fieldName={'file'}
                    ></DragAndDropButton>
                </Card>

                <Card className={classes['upload-card']} title="Pictures Wall" size="small">
                    <p>
                        This example demonstrates <b>JPG/PNG smaller than 2MB photos uploading (max count 4).</b>
                    </p>
                    <ul>
                        <p className={classes.props}>Component props: </p>
                        <li>multiple(true),</li>
                        <li>beforeUpload(fileTypes: Array('image/jpeg', 'image/png'), maxFileSizeMB: 2)</li>
                        <li>maxCount(4)</li>
                        <li>fieldName(file)</li>
                        <li>path(baseApiUrl='/action-demo-files')</li>
                    </ul>
                    <p>You can change props and beforeUpload rules depending on the specific requirements.</p>
                    <p>Set fieldName and path values to specify a required endpoint</p>
                    <p>Warning：beforeUpload function is not supported in IE9</p>
                    <p>
                        After users upload picture, the thumbnail will be shown in list. The upload button will
                        disappear when count meets limitation.
                    </p>
                    <Divider />
                    <PicturesWallUpload
                        isMultiple={true}
                        maxCount={4}
                        maxFileSizeMb={2}
                        fieldName={'file'}
                        fileTypes={['image/jpeg', 'image/png']}
                        path={baseApiUrl}
                    ></PicturesWallUpload>
                </Card>

                <Card className={classes['upload-card']} title="Crop image before uploading" size="small">
                    <p>
                        This example demonstrates <b>cropping images before uploading.</b>
                    </p>
                    <ul>
                        <p className={classes.props}>ImgCrop props: </p>
                        <li>fileQuality(0.6 - (0 to 1)),</li>
                        <li>fieldName(file)</li>
                        <li>path(baseApiUrl='/action-demo-files')</li>
                    </ul>
                    <p>Set fieldName and path values to specify a required endpoint</p>
                    <p>
                        This will help optimize the size of the image and improve the overall appearance of the uploaded
                        content.
                    </p>
                    <p>
                        <span>Uses </span>
                        <a
                            href="https://yarnpkg.com/package?q=antd-img-crop&name=antd-img-crop"
                            target="_blank"
                            rel="noreferrer"
                        >
                            image cropper for Ant Design
                        </a>{' '}
                        to crop image before uploading.
                    </p>
                    <Divider />
                    <CropImageBeforeUpload
                        fileQuality={0.6}
                        fieldName={'file'}
                        path={baseApiUrl}
                    ></CropImageBeforeUpload>
                </Card>
            </Flex>
        </>
    );
}

export default FileActionsDemo;
