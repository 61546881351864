import React from 'react';
import { Form, Input, Radio } from 'antd';
import { spacing, radius } from '../../theming';
import getInputRules, { inputType } from '../../utils/getInputRules';

export default function StatusForm({ status, index, colors, t, handleChange }) {
    const radioButtonStyle = {
        borderRadius: radius.huge,
        minWidth: spacing[4],
        minHeight: spacing[4],
        height: spacing[4],
        width: spacing[4],
        display: 'flex',
        alignContent: 'center',
        justifyContent: 'center',
        marginInlineEnd: 0,
        transition: 'all 0.1s',
    };

    return (
        <>
            <Form.Item
                name={`name_${index}`}
                rules={getInputRules(
                    t,
                    inputType.STATUS_NAME,
                    t('form.error.name-required'),
                    t('form.error.name-invalid')
                )}
                label={t('form.name')}
                initialValue={status.name}
            >
                <Input
                    placeholder={t('form.placeholder.name')}
                    onChange={e => handleChange(index, 'name', e.target.value)}
                />
            </Form.Item>
            <Form.Item
                name={`primaryColor_${index}`}
                rules={[{ required: true, message: t('form.error.color-required') }]}
                label={t('form.color')}
                initialValue={status.primaryColor}
            >
                <Radio.Group
                    onChange={e => handleChange(index, 'primaryColor', e.target.value)}
                    style={{
                        display: 'flex',
                        flexWrap: 'wrap',
                        gap: spacing[3],
                    }}
                    size="large"
                    initialValue={status.primaryColor}
                >
                    {Object.values(colors).map((color, index) => (
                        <Radio
                            key={index}
                            value={color}
                            style={{
                                backgroundColor: status.primaryColor !== color ? color : 'transparent',
                                border: status.primaryColor === color ? `7px solid ${color}` : 'none',
                                ...radioButtonStyle,
                            }}
                        />
                    ))}
                </Radio.Group>
            </Form.Item>
        </>
    );
}
