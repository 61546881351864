import { useState, useEffect } from 'react';
import { Form, Input, Space, Button, Flex, Tag } from 'antd';
import { BusinessesService } from '../../services/businessesService';
import { leadState } from '../../common/constants/leadState';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';
import { constants } from '../../common/constants/constants';
import classes from '../../common/styles/FormStyles.module.scss';
import { defaultSocialOptions } from '../../common/constants/defaultSocialOptions';
import { getDefaultSocialSelectValue, updateSocialsList, getSocialsOptionsList } from '../../utils/formHelper';
import { getSocialMediaValidationRules } from '../../common/constants/socialMediaValidationRules';
import { spacing, radius, primitiveColors, fontSize, inputStyle } from './../../theming';
import FormImageUpload from '../../common/components/FormImageUpload/FormImageUpload';
import { DeleteButton } from '../../common/components/DeleteButton/DeleteButton';
import Select from '../../common/components/Select';
import { useMedia } from '../../hooks/useMediaQuery';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import getRegexPattern from '../../common/constants/regexPatterns';
import getInputRules, { inputType } from '../../utils/getInputRules';

export const LeadForm = ({
    mode,
    lead,
    loading,
    handleAdd,
    handleEdit,
    isOpened,
    openPreviewDrawer,
    isClickFromView,
    statuses,
}) => {
    const [businesses, setBusinesses] = useState([]);
    const [phoneErrors, setPhoneErrors] = useState([]);
    const [form] = Form.useForm();
    const [socialMediaOptions, setSocialMediaOptions] = useState(defaultSocialOptions);
    const [socialsList, setSocialsList] = useState({});

    const { getAllActiveBusinesses } = BusinessesService();

    const { API_BASE_URL } = constants;
    const baseApiUrl = {
        upload: '/leads/upload-photo',
        delete: '/leads/delete-photo',
        get: '/leads/get-photo', // this is for fallback if url is not provided in the form while uploading photo
    };
    const { t } = useTranslation(['form', 'leads']);

    const { isSmallDevice } = useMedia();

    const tagStyle = {
        backgroundColor: primitiveColors.gray50,
        padding: spacing[1],
        fontSize: fontSize.sm,
        borderRadius: radius.md,
        marginLeft: 1,
    };

    // #region Handlers
    const handleSubmit = values => {
        const businessesIds = values.business ? values.business.map(business => business.value) : [];

        const socialMediaObject = values.socialMedia.reduce((acc, item) => {
            acc[item.type] = item.url;
            return acc;
        }, {});

        const newValues = {
            ...values,
            socialMedia: socialMediaObject,
            statusId: values.status,
            business: businessesIds,
        };

        delete newValues.photo;
        delete newValues.status; // we send only statusId to the backend

        if (mode === 'edit') {
            handleEdit(lead.id, newValues).then(updatedLead => {
                setTimeout(() => {
                    form.resetFields();
                }, 1000);

                if (isClickFromView) {
                    setTimeout(() => {
                        // we need setTimeout here for smooth animation, drawer closing takes around 500ms, after that we will open preview drawer
                        openPreviewDrawer(updatedLead);
                    }, 500);
                }
            });
        } else {
            handleAdd(newValues, () => form.resetFields());
        }
    };

    const fetchBusinessesData = async () => {
        const data = await getAllActiveBusinesses();
        const { businesses } = data;
        setBusinesses(businesses);
    };

    const handleSelectKeyDown = event => {
        if (event.key === 'Backspace') {
            form.setFieldsValue({ business: null });
        }
    };

    const handleSocialsListChange = (index, value) => {
        const newSocials = { ...socialsList };
        newSocials[index] = value;

        const newSocialMediaOptions = socialMediaOptions.map(option => {
            if (Object.values(newSocials).includes(option.value)) {
                return { ...option, disabled: true };
            }

            return { ...option, disabled: false };
        });

        form.setFieldValue(['socialMedia', index, 'url'], '');
        form.setFieldValue(['socialMedia', index, 'type'], value);
        setSocialMediaOptions(newSocialMediaOptions);
        setSocialsList(newSocials);
    };

    const handleAddSocialMediaField = () => {
        const keys = Object.keys(socialsList).map(Number);
        const maxKey = keys.length > 0 ? Math.max(...keys) : 0;

        const defaultSocialValue = getDefaultSocialSelectValue(socialsList);

        handleSocialsListChange(maxKey + 1, defaultSocialValue);
        form.setFieldValue(['socialMedia', maxKey + 1, 'type'], defaultSocialValue);
        setSocialsList({ ...socialsList, [maxKey + 1]: defaultSocialValue });
    };

    const handleRemoveSocialMediaField = (removeFunc, name) => {
        const { [name]: removed, ...remainingSocialsList } = socialsList;

        const newSocialsList = Object.fromEntries(
            Object.entries(remainingSocialsList).map(([key, value], index) => [index, value])
        );

        setSocialsList(newSocialsList);
        setSocialMediaOptions(prev =>
            prev.map(option => {
                if (option.value === removed) {
                    return {
                        ...option,
                        disabled: false,
                    };
                }

                return option;
            })
        );
        removeFunc(name);
    };

    const filterOption = (input, option) =>
        `${option?.firstName.toLowerCase()} ${option?.lastName.toLowerCase()}`.includes(input.toLowerCase());
    const defaultFilterOption = (input, option) => option.label.toLowerCase().indexOf(input.toLowerCase()) >= 0;
    // #endregion

    // #region useEffects
    useEffect(() => {
        fetchBusinessesData();
    }, []);

    useEffect(() => {
        if (mode === 'edit' && lead) {
            form.resetFields(); // resetting fields to avoid the form to be filled with the previous lead data

            const leadSocials = Object.keys(lead.socialMedia)
                .map(key => {
                    if (lead.socialMedia[key]) {
                        return { type: key, url: lead.socialMedia[key] };
                    }
                    return undefined;
                })
                .filter(item => item !== undefined) || [{ type: 'facebook', url: '' }];

            lead.socialMedia = leadSocials;

            if (lead.socialMedia.length < 1) {
                lead.socialMedia = [{ type: 'facebook', url: '' }];
            }

            const newSocialsList = updateSocialsList(lead);
            setSocialsList(newSocialsList); // updating socialsList to set default values in fields

            // updating social media options to disable the selected social media
            const { newSocialMediaOptions } = getSocialsOptionsList(socialMediaOptions, newSocialsList);
            setSocialMediaOptions(newSocialMediaOptions);

            // setting the form fields with the lead data
            form.setFieldsValue({
                ...lead,
                status: lead.status?.id,
                state: leadState[0].value,
                business: lead.business.map(b => ({ label: b.name, value: b.id })),
            });
        } else if (mode === 'add') {
            form.resetFields(); // resetting fields to avoid the form to be filled with the previous lead data
            // setting default values for the form fields: state, status, and phone number
            form.setFieldsValue({
                phoneNumber: [''],
                state: leadState[0].value,
                status: statuses[0].id,
                socialMedia: [{ type: 'facebook', url: '' }],
            });

            // setting options for social media fields
            setSocialMediaOptions(defaultSocialOptions);
        }
    }, [lead, form, mode]);

    useEffect(() => {
        // this is just to handle socialsList when the form is opened in 'add' mode
        if (mode === 'add') {
            setSocialsList({ 0: 'facebook' });
        }
    }, [isOpened, mode]);
    // #endregion

    return (
        <Form
            name="basic"
            layout="vertical"
            autoComplete="off"
            form={form}
            onFinish={values => {
                handleSubmit(values);
            }}
        >
            <Flex vertical>
                {mode === 'edit' && (
                    <Form.Item name="photo">
                        <FormImageUpload
                            fileQuality={0.7}
                            fieldName={'photo'}
                            path={baseApiUrl}
                            id={lead.key}
                            file={lead.photo && `${API_BASE_URL}/leads/get-photo/${lead.photo}`}
                            withPreviewModal
                            setFormFields={value => form.setFieldsValue('photo', value)}
                            title={t('lead-photo')}
                        />
                    </Form.Item>
                )}
                <Flex
                    vertical={isSmallDevice}
                    justify="space-between"
                    align="start"
                    gap={isSmallDevice ? 0 : spacing[5]}
                    style={{ width: '100%' }}
                >
                    <Form.Item
                        name="firstName"
                        label={t('first-name')}
                        rules={getInputRules(t, inputType.FIRST_NAME, t('errors.required'), t('errors.string'))}
                        style={{ width: '100%' }}
                    >
                        <Input
                            placeholder={t('placeholder.first-name')}
                            allowClear
                            style={{ ...inputStyle, width: '100%' }}
                        />
                    </Form.Item>
                    <Form.Item
                        name="lastName"
                        label={t('last-name')}
                        rules={getInputRules(t, inputType.LAST_NAME, t('errors.required'), t('errors.string'))}
                        style={{ width: '100%' }}
                    >
                        <Input
                            placeholder={t('placeholder.last-name')}
                            allowClear
                            style={{ ...inputStyle, width: '100%' }}
                        />
                    </Form.Item>
                </Flex>
                <Form.Item
                    name="email"
                    label={t('email')}
                    rules={getInputRules(t, inputType.EMAIL, t('errors.required'), t('errors.email'))}
                >
                    <Input placeholder="example@email.com" allowClear readOnly={mode === 'edit'} />
                </Form.Item>
                <Form.Item
                    name="description"
                    label={t('description')}
                    rules={getInputRules(t, inputType.DESCRIPTION, undefined, undefined, false)}
                >
                    <Input.TextArea
                        placeholder={t('placeholder.description')}
                        allowClear
                        maxLength={200}
                        style={{ height: spacing[15], padding: spacing[1] }}
                        autoSize={{
                            minRows: 2,
                            maxRows: 6,
                        }}
                    />
                </Form.Item>
                <Flex justify="space-between" align="start">
                    <Form.Item
                        name="status"
                        label={t('status')}
                        style={{ width: '48%' }}
                        rules={[
                            {
                                required: true,
                                message: t('errors.status'),
                            },
                        ]}
                    >
                        <Select
                            size="large"
                            placeholder={t('placeholder.status')}
                            options={statuses.map(status => ({ label: status.name, value: status.id }))}
                            style={{ height: '48px' }}
                            filterOption={defaultFilterOption}
                            onKeyDown={handleSelectKeyDown}
                            tagRender={({ label, _, closable, onClose }) => (
                                <Tag
                                    onMouseDown={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    closable={closable}
                                    onClose={onClose}
                                    style={{
                                        ...tagStyle,
                                    }}
                                    bordered={false}
                                >
                                    {label}
                                </Tag>
                            )}
                        />
                    </Form.Item>
                    <Form.Item
                        name="state"
                        label={t('state')}
                        style={{ width: '48%' }}
                        rules={[
                            {
                                required: true,
                                message: t('errors.state'),
                            },
                        ]}
                    >
                        <Select
                            size="large"
                            placeholder={t('placeholder.state')}
                            options={leadState.map(state => ({ label: state.name, value: state.value }))}
                            style={{ height: '48px' }}
                            filterOption={defaultFilterOption}
                            onKeyDown={handleSelectKeyDown}
                            tagRender={({ label, _, closable, onClose }) => (
                                <Tag
                                    onMouseDown={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                    }}
                                    closable={closable}
                                    onClose={onClose}
                                    style={{
                                        ...tagStyle,
                                    }}
                                    bordered={false}
                                >
                                    {label}
                                </Tag>
                            )}
                        />
                    </Form.Item>
                </Flex>
                <Form.Item name="business" label={t('business')}>
                    <Select
                        size="large"
                        labelInValue
                        placeholder={t('placeholder.business')}
                        options={businesses.map(business => ({ label: business.name, value: business.id }))}
                        mode="multiple"
                        allowClear
                        autoClearSearchValue
                        maxTagCount={2}
                        showSearch
                        filterOption={defaultFilterOption}
                        onKeyDown={handleSelectKeyDown}
                        style={{ height: '48px' }}
                        tagRender={({ label, _, closable, onClose }) => (
                            <Tag
                                onMouseDown={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                closable={closable}
                                onClose={onClose}
                                style={{
                                    backgroundColor: primitiveColors.gray50,
                                    padding: spacing[1],
                                    fontSize: fontSize.sm,
                                    borderRadius: radius.md,
                                    marginLeft: 1,
                                }}
                                bordered={false}
                            >
                                {label}
                            </Tag>
                        )}
                    />
                </Form.Item>
                <Form.Item>
                    <Form.List name="phoneNumber" initialvalue={['']}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => {
                                    const initialvalue =
                                        lead?.phoneNumber && lead?.phoneNumber.length > index
                                            ? lead.phoneNumber[index]
                                            : field.value;

                                    return (
                                        <Flex key={field.key} align="center">
                                            <Form.Item
                                                {...field}
                                                key={field.key}
                                                label={index === 0 ? t('primary-phone') : t('additional-phone')}
                                                name={[field.name]}
                                                style={{ marginBottom: spacing[4] }}
                                                required={mode === 'add' ? true : false}
                                                rules={[
                                                    ({ getFieldValue }) => ({
                                                        validator(_, value) {
                                                            let newErrorsArray = [...phoneErrors];
                                                            if (!value) {
                                                                newErrorsArray[index] = true;
                                                                setPhoneErrors(newErrorsArray);
                                                                return Promise.reject(t('errors.required'));
                                                            }

                                                            if (value.length < 16) {
                                                                newErrorsArray[index] = true;
                                                                setPhoneErrors(newErrorsArray);
                                                                return Promise.reject(t('errors.phone-short'));
                                                            }

                                                            const phoneValues = getFieldValue('phoneNumber');
                                                            let duplicatePhoneFound = false;

                                                            phoneValues.forEach((phone, i) => {
                                                                const duplicatePhone = phoneValues.some(
                                                                    (innerPhone, innerIndex) => {
                                                                        return innerIndex !== i && innerPhone === phone;
                                                                    }
                                                                );
                                                                if (duplicatePhone) {
                                                                    duplicatePhoneFound = true;
                                                                    newErrorsArray[index] = true;
                                                                    setPhoneErrors(newErrorsArray);
                                                                }
                                                            });

                                                            if (duplicatePhoneFound) {
                                                                return Promise.reject(t('errors.phone-unique'));
                                                            }

                                                            newErrorsArray[index] = false;
                                                            setPhoneErrors(newErrorsArray);
                                                            return Promise.resolve();
                                                        },
                                                    }),
                                                ]}
                                            >
                                                <Space size="0">
                                                    <PhoneInput
                                                        country={'us'}
                                                        value={mode === 'edit' ? initialvalue : ''}
                                                        containerClass={classes['phone-input-container']}
                                                        inputClass={classNames(classes['phone-input'], {
                                                            [classes['phone-input-error']]: phoneErrors[index],
                                                        })}
                                                        buttonClass={classNames(classes['phone-input-button'], {
                                                            [classes['phone-input-error']]: phoneErrors[index],
                                                        })}
                                                        placeholder="+1 (000) 000-0000"
                                                    />
                                                    {index === 0 ? null : (
                                                        <DeleteButton
                                                            onDelete={() => {
                                                                remove(field.name);
                                                                setPhoneErrors(prev => {
                                                                    prev.splice(index, 1);
                                                                    return [...prev];
                                                                }, []);
                                                            }}
                                                        />
                                                    )}
                                                </Space>
                                            </Form.Item>
                                        </Flex>
                                    );
                                })}

                                <Button
                                    onClick={() => add()}
                                    className={classes['add-phone-button']}
                                    icon={<i className={`fi-rr-add ${classes['add-phone-button-icon']}`} />}
                                >
                                    {t('buttons.add-phone')}
                                </Button>
                            </>
                        )}
                    </Form.List>
                </Form.Item>

                <Form.Item
                    name="source"
                    label={t('source')}
                    rules={getInputRules(
                        t,
                        undefined,
                        undefined,
                        undefined,
                        true,
                        true,
                        getRegexPattern('cyrillicNumbersSpecials')
                    )}
                >
                    <Input placeholder={t('placeholder.source')} allowClear />
                </Form.Item>
                <Form.Item
                    name="location"
                    label={t('location')}
                    rules={getInputRules(t, inputType.LOCATION, t('errors.required'), t('errors.string'))}
                >
                    <Input placeholder={t('placeholder.location')} allowClear />
                </Form.Item>
                <Form.Item label={t('socials')}>
                    <Form.List name="socialMedia" initialvalue={[{ type: 'facebook', url: '' }]}>
                        {(fields, { add, remove }) => {
                            return (
                                <>
                                    {fields.map(({ key, name, fieldKey, ...restField }, index) => {
                                        const initialvalue =
                                            lead?.socialMedia && lead?.socialMedia.length > index
                                                ? lead.socialMedia[index].url
                                                : form.getFieldValue(['socialMedia', index, 'type']);

                                        return (
                                            <Flex key={key} align="center" style={{ width: isSmallDevice && '100%' }}>
                                                <Form.Item
                                                    {...restField}
                                                    style={{ marginBottom: spacing[4], width: isSmallDevice && '100%' }}
                                                >
                                                    <Flex vertical={isSmallDevice} align="center" gap={spacing[1]}>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[socialsList[index], 'type']}
                                                            noStyle
                                                        >
                                                            <Flex
                                                                align="center"
                                                                justify="space-between"
                                                                style={{ width: isSmallDevice && '100%' }}
                                                            >
                                                                <Select
                                                                    filterOption={filterOption}
                                                                    style={{
                                                                        height: spacing[7],
                                                                        width: 82,
                                                                    }}
                                                                    options={socialMediaOptions}
                                                                    defaultValue={
                                                                        socialsList[index] ||
                                                                        form.getFieldValue([
                                                                            'socialMedia',
                                                                            index,
                                                                            'type',
                                                                        ])
                                                                    }
                                                                    onChange={e => {
                                                                        handleSocialsListChange(index, e);
                                                                    }}
                                                                    optionRender={option => {
                                                                        if (option.data.disabled) {
                                                                            return (
                                                                                <div style={{ opacity: 0.5 }}>
                                                                                    {option.data.label}
                                                                                </div>
                                                                            );
                                                                        }

                                                                        return option.data.label;
                                                                    }}
                                                                />
                                                                {index === 0
                                                                    ? null
                                                                    : isSmallDevice && (
                                                                          <DeleteButton
                                                                              onDelete={() => {
                                                                                  handleRemoveSocialMediaField(
                                                                                      remove,
                                                                                      name
                                                                                  );
                                                                              }}
                                                                          />
                                                                      )}
                                                            </Flex>
                                                        </Form.Item>
                                                        <Form.Item
                                                            {...restField}
                                                            name={[name, 'url']}
                                                            fieldKey={[fieldKey, 'url']}
                                                            noStyle
                                                            rules={[
                                                                getSocialMediaValidationRules(
                                                                    form.getFieldValue([
                                                                        'socialMedia',
                                                                        index,
                                                                        'type',
                                                                    ]) || 'facebook',
                                                                    t
                                                                ),
                                                            ]}
                                                        >
                                                            <Input
                                                                placeholder={t('placeholder.socials')}
                                                                style={{ width: isSmallDevice ? '100%' : 278 }}
                                                                initialvalue={mode === 'edit' ? initialvalue : ''}
                                                            />
                                                        </Form.Item>
                                                        {index === 0
                                                            ? null
                                                            : !isSmallDevice && (
                                                                  <DeleteButton
                                                                      onDelete={() => {
                                                                          handleRemoveSocialMediaField(remove, name);
                                                                      }}
                                                                  />
                                                              )}
                                                    </Flex>
                                                </Form.Item>
                                            </Flex>
                                        );
                                    })}

                                    {Object.keys(socialsList).length < 4 && (
                                        <Button
                                            onClick={() => handleAddSocialMediaField(add)}
                                            className={classes['add-phone-button']}
                                            icon={<i className={`fi-rr-add ${classes['add-phone-button-icon']}`} />}
                                        >
                                            {t('buttons.add-social')}
                                        </Button>
                                    )}
                                </>
                            );
                        }}
                    </Form.List>
                </Form.Item>
                <Flex
                    vertical={isSmallDevice}
                    justify="space-between"
                    align="start"
                    gap={isSmallDevice ? spacing[1] : spacing[5]}
                    style={{ width: '100%' }}
                >
                    <Form.Item
                        name="company"
                        label={t('place-of-job')}
                        style={{
                            width: isSmallDevice && '100%',
                        }}
                        rules={getInputRules(
                            t,
                            undefined,
                            undefined,
                            t('errors.string-number-special'),
                            false,
                            true,
                            getRegexPattern('cyrillicNumbersSpecials')
                        )}
                    >
                        <Input placeholder="Microsoft" allowClear />
                    </Form.Item>
                    <Form.Item
                        name="position"
                        label={t('position')}
                        style={{
                            width: isSmallDevice && '100%',
                        }}
                        rules={getInputRules(
                            t,
                            undefined,
                            undefined,
                            t('errors.string-number-special'),
                            false,
                            true,
                            getRegexPattern('cyrillicNumbersSpecials')
                        )}
                    >
                        <Input placeholder="Software Engineer" allowClear />
                    </Form.Item>
                </Flex>
                <Form.Item>
                    <Button className={classes['submit-button']} type="primary" htmlType="submit" loading={loading}>
                        {mode === 'add' ? t('leads:actions.add') : t('leads:actions.save')}
                    </Button>
                </Form.Item>
            </Flex>
        </Form>
    );
};
