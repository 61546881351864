import { iconColor, coreBrandColors } from '../../../theming';

export function getIconColors(isActive, theme) {
    switch (theme) {
        case 'dark':
            return iconColor.white;
        case 'light':
            return isActive ? coreBrandColors.blueRibbon : iconColor.primary;
        default:
            return isActive ? coreBrandColors.blueRibbon : iconColor.primary;
    }
}
