import { Flex, Tag } from 'antd';
import { fontSize, primitiveColors, spacing } from '../../theming';
import icon from '../../common/styles/Icon.module.scss';

export const LeadStatusTimeline = ({ currentStatus, marginTop, statuses }) => {
    const currentStatusIndex = statuses.findIndex(status => status.name === currentStatus);

    return (
        <Flex gap={spacing[1]} style={{ marginTop, flexWrap: 'wrap' }}>
            {statuses.map((status, index) => (
                <Tag
                    style={{
                        fontWeight: 500,
                        border: 'none',
                        height: 36,
                        fontSize: fontSize.xs,
                        marginRight: 0,
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        padding: `${spacing[1]} ${spacing[3]}`,
                        backgroundColor:
                            currentStatusIndex > index
                                ? status.secondaryColor
                                : currentStatusIndex < index
                                  ? primitiveColors.gray50
                                  : status.primaryColor,
                        color:
                            currentStatusIndex > index
                                ? status.primaryColor
                                : currentStatusIndex < index
                                  ? primitiveColors.gray700
                                  : primitiveColors.gray0,
                    }}
                >
                    {currentStatusIndex > index && (
                        <i
                            className={`fi-sr-check ${icon.xxs}`}
                            style={{ color: status.primaryColor, marginRight: spacing[1] }}
                        />
                    )}
                    {status.name}
                </Tag>
            ))}
        </Flex>
    );
};
