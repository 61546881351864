import { Button, Flex } from 'antd';
import { spacing, coreBrandColors, textStandard, fontSize, textColor } from '../../theming';

function AltTab({ handler, handleBack, classes, email, t }) {
    return (
        <>
            <Flex
                vertical
                align="center"
                gap={spacing[5]}
                style={{
                    maxWidth: 'clamp(300px, 85vw, 400px)',
                }}
            >
                <i className={`fi-ts-circle-envelope ${classes['email-icon']}`} />
                <h3
                    style={{
                        marginBottom: spacing[5],
                        fontFamily: "'Inter', sans-serif",
                    }}
                >
                    {t('check')}
                </h3>
            </Flex>
            <div className={classes.content}>
                <p>{t('check-sub')}</p>
                <p style={{ color: textColor.secondary }}>{email}</p>
                <Flex vertical justify="center" align="center" gap={spacing[6]}>
                    <Button
                        style={{
                            width: '100%',
                            borderColor: coreBrandColors.blueRibbon,
                            color: coreBrandColors.blueRibbon,
                            fontWeight: 500,
                        }}
                        size="large"
                        onClick={() => handler(email)}
                    >
                        {t('buttons.resend')}
                    </Button>
                    <Button
                        htmlType="button"
                        style={{
                            width: 'fit-content',
                            ...textStandard,
                        }}
                        type="link"
                        size="middle"
                        onClick={handleBack}
                        icon={
                            <i
                                className="fi-rr-arrow-left"
                                style={{ position: 'relative', top: 2, right: 5, fontSize: fontSize.lg }}
                            />
                        }
                    >
                        {t('buttons.back')}
                    </Button>
                </Flex>
            </div>
        </>
    );
}

export default AltTab;
