import { Empty, List } from 'antd';
import { Comment } from './commentsList/Comment';
import { Log } from './logsList/Log';
import { useTranslation } from 'react-i18next';

export const Journal = ({ logsAndComments, entityName, loading }) => {
    const { t } = useTranslation(['general']);

    return (
        <List
            dataSource={logsAndComments}
            renderItem={item => (
                <List.Item key={item.id} style={{ border: 'none' }}>
                    {item.action ? (
                        <Log log={item} key={item.createdAt} entityName={entityName} />
                    ) : (
                        <Comment comment={item} key={item.createdAt} />
                    )}
                </List.Item>
            )}
            locale={{
                emptyText: !loading ? <Empty description={t('general:no-data')} /> : ' ',
            }}
        />
    );
};
