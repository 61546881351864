import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from 'antd';
import { useAuthService } from '../../services/authService';
import { useAuth } from '../../context/AuthContext';
import { routesPath } from '../../common/constants/routesPath';
import { notificationType } from '../../common/constants/notificationType';
import { LOGIN_SCOPES } from '../../common/constants/googleScopes';
import { constants } from '../../common/constants/constants';
import { spacing, textColor, textStandard } from '../../theming';
import google from '../../assets/Google.png';

function GoogleSignInButton({ authType, setActiveTabKey, openNotification, t }) {
    let windowObjectReference = null;
    let previousUrl = null;
    let path = authType.toLowerCase();

    useEffect(() => {
        return () => {
            window.removeEventListener('message', receiveMessage, false);
        };
    }, []);

    const [loading, setLoading] = useState(false);
    const { setAuth } = useAuth();
    const navigate = useNavigate();
    const { googleAuth } = useAuthService();

    function encodeQueryData(data) {
        const ret = [];
        for (let d in data) {
            ret.push(encodeURIComponent(d) + '=' + encodeURIComponent(data[d]));
        }

        return ret.join('&');
    }

    const getCode = () => {
        const queryData = {
            scope: LOGIN_SCOPES,
            access_type: 'offline',
            // 'include_granted_scopes': 'true',
            response_type: 'code',
            redirect_uri: `${constants.BASE_URL}/auth/callback`,
            client_id: constants.GOOGLE.CLIENT_ID,
        };
        const queryString = encodeQueryData(queryData);

        const url = `https://accounts.google.com/o/oauth2/v2/auth?${queryString}`;

        openSignInWindow(url, 'google-redirect');
    };

    const openSignInWindow = (url, name) => {
        // Remove any existing event listeners
        window.removeEventListener('message', receiveMessage, false);

        // Window features
        const strWindowFeatures = 'toolbar=no, menubar=no, width=600, height=700, top=100, left=100';

        if (windowObjectReference === null || windowObjectReference.closed) {
            /* If the pointer to the window object in memory does not exist
             or if such pointer exists but the window was closed */
            windowObjectReference = window.open(url, name, strWindowFeatures);
        } else if (previousUrl !== url) {
            /* If the resource to load is different,
             then we load it in the already opened secondary window and then
             we bring such window back on top/in front of its parent window. */
            windowObjectReference = window.open(url, name, strWindowFeatures);
            windowObjectReference.focus();
        } else {
            /* Else the window reference must exist and the window
             is not closed; therefore, we can bring it back on top of any other
             window with the focus() method. There would be no need to re-create
             the window or to reload the referenced resource. */
            windowObjectReference.focus();
        }

        // Add the listener for receiving a message from the popup
        window.addEventListener('message', receiveMessage, false);
        // Assign the previous URL
        previousUrl = url;
    };

    const receiveMessage = useCallback(
        async event => {
            // Check if we could trust the event origin
            if (event.origin !== constants.BASE_URL) {
                return;
            }

            const { data } = event;
            // Check if the source is our popup
            if (event.source.name === 'google-redirect') {
                window.removeEventListener('message', receiveMessage, false);

                setLoading(true);
                let params = new URLSearchParams(data);
                const code = params.get('code');

                try {
                    const userTokens = await googleAuth(path, code);

                    if (userTokens?.data) {
                        const { accessToken, refreshToken, isTwoFactorEnable } = userTokens.data;

                        if (isTwoFactorEnable) {
                            setAuth(accessToken, null);
                            navigate(`${routesPath.AUTH.PATH}/${routesPath.AUTH.TWO_FACTOR_AUTH}`);
                        } else {
                            setAuth(accessToken, refreshToken);
                            navigate(routesPath.ROOT.PATH);
                        }
                    }

                    setLoading(false);
                } catch (error) {
                    openNotification({
                        type: notificationType.ERROR,
                        message: error.response?.data?.message ?? error.response.status,
                        description: error.response?.data?.description ?? error.response.statusText,
                    });
                    setLoading(false);
                    setActiveTabKey(error.response.data?.redirectToSignUp ? '1' : '0');
                    navigate('/auth/sign-in');
                }
                // Remove any existing event listeners to prevent multiple requests
                window.removeEventListener('message', receiveMessage, false);
            }
        },
        [authType]
    );

    return (
        <Button
            loading={loading}
            onClick={getCode}
            style={{
                background: 'inherit',
                width: '100%',
                color: textColor.secondaryAlt,
                ...textStandard,
                fontWeight: 500,
            }}
            icon={<img src={google} alt="google_icon" style={{ marginRight: spacing[1] }} />}
            size="large"
        >
            {t('auth:buttons.google')}
        </Button>
    );
}

export default GoogleSignInButton;
