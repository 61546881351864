import { useState } from 'react';
import { Form, Button, Flex } from 'antd';
import { buttonStyle, spacing } from '../../theming';
import classes from './style.module.scss';
import StatusForm from './statusForm';
import ListControlButtons from './listControlButtons';
import globalNotification from '../../common/hooks/globalNotification';
import { notificationType } from '../../common/constants/notificationType';
import { useMedia } from '../../hooks/useMediaQuery';

export default function AddStatus({ t, colors, handleCreateStatus }) {
    const [form] = Form.useForm();
    const [statusList, setStatusList] = useState([{ name: '', primaryColor: Object.values(colors)[0] }]);
    const { isSmallDevice } = useMedia();

    // #region handlers
    const handleAddStatus = () => {
        if (statusList.length < Object.keys(colors).length) {
            setStatusList(prevStatusList => [...prevStatusList, { name: '', primaryColor: Object.values(colors)[0] }]);
        } else {
            globalNotification.open({
                type: notificationType.WARNING,
                message: t('add-status.error.max-length'),
            });
        }
    };

    const handleRemoveStatus = index => {
        if (statusList.length > 1) {
            form.resetFields([`name_${index}`, `primaryColor_${index}`]);

            const newStatusList = statusList.filter((_, idx) => idx !== index);
            setStatusList(newStatusList);

            const updatedValues = {};
            newStatusList.forEach((_, newIndex) => {
                updatedValues[`name_${newIndex}`] = form.getFieldValue(`name_${newIndex + 1}`);
                updatedValues[`primaryColor_${newIndex}`] = form.getFieldValue(`primaryColor_${newIndex + 1}`);
            });

            form.setFieldsValue(updatedValues);

            const removedIndex = newStatusList.length;
            form.resetFields([`name_${removedIndex}`, `primaryColor_${removedIndex}`]);
        }
    };

    const handleChange = (index, field, value) => {
        const newStatusList = statusList.map((status, idx) => {
            if (idx === index) {
                return { ...status, [field]: value };
            }
            return status;
        });

        setStatusList(newStatusList);
    };

    const handleCreate = async () => {
        await handleCreateStatus(statusList);

        setStatusList(prev => [{ name: '', primaryColor: Object.values(colors)[0] }]);

        setTimeout(() => {
            form.resetFields();
        }, 0); // queue macrotask because form should reset only after set state
    };
    // #endregion

    return (
        <Form layout="vertical" name="add_status" form={form} onFinish={handleCreate} className={classes.form}>
            {statusList.map((status, index) => (
                <Flex vertical key={index} style={{ marginBottom: spacing[4] }}>
                    <StatusForm
                        key={index}
                        status={status}
                        index={index}
                        colors={colors}
                        t={t}
                        handleChange={handleChange}
                    />
                    <ListControlButtons
                        index={index}
                        t={t}
                        handleAdd={handleAddStatus}
                        handleRemove={handleRemoveStatus}
                        statusList={statusList}
                    />
                </Flex>
            ))}
            <Form.Item>
                <Button
                    type="primary"
                    htmlType="submit"
                    style={{
                        ...(isSmallDevice ? buttonStyle.medium : buttonStyle.large),
                        width: 125,
                        marginTop: spacing[2],
                    }}
                >
                    {t('add-status.button')}
                </Button>
            </Form.Item>
        </Form>
    );
}
