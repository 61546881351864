import dayjs from 'dayjs';
import { useAxios } from './axiosService';

export const useLeadService = () => {
    const { axios } = useAxios();

    const getIds = leads => {
        const { ids } = leads.reduce(
            (acc, lead) => {
                acc.ids.push(lead.id);
                return acc;
            },
            { ids: [] }
        );
        return ids;
    };

    const getAllLeads = async ({
        currentPage,
        pageSize,
        search,
        business,
        state,
        status,
        sortField,
        sortOrder,
        isDeleted,
    }) => {
        return await axios.get('/leads', {
            params: {
                offset: (currentPage - 1) * pageSize,
                limit: pageSize,
                search: search,
                business,
                state,
                status,
                sortField,
                sortOrder,
                isDeleted,
            },
        });
    };

    const getLeadById = async id => {
        return await axios.get(`/leads/${id}`);
    };

    const createLead = async body => {
        await axios.post('/leads', body);
    };

    const updateLead = async (id, body) => {
        delete body.email;
        const response = await axios.put(`/leads/${id}`, body);

        return response.data;
    };

    const changeLeadsStatus = async (ids, statusId) => {
        const response = await axios.put('/leads/change-status', { ids, statusId });

        return response.data;
    };

    const countLeadsOverPeriod = async (startDate, endDate, businessesIds) => {
        const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
        const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');

        const response = await axios.get('/leads/total-leads', {
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                businessesIds,
            },
        });

        return response.data;
    };

    const getFiveRecentLeads = async (startDate, endDate, businessesIds) => {
        const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
        const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');

        const response = await axios.get('/leads/recent-leads', {
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                businessesIds,
            },
        });

        return response.data;
    };

    const getLeadsPerBusinesses = async (startDate, endDate, businessesIds) => {
        const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
        const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');

        const response = await axios.get('/leads/leads-per-business', {
            params: {
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                businessesIds,
            },
        });

        return response.data;
    };

    const getLeadsVolumePerBusinesses = async (startDate, endDate, businessesIds, mode) => {
        const formattedStartDate = dayjs(startDate).format('YYYY-MM-DD');
        const formattedEndDate = dayjs(endDate).format('YYYY-MM-DD');

        const response = await axios.get('/leads/leads-volume', {
            params: {
                mode,
                startDate: formattedStartDate,
                endDate: formattedEndDate,
                businessesIds,
            },
        });

        return response.data;
    };

    const deleteLead = async leads => {
        const ids = getIds(leads);
        await axios.delete('/leads', { data: { ids } });
    };

    const archive = async leads => {
        const ids = getIds(leads);
        await axios.patch('/leads/archive', { ids });
    };

    const restore = async leads => {
        const ids = getIds(leads);
        await axios.patch('/leads/restore', { ids });
    };

    const getLeadComments = async (id, skip, take) => {
        const response = await axios.get(`/leads/comments/${id}?skip=${skip}&take=${take}`);

        return response.data;
    };

    const leaveLeadComment = async (id, content) => {
        const response = await axios.post(`/leads/comments/${id}`, { content });

        return response.data;
    };

    const deleteLeadComment = async id => {
        await axios.delete(`/leads/comments/${id}`);
    };

    const editLeadComment = async (id, content) => {
        const response = await axios.put(`/leads/comments/${id}`, { content });

        return response.data;
    };

    const getLeadLogs = async (id, skip, take) => {
        const response = await axios.get(`/leads/logs/${id}?skip=${skip}&take=${take}`);

        return response.data;
    };

    const getLeadLogsAndComments = async (id, logsSkip, logsTake, commentsSkip, commentsTake) => {
        const response = await axios.get(
            `/leads/logs-and-comments/${id}?logsSkip=${logsSkip}&logsTake=${logsTake}&commentsSkip=${commentsSkip}&commentsTake=${commentsTake}`
        );

        return response.data;
    };

    // #region statuses
    const getStatuses = async () => {
        return await axios.get(`/leads/statuses`);
    };

    const createStatus = async body => {
        await axios.post('/leads/status', body);
    };

    const updateStatus = async (id, body) => {
        await axios.put(`/leads/status/${id}`, body);
    };

    const updateStatusOrder = async statuses => {
        await axios.put('/leads/statuses/order', statuses);
    };

    const deleteStatus = async id => {
        await axios.delete(`/leads/status/${id}`);
    };
    // #endregion

    return {
        getAllLeads,
        getLeadById,
        createLead,
        updateLead,
        deleteLead,
        countLeadsOverPeriod,
        getFiveRecentLeads,
        getLeadsPerBusinesses,
        getLeadsVolumePerBusinesses,
        archive,
        restore,
        getLeadComments,
        leaveLeadComment,
        deleteLeadComment,
        editLeadComment,
        getLeadLogs,
        getLeadLogsAndComments,
        getStatuses,
        createStatus,
        updateStatus,
        updateStatusOrder,
        deleteStatus,
        changeLeadsStatus,
    };
};
