import { assetsConstants } from '../common/constants/assetsConstants';
import { teamRolesPriority } from '../common/constants/TeamRolesPriority';

/**
 * This function checks if the current user is a super admin
 * @param {object} currentUser
 * @returns {boolean} returns true if the current user is a super admin
 */
export function checkForSuperAdmin(currentUser) {
    return currentUser?.userRoleNames?.some(role => assetsConstants.ADMIN_ROLES.includes(role));
}

/**
 * This function checks if the current user has a specific team role or is a super admin
 * @param {object} currentUser - the current user logged in
 * @param {string} role - the team role to check for
 * @returns {boolean} returns true if the current user has the required team role or is a super admin. Otherwise, returns false. The result of truthiness depends on TeamRolesPriority.js
 * @see {@link teamRolesPriority} for more information
 */
export function checkTeamRole(currentUser, role) {
    if (checkForSuperAdmin(currentUser)) {
        return true;
    }

    const userRoleIndex = teamRolesPriority.indexOf(currentUser?.teamRole);
    const requiredRoleIndex = teamRolesPriority.indexOf(role);

    return userRoleIndex >= 0 && requiredRoleIndex >= 0 && userRoleIndex <= requiredRoleIndex;
}
