import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Divider, Card, Flex } from 'antd';
import { loadStripe } from '@stripe/stripe-js';
import { Elements } from '@stripe/react-stripe-js';
import { constants } from '../../common/constants/constants';
import CheckoutForm from '../../components/payments/CheckoutForm';

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(constants.STRIPE_PUBLIC_KEY);

function Payments() {
    const location = useLocation();
    const paymentData = location.state.orderData;

    const [clientSecret, setClientSecret] = useState('');

    useEffect(() => {
        setClientSecret(paymentData.clientSecret);
    }, [paymentData]);

    const appearance = {
        theme: 'flat',
    };

    const options = {
        clientSecret,
        appearance,
    };

    return (
        <>
            <Divider orientation="left">
                <b>Payment Demo</b>
            </Divider>

            <p>This page is used to demonstrate how the Stripe payment system works.</p>
            <p>The packages used to work with Stripe are 'React Stripe.js' and 'Stripe.js'</p>
            <ul>
                <li>
                    React Stripe.js - is a thin wrapper around Stripe Elements. It allows you to add Elements to any
                    React app.
                </li>
                <li>Stripe.js - reference covers complete Elements customization details.</li>
            </ul>
            <p>
                For more information visit{' '}
                <a href="https://docs.stripe.com/stripe-js/react#setup" target="blank">
                    Stripe Docs
                </a>
            </p>

            {clientSecret && (
                <Card title="Payment">
                    <Flex
                        vertical={false}
                        align="center"
                        justify="space-between"
                        style={{ width: '30vw', 'min-width': '500px', margin: '0 auto' }}
                    >
                        <h3>Order Number: {paymentData.orderNumber}</h3>
                        <p>
                            Total Cost: <b>{paymentData.amountInCents / 100} $</b>
                        </p>
                    </Flex>
                    <Flex vertical={false} align="center" justify="center">
                        <Elements options={options} stripe={stripePromise}>
                            <CheckoutForm />
                        </Elements>
                    </Flex>
                </Card>
            )}

            <h3>Testing interactively</h3>
            <p>
                To confirm that your integration works correctly, simulate transactions without moving any money using
                special values in test mode.
            </p>
            {/* <p>When testing interactively, use a card number, such as 4242 4242 4242 4242.</p> */}
            <p>To simulate a successful payment, use test card 4242 4242 4242 4242.</p>
            <p>
                To test your integration's error-handling logic by simulating payments that the issuer declines - 4000
                0000 0000 0002. You can find the full list of available cards{' '}
                <a href="https://docs.stripe.com/testing?testing-method=card-numbers#declined-payments" target="blank">
                    here.
                </a>
            </p>
            <ul>
                <li>Use a valid future date, such as 12/33.</li>
                <li>Use any three-digit CVC.</li>
                <li>Use any value you like for other form fields.</li>
            </ul>
            <br />
        </>
    );
}

export default Payments;
