import { Card, Flex, Typography } from 'antd';
import { primitiveColors, spacing } from '../../../theming';

export const PreviewCardInfo = ({ title, items }) => (
    <Card styles={{ body: { paddingInline: spacing[3], paddingBlock: spacing[2] } }} style={{ width: '100%' }}>
        <Flex gap={spacing[1]} vertical>
            <Typography.Text style={{ fontWeight: 500, color: primitiveColors.gray400 }}>{title}</Typography.Text>

            <div>
                {items.length > 0 ? (
                    items.map(item => (
                        <Typography.Text
                            style={{ fontWeight: 500, color: primitiveColors.gray700, marginTop: spacing[1] }}
                        >
                            {item}
                        </Typography.Text>
                    ))
                ) : (
                    <Typography.Text style={{ fontWeight: 500, color: primitiveColors.gray700 }}>None</Typography.Text>
                )}
            </div>
        </Flex>
    </Card>
);
