import { useNavigate, useParams } from 'react-router-dom';
import globalNotification from '../../common/hooks/globalNotification';
import { notificationType } from '../../common/constants/notificationType';
import { useEffect, useState } from 'react';
import { useAuthService } from '../../services/authService';

export const RestorePasswordGuard = ({ children }) => {
    const params = useParams();
    const [tokenValid, setTokenValid] = useState(false);
    const navigate = useNavigate();
    const { validateRestoreToken } = useAuthService();

    useEffect(() => {
        validateRestoreToken(params.token)
            .then(res => setTokenValid(res.data))
            .catch(error => {
                globalNotification.open({
                    type: notificationType.ERROR,
                    message: error.response?.data?.message ?? error.response?.status,
                    description: error.response?.data?.description ?? error.response?.statusText,
                    placement: 'bottomRight',
                });
                navigate('/auth/sign-in');
            });
    }, []);

    return tokenValid ? children : null;
};
