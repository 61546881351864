import { Empty, Select } from 'antd';
import classes from '../styles/FormStyles.module.scss';
import { useTranslation } from 'react-i18next';

const CustomSelect = ({ suffixIcon, children, ...rest }) => {
    const { t } = useTranslation(['general']);
    return (
        <Select
            {...rest}
            suffixIcon={suffixIcon || <i className={`fi-rr-angle-small-down ${classes['select-suffix-icon']}`} />}
            notFoundContent={<Empty description={t('general:no-data')} />}
        >
            {children}
        </Select>
    );
};

export default CustomSelect;
