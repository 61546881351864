import { useState, useEffect, Suspense, lazy, useCallback } from 'react';
import { Button, Flex, Row } from 'antd';
import { spacing, buttonStyle, fontSize } from '../../theming';
import logo from '../../assets/Main_logo.png';
import { useSubscriptionService } from '../../services/subscriptionService';
import { useMedia } from '../../hooks/useMediaQuery';

const SubscriptionCard = lazy(() => import('../subscriptions/subscription-card'));

function UpgradePlan({ activeTabKey, setActiveTabKey, t, currentUser }) {
    const [subscriptions, setSubscriptions] = useState([]);
    const [userSubscription, setUserSubscription] = useState();
    const [userSubscriptionLoading, setUserSubscriptionLoading] = useState(true);
    const { isSmallDevice } = useMedia();

    const { getAllSubscriptions, getUserSubscription } = useSubscriptionService();

    const fetchSubscriptions = useCallback(async () => {
        try {
            const userSubscriptionResponse = await getUserSubscription(localStorage.getItem('teamId'));
            setUserSubscription(userSubscriptionResponse.data);
            setUserSubscriptionLoading(false);

            const subscriptionsResponse = await getAllSubscriptions();
            setSubscriptions(subscriptionsResponse.data.reverse());
        } catch {
            console.error('Error fetching subscriptions'); // just to handle errors, no need in notification
        }
    }, [getUserSubscription, getAllSubscriptions]);

    useEffect(() => {
        fetchSubscriptions();
    }, [activeTabKey]);

    const handleSubscriptionChange = useCallback(() => {
        fetchSubscriptions();
    }, [fetchSubscriptions]);

    return (
        <Flex
            vertical
            style={{
                marginTop: isSmallDevice ? spacing[4] : 0,
            }}
        >
            {!currentUser?.teamId && (
                <img
                    src={logo}
                    alt="logo"
                    style={{
                        width: 60,
                    }}
                />
            )}
            <h1
                style={{
                    fontFamily: "'Inter', sans-serif",
                    placeSelf: isSmallDevice ? 'center' : 'start',
                    fontSize: isSmallDevice && fontSize.H4,
                }}
            >
                {t('upgrade-tab-title')}
            </h1>
            <Flex align="center" justify="end">
                <Button
                    type="primary"
                    style={{
                        ...buttonStyle.large,
                    }}
                    onClick={() => setActiveTabKey('2')}
                >
                    {t('buttons.proceed')}
                </Button>
            </Flex>
            <Row
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
                style={{
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: `${spacing[4]} 0 ${spacing[6]} 0`,
                    gap: spacing[2],
                    minHeight: 760,
                    height: '100%',
                    width: '100%',
                }}
            >
                <Suspense fallback={<span>{t('loading')}</span>}>
                    {subscriptions.map(subscription => (
                        <SubscriptionCard
                            key={subscription.subscriptionId}
                            subscription={subscription}
                            userPriority={userSubscription}
                            loading={userSubscriptionLoading}
                            setLoading={setUserSubscriptionLoading}
                            handleSubscriptionChange={handleSubscriptionChange}
                            isTeamCreation
                        />
                    ))}
                </Suspense>
            </Row>
        </Flex>
    );
}
export default UpgradePlan;
