import { coreBrandColors, primitiveColors } from './colors';
import { backgroundColor, textColor, borderColor } from './themeColors';
import { radius } from './radius';
import { fontSize, lineHeight } from './typography';
import { spacing } from './spacing';

export const components = {
    // Here can be defined the components' styles
    Button: {
        colorPrimary: backgroundColor.Action,
        colorPrimaryActive: backgroundColor.ActionActive,
        colorPrimaryBorder: borderColor.actionPrimary,
        colorPrimaryHover: backgroundColor.ActionHover,
        colorTextLightSolid: textColor.white,
        borderRadius: radius.md,
        defaultBg: backgroundColor.white,
        defaultHoverBg: primitiveColors.blue100,
        defaultActiveBg: primitiveColors.blue200,
        defaultBorderColor: backgroundColor.Action,
        defaultColor: textColor.brandPrimary,
        textHoverBg: primitiveColors.blue100,
        colorBgTextActive: primitiveColors.blue200,
        colorText: textColor.brandPrimary,
        colorTextDisabled: primitiveColors.gray200,
        borderColorDisabled: 'transparent',
        colorBgContainerDisabled: primitiveColors.gray50,
        colorError: backgroundColor.error,
        colorErrorBg: backgroundColor.error,
        colorErrorBgActive: primitiveColors.red700,
        colorErrorHover: primitiveColors.red700,
        colorErrorBorderHover: primitiveColors.red600,
        colorErrorActive: backgroundColor.errorActive,
        colorLink: primitiveColors.gray200,
    },
    Menu: {
        darkItemSelectedBg: coreBrandColors.mintMorning,
        itemSelectedBg: 'transparent',
        darkItemSelectedColor: textColor.primary,
        itemSelectedColor: coreBrandColors.blueRibbon,
        darkItemBg: coreBrandColors.blueRibbon,
        itemBg: coreBrandColors.blueRibbon,
        darkItemHoverBg: primitiveColors.blue600,
        darkItemActiveBg: coreBrandColors.mintMorning,
        darkItemColor: textColor.white,
        itemColor: textColor.secondary,
        darkItemHeight: spacing[7],
        itemHeight: spacing[7],
        darkItemBorderRadius: radius.md,
        itemBorderRadius: radius.md,
        popupBg: coreBrandColors.blueRibbon,
        fontSize: fontSize.sm,
        iconSize: fontSize.lg,
        lineHeight: lineHeight.md,
        iconMarginInlineEnd: spacing[3],
        collapsedIconSize: fontSize.sm,
        colorBgElevated: coreBrandColors.mintMorning,
        colorTextHeading: textColor.white,
    },
    Layout: {
        siderBg: coreBrandColors.blueRibbon,
        lightSiderBg: primitiveColors.gray50,
        headerBg: primitiveColors.gray0,
        contentBg: backgroundColor.page,
    },
    Input: {
        size: 'large',
        colorTextPlaceholder: textColor.placeholder,
        colorText: textColor.secondary,
        fontSizeIcon: spacing[3],
        activeBorderColor: primitiveColors.blue400,
        hoverBorderColor: primitiveColors.blue400,
        activeShadow: 'none',
        inputFontSize: fontSize.sm,
        inputFontSizeLG: fontSize.sm,
        paddingBlock: spacing[2],
        paddingInline: spacing[2],
        colorErrorText: primitiveColors.red500,
        lineHeight: lineHeight.md,
    },
    Form: {
        labelColor: primitiveColors.gray900,
        itemMarginBottom: spacing[5],
        labelRequiredMarkColor: primitiveColors.orange500,
        verticalLabelMargin: `0 0 ${spacing[0.5]} 0`,
        verticalLabelPadding: 0,
    },
    Upload: {
        padding: 0,
        lineWidth: 0,
    },
    Password: {
        iconRender: visible =>
            visible ? (
                <span>
                    <i className="fi fi-rr-eye"></i>
                </span>
            ) : (
                <span>
                    <i className="fi fi-rr-eye-crossed"></i>
                </span>
            ),
    },
    Select: {
        colorTextPlaceholder: textColor.placeholder,
        optionSelectedBg: primitiveColors.blue50,
        optionPadding: `${spacing[1]} ${spacing[2]}`,
        optionSelectedFontWeight: 500,
        selectorBg: primitiveColors.gray0,
        lineHeight: 1,
        colorBgContainerDisabled: primitiveColors.gray50,
        colorBorder: primitiveColors.gray100,
        colorTextDisabled: primitiveColors.gray200,
        borderRadius: radius.md,
        activeShadow: 'none',
        inputFontSize: fontSize.sm,
        paddingBlock: spacing[2],
        paddingInline: spacing[2],
        colorErrorText: primitiveColors.red500,
        controlOutlineWidth: 0,
        colorIcon: textColor.subtle,
    },
    Pagination: {
        itemActiveBg: primitiveColors.blue50,
        itemSize: spacing[5],
        lineWidth: 0,
        colorText: primitiveColors.gray500,
    },
    Table: {
        headerBg: primitiveColors.blue0,
        footerBg: primitiveColors.gray0,
        headerColor: textColor.subtle,
        borderColor: primitiveColors.gray100,
        rowHoverBg: 'inherit',
        colorText: textColor.secondary,
        rowExpandedBg: primitiveColors.blue50,
        bodySortBg: 'inherit',
        headerSortActiveBg: primitiveColors.blue0,
        headerSortHoverBg: primitiveColors.blue0,
        rowSelectedBg: primitiveColors.blue50,
    },
    Checkbox: {
        borderRadiusSM: '2px',
        colorBorder: primitiveColors.gray400,
        colorPrimaryBorder: primitiveColors.blue500,
        paddingXS: spacing[1],
    },
    Tag: {
        borderRadiusSM: radius.md,
    },
    Typography: {
        colorText: textColor.secondary,
    },
    Segmented: {
        itemColor: textColor.primary,
        itemHoverBg: primitiveColors.gray50,
        itemActiveBg: primitiveColors.gray50,
        trackBg: primitiveColors.gray50,
        trackPadding: `${spacing[0.5]} ${spacing[1]}`,
        borderRadius: radius.md,
    },
    Tooltip: {
        sizePopupArrow: 0,
    },
    DatePicker: {
        activeBorderColor: coreBrandColors.blueRibbon,
        activeShadow: 'none',
        inputFontSize: fontSize.sm,
        paddingInline: spacing[3],
        colorBorder: primitiveColors.gray100,
        colorTextPlaceholder: primitiveColors.gray300,
    },
    Modal: {
        padding: 0,
    },
    Descriptions: {
        itemPaddingBottom: 0,
    },
};
