import { coreBrandColors, primitiveColors } from './colors';
import { radius } from './radius';
import { backgroundColor, borderColor, textColor } from './themeColors';

export const token = {
    colorPrimary: coreBrandColors.blueRibbon,
    colorSuccess: textColor.success,
    colorWarning: textColor.warning,
    colorError: textColor.error,
    colorTextBase: textColor.primary,
    colorBgBase: backgroundColor.white,
    colorWhite: primitiveColors.gray0,
    colorLink: textColor.link,
    borderRadius: radius.md,
    colorBorder: borderColor.primary,
    fontFamily: '"Inter", sans-serif',
};
