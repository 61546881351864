import { useState } from 'react';
import { notification, Tabs } from 'antd';
import classes from '../../common/styles/FormStyles.module.scss';
import { useNavigate } from 'react-router-dom';
import { notificationType } from '../../common/constants/notificationType';
import { routesPath } from '../../common/constants/routesPath';
import logo from '../../assets/Main_logo.png';
import MainTab from '../../components/restorePassword/restoreMainTab';
import AltTab from '../../components/restorePassword/restoreAltTab';
import { useTranslation } from 'react-i18next';
import { useAuthService } from '../../services/authService';

export const ForgotPassword = () => {
    const [activeTabKey, setActiveTabKey] = useState('0');
    const [email, setEmail] = useState('');
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const { t } = useTranslation(['forgotPassword']);
    const { forgotPassword } = useAuthService();

    const openNotification = ({ type = 'error', message = '', description = '', placement = 'bottomRight' }) => {
        api[type]({
            message: message,
            description: description,
            placement: placement,
        });
    };

    const handleBack = () => {
        navigate(routesPath.AUTH.PATH);
    };

    const sendEmail = async email => {
        try {
            await forgotPassword(email);
            setActiveTabKey('1');
            openNotification({
                type: notificationType.SUCCESS,
                message: t('notification.success.message'),
                description: t('notification.success.description'),
            });
        } catch {
            openNotification({
                type: notificationType.ERROR,
                message: t('notification.error.message'),
                description: t('notification.error.description'),
            });
        }
    };

    const onFormSubmit = async form => {
        setEmail(form.email);
        sendEmail(form.email);
    };

    const items = [
        {
            key: '0',
            children: <MainTab handler={onFormSubmit} classes={classes} handleBack={handleBack} t={t} />,
        },
        {
            key: '1',
            children: <AltTab handler={sendEmail} classes={classes} email={email} handleBack={handleBack} t={t} />,
        },
    ];

    return (
        <div className={classes['auth-wrapper']}>
            {contextHolder}
            <img src={logo} alt="InterCode_logo" className={classes.logo} />
            <Tabs activeKey={activeTabKey} centered items={items} />
        </div>
    );
};
