import React, { useEffect, useState } from 'react';
import { PaymentElement, useStripe, useElements } from '@stripe/react-stripe-js';
import classes from './CheckoutForm.module.scss';
import { constants } from '../../common/constants/constants';
import { routesPath } from '../../common/constants/routesPath';

export default function CheckoutForm() {
    const stripe = useStripe();
    const elements = useElements();

    const [message, setMessage] = useState(null);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (!stripe) {
            return;
        }

        const clientSecret = new URLSearchParams(window.location.search).get('payment_intent_client_secret');

        if (!clientSecret) {
            return;
        }

        stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
            switch (paymentIntent.status) {
                case 'succeeded':
                    setMessage('Payment succeeded!');
                    break;
                case 'processing':
                    setMessage('Your payment is processing.');
                    break;
                case 'requires_payment_method':
                    setMessage('Your payment was not successful, please try again.');
                    break;
                default:
                    setMessage('Something went wrong.');
                    break;
            }
        });
    }, [stripe]);

    const handleSubmit = async e => {
        e.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        setIsLoading(true);

        const { error } = await stripe.confirmPayment({
            elements,
            confirmParams: {
                return_url: constants.BASE_URL + routesPath.ROOT.ORDERS,
            },
        });

        if (error.type === 'card_error' || error.type === 'validation_error') {
            setMessage(error.message);
        } else {
            setMessage('An unexpected error occurred.');
        }

        setIsLoading(false);
    };

    const paymentElementOptions = {
        layout: 'tabs',
    };

    return (
        <form className={classes['payment-form']} onSubmit={handleSubmit}>
            <PaymentElement className={classes['payment-element']} options={paymentElementOptions} />
            <button className={classes['payment-submit']} disabled={isLoading || !stripe || !elements}>
                <span>{isLoading ? <div className={classes.spinner}></div> : 'Pay now'}</span>
            </button>
            {/* Show any error or success messages */}
            {message && <div className={classes['payment-message']}>{message}</div>}
        </form>
    );
}
