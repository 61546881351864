import { useAxios } from './axiosService';

export const useUserService = () => {
    const { axios } = useAxios();

    const getUser = async () => {
        return await axios.get('/auth/me');
    };
    const getUserById = async id => {
        return await axios.get(`/users/${id}`);
    };
    const getAllUsers = async ({ offset, limit, isDeleted, globalSearch, status, roles, sortField, sortOrder }) => {
        return await axios.post('/users', {
            offset: (offset - 1) * limit,
            limit: limit,
            isDeleted,
            globalSearch,
            status,
            roles,
            sortField,
            sortOrder,
        });
    };

    const getUserRoles = async () => {
        return await axios.get('/users/user-roles');
    };

    const updateUser = async body => {
        await axios.put('/users/update-user', body);
    };

    const updateProfile = async body => {
        await axios.put('/users/update-profile', body);
    };

    const deleteUsers = async ids => {
        await axios.delete('/users/', {
            data: { ids },
        });
    };

    const archiveUsers = async ids => {
        await axios.patch('/users/archive', { ids });
    };

    const unarchiveUsers = async ids => {
        await axios.patch('/users/restore', { ids });
    };

    return {
        getUser,
        getUserById,
        getAllUsers,
        getUserRoles,
        updateUser,
        deleteUsers,
        archiveUsers,
        unarchiveUsers,
        updateProfile,
    };
};
