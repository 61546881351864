import { useEffect, useState } from 'react';
import { useLeadService } from '../../../services/leadService';
import globalNotification from '../../../common/hooks/globalNotification';
import { notificationType } from '../../../common/constants/notificationType';
import { Select, Spin, Typography, Flex, Card, Switch, Tooltip } from 'antd';
import { WidgetReload } from './WidgetReload';
import { LineChart } from '../../../common/components/LineChart';
import { chartColors } from '../../../common/constants/chartColors';
import { getDateRange } from '../../../utils/getDateRange';
import dayjs from 'dayjs';
import { chartModesConstants } from '../../../common/constants/chartModes';
import { fontSize, primitiveColors, radius, spacing } from '../../../theming';
import { lineChartGradients } from '../../../common/constants/lineChartGradients';
import CustomDatePicker from '../../../common/components/DatePicker';
import CustomSelect from '../../../common/components/Select';
import { useTranslation } from 'react-i18next';
import { useMedia } from '../../../hooks/useMediaQuery';
import { useMediaQuery } from '@uidotdev/usehooks';

const { Option } = Select;

export const LeadVolumeWidget = ({ selectedDates, selectedBusinesses, selectedBusinessesNames }) => {
    const { t } = useTranslation(['dashboard', 'general']);

    const [chartData, setChartData] = useState(null);
    const [percentageChange, setPercentageChange] = useState(null);
    const [totalLeadsAmount, setTotalLeadsAmount] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const [type, setType] = useState('week');
    const [comparisonDates, setComparisonDates] = useState([]);

    const [mode, setMode] = useState(chartModesConstants.Analysis);

    const { isExtraLargeDevice } = useMedia();

    const mobileDevicesBreakpoint = useMediaQuery('(max-width : 1680px)');

    const [isComparisonModeActive, setIsComparisonActive] = useState(false);

    const { getLeadsVolumePerBusinesses } = useLeadService();

    const createGradient = (ctx, index) => {
        const gradient = ctx.createLinearGradient(0, 0, 0, 500);
        gradient.addColorStop(0.03, lineChartGradients[index].startColor); // start of the gradient
        gradient.addColorStop(0.4, lineChartGradients[index].stopColor); // end of the gradient
        return gradient;
    };

    useEffect(() => {
        if (!isExtraLargeDevice) {
            handleToggleSwitch(false);
        }
    }, [isExtraLargeDevice]);

    const fetchLeadsVolumePerBusiness = async (selectedDates, selectedBusinesses) => {
        setError('');
        setLoading(true);

        try {
            const result = await getLeadsVolumePerBusinesses(
                selectedDates[0],
                selectedDates[1],
                selectedBusinesses,
                chartModesConstants.Analysis
            );

            const { labels, data, totalLeadsAmount, percentageChange } = result;

            const datasets = Object.keys(data).map((key, index) => ({
                label: key,
                data: data[key],
                borderColor: chartColors[index],
                fill: 'start',
                backgroundColor: context => {
                    const ctx = context.chart.ctx;
                    return createGradient(ctx, index);
                },
                cubicInterpolationMode: 'monotone',
                tension: 0.3,
                pointBackgroundColor: primitiveColors.gray0,
            }));

            if (Object.keys(data).length !== selectedBusinesses.length) {
                const businessesWithoutData = selectedBusinessesNames.filter(
                    business => !Object.keys(data).includes(business)
                );

                globalNotification.open({
                    type: notificationType.INFO,
                    message: t('notifications.business-no-data'),
                    description: `${businessesWithoutData.join(', ')} ${t('notifications.have-no-data')}`,
                });
            }

            setChartData({ labels, datasets });
            setPercentageChange(percentageChange);
            setTotalLeadsAmount(totalLeadsAmount);
        } catch (err) {
            console.error(err);
            globalNotification.open({
                type: notificationType.ERROR,
                message: err.response.data.message,
                description: err.response.data.description,
            });

            setError(err.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    const fetchLeadsVolumePerBusinessComparison = async (firstDateRange, secondDateRange, selectedBusinesses) => {
        setError('');
        setLoading(true);

        try {
            const firstRangeData = await getLeadsVolumePerBusinesses(
                firstDateRange[0],
                firstDateRange[1],
                selectedBusinesses,
                chartModesConstants.Comparison
            );
            const secondRangeData = await getLeadsVolumePerBusinesses(
                secondDateRange[0],
                secondDateRange[1],
                selectedBusinesses,
                chartModesConstants.Comparison
            );

            const { labels: firstDatasetLabels, data: firstDatasetData } = firstRangeData;
            const { labels: secondDatasetLabels, data: secondDatasetData } = secondRangeData;

            const datasets = [];

            datasets.push(
                ...Object.keys(firstDatasetData).map((key, index) => ({
                    label: key,
                    data: firstDatasetData[key],
                    borderColor: chartColors[index],
                    borderWidth: 2,
                    fill: 'start',
                    backgroundColor: context => {
                        const ctx = context.chart.ctx;
                        return createGradient(ctx, index);
                    },
                    cubicInterpolationMode: 'monotone',
                    tension: 0.3,
                    pointBackgroundColor: primitiveColors.gray0,
                }))
            );

            datasets.push(
                ...Object.keys(secondDatasetData).map((key, index) => ({
                    label: key,
                    data: secondDatasetData[key],
                    borderColor: chartColors[index],
                    borderWidth: 2,
                    borderDash: [5, 3],
                    fill: false,
                    cubicInterpolationMode: 'monotone',
                    tension: 0.3,
                    pointBackgroundColor: primitiveColors.gray0,
                }))
            );

            const labels = firstDatasetLabels.map((value, index) => `${value}#${secondDatasetLabels[index]}`);

            setChartData({
                labels,
                datasets,
            });
        } catch (err) {
            console.error(err);
            globalNotification.open({
                type: notificationType.ERROR,
                message: err.response.data.message,
                description: err.response.data.description,
            });

            setError(err.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (selectedDates.length > 0 && selectedBusinesses.length > 0) {
            if (mode === chartModesConstants.Analysis) {
                fetchLeadsVolumePerBusiness(selectedDates, selectedBusinesses);
            }
        }
    }, [selectedDates, selectedBusinesses, mode]);

    useEffect(() => {
        if (comparisonDates[0] && comparisonDates[1]) {
            const firstDateRange = getDateRange(comparisonDates[0], type);
            const secondDateRange = getDateRange(comparisonDates[1], type);

            fetchLeadsVolumePerBusinessComparison(firstDateRange, secondDateRange, selectedBusinesses);
        }
    }, [selectedBusinesses, comparisonDates]);

    const handleTypeChange = value => {
        setComparisonDates([null, null]);
        setType(value);
    };

    const handleComparisonDatesChange = (value, index) => {
        const newDates = [...comparisonDates];

        newDates[index] = value;

        if (newDates[0] && newDates[1]) {
            setMode(chartModesConstants.Comparison);
        } else if (!newDates[0] && !newDates[1]) {
            setMode(chartModesConstants.Analysis);
        }

        setComparisonDates(newDates);
    };

    const handleToggleSwitch = value => {
        setIsComparisonActive(value);

        if (!value) {
            setComparisonDates([null, null]);
            setMode(chartModesConstants.Analysis);
        }
    };

    return (
        <Card
            style={{
                border: '1px solid #E9EBED',
                borderRadius: radius.lg,
                height: mobileDevicesBreakpoint ? '500px' : '100%',
            }}
            styles={{
                body: {
                    padding: spacing[4],
                    height: '100%',
                    display: loading ? 'flex' : 'block',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }}
        >
            {' '}
            {loading ? (
                <Spin size="large" />
            ) : error ? (
                <WidgetReload
                    errorMessage={error}
                    reloadFunction={() => fetchLeadsVolumePerBusiness(selectedDates, selectedBusinesses)}
                />
            ) : (
                <Flex vertical style={{ height: '100%' }}>
                    <Flex justify="space-between" align="center">
                        <Typography.Title style={{ margin: 0, fontSize: fontSize.md, fontWeight: 700 }}>
                            {t('leads-volume.widget-title')}
                        </Typography.Title>

                        <Flex gap={spacing[3]} align="center" style={{ minHeight: 36 }}>
                            {isExtraLargeDevice && (
                                <Typography.Title style={{ margin: 0, fontSize: fontSize.md, fontWeight: 700 }}>
                                    {t('leads-volume.comparison')}
                                </Typography.Title>
                            )}

                            {isComparisonModeActive && (
                                <>
                                    <CustomSelect
                                        value={type}
                                        onChange={handleTypeChange}
                                        className="lead-volume-widget-custom-select"
                                        style={{ width: '125px', height: '36px' }}
                                    >
                                        <Option value="week">{t('general:dates.week')}</Option>
                                        <Option value="month">{t('general:dates.month')}</Option>
                                        <Option value="quarter">{t('general:dates.quarter')}</Option>
                                        <Option value="year">{t('general:dates.year')}</Option>
                                    </CustomSelect>
                                    <CustomDatePicker
                                        picker={type}
                                        type={type}
                                        placeholder={t('leads-volume.first-date-range')}
                                        value={comparisonDates[0]}
                                        onChange={value => handleComparisonDatesChange(value, 0)}
                                        maxDate={dayjs()}
                                        allowClear={true}
                                        style={{
                                            height: '36px',
                                            width: '150px',
                                            backgroundColor: primitiveColors.gray25,
                                            borderColor: primitiveColors.gray25,
                                        }}
                                        suffixIcon={<i className="fi-rr-calendar" />}
                                    />
                                    <CustomDatePicker
                                        picker={type}
                                        type={type}
                                        placeholder={t('leads-volume.second-date-range')}
                                        value={comparisonDates[1]}
                                        onChange={value => handleComparisonDatesChange(value, 1)}
                                        maxDate={dayjs()}
                                        allowClear={true}
                                        style={{
                                            height: '36px',
                                            width: '150px',
                                            backgroundColor: primitiveColors.gray25,
                                            borderColor: primitiveColors.gray25,
                                        }}
                                        suffixIcon={<i className="fi-rr-calendar" />}
                                    />
                                </>
                            )}

                            {isExtraLargeDevice && (
                                <Tooltip title={t('leads-volume.switch-tooltip')}>
                                    <Switch
                                        value={isComparisonModeActive}
                                        onChange={value => handleToggleSwitch(value)}
                                    />
                                </Tooltip>
                            )}
                        </Flex>
                    </Flex>
                    <Flex vertical style={{ height: '100%', marginTop: spacing[3], gap: spacing[3] }}>
                        {mode === chartModesConstants.Analysis && (
                            <Flex gap={spacing[1]} align="end">
                                <Typography.Text
                                    style={{
                                        fontSize: fontSize.H3,
                                        fontWeight: 700,
                                        color: primitiveColors.gray900,
                                        lineHeight: 1,
                                    }}
                                >
                                    {totalLeadsAmount}
                                </Typography.Text>
                                <Typography.Text
                                    style={{
                                        color: percentageChange < 0 ? primitiveColors.red500 : primitiveColors.blue500,
                                        fontSize: fontSize.xxs,
                                        fontWeight: 500,
                                    }}
                                >{`${percentageChange > 0 ? '+' : ''}${percentageChange}%`}</Typography.Text>
                            </Flex>
                        )}
                        {chartData.datasets.length > 0 ? (
                            <LineChart chartData={chartData} mode={mode} />
                        ) : (
                            <WidgetReload errorMessage={t('widget-reload.no-data')} />
                        )}
                    </Flex>
                </Flex>
            )}
        </Card>
    );
};
