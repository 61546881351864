import { Avatar } from 'antd';
import { getInitialsColor } from '../../utils/getInitialColor';
import { constants } from '../constants/constants';
import { isGooglePhoto } from '../../utils/isGooglePhoto';

const CustomAvatar = ({ size, photoName, firstName, lastName, style, controller, ...props }) => {
    const firstInitial = firstName ? firstName.charAt(0) : '';
    const lastInitial = lastName ? lastName.charAt(0) : '';

    const isPhotoFromGoogle = isGooglePhoto(photoName);

    const { API_BASE_URL } = constants;

    return (
        <Avatar
            size={size}
            src={
                photoName
                    ? isPhotoFromGoogle
                        ? photoName
                        : `${API_BASE_URL}/${controller}/get-photo/${photoName}`
                    : null
            }
            style={{
                backgroundColor: photoName ? 'transparent' : getInitialsColor(firstInitial, lastInitial),
                color: photoName ? 'transparent' : 'white',
                ...style,
            }}
            onClick={e => {
                e.preventDefault();
            }}
            {...props} // props here used for forwardRef or ref events from parent component
        >
            {!photoName && (
                <div style={{ display: 'flex', gap: '2px', fontWeight: '600' }}>
                    <span>{firstInitial.toUpperCase()}</span>
                    <span>{lastInitial.toUpperCase()}</span>
                </div>
            )}
            {!photoName && !firstInitial && !lastInitial && (
                <i
                    className="fi-rr-user"
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                />
            )}
        </Avatar>
    );
};

export default CustomAvatar;
