import { useState } from 'react';
import CreateTeamMain from '../../components/teams/create-team-main';
import CreateTeamForm from '../../components/teams/create-team-form';
import Invitation from '../../components/teams/invitation-form';
import JoinTeam from '../../components/teams/join-team';
import UpgradePlan from '../../components/teams/upgrade-plan-tab';
import { Flex, Tabs } from 'antd';
import image from '../../assets/Create_team.png';
import { useMedia } from '../../hooks/useMediaQuery';
import { spacing } from '../../theming';
import classes from './CreateTeam.module.scss';
import { useTranslation } from 'react-i18next';
import { useAuth } from './../../context/AuthContext';

function CreateTeam() {
    const { isMediumDevice, isSmallDevice } = useMedia();
    const isSubscriptionsChanged = localStorage.getItem('isSubscriptionChanged');
    const [activeTabKey, setActiveTabKey] = useState(isSubscriptionsChanged ? '3' : '0');
    const { t } = useTranslation(['teamCreation', 'general', 'form', 'apiResponses']);
    const { currentUser } = useAuth();

    const items = [
        {
            key: '0',
            children: <CreateTeamMain setActiveTabKey={setActiveTabKey} t={t} currentUser={currentUser} />,
        },
        {
            key: '1',
            children: (
                <CreateTeamForm
                    activeTabKey={activeTabKey}
                    setActiveTabKey={setActiveTabKey}
                    t={t}
                    currentUser={currentUser}
                />
            ),
        },
        {
            key: '2',
            children: (
                <Invitation
                    activeTabKey={activeTabKey}
                    setActiveTabKey={setActiveTabKey}
                    t={t}
                    currentUser={currentUser}
                />
            ),
        },
        {
            key: '3',
            children: (
                <UpgradePlan
                    activeTabKey={activeTabKey}
                    setActiveTabKey={setActiveTabKey}
                    t={t}
                    currentUser={currentUser}
                />
            ),
        },
    ];

    return (
        <Flex
            className={classes.container}
            vertical
            justify={activeTabKey === '3' ? 'flex-start' : 'center'}
            align="center"
            style={{
                marginTop: currentUser?.teamId && activeTabKey !== '3' && 'clamp(10px, 5vw, 50px)',
            }}
        >
            <Flex gap="clamp(10px, 20vw, 32px)" justify="center" align="center">
                <Tabs
                    activeKey={activeTabKey}
                    centered
                    items={items}
                    style={{
                        width: activeTabKey === '3' ? 'clamp(300px, 75vw, 1350px)' : 'clamp(300px, 85vw, 400px)',
                        margin: isSmallDevice ? 0 : `${spacing[2]} 0`,
                    }}
                />
                {!isMediumDevice && !isSmallDevice && activeTabKey === '0' && (
                    <img src={image} alt="Create Team" width={480} />
                )}
            </Flex>
            {activeTabKey === '0' && !currentUser?.teamId && <JoinTeam t={t} />}
        </Flex>
    );
}

export default CreateTeam;
