import { Flex, Button } from 'antd';
import { spacing } from '../../theming';
import { useMedia } from '../../hooks/useMediaQuery';

export default function ListControlButtons({ index, t, handleAdd, handleRemove, statusList }) {
    const { isSmallDevice } = useMedia();
    return (
        <Flex gap={spacing[4]}>
            <Button
                type="text"
                onClick={handleAdd}
                style={{
                    padding: isSmallDevice && spacing[0.5],
                }}
            >
                <i className="fi-rr-add" style={{ marginTop: spacing[0.5] }} />
                {t('form.add')}
            </Button>
            {statusList.length > 1 && (
                <Button
                    type="text"
                    onClick={() => handleRemove(index)}
                    style={{
                        padding: isSmallDevice && spacing[0.5],
                    }}
                >
                    <i className="fi-rr-trash" style={{ marginTop: spacing[0.5] }} />
                    {t('form.remove')}
                </Button>
            )}
        </Flex>
    );
}
