import { textColor } from './themeColors';

export const fontSize = {
    H1: '48px',
    H2: '40px',
    H3: '32px',
    H4: '24px',
    lg: '20px',
    md: '18px',
    sm: '16px',
    xs: '14px',
    xxs: '12px',
};

export const lineHeight = {
    H1: '40px',
    H2: '48px',
    H3: '40px',
    H4: '32px',
    H5: '24px',
    H6: '24px',
    lg: '24px',
    md: '20px',
    sm: '16px',
    xs: '14px',
};

export const fontWeight = {
    xl: 600,
    lg: 'Semi Bold',
    md: 'Medium',
    sm: 'Regular',
};

export const textSmall = {
    fontSize: fontSize.xs,
    lineHeight: lineHeight.md,
    fontWeight: 500,
};

export const textStandard = {
    fontSize: fontSize.sm,
    lineHeight: lineHeight.md,
    fontWeight: 400,
};

export const pageHeading = {
    fontSize: fontSize.H3,
    lineHeight: lineHeight.H3,
    fontWeight: fontWeight.xl,
};

export const descriptionText = {
    lineHeight: lineHeight.lg,
    fontWeight: 500,
    fontSize: fontSize.xs,
    color: textColor.primary,
};
