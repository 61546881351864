import { useState } from 'react';
import { Form, Input, Button } from 'antd';
import FormImageUpload from './../../common/components/FormImageUpload/FormImageUpload';
import { constants } from '../../common/constants/constants';
import getRegexPattern from './../../common/constants/regexPatterns';
import { buttonStyle } from './../../theming';
import { useTranslation } from 'react-i18next';
import { useMedia } from '../../hooks/useMediaQuery';

export default function ProfileForm({ currentUser, fullName, handler, loading, t }) {
    const { API_BASE_URL } = constants;
    const baseApiUrl = {
        upload: '/users/upload-photo',
        delete: '/users/delete-photo',
        get: '/users/get-photo',
    };
    const [form] = Form.useForm();
    const { i18n } = useTranslation();
    const [isFormValid, setIsFormValid] = useState(true);
    const { isSmallDevice } = useMedia();

    const handleFormValidation = () => {
        try {
            form.validateFields();
            setIsFormValid(true);
        } catch {
            setIsFormValid(false);
        }
    };

    return (
        <Form
            initialValues={{
                firstName: currentUser?.firstName,
                lastName: currentUser?.lastName,
                email: currentUser?.email,
            }}
            form={form}
            name="edit-profile"
            layout="vertical"
            onFinish={values => {
                handler(values);
            }}
            onChange={handleFormValidation}
        >
            <Form.Item name="photo">
                <FormImageUpload
                    fileQuality={0.7}
                    fieldName="photo"
                    path={baseApiUrl}
                    id={currentUser?.id}
                    file={currentUser?.photo && `${API_BASE_URL + baseApiUrl.get}/${currentUser?.photo}`}
                    withPreviewModal
                    setFormFields={value => form.setFieldsValue('photo', value)}
                    title={
                        i18n.language === 'en'
                            ? `${fullName}'s ${t('form.label.photo')}`
                            : `${t('form.label.photo')} ${fullName}`
                    }
                />
            </Form.Item>
            <Form.Item
                name="firstName"
                label={t('form.label.first-name')}
                rules={[
                    {
                        required: true,
                        message: t('form.errors.first-name'),
                    },
                    {
                        pattern: getRegexPattern('cyrillic'),
                        message: t('form.errors.first-name-invalid'),
                    },
                ]}
            >
                <Input allowClear />
            </Form.Item>
            <Form.Item
                name="lastName"
                label={t('form.label.last-name')}
                rules={[
                    {
                        required: true,
                        message: t('form.errors.last-name'),
                    },
                    {
                        pattern: getRegexPattern('cyrillic'),
                        message: t('form.errors.last-name-invalid'),
                    },
                ]}
            >
                <Input allowClear />
            </Form.Item>
            <Form.Item
                name="email"
                label={t('form.label.email')}
                rules={[
                    {
                        required: true,
                        message: t('form.errors.email'),
                    },
                    {
                        type: 'email',
                        message: t('form.errors.email-invalid'),
                    },
                ]}
            >
                <Input allowClear />
            </Form.Item>
            <Button
                type="primary"
                htmlType="submit"
                size="large"
                style={{
                    ...buttonStyle.large,
                    width: isSmallDevice ? '100%' : 125,
                }}
                loading={loading}
                disabled={!isFormValid}
            >
                {t('button.save')}
            </Button>
        </Form>
    );
}
