import { textStandard, fontSize } from './typography';
import { spacing } from './spacing';
import { textColor } from './themeColors';

export const menuActionText = {
    ...textStandard,
    paddingBottom: spacing[0.5],
    color: textColor.subtle,
    fontSize: fontSize.sm,
};
