import { Button, Form, Input, Row, notification } from 'antd';
import classes from '../../common/styles/FormStyles.module.scss';
import axios from 'axios';
import { useNavigate, useParams } from 'react-router-dom';
import { notificationType } from '../../common/constants/notificationType';
import globalNotification from '../../common/hooks/globalNotification';
import { constants } from '../../common/constants/constants';
import logo from '../../assets/Main_logo.png';
import { spacing, iconColor, coreBrandColors } from '../../theming';
import { useTranslation } from 'react-i18next';
import getInputRules, { inputType } from '../../utils/getInputRules';

export const RestorePassword = () => {
    const navigate = useNavigate();
    const params = useParams();
    const { t } = useTranslation(['resetPassword', 'apiResponses']);

    const onFormSubmit = async form => {
        try {
            await axios
                .post(`${constants.API_BASE_URL}/auth/restore-password`, {
                    id: params.id,
                    token: params.token,
                    newPassword: form.password,
                })
                .then(() => {
                    globalNotification.open({
                        type: notificationType.SUCCESS,
                        message: t('notification.success.message'),
                        description: t('notification.success.description'),
                        placement: 'top',
                    });
                    navigate('/auth/sign-in');
                });
        } catch (error) {
            const errorCode = error.response?.data?.code;

            globalNotification.open({
                type: notificationType.ERROR,
                message: t(`apiResponses:${errorCode}.message`),
                description: t(`apiResponses:${errorCode}.description`),
                placement: 'top',
            });
        }
    };

    return (
        <div className={classes['auth-wrapper']}>
            <img src={logo} alt="InterCode_logo" className={classes.logo} />
            <h3>{t('title')}</h3>
            <div className={classes.content}>
                <p>{t('sub')}</p>
                <Form onFinish={onFormSubmit}>
                    <Form.Item
                        name="password"
                        rules={getInputRules(t, inputType.PASSWORD, t('form.errors.password'))}
                        hasFeedback
                    >
                        <Input.Password
                            prefix={
                                <i
                                    className="fi-rr-lock"
                                    style={{ margin: '5px 10px 0 0', color: iconColor.primary }}
                                />
                            }
                            size="large"
                            placeholder={t('form.placeholder.password')}
                            style={{
                                padding: `${spacing[1]} ${spacing[3]}`,
                            }}
                        />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: t('form.errors.confirm'),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('form.errors.confirm-match')));
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            prefix={
                                <i
                                    className="fi-rr-lock"
                                    style={{ margin: '5px 10px 0 0', color: iconColor.primary }}
                                />
                            }
                            size="large"
                            placeholder={t('form.placeholder.confirm')}
                            style={{
                                padding: `${spacing[1]} ${spacing[3]}`,
                            }}
                        />
                    </Form.Item>
                    <Row justify="center" align="center">
                        <Button
                            htmlType="submit"
                            style={{ width: '100%', backgroundColor: coreBrandColors.blueRibbon }}
                            type="primary"
                            size="large"
                        >
                            {t('button')}
                        </Button>
                    </Row>
                </Form>
            </div>
        </div>
    );
};
