export const datesFormat = {
    uk: {
        long: 'DD MMM YYYY',
        short: 'MMM DD YYYY',
    },
    en: {
        long: 'MMM DD YYYY',
        short: 'DD MMM YYYY',
    },
};
