import { Button, Empty } from 'antd';
import { ReloadOutlined } from '@ant-design/icons';
import { primitiveColors, small, spacing } from '../../../theming';
import { useTranslation } from 'react-i18next';

export const WidgetReload = ({ reloadFunction, errorMessage }) => {
    const { t } = useTranslation(['dashboard']);

    return (
        <Empty
            description={errorMessage}
            style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexDirection: 'column',
                height: '100%',
                gap: spacing[4],
            }}
        >
            {reloadFunction && (
                <Button
                    onClick={reloadFunction}
                    style={{
                        ...small,
                        border: `1px solid ${primitiveColors.gray200}`,
                        gap: spacing[2],
                        color: primitiveColors.gray400,
                    }}
                >
                    <ReloadOutlined style={{ color: primitiveColors.gray400 }} size="xl" />
                    {t('widget-reload.reload')}
                </Button>
            )}
        </Empty>
    );
};
