import { Button, List, Flex } from 'antd';

const btnStyles = { maxWidth: 'fit-content' }; // styles will be changed when design is finalized

export const RenderLeads = ({ leads, showModal, t }) => (
    <Flex vertical gap={8}>
        {leads.map(lead => (
            <List.Item key={lead.id}>
                <Button type="dashed" onClick={() => showModal(lead)} style={{ ...btnStyles }}>
                    {lead.firstName} {lead.lastName}
                </Button>
            </List.Item>
        ))}
    </Flex>
);

export const RenderBusinesses = ({ businesses, showModal, t }) => (
    <Flex vertical gap={8}>
        {businesses.map(business => (
            <List.Item key={business.id}>
                <Button type="dashed" onClick={() => showModal(business)} style={{ ...btnStyles }}>
                    {business.name}
                </Button>
            </List.Item>
        ))}
    </Flex>
);

export const RenderUsers = ({ users, showModal, t }) => (
    <Flex vertical gap={8}>
        {users.map(user => (
            <List.Item key={user.id}>
                <Button type="dashed" onClick={() => showModal(user)} style={{ ...btnStyles }}>
                    {user.firstName} {user.lastName}
                </Button>
            </List.Item>
        ))}
    </Flex>
);
