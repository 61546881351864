import { Spin } from 'antd';
import classes from './AppLoader.module.scss';

function AppLoader() {
    return (
        <div className={classes.wrapper}>
            <Spin size="large" />
        </div>
    );
}

export default AppLoader;
