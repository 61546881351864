import React from 'react';
import { Segmented } from 'antd';
import { fontSize, lineHeight, spacing } from '../../theming';
import { useTranslation } from 'react-i18next';

const StatusControl = ({ value, onChange, options }) => {
    const { t } = useTranslation();
    return (
        <Segmented
            options={options || [t('general:statuses.active'), t('general:statuses.archived')]}
            value={value && value}
            onChange={value => {
                onChange(value);
            }}
            block
            style={{
                display: 'flex',
                alignItems: 'center',
                width: '200px',
                height: '44px',
                fontSize: fontSize.sm,
                lineHeight: lineHeight.md,
                fontWeight: 500,
                padding: spacing[0.5],
            }}
        />
    );
};

export default StatusControl;
