import { Col, Empty, Flex, Row, Spin, Typography } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import 'chart.js/auto';
import globalNotification from '../../common/hooks/globalNotification';
import { notificationType } from '../../common/constants/notificationType';
import { BusinessesService } from '../../services/businessesService';
import dayjs from 'dayjs';
import { debounce } from 'lodash';
import { arrayComparator } from '../../utils/arrayComparator';
import { RecentLeadsTableWidget } from '../../components/adminDashboard/widgets/RecentLeadsTableWidget';
import { DoughnutChartWidget } from '../../components/adminDashboard/widgets/DoughnutChartWidget';
import { LeadCountOverPeriodWidget } from '../../components/adminDashboard/widgets/LeadCountOverPeriodWidget';
import { LeadVolumeWidget } from '../../components/adminDashboard/widgets/LeadVolumeWidget';
import PageContainer from '../../common/components/PageContainer';
import { fontSize, primitiveColors, spacing } from '../../theming';
import Select from '../../common/components/Select';
import CustomDatePicker from '../../common/components/DatePicker';
import { useTranslation } from 'react-i18next';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useMedia } from '../../hooks/useMediaQuery';

export const Dashboard = () => {
    const { t } = useTranslation(['dashboard']);

    const { getAllActiveBusinesses } = BusinessesService();

    const [businessesDataLoading, setBusinessesDataLoading] = useState(true);
    const [businessesData, setBusinessesData] = useState([]);
    const [defaultSelectValues, setDefaultSelectValues] = useState([]);

    const [previousSelectedBusinesses, setPreviousSelectedBusinesses] = useState(defaultSelectValues);
    const [selectedBusinesses, setSelectedBusinesses] = useState(defaultSelectValues);
    const [selectedOptions, setSelectedOptions] = useState(defaultSelectValues);

    const [searchText, setSearchText] = useState('');

    const last30Days = [dayjs().subtract(30, 'day'), dayjs()];

    const [selectedDates, setSelectedDates] = useState(last30Days);

    const { isExtraSmallDevice, isExtraLargeDevice } = useMedia();

    const mobileDevicesBreakpoint = useMediaQuery('(max-width : 1800px)');

    const fetchBusinesses = async () => {
        setBusinessesDataLoading(true);

        try {
            const result = await getAllActiveBusinesses();

            setBusinessesData(result.businesses);
        } catch (err) {
            console.log(err);
            globalNotification.open({
                type: notificationType.ERROR,
                message: err.response.data.message,
                description: err.response.data.description,
            });
        } finally {
            setBusinessesDataLoading(false);
        }
    };

    useEffect(() => {
        fetchBusinesses();
    }, []);

    const handleSelectedBusinessesChange = businessesIds => {
        setSelectedBusinesses(businessesIds);
        setPreviousSelectedBusinesses(businessesIds);
    };

    const debouncedSetSelectedBusinesses = useCallback(
        debounce(businessesIds => handleSelectedBusinessesChange(businessesIds), 1000),
        []
    );

    useEffect(() => {
        const defaultValues = businessesData.slice(0, 5).map(business => business.id);

        setDefaultSelectValues(defaultValues);
        setSelectedBusinesses(defaultValues);
        setSelectedOptions(defaultValues);
        setPreviousSelectedBusinesses(defaultValues);
    }, [businessesData]);

    const handleChange = value => {
        if (arrayComparator(value, previousSelectedBusinesses)) {
            debouncedSetSelectedBusinesses.cancel();
        } else {
            debouncedSetSelectedBusinesses(value);
        }
        setSelectedOptions(value);
    };

    const handleSecondDateChange = value => {
        if (value < selectedDates[0]) {
            setSelectedDates([value, value]);
        } else {
            setSelectedDates(prev => [prev[0], value]);
        }
    };

    const tagRender = props => {
        return searchText ? (
            <span style={{ marginLeft: spacing[3] }}></span>
        ) : (
            <span style={{ marginLeft: spacing[3], color: primitiveColors.gray300 }}>{t('select-business')}</span>
        );
    };

    return (
        <PageContainer style={{ padding: spacing[4] }}>
            <Row gutter={24}>
                <Col span={isExtraLargeDevice ? 6 : 24}>
                    <Typography.Title style={{ fontSize: fontSize.H3, margin: 0 }}>{t('page-title')}</Typography.Title>
                </Col>
                <Col span={isExtraLargeDevice ? 18 : 24} style={{ marginTop: isExtraLargeDevice ? 0 : spacing[4] }}>
                    <Flex justify={isExtraLargeDevice ? 'end' : 'start'} gap={spacing[4]} wrap={isExtraSmallDevice}>
                        <Flex gap={spacing[3]}>
                            <CustomDatePicker
                                value={selectedDates[0]}
                                allowClear={false}
                                maxDate={selectedDates[1]}
                                style={{ height: isExtraSmallDevice && '40px' }}
                                onChange={value => setSelectedDates(prev => [value, prev[1]])}
                                suffixIcon={<i className="fi-rr-calendar" />}
                            />
                            <CustomDatePicker
                                value={selectedDates[1]}
                                allowClear={false}
                                maxDate={dayjs()}
                                style={{ height: isExtraSmallDevice && '40px' }}
                                onChange={value => handleSecondDateChange(value)}
                                suffixIcon={<i className="fi-rr-calendar" />}
                            />
                        </Flex>

                        <Select
                            mode="multiple"
                            showSearch
                            onSearch={value => setSearchText(value)}
                            onBlur={() => setSearchText('')}
                            maxTagCount={0}
                            tagRender={tagRender}
                            loading={businessesDataLoading}
                            filterOption={(input, option) => option.label.toLowerCase().includes(input.toLowerCase())}
                            style={{
                                width: isExtraSmallDevice ? '100%' : '180px',
                                height: '40px',
                                order: isExtraSmallDevice ? '-1' : '0',
                                maxWidth: isExtraSmallDevice ? '365.33px' : 'none',
                            }}
                            value={selectedOptions}
                            placeholder={t('placeholders.businesses')}
                            onChange={handleChange}
                            defaultValue={defaultSelectValues}
                            options={businessesData.map(business => ({
                                value: business.id,
                                label: business.name,
                            }))}
                        />
                    </Flex>
                </Col>
            </Row>

            {businessesDataLoading ? (
                <Spin />
            ) : businessesData.length === 0 || selectedBusinesses.length === 0 ? (
                <Empty
                    description={t('no-businesses')}
                    style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        flexDirection: 'column',
                        height: '100%',
                        minHeight: '850px',
                        gap: spacing[4],
                    }}
                />
            ) : (
                <>
                    <Row style={{ marginTop: spacing[4], minHeight: '453px' }} gutter={24}>
                        <Col span={mobileDevicesBreakpoint ? 24 : 14}>
                            <LeadVolumeWidget
                                selectedDates={selectedDates}
                                selectedBusinesses={selectedBusinesses}
                                selectedBusinessesNames={businessesData
                                    .filter(business => selectedBusinesses.includes(business.id))
                                    .map(business => business.name)}
                            />
                        </Col>
                        <Col
                            span={isExtraSmallDevice ? 24 : mobileDevicesBreakpoint ? 12 : 4}
                            style={{ marginTop: mobileDevicesBreakpoint ? spacing[4] : 0 }}
                        >
                            <LeadCountOverPeriodWidget
                                selectedDates={selectedDates}
                                selectedBusinesses={selectedBusinesses}
                            />
                        </Col>
                        <Col
                            span={isExtraSmallDevice ? 24 : mobileDevicesBreakpoint ? 12 : 6}
                            style={{ marginTop: mobileDevicesBreakpoint ? spacing[4] : 0 }}
                        >
                            <DoughnutChartWidget
                                selectedDates={selectedDates}
                                selectedBusinesses={selectedBusinesses}
                            />
                        </Col>
                    </Row>
                    <Row gutter={24} style={{ marginTop: spacing[4], minHeight: '320px' }}>
                        <Col span={mobileDevicesBreakpoint ? 24 : 18}>
                            <RecentLeadsTableWidget
                                selectedDates={selectedDates}
                                selectedBusinesses={selectedBusinesses}
                            />
                        </Col>
                    </Row>
                </>
            )}
        </PageContainer>
    );
};
