import { backgroundColor, spacing, radius } from './../../theming';

function PageContainer({ children, ...props }) {
    return (
        <div
            style={{
                backgroundColor: backgroundColor.white,
                padding: `${spacing[2]} ${spacing[5]}`,
                borderRadius: radius.md,
                ...props.style,
            }}
        >
            {children}
        </div>
    );
}

export default PageContainer;
