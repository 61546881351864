import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';

dayjs.extend(isBetween);
dayjs.extend(customParseFormat);
dayjs.extend(quarterOfYear);

export const getDateRange = (date, type) => {
    let formattedDate = dayjs(date, 'YYYY-MM-DD').format('YYYY-MM-DD');

    switch (type) {
        case 'date':
            return [formattedDate, formattedDate];
        case 'week':
            const startOfWeek = dayjs(formattedDate).startOf('week').format('YYYY-MM-DD');
            const endOfWeek = dayjs(formattedDate).endOf('week').format('YYYY-MM-DD');
            return [startOfWeek, endOfWeek];
        case 'month':
            const startOfMonth = dayjs(formattedDate).startOf('month').format('YYYY-MM-DD');
            const endOfMonth = dayjs(formattedDate).endOf('month').format('YYYY-MM-DD');
            return [startOfMonth, endOfMonth];
        case 'quarter':
            const startOfQuarter = dayjs(formattedDate).startOf('quarter').format('YYYY-MM-DD');
            const endOfQuarter = dayjs(formattedDate).endOf('quarter').format('YYYY-MM-DD');
            return [startOfQuarter, endOfQuarter];
        case 'year':
            const startOfYear = dayjs(formattedDate).startOf('year').format('YYYY-MM-DD');
            const endOfYear = dayjs(formattedDate).endOf('year').format('YYYY-MM-DD');
            return [startOfYear, endOfYear];
        default:
            return [formattedDate, formattedDate];
    }
};
