import { useEffect, useState } from 'react';
import { DoughnutChart } from '../../../common/components/DoughnutChart';
import { useLeadService } from '../../../services/leadService';
import globalNotification from '../../../common/hooks/globalNotification';
import { notificationType } from '../../../common/constants/notificationType';
import { Card, Spin, Typography } from 'antd';
import { WidgetReload } from './WidgetReload';
import { chartColors } from '../../../common/constants/chartColors';
import { colors, fontSize, primitiveColors, radius, spacing } from '../../../theming';
import { useTranslation } from 'react-i18next';
import { useMedia } from '../../../hooks/useMediaQuery';
import { useMediaQuery } from '@uidotdev/usehooks';

export const DoughnutChartWidget = ({ selectedDates, selectedBusinesses }) => {
    const { t } = useTranslation(['dashboard']);

    const { isExtraSmallDevice } = useMedia();

    const mobileDevicesBreakpoint = useMediaQuery('(max-width : 1680px)');

    const [chartData, setChartData] = useState(null);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const { getLeadsPerBusinesses } = useLeadService();

    const fetchLeadsPerBusiness = async () => {
        setError('');
        setLoading(true);

        try {
            const result = await getLeadsPerBusinesses(selectedDates[0], selectedDates[1], selectedBusinesses);

            const labels = Object.keys(result);
            const data = Object.values(result);

            setChartData({
                labels,

                datasets: [
                    {
                        data,
                        backgroundColor: chartColors,
                        borderWidth: 0,
                        datalabels: {
                            anchor: 'end',
                            shadowOffsetX: 0,
                            shadowOffsetY: 3,
                            shadowBlur: 5,
                            shadowColor: colors.black,
                        },
                    },
                ],
            });
        } catch (err) {
            console.error(err);
            globalNotification.open({
                type: notificationType.ERROR,
                message: err.response.data.message,
                description: err.response.data.description,
            });

            setError(err.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if ((selectedDates.length > 0, selectedBusinesses.length > 0)) {
            fetchLeadsPerBusiness();
        }
    }, [selectedDates, selectedBusinesses]);

    return (
        <Card
            style={{
                border: '1px solid #E9EBED',
                borderRadius: radius.lg,
                height: mobileDevicesBreakpoint && !isExtraSmallDevice ? 500 : '100%',
                // maxHeight: isExtraSmallDevice ? '500px' : 'auto',s
            }}
            styles={{
                body: {
                    padding: spacing[4],
                    height: '100%',
                    display: loading ? 'flex' : 'block',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }}
        >
            {loading ? (
                <Spin size="large" />
            ) : error ? (
                <WidgetReload errorMessage={error} reloadFunction={fetchLeadsPerBusiness} />
            ) : chartData.labels.length > 0 ? (
                <>
                    <Typography.Title
                        style={{
                            fontWight: 600,
                            fontSize: fontSize.lg,
                            color: primitiveColors.gray900,
                            textAlign: 'center',
                            marginBottom: isExtraSmallDevice ? spacing[3] : spacing[6],
                        }}
                    >
                        {t('doughnut-chart-widget.title')}
                    </Typography.Title>
                    <DoughnutChart chartData={chartData} title={t('doughnut-chart-widget.label-center')} />
                </>
            ) : (
                <WidgetReload errorMessage={t('widget-reload.no-data')} />
            )}
        </Card>
    );
};
