import { menuActionText, textColor } from '../../../theming';
import icon from '../../styles/Icon.module.scss';

const ActionsMenu = ({ record, handleEdit, handleDelete, handleArchive, handleView, isTeamAction, t }) => {
    return [
        !record.isDeleted && {
            key: 1,
            icon: <i className={`fi fi-rr-edit ${icon.md}`} style={{ color: textColor.subtle }} />,
            label: (
                <span
                    gap="8px"
                    align="center"
                    style={{
                        ...menuActionText,
                    }}
                >
                    {t('general:actions.simple.edit')}
                </span>
            ),
            onClick: () => {
                handleEdit({
                    firstName: record?.name?.split(' ')[0],
                    lastName: record?.name?.split(' ')[1],
                    ...record,
                });
            },
        },
        !record.isDeleted &&
            handleView && {
                key: 2,
                icon: <i className={`fi fi-rr-eye ${icon.md}`} style={{ color: textColor.subtle }} />,
                label: (
                    <span
                        gap="8px"
                        align="center"
                        style={{
                            ...menuActionText,
                        }}
                    >
                        {t('general:actions.simple.view')}
                    </span>
                ),
                onClick: () => {
                    handleView();
                },
            },
        {
            key: 3,
            icon: <i className={`fi fi-rr-trash ${icon.md}`} style={{ color: textColor.subtle }} />,
            label: (
                <span
                    gap="8px"
                    align="center"
                    style={{
                        ...menuActionText,
                    }}
                >
                    {t('general:actions.simple.delete')}
                </span>
            ),
            onClick: () => {
                handleDelete([record]);
            },
        },
        handleArchive && {
            key: 4,
            icon: record.isDeleted ? (
                <i className={`fi fi-rr-inbox-full ${icon.md}`} style={{ color: textColor.subtle }}></i>
            ) : (
                <i className={`fi fi-rr-archive ${icon.md}`} style={{ color: textColor.subtle }}></i>
            ),
            label: (
                <span
                    gap="8px"
                    align="center"
                    style={{
                        ...menuActionText,
                    }}
                >
                    {record.isDeleted
                        ? isTeamAction
                            ? t('general:actions.simple.activate')
                            : t('general:actions.simple.restore')
                        : t('general:actions.simple.archive')}
                </span>
            ),
            onClick: () => {
                handleArchive([record]);
            },
        },
    ];
};

export default ActionsMenu;
