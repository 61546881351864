import { useEffect, useState } from 'react';
import CustomSelect from '../../common/components/Select';
import { useTeamService } from '../../services/teamService';
import { Button, Flex, Form, Space, Tag, Typography } from 'antd';
import { fontSize, primitiveColors, radius, spacing } from '../../theming';
import { useTranslation } from 'react-i18next';
import { teamRole } from '../../common/constants/teamRole';
import globalNotification from '../../common/hooks/globalNotification';
import { notifications } from '../../common/constants/notifications';

export const UserToogleMembershipForm = ({
    editUser,
    archiveUsermemberships,
    restoreUsermemberships,
    userRoles,
    handleSubmitEditUser,
    closeDrawer,
}) => {
    const { t } = useTranslation(['usersForm', 'general']);

    const { getMembershipsByUserId } = useTeamService();

    const [activeUserMembershipsOptions, setActiveUserMembershipsOptions] = useState([]);

    const [archivedUserMembershipsOptions, setArchivedUserMembershipsOptions] = useState([]);

    const [form] = Form.useForm();

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (!editUser) {
            return;
        }

        const fetchUserMemberships = async () => {
            try {
                const activeMemberships = await getMembershipsByUserId(editUser.id, false);

                const archivedMemberships = await getMembershipsByUserId(editUser.id, true);

                setActiveUserMembershipsOptions(
                    activeMemberships.data.map(membership => ({
                        value: membership.id,
                        disabled: membership.role === teamRole.OWNER,
                        label: (
                            <Space gap={spacing[3]}>
                                <Typography.Text>{membership.team.name}</Typography.Text>
                                <Typography.Text>{`${membership.firstName || membership.user.firstName} ${membership.lastName || membership.user.lastName}`}</Typography.Text>
                                <Typography.Text>{`(${membership.role})`}</Typography.Text>
                            </Space>
                        ),
                    }))
                );

                setArchivedUserMembershipsOptions(
                    archivedMemberships.data.map(membership => ({
                        value: membership.id,
                        disabled: membership.role === teamRole.OWNER,
                        label: (
                            <Space gap={spacing[3]}>
                                <Typography.Text>{membership.team.name}</Typography.Text>
                                <Typography.Text>{`${membership.firstName || membership.user.firstName} ${membership.lastName || membership.user.lastName}`}</Typography.Text>
                                <Typography.Text>{`(${membership.role})`}</Typography.Text>
                            </Space>
                        ),
                    }))
                );
            } catch (e) {
                globalNotification.request({
                    type: notifications.type.ERROR,
                    resource: notifications.resource.MEMBERS,
                    action: notifications.action.FETCH,
                    plural: false,
                });
            }
        };

        fetchUserMemberships();
    }, [editUser, form]);

    const handleFormSubmit = async data => {
        setLoading(true);

        try {
            if (data.userMembershipsToArchive && data.userMembershipsToArchive.length > 0) {
                await archiveUsermemberships(data.userMembershipsToArchive);
            }

            if (data.userMembershipsToRestore && data.userMembershipsToRestore.length > 0) {
                await restoreUsermemberships(data.userMembershipsToRestore);
            }

            if (data.roleIds && data.roleIds.length > 0) {
                await handleSubmitEditUser({ roleIds: data.roleIds });
            }

            closeDrawer();
            form.resetFields();
        } catch (e) {
            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.MEMBERS,
                action: notifications.action.UPDATE,
                plural: false,
            });
        } finally {
            setLoading(false);
        }
    };

    return (
        <Form
            form={form}
            labelCol={{ span: 24 }}
            wrapperCol={{ span: 24 }}
            style={{ width: '100%' }}
            onFinish={event => handleFormSubmit(event)}
        >
            <Flex gap={spacing[3]} vertical>
                <Form.Item
                    label={t('labels.archive-user-memberships')}
                    name="userMembershipsToArchive"
                    initialValue={[]}
                >
                    <CustomSelect
                        mode="multiple"
                        maxTagCount={1}
                        placeholder={t('placeholders.user-memberships')}
                        allowClear
                        style={{ height: 48 }}
                        options={activeUserMembershipsOptions}
                        loading={loading}
                        disabled={loading}
                    />
                </Form.Item>

                <Form.Item
                    label={t('labels.restore-user-memberships')}
                    name="userMembershipsToRestore"
                    initialValue={[]}
                >
                    <CustomSelect
                        mode="multiple"
                        maxTagCount={1}
                        placeholder={t('placeholders.user-memberships')}
                        allowClear
                        style={{ height: 48 }}
                        options={archivedUserMembershipsOptions}
                        loading={loading}
                        disabled={loading}
                    />
                </Form.Item>

                <Form.Item
                    label={t('labels.user-roles')}
                    name="roleIds"
                    initialValue={editUser.roleIds}
                    rules={[{ required: true, message: t('errors.required-user-role') }]}
                >
                    <CustomSelect
                        mode="multiple"
                        allowClear
                        style={{ height: 48 }}
                        placeholder={t('placeholders.user-roles')}
                        options={userRoles}
                        loading={loading}
                        disabled={loading}
                        tagRender={({ label, _, closable, onClose }) => (
                            <Tag
                                onMouseDown={e => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                }}
                                closable={closable}
                                onClose={onClose}
                                style={{
                                    backgroundColor: primitiveColors.gray50,
                                    padding: spacing[1],
                                    fontSize: fontSize.sm,
                                    borderRadius: radius.md,
                                    marginLeft: 1,
                                }}
                                bordered={false}
                            >
                                {label}
                            </Tag>
                        )}
                    />
                </Form.Item>

                <Button htmlType="submit" type="primary" size={'large'} loading={loading}>
                    {t('buttons.save')}
                </Button>
            </Flex>
        </Form>
    );
};
