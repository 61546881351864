import { useState, useEffect } from 'react';
import { Button, List } from 'antd';
import classes from './TeamSelector.module.scss';
import classNames from 'classnames';
import icon from '../../styles/Icon.module.scss';
import { useNavigate } from 'react-router-dom';
import { routesPath } from '../../constants/routesPath';
import { useTranslation } from 'react-i18next';
import { useTeamService } from '../../../services/teamService';
import ModalWarning from '../ModalWarning';

const TeamList = ({ data, handleChangeTeam, currentUser, toggleMenu, isMobileMenuOpen }) => {
    const [isTeamsLimitReached, setIsTeamsLimitReached] = useState();
    const navigate = useNavigate();
    const { t } = useTranslation(['teams', 'teamCreation']);
    const { checkTeamsLimit } = useTeamService();

    const handleCheckTeamsLimit = async () => {
        try {
            const isLimitExceeded = await checkTeamsLimit();
            setIsTeamsLimitReached(isLimitExceeded);
        } catch (error) {
            console.error('Error checking teams limit', error);
        }
    };

    useEffect(() => {
        handleCheckTeamsLimit();
    }, []);

    const handleNavigate = () => {
        if (isTeamsLimitReached) {
            return ModalWarning({
                title: t('teamCreation:modal.warning.title'),
                text: t('teamCreation:modal.warning.content'),
                okText: t('teamCreation:buttons.close'),
            });
        }
        navigate(routesPath.ROOT.CREATE_TEAM);

        if (toggleMenu) {
            toggleMenu(!isMobileMenuOpen);
        }
    };

    return (
        <>
            <List
                itemLayout="horizontal"
                dataSource={data}
                renderItem={item => {
                    return (
                        <List.Item
                            className={classNames({
                                [classes['select-item']]: true,
                                [classes.selected]: item.value === currentUser.teamId,
                            })}
                        >
                            <List.Item.Meta
                                value={item.value}
                                onClick={() => {
                                    handleChangeTeam(item.value);
                                    if (toggleMenu) {
                                        toggleMenu(!isMobileMenuOpen);
                                    }
                                }}
                                title={item.label}
                            />
                        </List.Item>
                    );
                }}
            />
            <Button
                className={classes['add-button']}
                icon={<i className={`fi fi-rr-add ${icon.sm}`} />}
                onClick={handleNavigate}
            >
                {t('actions.create')}
            </Button>
        </>
    );
};

export default TeamList;
