import { spacing } from '../../../theming';
import icon from '../../styles/Icon.module.scss';

export default function ClearInputIcon({ setSearchText, handleReset }) {
    return (
        <span
            style={{ paddingTop: spacing[0.5] }}
            onClick={e => {
                e.preventDefault();
                setSearchText('');
                handleReset && handleReset();
            }}
        >
            <i className={`fi fi-rr-cross-circle ${icon['secondary-hover']}`} />
        </span>
    );
}
