import { useTranslation } from 'react-i18next';
import classes from './UploadButton.module.scss';
import { spacing, primitiveColors } from '../../../theming';

export const UploadButton = () => {
    const { t } = useTranslation(['businessForm']);
    return (
        <div className={classes.wrapper}>
            <p className={classes['hover-text']}>{t('buttons.add-image')}</p>
            <button className={classes['button-style']} type="button">
                <div
                    style={{
                        marginTop: spacing[1],
                    }}
                >
                    <svg width="49" height="48" viewBox="0 0 49 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M26.5 41C26.5 41.552 26.052 42 25.5 42H9.5C4.538 42 0.5 37.962 0.5 33V9C0.5 4.038 4.538 0 9.5 0H33.5C38.462 0 42.5 4.038 42.5 9V25C42.5 25.552 42.052 26 41.5 26C40.948 26 40.5 25.552 40.5 25V9C40.5 5.14 37.36 2 33.5 2H9.5C5.64 2 2.5 5.14 2.5 9V25.672L10.122 18.05C12.766 15.406 17.378 15.406 20.022 18.05L34.21 32.294C34.6 32.684 34.598 33.318 34.21 33.708C34.014 33.902 33.758 34 33.504 34C33.248 34 32.992 33.902 32.796 33.706L18.608 19.464C16.722 17.578 13.426 17.576 11.538 19.464L2.5 28.5V33C2.5 36.86 5.64 40 9.5 40H25.5C26.052 40 26.5 40.448 26.5 41ZM34.5 11C34.5 13.758 32.256 16 29.5 16C26.744 16 24.5 13.758 24.5 11C24.5 8.242 26.744 6 29.5 6C32.256 6 34.5 8.242 34.5 11ZM32.5 11C32.5 9.346 31.154 8 29.5 8C27.846 8 26.5 9.346 26.5 11C26.5 12.654 27.846 14 29.5 14C31.154 14 32.5 12.654 32.5 11ZM47.5 38H40.5V31C40.5 30.448 40.052 30 39.5 30C38.948 30 38.5 30.448 38.5 31V38H31.5C30.948 38 30.5 38.448 30.5 39C30.5 39.552 30.948 40 31.5 40H38.5V47C38.5 47.552 38.948 48 39.5 48C40.052 48 40.5 47.552 40.5 47V40H47.5C48.052 40 48.5 39.552 48.5 39C48.5 38.448 48.052 38 47.5 38Z"
                            fill={primitiveColors.gray0}
                        />
                    </svg>
                </div>
            </button>
        </div>
    );
};
