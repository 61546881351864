import { useEffect } from 'react';
import axios from 'axios';
import { getToken, removeToken } from './tokenService';
import { memoizedRefreshToken } from './refreshTokenService';
import { TOKEN, REFRESH_TOKEN } from '../common/constants/localStorage';
import { routesPath } from '../common/constants/routesPath';
import globalRouter from '../routes/GlobalRouter';
import globalNotification from '../common/hooks/globalNotification';
import { notificationType } from '../common/constants/notificationType';
import { constants } from '../common/constants/constants';

function authInterceptor(config) {
    const finalConfig = { ...config };
    const token = getToken(TOKEN);

    if (!finalConfig.headers) {
        finalConfig.headers = {};
    }
    if (token) {
        finalConfig.headers.Authorization = `Bearer ${token}`;
    }

    return finalConfig;
}
// Creating axios instance for all fetch requests
const instance = axios.create({
    baseURL: `${constants.API_BASE_URL}`,
});

// Creating axios instance for refreshing token request only
const refreshTokenInstance = axios.create({
    baseURL: `${constants.API_BASE_URL}`,
});

instance.interceptors.request.use(authInterceptor);

instance.interceptors.response.use(
    res => {
        return res;
    },
    async err => {
        const originalConfig = err.config;
        const errStatus = err?.response?.status;
        const errStatusText = err?.response?.statusText;

        // Access Token was expired
        if (errStatus !== 401) {
            switch (errStatus) {
                case 400:
                    // We will display a notification at the same level when we send a request to the server
                    // Here, we simply reject the promise to avoid double notifications on bad request errors
                    return Promise.reject(err);
                case 403:
                    navigateWithError(errStatus, 'Sorry, you are not authorized to access this page.');
                    break;
                case 404:
                    navigateWithError(errStatus, 'Sorry, the page you visited does not exist.');
                    break;
                case 429:
                    globalNotification.open({
                        type: notificationType.ERROR,
                        message: 'Too many requests',
                        description: 'Sorry, you have exceeded the limit of requests.',
                    });
                    break;
                default:
                    globalNotification.open({
                        type: notificationType.ERROR,
                        message: errStatus ? errStatus + ' ' + errStatusText : 'Error',
                        description: 'Sorry, something went wrong.',
                    });
                    return Promise.reject(err);
            }

            return Promise.reject(err);
        }

        // Refreshing token only once
        const result = await memoizedRefreshToken();

        if (result) {
            originalConfig.headers = {
                ...originalConfig.headers,
                Authorization: `Bearer ${result.accessToken}`,
            };
            return instance(originalConfig);
        } else {
            return Promise.reject(err);
        }
    }
);

// Catch error during token refreshing process.
refreshTokenInstance.interceptors.response.use(
    res => {
        return res;
    },
    async err => {
        removeToken(TOKEN);
        removeToken(REFRESH_TOKEN);

        await globalNotification.open({
            type: notificationType.ERROR,
            message: 'Yor session has expired.',
            description: 'Please proceed to sign-in',
        });

        setTimeout(() => window.location.replace('/auth/sign-in'), 3000);
    }
);

function navigateWithError(status, subTitle) {
    globalRouter.navigate(routesPath.ERROR.PATH, {
        state: {
            status,
            title: status,
            subTitle,
        },
    });
}

export const axiosInstance = instance;

export const axiosRefTokenInst = refreshTokenInstance;

export const CancelSignal = axios.CancelToken.source;

export const isCancelSignal = e => axios.isCancel(e);

export const useAxios = () => {
    const signal = CancelSignal();

    // eslint-disable-next-line arrow-body-style
    useEffect(() => {
        return () => {
            signal.cancel('Unmount useAxios');
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return { axios: axiosInstance, cancelToken: signal.token };
};
