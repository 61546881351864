import { useState } from 'react';
import { Divider, Button, Modal, notification, Flex } from 'antd';
import { QrcodeOutlined, PoweroffOutlined } from '@ant-design/icons';
import TwoFactorAuthForm from '../auth/TwoFactorAuthForm';
import { useAuthService } from '../../services/authService';
import { useAuth } from '../../context/AuthContext';
import { notificationType } from '../../common/constants/notificationType';
import { buttonStyle } from '../../theming';
import { useTranslation } from 'react-i18next';

export default function TwoFactorAuth() {
    const [api, contextHolder] = notification.useNotification();
    const { currentUser, fetchUser } = useAuth();
    const { t } = useTranslation(['settings', 'apiResponses']);

    const { getGeneratedQr, enableTwoFactorAuth, disableTwoFactorAuth } = useAuthService();
    const [loading, setLoading] = useState(false);
    const [qrCode, setQrCode] = useState(null);

    // #region handlers
    function errorNotification(error) {
        api[notificationType.ERROR]({
            message: error.response?.data?.message ?? 'Error occurred while working with 2FA.',
            description: error.response?.data?.description ?? '',
            placement: 'bottomRight',
        });
    }

    async function getQrCode() {
        try {
            const { data } = await getGeneratedQr();

            if (data) {
                setQrCode(data);
            }
        } catch (error) {
            errorNotification(error);
        }
    }

    async function handleDisable() {
        Modal.confirm({
            title: 'Turn off 2FA',
            okText: 'Yes',
            width: 'fit-content',
            content: 'Are you sure you want to disable Two-Factor Authentication.',
            centered: true,
            maskClosable: true,
            onOk: turnOffTwoFactorAuth,
        });
    }

    async function turnOffTwoFactorAuth() {
        try {
            setLoading(true);
            const response = await disableTwoFactorAuth();

            if (response) {
                fetchUser();
            }

            setLoading(false);
        } catch (error) {
            errorNotification(error);
        } finally {
            setLoading(false);
        }
    }

    async function onFormSubmit(form) {
        try {
            setLoading(true);
            const response = await enableTwoFactorAuth(form.code);
            if (response) {
                fetchUser();
            }

            setLoading(false);
        } catch (error) {
            errorNotification(error);
        } finally {
            setLoading(false);
        }
    }
    // #endregion

    if (!currentUser.isTwoFactorEnable) {
        return (
            <Flex vertical justify="center">
                {contextHolder}
                <Divider orientation="left">{t('settings:2FA.step-1.title')}</Divider>
                <div style={{ margin: '0 50px' }}>
                    <p>{t('settings:2FA.step-1.subtitle')}</p>
                </div>
                <Divider orientation="left">{t('settings:2FA.step-2.title')}</Divider>
                <div style={{ margin: '0 50px' }}>
                    <p>{t('settings:2FA.step-2.subtitle')}</p>
                    <Button
                        type="primary"
                        icon={<QrcodeOutlined />}
                        onClick={getQrCode}
                        disabled={qrCode ? true : false}
                        style={{ ...buttonStyle.medium }}
                    >
                        {t('settings:2FA.step-2.button')}
                    </Button>
                    <div style={{ margin: '10px 0' }}>
                        {qrCode && <img src={qrCode} alt="Two-Factor Authentication QR Code" />}
                    </div>
                </div>

                <Divider orientation="left">{t('settings:2FA.step-3.title')}</Divider>
                <div style={{ margin: '0 50px' }}>
                    <p>{t('settings:2FA.step-3.subtitle')}</p>
                    <div style={{ maxWidth: 350 }}>
                        <TwoFactorAuthForm
                            loading={loading}
                            showCancel={false}
                            submitDisabled={qrCode ? false : true}
                            onFormSubmit={onFormSubmit}
                            t={t}
                        />
                    </div>
                </div>
            </Flex>
        );
    }

    if (currentUser.isTwoFactorEnable) {
        return (
            <div>
                {contextHolder}
                <Divider orientation="left">{t('settings:2FA.turn-off.title')}</Divider>
                <Button
                    type="primary"
                    icon={<PoweroffOutlined />}
                    onClick={handleDisable}
                    loading={loading}
                    danger
                    style={{ ...buttonStyle.medium }}
                >
                    {t('settings:2FA.turn-off.button')}
                </Button>
            </div>
        );
    }
}
