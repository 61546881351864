import React from 'react';
import { Typography } from 'antd';
import {
    spacing,
    descriptionText,
    backgroundColor,
    borderColor,
    radius,
    textSmall,
    textStandard,
    fontSize,
    textColor,
} from '../../theming';
import { useMedia } from '../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';

const { Text } = Typography;

const TableRowDescription = ({ record }) => {
    const { description, business, phoneNumber, phones } = record;
    const { isSmallDevice } = useMedia();
    const { t } = useTranslation(['leads']);

    const textStyles = {
        margin: 0,
        ...descriptionText,
        fontSize: fontSize.xxs,
        color: textColor.subtle,
    };

    return (
        <div
            style={{
                padding: spacing[2],
                borderRadius: radius.md,
                backgroundColor: backgroundColor.white,
                border: `1px solid ${borderColor.primary}`,
            }}
        >
            <Text style={{ ...(isSmallDevice ? textSmall : textStandard) }}>{t('description.title')}</Text>
            {business &&
                (business.length > 0 ? (
                    <p style={{ ...textStyles }}>
                        {t('description.all-business')}:&nbsp;
                        {business.map((business, index, array) => (
                            <span key={index} style={{ ...descriptionText, fontSize: fontSize.xxs }}>
                                {business.name}
                                {index < array.length - 1 ? ', ' : ''}
                            </span>
                        ))}
                    </p>
                ) : (
                    <p style={{ ...textStyles }}>{t('no-business')}</p>
                ))}
            {phoneNumber && (
                <p style={{ ...textStyles }}>
                    {t('description.phones')}:&nbsp;
                    {phoneNumber.map((phone, index) => (
                        <span key={phone}>
                            <a href={`tel:${phone}`} style={{ ...descriptionText, fontSize: fontSize.xxs }}>
                                {phone}
                            </a>
                            {index < phoneNumber.length - 1 ? ', ' : ''}
                        </span>
                    ))}
                </p>
            )}
            {phones && (
                <p style={{ ...textStyles }}>
                    {t('description.phones')}:&nbsp;
                    {phones.map((phone, index) => (
                        <span key={phone}>
                            <a href={`tel:${phone}`} style={{ ...descriptionText, fontSize: fontSize.xxs }}>
                                {phone}
                            </a>
                            {index < phones.length - 1 ? ', ' : ''}
                        </span>
                    ))}
                </p>
            )}
            {description ? (
                <p style={{ ...textStyles }}>
                    {t('fields.description')}:&nbsp;
                    <span style={{ ...descriptionText, fontSize: fontSize.xxs }}>{description}</span>
                </p>
            ) : (
                <p style={{ ...textStyles }}>{t('description.no-description')}</p>
            )}
        </div>
    );
};

export default TableRowDescription;
