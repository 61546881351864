import { Outlet } from 'react-router-dom';
import { notification } from 'antd';
import globalNotification from '../../common/hooks/globalNotification';
import { useTranslation } from 'react-i18next';

export const NotificationLayer = () => {
    const { t } = useTranslation(['general', 'notifications']);

    // For displaying a notification and calling it from a component, use globalNotification.open() with the required parameters
    const [api, contextHolder] = notification.useNotification();
    const openNotification = ({ type = 'error', message = '', description = '', placement = 'bottomRight' }) => {
        api[type]({
            message: message,
            description: description,
            placement: placement,
        });
    };

    const requestNotification = ({ type, resource, action, plural }) => {
        const nounType = plural ? 'plural' : 'singular';

        const resourceI18n = t(`notifications:resources.${resource}.${nounType}`);

        api[type]({
            message: t(`general:${type}`),
            description: t(`notifications:${type}`, {
                resource: resourceI18n,
                noun: t(`notifications:noun.${nounType}`),
                action: t(`general:actions.continuous.${action}`).toLowerCase(),
            }),
            placement: 'top',
        });
    };

    globalNotification.open = openNotification;
    globalNotification.request = requestNotification;

    return (
        <>
            {contextHolder}
            <Outlet />
        </>
    );
};
