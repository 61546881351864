import React, { useState } from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { Modal, Upload } from 'antd';
import { useFileService } from '../../services/fileService';
import { beforeUpload, handleFileUpload, handleRemove } from './UploadHelper';
import getBase64 from '../../utils/getBase64';
import { useTranslation } from 'react-i18next';

function PicturesWallUpload({ isMultiple, maxCount, fileTypes, maxFileSizeMb, fieldName, path }) {
    const { t } = useTranslation(['form']);

    const fileService = useFileService();
    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const [fileList, setFileList] = useState([]);

    const props = {
        multiple: isMultiple,
        maxCount: maxCount,
    };

    const handleCancel = () => setPreviewOpen(false);
    const handlePreview = async file => {
        if (!file) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    const handleChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };
    const uploadButton = (
        <button
            style={{
                border: 0,
                background: 'none',
            }}
            type="button"
        >
            <PlusOutlined />
            <div
                style={{
                    marginTop: 8,
                }}
            >
                Upload
            </div>
        </button>
    );

    return (
        <>
            <Upload
                {...props}
                beforeUpload={file => beforeUpload(file, fileTypes, maxFileSizeMb, t)}
                customRequest={fileInfo => handleFileUpload(path, fileInfo, fileService, fieldName)}
                listType="picture-card"
                fileList={fileList}
                onPreview={handlePreview}
                onChange={handleChange}
                onRemove={fileInfo => handleRemove(fileInfo, path, fileService, t)}
            >
                {fileList.length >= maxCount ? null : uploadButton}
            </Upload>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={handleCancel}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    );
}

export default PicturesWallUpload;
