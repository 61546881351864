export const routesPath = {
    ROOT: {
        PATH: '/',
        DASHBOARD: '/dashboard',
        USERS_LIST: '/users-list',
        EXPORT_PDF: '/export-pdf',
        FILE_UPLOAD: '/file-upload',
        SETTINGS: '/settings',
        ORDERS: '/orders',
        CART: '/cart',
        PAYMENTS: '/payments',
        USER_SETTINGS: '/user-settings',
        SUBSCRIPTIONS: '/subscriptions',
        TEAM: '/team',
        CREATE_TEAM: '/create-team-app',
        CREATE_TEAM_AUTH: '/create-team',
        BUSINESSES: '/businesses',
        LEADS: '/leads',
        RECENT_UPDATES: '/recent-updates',
        PROFILE: '/profile',
    },
    AUTH: {
        PATH: '/auth',
        SIGN_IN: 'sign-in',
        CALLBACK: 'callback',
        FORGOT_PASSWORD: 'forgot-password',
        RESTORE_PASSWORD: 'restore-password/:token',
        TWO_FACTOR_AUTH: 'two-factor-authentication',
        INVITATION: 'invitation/:token',
    },
    ERROR: {
        PATH: '/error',
    },
};
