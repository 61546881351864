import { Tag } from 'antd';
import { textColor } from '../../../theming/themeColors';
import classes from './SelectFilterTag.module.scss';

const SelectFilterTag = ({ label, closable, onClose }) => {
    const onPreventMouseDown = event => {
        event.preventDefault();
        event.stopPropagation();
    };
    return (
        <div className={classes.tag}>
            <Tag
                color={textColor.white}
                onMouseDown={onPreventMouseDown}
                closable={closable}
                onClose={onClose}
                style={{
                    marginInlineEnd: 4,
                    color: textColor.link,
                }}
            >
                {label}
            </Tag>
        </div>
    );
};

export default SelectFilterTag;
