import { Button, Flex, Modal, Typography } from 'antd';
import classes from './SuccesModal.module.scss';
import teamMembers from '../../../assets/invite-team-member.png';
import { spacing } from '../../../theming';
import { useTranslation } from 'react-i18next';

export const SuccesModal = ({ userEmail, isModalOpen, closeModal }) => {
    const { t } = useTranslation(['members']);

    return (
        <Modal
            centered
            open={isModalOpen}
            width={464}
            onCancel={closeModal}
            footer={null}
            closeIcon={null}
            styles={{ body: { padding: spacing[5] } }}
        >
            <Button className={classes['modal-close-button']} onClick={closeModal} title="close">
                <i className={`fi-rr-cross ${classes['modal-close-button-icon']}`} />
            </Button>

            <Flex vertical>
                <img src={teamMembers} alt="team-members" className={classes['modal-image']} />

                <Flex vertical gap={spacing[1]}>
                    <Typography.Text className={classes['modal-info-text']}>{t('invite-sent-succes')}</Typography.Text>

                    <Typography.Text className={classes['modal-user-email']}>{userEmail}</Typography.Text>
                </Flex>
            </Flex>

            <Button className={classes['modal-confirm-button']} onClick={closeModal} title="close">
                {t('actions.close')}
            </Button>
        </Modal>
    );
};
