import { Button } from 'antd';
import classes from './ButtonIcon.module.scss';

const ButtonIcon = ({ icon, ...props }) => {
    return (
        <Button className={classes.button} {...props}>
            <i className={`${icon} ${classes['button-icon']}`} />
        </Button>
    );
};

export default ButtonIcon;
