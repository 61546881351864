import { Flex, Typography } from 'antd';
import { fontSize, primitiveColors, spacing } from '../../../theming';
import { useTranslation } from 'react-i18next';

export const PreviewAdditionalInfo = ({ description, phones }) => {
    const { t } = useTranslation(['previewAdditionalInfo', 'general']);

    return (
        (description || phones.length > 1) && (
            <Flex vertical gap={spacing[1]}>
                <Typography.Text style={{ fontSize: fontSize.xs, fontWeight: 500 }}>{t('description')}</Typography.Text>

                <Flex gap={12}>
                    <Typography.Text>{`${t('additional-phones')}:`} </Typography.Text>
                    {phones.length === 1 ? (
                        <Typography.Text>{t('general:none')}</Typography.Text>
                    ) : (
                        phones.slice(1).map(phone => (
                            <a href={`tel:${phone}`}>
                                <Typography.Text style={{ fontWeight: 500, color: primitiveColors.gray500 }}>
                                    {phone};
                                </Typography.Text>
                            </a>
                        ))
                    )}
                </Flex>

                <Typography.Text style={{ fontSize: fontSize.xs, fontWeight: 500, color: primitiveColors.gray500 }}>
                    {description}
                </Typography.Text>
            </Flex>
        )
    );
};
