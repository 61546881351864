import React, { useEffect } from 'react';
import { Button, Result } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import { routesPath } from '../../common/constants/routesPath';

function ErrorPage() {
    const location = useLocation();
    const navigate = useNavigate();

    useEffect(() => {
        if (!location.state) {
            navigate(routesPath.ROOT.DASHBOARD);
        }
    }, [location, navigate]);

    function onClickBackHome() {
        navigate(routesPath.ROOT.DASHBOARD);
    }

    return (
        <>
            <Result
                status={location.state?.status}
                title={location.state?.title}
                subTitle={location.state?.subTitle}
                extra={
                    <Button onClick={onClickBackHome} type="primary">
                        Back Home
                    </Button>
                }
            />
        </>
    );
}
export default ErrorPage;
