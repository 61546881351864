import { Flex, Tag, Tooltip, Typography } from 'antd';
import { fontSize, primitiveColors, spacing, tagIcon, tagStyle } from '../../../theming';
import CustomAvatar from '../Avatar';
import { socialMediaPatterns } from '../../constants/socialMediaPatterns';
import getSocialMediaIcon from '../../../utils/getSocialMediaIcon';
import icon from '../../styles/Icon.module.scss';
import { leadState } from '../../constants/leadState';

export const PreviewMainInfo = ({ id, name, photo, email, phone, socialMedia, state }) => {
    const stateName = leadState.find(s => s.value === state);

    return (
        <Flex gap={spacing[4]} align="center" style={{ width: '100%' }}>
            <CustomAvatar
                key={id}
                size={72}
                photoName={photo}
                firstName={name.split(' ')[0]}
                lastName={name.split(' ')[1]}
                controller={'businesses'}
                style={{ backgroundColor: primitiveColors.gray100, minWidth: 72 }}
            />

            <Flex vertical gap={spacing[3]} style={{ width: '100%' }}>
                <Flex align="center" justify="space-between">
                    <Typography.Title style={{ margin: 0, fontSize: fontSize.H4 }}>{name}</Typography.Title>

                    {state && (
                        <Tag
                            style={{
                                ...tagStyle,
                                gap: spacing[1],
                                width: 'fit-content',
                                height: '28px',
                                color: stateName?.color,
                                backgroundColor: stateName?.backgroundColor,
                                fontSize: 'inherit',
                            }}
                            bordered={false}
                            icon={
                                <span
                                    style={{
                                        backgroundColor: stateName?.color,
                                        ...tagIcon,
                                    }}
                                ></span>
                            }
                        >
                            {stateName?.label}
                        </Tag>
                    )}
                </Flex>
                <Flex justify="space-between" align="center" style={{ width: '100%' }} gap={spacing[1]} wrap>
                    <Flex gap={12} align="center">
                        <i className={`fi fi-rr-envelope ${icon.sm}`} style={{ color: primitiveColors.gray400 }} />
                        <a href={`mailto:${email}`}>
                            <Typography.Text style={{ fontWeight: 500, color: primitiveColors.gray400 }}>
                                {email}
                            </Typography.Text>
                        </a>
                    </Flex>

                    <Flex gap={12} align="center">
                        <i
                            className={`fi fi-rr-phone-flip ${icon.sm}`}
                            style={{ color: primitiveColors.gray400, transform: 'rotate(90deg)' }}
                        />

                        <a href={`tel:${phone}`}>
                            <Typography.Text style={{ fontWeight: 500, color: primitiveColors.gray400 }}>
                                {phone}
                            </Typography.Text>
                        </a>
                    </Flex>

                    <Flex gap={spacing[1]}>
                        {Object.values(socialMedia).map((item, index) => {
                            return (
                                item &&
                                socialMediaPatterns.some(pattern => new RegExp(pattern).test(item)) && (
                                    <a
                                        key={index}
                                        href={item.startsWith('http') ? item : `https://${item}`}
                                        target="_blank"
                                        rel="noreferrer"
                                        style={{
                                            textDecoration: 'none',
                                            color: primitiveColors.gray400,
                                            width: 22,
                                            height: 22,
                                        }}
                                    >
                                        {getSocialMediaIcon(item)}
                                    </a>
                                )
                            );
                        })}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
};
