export const colors = {
    colorPrimary: '#0065FF',
    colorSecondary: '#1e2021',
    colorTertiary: '#3c3d40',
    colorSuccess: '#52c41a',
    colorWarning: '#faad14',
    colorError: '#ff4d4f',
    colorLink: '#1677ff',
    white: '#ffffff',
    black: '#000000',
};

export const coreBrandColors = {
    blueRibbon: '#0065FF',
    mintMorning: '#00EBCE',
    spaceman: '#596579',
    white: '#FFFFFF',
};

export const primitiveColors = {
    blue0: '#F0F6FF',
    blue25: '#F5F9FF',
    blue50: '#E6F0FF',
    blue100: '#B0CFFF',
    blue200: '#8AB8FF',
    blue300: '#5498FF',
    blue400: '#3384FF',
    blue500: '#0065FF',
    blue600: '#005CE8',
    blue700: '#004BB5',
    blue800: '#00388C',
    blue900: '#002A6B',
    gray0: '#FFFFFF',
    gray25: '#F6F7F8',
    gray50: '#EEF0F2',
    gray75: '#E9EBED',
    gray100: '#CCCFD5',
    gray200: '#B3B8C1',
    gray300: '#9098A5',
    gray400: '#7A8494',
    gray500: '#596579',
    gray600: '#515C6E',
    gray700: '#3F4856',
    gray800: '#313843',
    gray900: '#252A33',
    mint50: '#E6FDFA',
    mint100: '#B0F9F0',
    mint200: '#8AF6E8',
    mint300: '#54F2DE',
    mint400: '#33EFD8',
    mint500: '#00EBCE',
    mint600: '#00D6BB',
    mint700: '#00A792',
    mint800: '#008171',
    mint900: '#006357',
    green50: '#E7FFFB',
    green100: '#B1EEE4',
    green200: '#8BE6D7',
    green300: '#56D8C5',
    green400: '#35D4BA',
    green500: '#03C9A9',
    green600: '#00B79A',
    green700: '#028F78',
    green800: '#026F5D',
    green900: '#026F5D',
    orange50: '#FFFOE7',
    orange100: '#FFE9DC',
    orange200: '#FFBA92',
    orange300: '#FF9A60',
    orange400: '#FF8641',
    orange500: '#FF6812',
    orange600: '#FF5D00',
    orange700: '#F05A04',
    orange800: '#DC5A04',
    orange900: '#C85A04',
    red50: '#FCEDED',
    red100: '#F5C8C6',
    red200: '#F0AEAA',
    red300: '#E88983',
    red400: '#E57268',
    red500: '#DE4F46',
    red600: '#CA4840',
    red700: '#9E3834',
    red800: '#7A2B27',
    red900: '#5D211D',
    magenta50: '#FCE8F1',
    magenta100: '#F7BFD9',
    magenta500: '#E83E8C',
    ocean50: '#E6F6FF',
    ocean100: '#AEE1FE',
    ocean500: '#038FDD',
    cyan50: '#E8F9FC',
    cyan100: '#B6EDF6',
    cyan500: '#17A2B8',
    geekblue50: '#F0E7FE',
    geekblue100: '#D4BBFB',
    geekblue500: '#6610F2',
    gold50: '#FFF9E5',
    gold100: '#FFEBAD',
    gold500: '#EBB000',
    vitalize50: '#EAFAEE',
    vitalize100: '#C5F1CF',
    vitalize500: '#28A745',
    lime50: '#F7FDE7',
    lime100: '#D6F882',
    lime500: '#94CA0C',
    nectarine50: '#FFF1E6',
    nectarine100: '#FED8B8',
    nectarine500: '#FD7E14',
    purple50: '#F0ECF9',
    purple100: '#D6C9ED',
    purple500: '#6F42C1',
    ruby50: '#FBE9EB',
    ruby100: '#F4C2C7',
    ruby500: '#DC3545',
    volcano50: '#FEECE6',
    volcano100: '#FDCAB9',
    volcano500: '#FA541C',
};
