import { useAxios } from './axiosService';

export const useTeamService = () => {
    const { axios } = useAxios();

    const createTeam = teamName => {
        return axios.post('/teams', { teamName });
    };

    const getTeamInfo = () => {
        return axios.get('/teams');
    };

    const getTeamMembers = params => {
        return axios.get('/teams/members?', {
            params: {
                offset: params.offset,
                limit: params.limit,
                globalSearch: params.globalSearch,
                status: params.status,
                roles: params.roles,
                isDeleted: params.isDeleted,
                teamId: params.teamId,
                sortOrder: params.sortOrder,
                sortField: params.sortField,
            },
        });
    };

    const sendInvitation = data => {
        return axios.post('/teams/invitation', data);
    };

    const cancelInvitation = (userId, membershipId) => {
        return axios.delete(`/teams/invitation/${userId}`, {
            data: {
                membershipId,
            },
        });
    };

    const updateMembership = data => {
        return axios.patch(`/memberships`, data);
    };

    const cancelMemberships = ids => {
        return axios.delete(`/memberships`, {
            data: { ids },
        });
    };

    const archiveMemberships = async ids => {
        return await axios.put('/memberships/archive', { ids });
    };

    const restoreMemberships = async (ids, teamRole) => {
        return await axios.put('/memberships/restore', { ids, teamRole });
    };

    const findUserMembership = async () => {
        return await axios.get(`/memberships/find-membership`);
    };

    const getUserMemberships = withDeleted => {
        return axios.get('/memberships/me', {
            params: {
                withDeleted,
            },
        });
    };

    const getMembershipsByUserId = async (userId, withDeleted) => {
        return axios.get(`/memberships/user-memberships/${userId}`, {
            params: { withDeleted },
        });
    };

    const deleteTeam = teamId => {
        return axios.delete(`/teams/${teamId}`);
    };

    const leaveTeam = async (teamId, newOwnerId) => {
        await axios.put(`/teams/leave-team/${teamId}`, {
            newOwnerId,
        });
    };

    const checkTeamsLimit = async () => {
        const data = await axios.get('/teams/limit');
        return data.data;
    };

    return {
        createTeam,
        getTeamInfo,
        getTeamMembers,
        sendInvitation,
        cancelInvitation,
        cancelMemberships,
        archiveMemberships,
        updateMembership,
        restoreMemberships,
        findUserMembership,
        getUserMemberships,
        getMembershipsByUserId,
        deleteTeam,
        leaveTeam,
        checkTeamsLimit,
    };
};
