import { Flex } from 'antd';
import { SortableContext, rectSwappingStrategy } from '@dnd-kit/sortable';
import { SortableItem } from './helper';
import { spacing } from '../../theming';

export default function StatusesList({ statuses }) {
    return (
        <Flex
            align="center"
            gap={spacing[2]}
            style={{
                flexWrap: 'wrap',
            }}
        >
            <SortableContext items={statuses.map(status => status.statusOrder)} strategy={rectSwappingStrategy}>
                {statuses
                    .sort((a, b) => a.statusOrder - b.statusOrder)
                    .map(status => (
                        <SortableItem key={status.statusOrder} status={status} />
                    ))}
            </SortableContext>
        </Flex>
    );
}
