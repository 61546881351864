import React, { useState, useEffect } from 'react';
import { Upload, Modal, Typography, Flex, Space } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useFileService } from '../../services/fileService';
import { handleFileUpload, handleRemove, beforeUpload } from './UploadHelper';
import getBase64 from '../../utils/getBase64';
import { UploadButton } from '../../common/components/UploadButton/UploadButton';
import { useTranslation } from 'react-i18next';
import { DeleteButton } from '../../common/components/DeleteButton/DeleteButton';
import { spacing } from '../../theming';

function CropImageBeforeUpload({ fileQuality, fieldName, path, maxCount = 1, id, file, withPreviewModal = false }) {
    const { t } = useTranslation(['general', 'form']);

    const fileService = useFileService();
    const [fileList, setFileList] = useState([]);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const handlePreviewModal = async file => {
        if (!file.url) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url?.substring(file.url.lastIndexOf('/') + 1) || file.substring(file.lastIndexOf('/') + 1)
        );
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const handlePhotoDelete = async () => {
        await handleRemove(fileList[0], path.delete || path, fileService, t);
        setFileList([]);
    };

    useEffect(() => {
        if (file) {
            setFileList([
                {
                    uid: '-1',
                    name: file.name || file.substring(file.lastIndexOf('/') + 1),
                    status: 'done',
                    url: file.url || file,
                },
            ]);
        }
        if (file === null) {
            setFileList([]); // reset the fileList if user have no image
        }
    }, [file]);

    return (
        <Flex gap={80.5}>
            <Space direction="vertical" align="center">
                <Space gap={spacing[4]}>
                    <ImgCrop rotationSlider quality={fileQuality}>
                        <Upload
                            beforeUpload={fileInfo =>
                                beforeUpload(fileInfo, ['image/jpeg', 'image/png', 'image/jpg'], 5, t)
                            }
                            customRequest={fileInfo =>
                                handleFileUpload(path.upload || path, fileInfo, fileService, fieldName, id)
                            }
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={withPreviewModal ? handlePreviewModal : onPreview}
                            onRemove={fileInfo => handleRemove(fileInfo, path.delete || path, fileService, t)}
                            maxCount={maxCount}
                        >
                            {fileList.length < maxCount && <UploadButton />}
                        </Upload>
                    </ImgCrop>
                    {fileList.length >= maxCount && <DeleteButton onDelete={handlePhotoDelete} />}
                </Space>
                {fileList.length < maxCount && (
                    <Typography.Text style={{ maxWidth: '200px' }}>{t('actions.complex.add-image')}</Typography.Text>
                )}
            </Space>
            <Modal open={previewOpen} title={previewTitle} footer={null} onCancel={() => setPreviewOpen(false)}>
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </Flex>
    );
}
export default CropImageBeforeUpload;
