import { Typography, Flex } from 'antd';
import ButtonIcon from '../components/buttonIcon/ButtonIcon';
import { spacing } from './../../theming';

export default function PopoverTitle({ title, onClickClose }) {
    return (
        <Flex align="center" justify="space-between">
            <Typography.Text strong>{title}</Typography.Text>
            <ButtonIcon
                onClick={onClickClose}
                icon="fi-rr-cross"
                style={{
                    top: spacing[1],
                    right: spacing[2],
                }}
            />
        </Flex>
    );
}
