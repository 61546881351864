import React, { useEffect, useState } from 'react';
import { Button, Flex, Form, Input, Space } from 'antd';
import { BusinessesService } from '../../services/businessesService';
import globalNotification from '../../common/hooks/globalNotification';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import { constants } from '../../common/constants/constants';
import classes from '../../common/styles/FormStyles.module.scss';
import CustomAvatar from '../../common/components/Avatar';
import { useTranslation } from 'react-i18next';
import { notifications } from '../../common/constants/notifications';
import { DeleteButton } from '../../common/components/DeleteButton/DeleteButton';
import '../../common/styles/FormStyles.module.scss';
import { defaultSocialOptions } from '../../common/constants/defaultSocialOptions';
import { spacing } from '../../theming';
import CustomSelect from '../../common/components/Select';
import classNames from 'classnames';
import { getSocialMediaValidationRules } from '../../common/constants/socialMediaValidationRules';
import FormImageUpload from '../../common/components/FormImageUpload/FormImageUpload';
import { useMedia } from '../../hooks/useMediaQuery';
import { notificationType } from '../../common/constants/notificationType';

const BusinessesForm = ({
    defaultValues,
    type,
    closeDrawer,
    openPreviewDrawer,
    fetchData,
    isDrawerOpened,
    isClickFromView,
    openNotification,
}) => {
    const { t } = useTranslation(['businessForm', 'general']);

    const { isSmallDevice } = useMedia();

    const [form] = Form.useForm();
    const [isFormLoading, setIsFormLoading] = useState(false);

    const [contactPersonSelectOptions, setContactPersonSelectOptions] = useState([]);
    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [selectedContactPerson, setSelectedContactPerson] = useState(undefined);

    const [phoneFieldsErrors, setPhoneFieldsErrors] = useState([]);

    const [socialMediaOptions, setSocialMediaOptions] = useState(defaultSocialOptions);
    const [socialsList, setSocialsList] = useState({ 0: 'facebook' });

    const { API_BASE_URL } = constants;
    const baseApiUrl = {
        upload: '/businesses/upload-photo',
        delete: '/businesses/delete-photo',
        get: '/businesses/get-photo',
    };

    const { createBusiness, updateBusiness, getMembersWithoutBusiness } = BusinessesService();

    const fetchUsers = async () => {
        setIsUsersLoading(true);

        try {
            const response = await getMembersWithoutBusiness();

            let usersSelectOptions = [
                {
                    value: 0,
                    label: t('general:none'),
                    firstName: t('general:none'),
                    lastName: t('general:none'),
                },
            ];

            if (defaultValues && defaultValues.contactPerson) {
                usersSelectOptions.push({
                    value: defaultValues.contactPerson.id,
                    photo: defaultValues.contactPerson.photo,
                    firstName: defaultValues.contactPerson.firstName,
                    lastName: defaultValues.contactPerson.lastName,
                    label: (
                        <Space gap={spacing[1]}>
                            <CustomAvatar
                                key={defaultValues.contactPerson.id}
                                size={32}
                                photoName={defaultValues.contactPerson.photo}
                                firstName={defaultValues.contactPerson.firstName}
                                lastName={defaultValues.contactPerson.lastName}
                                controller={'businesses'}
                            />

                            {`${defaultValues.contactPerson.firstName} ${defaultValues.contactPerson.lastName}`}
                        </Space>
                    ),
                });
            }

            usersSelectOptions = [
                ...usersSelectOptions,
                ...response.users.map(user => ({
                    value: user.id,
                    photo: user.photo,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    label: (
                        <Space gap={spacing[1]}>
                            <CustomAvatar
                                key={user.id}
                                size={32}
                                photoName={user.photo}
                                firstName={user.firstName}
                                lastName={user.lastName}
                                controller={'businesses'}
                            />

                            {`${user.firstName} ${user.lastName}`}
                        </Space>
                    ),
                })),
            ];

            setContactPersonSelectOptions(usersSelectOptions);
        } catch (error) {
            console.log(error);
        } finally {
            setIsUsersLoading(false);
        }
    };

    useEffect(() => {
        if (defaultValues) {
            const defaultSocials = Object.keys(defaultValues.socialMedia)
                .map(key => {
                    if (defaultValues.socialMedia[key]) {
                        return { type: key, url: defaultValues.socialMedia[key] };
                    }
                    return undefined;
                })
                .filter(item => item !== undefined) || [{ type: 'facebook', url: '' }];

            if (defaultSocials.length < 1) {
                defaultSocials.push({ type: 'facebook', url: '' });
            }

            const socialOptions = socialMediaOptions.map(option => {
                if (defaultSocials.some(social => social.type === option.value)) {
                    return { ...option, disabled: true };
                }

                return option;
            });

            const newSocialsList = {};

            defaultSocials.forEach((social, index) => {
                newSocialsList[index] = social.type;
            });

            form.setFieldsValue({
                name: defaultValues.name,
                addressOne: defaultValues.address.addressOne,
                addressTwo: defaultValues.address.addressTwo,
                city: defaultValues.address.city,
                country: defaultValues.address.country,
                state: defaultValues.address.state,
                zipCode: defaultValues.address.zipCode,
                phones: defaultValues.phones,
                email: defaultValues.email,
                photo: defaultValues.photo,
                contactPerson: defaultValues.contactPerson ? defaultValues.contactPerson.id : 0,
                description: defaultValues.description,
                socialMedia: defaultSocials,
            });

            setSocialsList(newSocialsList);
            setSocialMediaOptions(socialOptions);
            setSelectedContactPerson(defaultValues.contactPerson ? defaultValues.contactPerson.id : undefined);
            setPhoneFieldsErrors(Array(defaultValues.phones.length).fill(false));
        } else {
            form.resetFields();
            form.setFieldsValue({ contactPerson: null });
            form.setFieldsValue({ socialMedia: [{ type: 'facebook', url: '' }] });
            setContactPersonSelectOptions([
                {
                    value: 0,
                    label: t('general:none'),
                },
            ]);

            setPhoneFieldsErrors([]);
            setSocialMediaOptions(defaultSocialOptions);
            setSocialsList({ 0: 'facebook' });
        }
    }, [defaultValues, form]);

    useEffect(() => {
        if (isDrawerOpened) {
            fetchUsers();
        } else {
            form.resetFields();
        }
    }, [defaultValues, form, isDrawerOpened]);

    const onUpdate = async () => {
        setIsFormLoading(true);

        const socialMediaObject = form.getFieldValue('socialMedia').reduce((acc, item) => {
            acc[item.type] = item.url;
            return acc;
        }, {});

        try {
            const newValues = form.getFieldsValue();

            const address = {
                addressOne: newValues.addressOne,
                addressTwo: newValues.addressTwo,
                city: newValues.city,
                country: newValues.country,
                state: newValues.state,
                zipCode: newValues.zipCode,
            };

            delete newValues.photo;
            delete newValues.addressOne;
            delete newValues.addressTwo;
            delete newValues.city;
            delete newValues.country;
            delete newValues.state;
            delete newValues.zipCode;

            const updatedBusiness = await updateBusiness(defaultValues.id, {
                ...newValues,
                address,
                socialMedia: socialMediaObject,
            });

            globalNotification.request({
                type: notifications.type.SUCCESS,
                resource: notifications.resource.BUSINESSES,
                action: notifications.action.UPDATE,
                single: true,
            });

            fetchData();
            closeDrawer();

            if (isClickFromView) {
                setTimeout(() => {
                    // we need setTimeout here for smooth animation, drawer closing takes around 500ms, after that we will open preview drawer
                    openPreviewDrawer(updatedBusiness);
                }, 500);
            }
        } catch (error) {
            if (error.response.status === 400) {
                const errorCode = error.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            }
        } finally {
            setIsFormLoading(false);
        }
    };

    const onCreate = async () => {
        setIsFormLoading(true);

        const socialMediaObject = form.getFieldValue('socialMedia').reduce((acc, item) => {
            acc[item.type] = item.url;
            return acc;
        }, {});

        try {
            const formValues = form.getFieldsValue();

            const address = {
                addressOne: formValues.addressOne,
                addressTwo: formValues.addressTwo,
                city: formValues.city,
                country: formValues.country,
                state: formValues.state,
                zipCode: formValues.zipCode,
            };

            delete formValues.addressOne;
            delete formValues.addressTwo;
            delete formValues.city;
            delete formValues.country;
            delete formValues.state;
            delete formValues.zipCode;
            await createBusiness({ ...formValues, address, socialMedia: socialMediaObject });

            globalNotification.request({
                type: notifications.type.SUCCESS,
                resource: notifications.resource.BUSINESSES,
                action: notifications.action.ADD,
                single: true,
            });

            fetchData();
            form.resetFields();
            form.setFieldsValue({ contactPerson: 0 });
            closeDrawer();
        } catch (error) {
            if (error.response.status === 400) {
                const errorCode = error.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            }
        } finally {
            setIsFormLoading(false);
        }
    };

    const handleSelectKeyDown = event => {
        if (event.key === 'Backspace') {
            form.setFieldsValue({ contactPerson: null });
        }
    };

    const handleSocialsListChange = (index, value) => {
        const newSocials = { ...socialsList };

        newSocials[index] = value;

        const newSocialMediaOptions = socialMediaOptions.map(option => {
            if (Object.values(newSocials).includes(option.value)) {
                return { ...option, disabled: true };
            }

            return { ...option, disabled: false };
        });

        form.setFieldValue(['socialMedia', index, 'url'], '');
        form.setFieldValue(['socialMedia', index, 'type'], value);
        setSocialMediaOptions(newSocialMediaOptions);
        setSocialsList(newSocials);
    };

    const getDefaultSocialSelectValue = () => {
        const result = defaultSocialOptions.find(option => !Object.values(socialsList).includes(option.value));

        return result.value;
    };

    const handleAddSocialMediaField = () => {
        const keys = Object.keys(socialsList).map(Number);
        const maxKey = keys.length > 0 ? Math.max(...keys) : 0;

        const defaultSocialValue = getDefaultSocialSelectValue();

        handleSocialsListChange(maxKey + 1, defaultSocialValue);
        form.setFieldValue(['socialMedia', maxKey + 1, 'type'], defaultSocialValue);
        setSocialsList({ ...socialsList, [maxKey + 1]: defaultSocialValue });
    };

    const handleRemoveSocialMediaField = (removeFunc, name) => {
        const { [name]: removed, ...remainingSocialsList } = socialsList;

        const newSocialsList = Object.fromEntries(
            Object.entries(remainingSocialsList).map(([key, value], index) => [index, value])
        );

        setSocialsList(newSocialsList);
        setSocialMediaOptions(prev =>
            prev.map(option => {
                if (option.value === removed) {
                    return {
                        ...option,
                        disabled: false,
                    };
                }

                return option;
            })
        );
        removeFunc(name);
    };

    const filterOption = (input, option) =>
        `${option?.firstName.toLowerCase()} ${option?.lastName.toLowerCase()}`.includes(input.toLowerCase());

    return (
        <div className="form-wrapper">
            <Form
                name="basic"
                layout="vertical"
                onFinish={type === 'create' ? onCreate : onUpdate}
                autoComplete="off"
                form={form}
            >
                {defaultValues && (
                    <Form.Item name="photo">
                        <FormImageUpload
                            fileQuality={0.7}
                            fieldName={'photo'}
                            path={baseApiUrl}
                            id={defaultValues.id}
                            file={
                                form.getFieldValue('photo') &&
                                `${API_BASE_URL}/businesses/get-photo/${form.getFieldValue('photo')}`
                            }
                            withPreviewModal
                            setFormFields={value => form.setFieldsValue('photo', value)}
                            title={t('labels.business-logo')}
                        />
                    </Form.Item>
                )}
                <Form.Item
                    label={t('labels.name')}
                    name="name"
                    initialValue=""
                    className="custom-error-message"
                    rules={[
                        {
                            required: true,
                            message: t('errors.required-name'),
                        },
                        {
                            pattern: /^[a-zA-Z0-9\s]*$/,
                            message: t('errors.only-latin-and-numbers-name'),
                        },
                        {
                            min: 3,
                            message: t('errors.at-least-3-characters-name'),
                        },
                    ]}
                >
                    <Input placeholder={t('placeholders.name')} />
                </Form.Item>

                <Form.Item
                    label={t('labels.email')}
                    name="email"
                    initialValue=""
                    rules={[
                        {
                            required: true,
                            message: t('errors.required-email'),
                        },
                        {
                            type: 'email',
                            message: t('errors.invalid-email-format'),
                        },
                    ]}
                >
                    <Input placeholder="example@company.com" />
                </Form.Item>

                <Form.Item name="description" label={t('labels.description')}>
                    <Input.TextArea
                        placeholder={t('placeholders.description')}
                        maxLength={500}
                        style={{ minHeight: 120 }}
                        autoSize
                    />
                </Form.Item>

                <Form.Item>
                    <Form.List name="phones" initialValue={['']}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map((field, index) => (
                                    <Space key={field.key} style={{ display: 'flex' }} align="center" size="0">
                                        <Form.Item
                                            {...field}
                                            label={
                                                index === 0 ? t('labels.primary-phone') : t('labels.additional-phone')
                                            }
                                            name={[field.name]}
                                            style={{ marginBottom: spacing[4] }}
                                            fieldKey={[field.fieldKey]}
                                            initialValue={index === 0 ? '' : undefined}
                                            required
                                            rules={[
                                                {
                                                    validator: async (_, phone) => {
                                                        let newErrorsArray = [...phoneFieldsErrors];

                                                        if (!phone) {
                                                            newErrorsArray[index] = true;

                                                            setPhoneFieldsErrors(newErrorsArray);

                                                            return Promise.reject(
                                                                new Error(t('errors.required-phone'))
                                                            );
                                                        }
                                                        // it`s 16 here because library also added plus, bracets and spaces for value
                                                        if (phone.trim().length < 16) {
                                                            newErrorsArray[index] = true;

                                                            setPhoneFieldsErrors(newErrorsArray);

                                                            return Promise.reject(
                                                                new Error(t('errors.at-least-10-characters-phone'))
                                                            );
                                                        }

                                                        const phoneValues = form.getFieldValue('phones');
                                                        let duplicatePhoneFound = false;

                                                        phoneValues.forEach((phone, i) => {
                                                            const duplicatePhone = phoneValues.some(
                                                                (innerPhone, innerIndex) => {
                                                                    return innerIndex !== i && innerPhone === phone;
                                                                }
                                                            );
                                                            if (duplicatePhone) {
                                                                duplicatePhoneFound = true;
                                                                newErrorsArray[index] = true;
                                                                setPhoneFieldsErrors(newErrorsArray);
                                                            }
                                                        });

                                                        if (duplicatePhoneFound) {
                                                            return Promise.reject('Phone number must be unique');
                                                        }

                                                        newErrorsArray[index] = false;

                                                        setPhoneFieldsErrors(newErrorsArray);

                                                        return Promise.resolve();
                                                    },
                                                },
                                            ]}
                                        >
                                            <Space size="0">
                                                <PhoneInput
                                                    country={'us'}
                                                    value={
                                                        form.getFieldValue('phones')
                                                            ? form.getFieldValue('phones')[index]
                                                            : ''
                                                    }
                                                    containerClass={classes['phone-input-container']}
                                                    inputClass={classNames(classes['phone-input'], {
                                                        [classes['phone-input-error']]: phoneFieldsErrors[index],
                                                    })}
                                                    buttonClass={classNames(classes['phone-input-button'], {
                                                        [classes['phone-input-error']]: phoneFieldsErrors[index],
                                                    })}
                                                />
                                                {index === 0 ? null : (
                                                    <DeleteButton onDelete={() => remove(field.name)} />
                                                )}
                                            </Space>
                                        </Form.Item>
                                    </Space>
                                ))}

                                <Button
                                    onClick={() => add()}
                                    className={classes['add-phone-button']}
                                    icon={<i className={`fi-rr-add ${classes['add-phone-button-icon']}`} />}
                                >
                                    {t('buttons.add-phone')}
                                </Button>
                            </>
                        )}
                    </Form.List>
                </Form.Item>

                <Form.Item
                    label={t('labels.address')}
                    name="addressOne"
                    initialValue=""
                    rules={[
                        {
                            required: true,
                            message: t('errors.required-address'),
                        },
                        {
                            pattern: /^[a-zA-Z0-9\s,.'\-]*$/,
                            message: t('errors.valid-address'),
                        },
                        {
                            min: 3,
                            message: t('errors.at-least-3-characters-address'),
                        },
                    ]}
                >
                    <Input placeholder={t('placeholders.address')} />
                </Form.Item>

                <Form.Item
                    label={`${t('labels.address')} 2`}
                    name="addressTwo"
                    initialValue=""
                    rules={[
                        {
                            pattern: /^[a-zA-Z0-9\s,.'\-]*$/,
                            message: t('errors.valid-address'),
                        },
                        {
                            min: 3,
                            message: t('errors.at-least-3-characters-address'),
                        },
                    ]}
                >
                    <Input placeholder={t('placeholders.address-two')} />
                </Form.Item>

                <Flex gap={spacing[5]}>
                    <Form.Item
                        label={t('labels.city')}
                        name="city"
                        initialValue=""
                        style={{ flex: 1 }}
                        rules={[
                            {
                                required: true,
                                message: t('errors.required-city'),
                            },
                            {
                                pattern: /^[A-Za-z\s]+$/,
                                message: t('errors.only-text-city'),
                            },
                            {
                                min: 2,
                                message: t('errors.at-least-2-characters-city'),
                            },
                        ]}
                    >
                        <Input placeholder={t('placeholders.city')} />
                    </Form.Item>

                    <Form.Item
                        label={t('labels.country')}
                        name="country"
                        style={{ flex: 1 }}
                        initialValue=""
                        rules={[
                            {
                                required: true,
                                message: t('errors.required-country'),
                            },
                            {
                                pattern: /^[A-Za-z\s]+$/,
                                message: t('errors.only-text-country'),
                            },
                            {
                                min: 2,
                                message: t('errors.at-least-2-characters-country'),
                            },
                        ]}
                    >
                        <Input placeholder={t('placeholders.country')} />
                    </Form.Item>
                </Flex>

                <Flex gap={spacing[5]}>
                    <Form.Item
                        label={t('labels.state')}
                        name="state"
                        initialValue=""
                        style={{ flex: 1 }}
                        rules={[
                            {
                                required: true,
                                message: t('errors.required-state'),
                            },
                            {
                                pattern: /^[A-Za-z\s]+$/,
                                message: t('errors.only-text-state'),
                            },
                            {
                                min: 2,
                                message: t('errors.at-least-2-characters-state'),
                            },
                        ]}
                    >
                        <Input placeholder={t('placeholders.state')} />
                    </Form.Item>

                    <Form.Item
                        label={t('labels.zip-code')}
                        name="zipCode"
                        initialValue=""
                        style={{ flex: 1 }}
                        rules={[
                            {
                                required: true,
                                message: t('errors.required-zip-code'),
                            },
                            {
                                pattern: /^[A-Za-z0-9\s\-]{3,10}$/,
                                message: t('errors.invalid-zip-code'),
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                </Flex>

                <Form.Item
                    label={t('labels.contact-person')}
                    name="contactPerson"
                    rules={[
                        {
                            required: true,
                            message: t('errors.required-contact-person'),
                        },
                    ]}
                >
                    <CustomSelect
                        showSearch={selectedContactPerson === undefined}
                        filterOption={filterOption}
                        placeholder={t('placeholders.contact-person')}
                        suffixIcon={<i className={`fi-rr-angle-small-down ${classes['select-suffix-icon']}`} />}
                        allowClear
                        onChange={setSelectedContactPerson}
                        onKeyDown={handleSelectKeyDown}
                        style={{ height: 48 }}
                        options={contactPersonSelectOptions}
                        loading={isUsersLoading}
                        disabled={isUsersLoading}
                        optionRender={option => {
                            if (option.value === 0) {
                                return (
                                    <Space gap={spacing[1]}>
                                        <CustomAvatar
                                            key={0}
                                            size={32}
                                            firstName={t('general:none')}
                                            lastName={t('general:none')}
                                            controller={'businesses'}
                                            style={{ opacity: 0 }}
                                        />
                                        {t('general:none')}
                                    </Space>
                                );
                            }

                            return (
                                <Space gap={spacing[1]}>
                                    <CustomAvatar
                                        key={option.value}
                                        size={32}
                                        photoName={option.data.photo}
                                        firstName={option.data.firstName}
                                        lastName={option.data.lastName}
                                        controller={'businesses'}
                                    />
                                    {`${option.data.firstName} ${option.data.lastName}`}
                                </Space>
                            );
                        }}
                    />
                </Form.Item>

                <Form.Item>
                    <Form.List name="socialMedia" initialValue={[{ type: 'facebook', url: '' }]}>
                        {(fields, { add, remove }) => (
                            <>
                                {fields.map(({ key, name, fieldKey, ...restField }, index) => (
                                    <Flex key={key} align="center" style={{ width: isSmallDevice && '100%' }}>
                                        <Form.Item
                                            {...restField}
                                            label={t('labels.social-media')}
                                            style={{ marginBottom: spacing[4], width: '100%' }}
                                        >
                                            <Flex
                                                vertical={isSmallDevice}
                                                style={{ width: '100%', gap: spacing[1] }}
                                                align={isSmallDevice ? 'start' : 'center'}
                                            >
                                                <Form.Item
                                                    {...restField}
                                                    name={[socialsList[index], 'type']}
                                                    fieldKey={[fieldKey, 'type']}
                                                    noStyle
                                                >
                                                    <Flex
                                                        style={{ width: isSmallDevice && '100%' }}
                                                        align={'center'}
                                                        justify={isSmallDevice ? 'space-between' : 'start'}
                                                    >
                                                        <CustomSelect
                                                            key={form.getFieldValue(['socialMedia', index, 'type'])}
                                                            style={{ height: 48, minWidth: 98, maxWidth: 98 }}
                                                            options={socialMediaOptions}
                                                            suffixIcon={
                                                                <i
                                                                    className={`fi-rr-angle-small-down ${classes['select-suffix-icon']}`}
                                                                />
                                                            }
                                                            defaultValue={form.getFieldValue([
                                                                'socialMedia',
                                                                index,
                                                                'type',
                                                            ])}
                                                            onChange={e => handleSocialsListChange(index, e)}
                                                            optionRender={option => {
                                                                if (option.data.disabled) {
                                                                    return (
                                                                        <div style={{ opacity: 0.5 }}>
                                                                            {option.data.label}
                                                                        </div>
                                                                    );
                                                                }

                                                                return option.data.label;
                                                            }}
                                                        />

                                                        {index === 0 || !isSmallDevice ? null : (
                                                            <DeleteButton
                                                                onDelete={() =>
                                                                    handleRemoveSocialMediaField(remove, name)
                                                                }
                                                            />
                                                        )}
                                                    </Flex>
                                                </Form.Item>
                                                <Form.Item
                                                    {...restField}
                                                    name={[name, 'url']}
                                                    fieldKey={[fieldKey, 'url']}
                                                    noStyle
                                                    rules={[
                                                        getSocialMediaValidationRules(
                                                            socialsList[index] || 'facebook',
                                                            t
                                                        ),
                                                    ]}
                                                >
                                                    <Input
                                                        placeholder={t('placeholders.social-media')}
                                                        style={{
                                                            minWidth: isSmallDevice ? '100%' : 278,
                                                            maxWidth: isSmallDevice ? '100%' : 278,
                                                        }}
                                                    />
                                                </Form.Item>
                                                {index === 0 || isSmallDevice ? null : (
                                                    <DeleteButton
                                                        onDelete={() => handleRemoveSocialMediaField(remove, name)}
                                                    />
                                                )}
                                            </Flex>
                                        </Form.Item>
                                    </Flex>
                                ))}

                                {Object.keys(socialsList).length < 4 && (
                                    <Button
                                        onClick={() => handleAddSocialMediaField(add)}
                                        className={classes['add-phone-button']}
                                        icon={<i className={`fi-rr-add ${classes['add-phone-button-icon']}`} />}
                                    >
                                        {t('buttons.add-social-media')}
                                    </Button>
                                )}
                            </>
                        )}
                    </Form.List>
                </Form.Item>

                <Form.Item>
                    <Button
                        className={classes['submit-button']}
                        type="primary"
                        htmlType="submit"
                        loading={isFormLoading}
                    >
                        {type === 'create' ? t('buttons.add-business') : t('buttons.update-business')}
                    </Button>
                </Form.Item>
            </Form>
        </div>
    );
};
export default BusinessesForm;
