import { useEffect } from 'react';
import { useAuth } from './../../../context/AuthContext';
import { Popover, Collapse, Flex } from 'antd';
import TeamList from './TeamList';
import { coreBrandColors, primitiveColors, spacing, shadow } from './../../../theming';
import logo from '../../../assets/Main_logo.png';
import classes from './TeamSelector.module.scss';
import { getIconColors } from './SelectorHelper';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { useTeamsContext } from '../../../context/TeamsContext';

const TeamSelector = ({ isCollapsed, open, handleOpenChange, theme, toggleMenu, isMobileMenuOpen }) => {
    const { teams, fetchTeams } = useTeamsContext();
    const { changeTeam, currentUser } = useAuth();
    const { t } = useTranslation();

    const handleTeamChange = async teamId => {
        await changeTeam(teamId);
    };

    useEffect(() => {
        fetchTeams();
    }, []);

    const currentUserTeam = currentUser.memberships.find(memb => memb.value === currentUser.teamId);

    const memberships = [
        {
            key: currentUserTeam.value,
            label: currentUserTeam.label,
            children: (
                <TeamList
                    data={teams}
                    handleChangeTeam={handleTeamChange}
                    currentUser={currentUser}
                    isMobileMenuOpen={isMobileMenuOpen}
                    toggleMenu={toggleMenu}
                />
            ),
        },
    ];

    return isCollapsed ? (
        <>
            <Popover
                arrow={false}
                placement="leftBottom"
                open={open}
                onOpenChange={handleOpenChange}
                trigger="click"
                onClick={fetchTeams}
                content={<TeamList data={teams} handleChangeTeam={handleTeamChange} currentUser={currentUser} />}
                overlayInnerStyle={{
                    backgroundColor: theme === 'dark' ? coreBrandColors.blueRibbon : primitiveColors.gray50,
                    padding: spacing[0.5],
                    boxShadow: shadow.xl,
                }}
                overlayClassName={classes.logoPopover}
            >
                <img alt={'Intercode logo'} height="50px" width="50px" src={logo} style={{ cursor: 'pointer' }} />
            </Popover>
        </>
    ) : (
        <div
            style={{ position: 'relative', width: '100%' }}
            className={theme === 'dark' ? classes.dark : classes.light}
        >
            <span
                className={classNames({
                    [classes['selector-title']]: true,
                    [classes.dark]: theme === 'dark',
                    [classes.light]: theme === 'light',
                })}
            >
                {t('teams:singular')}
            </span>
            <img alt={'Intercode logo'} height="50px" src={logo} className={classes.logo} />
            <Collapse
                defaultValue={currentUser.memberships.find(memb => memb.value === currentUser.teamId)}
                items={memberships}
                header={currentUserTeam.label}
                onChange={fetchTeams}
                expandIconPosition="end"
                expandIcon={({ isActive }) => (
                    <Flex justify="end" align="center" style={{ marginRight: spacing[1] }}>
                        <i
                            className={`fi-rr-angle-small-${isActive ? 'up' : 'down'}`}
                            style={{
                                fontSize: '1.5rem',
                                color: getIconColors(isActive, theme),
                                marginTop: spacing[0.5],
                            }}
                        />
                    </Flex>
                )}
            />
        </div>
    );
};

export default TeamSelector;
