import { useAxios } from './axiosService';

export const useOrderService = () => {
    const { axios } = useAxios();

    const getAllOrders = async () => {
        return await axios.get(`/orders`);
    };

    const getOrderById = async () => {
        return await axios.get(`/orders/:id`);
    };

    const newOrder = async body => {
        return await axios.post(`/orders/new`, body);
    };

    const orderStatusUpdate = async body => {
        return await axios.post(`/orders/status-update`, body);
    };

    const getDemoCart = async () => {
        return await axios.get(`/orders/demo-cart`);
    };

    return { getAllOrders, getOrderById, newOrder, orderStatusUpdate, getDemoCart };
};
