import i18next from 'i18next';
import getRegexPattern, { regexPatterns } from '../common/constants/regexPatterns';

export const inputType = {
    FULLNAME: 'fullName',
    FIRST_NAME: 'firstName',
    LAST_NAME: 'lastName',
    TEAM_NAME: 'teamName',
    BUSINESS_NAME: 'businessName',
    EMAIL: 'email',
    PASSWORD: 'password',
    LOCATION: 'location',
    ADDRESS_ONE: 'addressOne',
    ADDRESS_TWO: 'addressTwo',
    CITY: 'city',
    COUNTRY: 'country',
    STATE: 'state',
    ZIP_CODE: 'zipCode',
    DESCRIPTION: 'description',
    STATE_STATUS: 'stateStatus',
    STATUS_NAME: 'statusName',
};

/**
 * Get input rules based on the input type and other parameters
 * @param {*} t - translation function from i18next
 * @param {string} type - input type, default is null
 * @param {string} requireMessage - message to show if the input is required, default is set inside each case and is not required
 * @param {string} message - message to show if the input is invalid, default is 'Invalid input'
 * @param {boolean} isRequired - is the input required, default is true
 * @param {boolean} withCyrillic - should the input contain cyrillic characters, default is false
 * @param {RegExp} pattern - custom pattern to validate the input, default is null
 * @returns {Array} array of rules
 * @example
 * getInputRules(inputType.FULLNAME, 'Full name is required!', 'Invalid full name. Type first and last name with one space between', true, true);
 * // returns [
 * //     {
 * //         pattern: /^[a-zA-Z\s\u0400-\u04FF]*$/,
 * //         message: 'Invalid full name. Type first and last name with one space between',
 * //     },
 * //     {
 * //         required: true,
 * //         message: 'Full name is required!',
 * //     },
 * //     {
 * //         min: 2,
 * //         message: 'Must be at least 2 characters long',
 * //     },
 * // ]
 */
export default function getInputRules(
    t,
    type = null,
    requireMessage = null,
    message = null,
    isRequired = true,
    withCyrillic = true,
    pattern = null
) {
    const commonRules = [
        {
            pattern: getRegexPattern(withCyrillic ? 'cyrillic' : 'latin'),
            message: message || t('form:errors.string'),
        },
        isRequired && {
            required: true,
            message: requireMessage || t('form:errors.required'),
        },
    ];

    const rules = {
        min2: {
            min: 2,
            message: t('form:errors.min2'),
        },
        min3: {
            min: 3,
            message: t('form:errors.min3'),
        },
        min4: {
            min: 4,
            message: t('form:errors.min4'),
        },
        min8: {
            min: 8,
            message: t('form:errors.min8'),
        },
    };

    switch (type) {
        case inputType.FIRST_NAME:
        case inputType.LAST_NAME:
            return [...commonRules, rules.min2];

        case inputType.TEAM_NAME:
            return [
                {
                    pattern: getRegexPattern(withCyrillic ? 'cyrillicNumbers' : 'latinNumbers'),
                    message: message || t('form:errors.team-name'),
                },
                isRequired && {
                    required: true,
                    message: requireMessage || t('form:errors.required'),
                },
                {
                    min: 4,
                    message: t('teamCreation:form.team-min'),
                },
            ];

        case inputType.BUSINESS_NAME:
        case inputType.STATUS_NAME:
            return [
                rules.min2,
                isRequired && {
                    required: true,
                    message: requireMessage || t('form:errors.required'),
                },
                {
                    pattern: getRegexPattern(withCyrillic ? 'cyrillicNumbers' : 'latinNumbers'),
                    message: message || t('form:errors.business-name'),
                },
            ];

        case inputType.EMAIL:
            return [
                {
                    type: 'email',
                    message: message || t('form:errors.email'),
                },
                isRequired && {
                    required: true,
                    message: requireMessage || t('form:errors.required'),
                },
            ];

        case inputType.PASSWORD:
            return [
                rules.min8,
                isRequired && {
                    required: true,
                    message: requireMessage || t('form:errors.required'),
                },
            ];

        case inputType.LOCATION:
        case inputType.ADDRESS_ONE:
        case inputType.ADDRESS_TWO:
            return [
                rules.min3,
                {
                    pattern: getRegexPattern(withCyrillic ? 'cyrillicAddress' : 'latinAddress'),
                    message: message || t('form:errors.address'),
                },
                isRequired && {
                    required: true,
                    message: requireMessage || t('form:errors.required'),
                },
            ];

        case inputType.CITY:
        case inputType.COUNTRY:
        case inputType.STATE:
            return [...commonRules, rules.min2];

        case inputType.ZIP_CODE:
            return [
                {
                    pattern: getRegexPattern(regexPatterns.zipCode),
                    message: message || t('form:errors.zip-code'),
                },
                isRequired && {
                    required: true,
                    message: requireMessage || t('form:errors.required'),
                },
            ];

        case inputType.DESCRIPTION:
            return [
                {
                    pattern: getRegexPattern(withCyrillic ? 'cyrillicNumbersSpecials' : 'latinNumbersSpecials'),
                    message: message || t('form:errors.string-with-symbols-and-numbers'),
                },
                isRequired && {
                    required: true,
                    message: requireMessage || t('form:errors.required'),
                },
            ];

        default:
            return [
                isRequired && {
                    required: true,
                    message: requireMessage || t('form:errors.required'),
                },
                pattern && {
                    pattern,
                    message,
                },
            ];
    }
}

/**
 * Validate full name input - a separate function to be used in the form where the full name is set and required
 * @param {*} _ - not used (from antd)
 * @param {string} value - input value to validate (from antd)
 * @returns Promise - result of the validation
 * @example
 * <Form.Item
 * name="fullName"
 * rules={[
 *     {
 *        validator: validateFullName,
 *     },
 * ]}
 * ><Input/></Form.Item>
 */
export function validateFullName(_, value) {
    const nameParts = value.split(' ').filter(part => part.trim() !== '');
    const validNamePartPattern = getRegexPattern('cyrillicFullName');

    if (nameParts.length === 2) {
        if (nameParts.every(part => part.length >= 2 && validNamePartPattern.test(part))) {
            return Promise.resolve();
        } else {
            return Promise.reject(i18next.t('form:errors.full-name-special'));
        }
    }
    if (nameParts.length === 1) {
        return Promise.reject(i18next.t('form:errors.full-name-short'));
    }
    if (nameParts.length > 2) {
        return Promise.reject(i18next.t('form:errors.full-name-long'));
    }
    return Promise.reject(i18next.t('form:errors.full-name'));
}
