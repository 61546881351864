import { Typography } from 'antd';
import PageContainer from '../../common/components/PageContainer';
import { useMediaQuery } from '@uidotdev/usehooks';
import { useTranslation } from 'react-i18next';
import { primitiveColors, spacing, fontSize } from '../../theming';
import TwoFactorAuth from '../../components/userSettings/twoFactorAuth';

const { Title } = Typography;

export default function UserSettings() {
    const mobileDevicesBreakpoint = useMediaQuery('only screen and (max-width : 840px)');
    const { t } = useTranslation(['settings']);

    return (
        <PageContainer
            style={{
                backgroundColor: mobileDevicesBreakpoint ? 'transparent' : primitiveColors.gray0,
                padding: mobileDevicesBreakpoint ? spacing[1] : spacing[4],
            }}
        >
            <Title level={2} style={{ fontSize: mobileDevicesBreakpoint ? fontSize.H4 : fontSize.H3, margin: 0 }}>
                {t('settings:2FA-title')}
            </Title>
            <TwoFactorAuth />
        </PageContainer>
    );
}
