export const getSocialMediaValidationRules = (socialName, t) => {
    switch (socialName) {
        case 'facebook':
            return {
                pattern: /^(https?:\/\/)?(www\.)?facebook\.com\/(profile\.php\?id=[0-9]+|[A-Za-z0-9._-]+)\/?$/,
                message: t('form:errors.facebook'),
            };
        case 'twitter':
            return {
                pattern: /^(https?:\/\/)?(www\.)?(twitter\.com|x\.com)\/[A-Za-z0-9_]+\/?$/,
                message: t('form:errors.twitter'),
            };
        case 'instagram':
            return {
                pattern: /^(https?:\/\/)?(www\.)?instagram\.com\/[A-Za-z0-9._-]+\/?$/,
                message: t('form:errors.instagram'),
            };
        case 'youtube':
            return {
                pattern: /^(https?:\/\/)?(www\.)?(youtube\.com|youtu\.?be)\/.+$/,
                message: t('form:errors.youtube'),
            };
        default:
            return {};
    }
};
