import { Button, Table, Tag, Modal, Flex, Typography, Empty, notification, Dropdown } from 'antd';
import { useCallback, useEffect, useState } from 'react';
import { useUserService } from '../../services/userService';
import { useAuth } from '../../context/AuthContext';
import globalNotification from '../../common/hooks/globalNotification';
import { accountStatus } from '../../common/constants/accountStatus';
import { useTranslation } from 'react-i18next';
import { notifications } from '../../common/constants/notifications';
import SideDrawer from '../../common/components/sideDrawer/SideDrawer';
import { UserToogleMembershipForm } from '../../components/usersList/UserToogleMembershipForm';
import { useTeamService } from '../../services/teamService';
import {
    buttonStyle,
    fontSize,
    pageHeading,
    primitiveColors,
    spacing,
    textColor,
    textSmall,
    textStandard,
} from '../../theming';
import './UsersList.scss';
import { ClearInputIcon, FilterIcon, SearchIcon, SorterIcon } from '../../common/components/icons';
import { useMediaQuery } from '@uidotdev/usehooks';
import CustomAvatar from '../../common/components/Avatar';
import { useMedia } from '../../hooks/useMediaQuery';
import PhoneInput from 'react-phone-input-2';
import TableFilterDropdown from '../../common/components/TableFilterDropdown';
import { AppRoles } from '../../common/constants/appRoles';
import { CustomPagination } from '../../common/components/pagination/Pagination';
import classNames from 'classnames';
import { appRole } from '../../common/constants/appRole';
import PageContainer from '../../common/components/PageContainer';
import StatusControl from '../../common/components/StatusSegmentController';
import SearchInput from '../../common/components/GlobalSearch/SearchInput';
import MobileFiltersDrawer from '../../components/leads/mobileFiltersDrawer';
import { notificationType } from '../../common/constants/notificationType';
import TableHeadingButtons from '../../common/components/TableHeadingButtons';
import FiltersHeading from '../../common/components/FiltersHeading';
import RenderFilter from '../../components/leads/renderFilter';
import ActionsMenu from '../../common/components/ActionsMenu/ActionsMenu';
import icon from '../../common/styles/Icon.module.scss';

function UsersList() {
    const { t, i18n } = useTranslation(['users', 'general', 'confirmModal']);

    const [api, contextHolder] = notification.useNotification();

    const openNotification = ({ type = 'error', message = '', description = '', placement = 'top' }) => {
        api[type]({
            message: message,
            description: description,
            placement: placement,
        });
    };

    const { currentUser } = useAuth();
    const userService = useUserService();

    const { archiveMemberships, restoreMemberships } = useTeamService();

    const [users, setUsers] = useState();
    const [userRoles, setUserRoles] = useState();

    const [isUsersLoading, setIsUsersLoading] = useState(false);
    const [editUser, setEditUser] = useState({});

    const [searchText, setSearchText] = useState('');

    const [searchParams, setSearchParams] = useState({
        limit: 20,
        offset: 1,
        isDeleted: false,
        globalSearch: '',
    });

    const [count, setCount] = useState(0);

    const [selectedUsers, setSelectedUsers] = useState([]);

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const [selectedTab, setSelectedTab] = useState(t('general:statuses.active'));

    const [sortedInfo, setSortedInfo] = useState({});

    const [openMobileFilters, setOpenMobileFilters] = useState(false);
    const [filteredInfo, setFilteredInfo] = useState({});
    const [selectedFilterValue, setSelectedFilterValue] = useState({});
    const [selectedFilters, setSelectedFilters] = useState({
        status: [],
        roles: [],
    });

    const filterOptions = {
        status:
            selectedTab === t('general:statuses.active') &&
            accountStatus
                .filter(status => status.value !== 'Inactive' && status.value !== 'Deleted')
                .map(status => ({
                    text: status.label,
                    value: status.value,
                })),
        roles: AppRoles.filter(role => role.value !== 'SuperAdmin').map(role => ({
            text: role.label,
            value: role.value,
        })),
    };
    const filtersRenderCondition =
        Object.keys(filteredInfo).length > 0 &&
        Object.values(filteredInfo).some(value => (Array.isArray(value) ? value.length > 0 : value !== null));

    useEffect(() => {
        setSelectedFilterValue(filteredInfo);
    }, [filteredInfo]);

    useEffect(() => {
        setSelectedTab(t('general:statuses.active'));
    }, [i18n.language]);

    const { isExtraSmallDevice, isSmallDevice, isMediumDevice } = useMedia();

    const mobileDevicesBreakpoint = useMediaQuery('only screen and (max-width : 992px)');

    useEffect(() => {
        fetchUserRoles();
    }, []);

    useEffect(() => {
        fetchUsersData();
    }, [searchParams, filteredInfo]);

    useEffect(() => {
        if (selectedTab === t('general:statuses.active')) {
            setSearchParams(prevValue => ({
                ...prevValue,
                isDeleted: false,
                currentPage: 1,
            }));
        } else {
            setSearchParams(prevValue => ({
                ...prevValue,
                isDeleted: true,
                currentPage: 1,
            }));
        }
    }, [selectedTab]);

    const fetchUsersData = async () => {
        setIsUsersLoading(true);
        try {
            let sortField = '';

            switch (sortedInfo.columnKey) {
                case 'name':
                    sortField = 'firstName';
                    break;
                default:
                    sortField = sortedInfo.columnKey;
                    break;
            }

            const newSearchParams = {
                ...searchParams,
                sortOrder: sortedInfo.order === 'ascend' ? 'ASC' : 'DESC',
                status: filteredInfo.status || [],
                roles: filteredInfo.roles || [],
                sortField,
            };

            const { data } = await userService.getAllUsers(newSearchParams);

            const { count, users } = data;

            setCount(count);

            setUsers(
                users.map(user => ({
                    ...user,
                    name: `${user.firstName} ${user.lastName}`,
                    roleNames: user.userRoles.map(userRole => userRole.role.name),
                    roleIds: user.userRoles.map(userRole => userRole.role.id),
                    key: user.id,
                }))
            );
        } catch {
            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.USERS,
                action: notifications.action.FETCH,
                plural: true,
            });
        } finally {
            setIsUsersLoading(false);
        }
    };

    const fetchUserRoles = useCallback(async () => {
        try {
            const { data } = await userService.getUserRoles();

            setUserRoles(
                data.map(role => ({
                    label: role.name,
                    value: role.id,
                }))
            );
        } catch (e) {
            console.error(e);
        }
        setIsUsersLoading(false);
    }, []);

    const handleOpenManageMembershipDrawer = user => {
        setIsDrawerOpen(true);
        setEditUser(user);
    };

    const handleRestoreMemberships = membershipIds => {
        restoreMemberships(membershipIds)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.MEMBERS,
                    action: notifications.action.RESTORE,
                    plural: false,
                });
                fetchUsersData();
            })
            .catch(error => {
                const errorCode = error.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            });
    };

    const handleArchiveMemberships = membershipIds => {
        archiveMemberships(membershipIds)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.MEMBERS,
                    action: notifications.action.ARCHIVE,
                    plural: false,
                });
                fetchUsersData();
            })
            .catch(error => {
                const errorCode = error.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            });
    };

    const handleArchiveUsers = async userIds => {
        await userService
            .archiveUsers(userIds)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.USERS,
                    action: notifications.action.ARCHIVE,
                    plural: false,
                });
                fetchUsersData();
                setSelectedUsers([]);
            })
            .catch(error => {
                const errorCode = error.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            });
    };

    const handleRestoreUsers = async userIds => {
        await userService
            .unarchiveUsers(userIds)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.USERS,
                    action: notifications.action.RESTORE,
                    plural: false,
                });
                fetchUsersData();
                setSelectedUsers([]);
            })
            .catch(error => {
                const errorCode = error.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            });
    };

    const handleDeleteUsers = async userIds => {
        await userService
            .deleteUsers(userIds)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.USERS,
                    action: notifications.action.DELETE,
                    plural: false,
                });
                fetchUsersData();
                setSelectedUsers([]);
            })
            .catch(error => {
                const errorCode = error.response.data?.code;

                openNotification({
                    type: notificationType.ERROR,
                    message: t(`apiResponses:${errorCode}.message`),
                    description: t(`apiResponses:${errorCode}.description`),
                    placement: 'top',
                });
            });
    };

    const hasPermissionToOperate = (loggedUser, userToOperate) => {
        const { roleNames: userRoles, email: userEmail } = userToOperate;
        const { userRoleNames: loggedUserRoles, email: loggedUserEmail } = loggedUser;

        return !(
            loggedUserEmail === userEmail ||
            (loggedUserRoles.includes(appRole.ADMIN) && userRoles.includes(appRole.ADMIN))
        );
    };

    const handleDrawerClose = () => {
        setIsDrawerOpen(false);
        setEditUser(null);
    };

    const onClickArchive = async ids => {
        Modal.confirm({
            title: `${t('confirmModal:actions.archive')} ${ids.length > 1 ? t('users:plural-with-article') : t('users:singular-with-article')}?`,
            icon: null,
            styles: { body: { padding: spacing[4] } },
            width: 'fit-content',
            okButtonProps: { danger: true },
            onOk: () => handleArchiveUsers(ids),
            okText: t('confirmModal:buttons.yes'),
            cancelText: t('confirmModal:buttons.cancel'),
        });
    };

    const onClickRestore = async ids => {
        Modal.confirm({
            title: `${t('confirmModal:actions.restore')} ${ids.length > 1 ? t('users:plural-with-article') : t('users:singular-with-article')}?`,
            icon: null,
            styles: { body: { padding: spacing[4] } },
            width: 'fit-content',
            okButtonProps: { danger: true },
            onOk: () => handleRestoreUsers(ids),
            okText: t('confirmModal:buttons.yes'),
            cancelText: t('confirmModal:buttons.cancel'),
        });
    };

    const onClickDelete = ids => {
        Modal.confirm({
            title: `${t('confirmModal:actions.delete')} ${ids.length > 1 ? t('users:plural-with-article') : t('users:singular-with-article')}?`,
            icon: null,
            styles: { body: { padding: spacing[4] } },
            width: 'fit-content',
            okButtonProps: { danger: true },
            onOk: () => handleDeleteUsers(ids),
            okText: t('confirmModal:buttons.yes'),
            cancelText: t('confirmModal:buttons.cancel'),
        });
    };

    const onSelectChange = newSelectedRowKeys => {
        setSelectedUsers(newSelectedRowKeys);
    };

    const rowSelection = {
        selectedRowKeys: selectedUsers,
        onChange: onSelectChange,
    };

    const columns = [
        {
            title: t('fields.name'),
            dataIndex: 'name',
            key: 'name',
            width: mobileDevicesBreakpoint ? 280 : 300,
            sorter: () => {},
            render: (name, record) => {
                return (
                    <Flex gap={mobileDevicesBreakpoint ? spacing[1] : spacing[3]} justify="start" align="center">
                        <CustomAvatar
                            key={record.id}
                            size={isSmallDevice || isExtraSmallDevice ? 32 : 36}
                            photoName={record.photo}
                            firstName={record.firstName}
                            lastName={record.lastName}
                            controller={'businesses'}
                            style={{ flexShrink: 0 }}
                        />
                        <Flex vertical>
                            <Typography.Text
                                style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard), fontWeight: 500 }}
                            >
                                {`${record.firstName} ${record.lastName}`}
                            </Typography.Text>

                            <a
                                href={`mailto:${record.email}`}
                                target="_blank"
                                rel="noreferrer"
                                onClick={e => e.stopPropagation()}
                                style={{
                                    color: textColor.subtle,
                                    ...textStandard,
                                }}
                            >
                                {record.email}
                            </a>
                        </Flex>
                    </Flex>
                );
            },
        },
        {
            title: t('fields.phone'),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: mobileDevicesBreakpoint ? 165 : 200,
            render: phoneNumber => {
                if (!phoneNumber) {
                    return;
                }

                return (
                    <a
                        key={phoneNumber}
                        href={`tel:${phoneNumber}`}
                        onClick={e => e.stopPropagation()}
                        style={{
                            display: 'block',
                            width: '165px',
                            textDecoration: 'none',
                        }}
                    >
                        <PhoneInput
                            value={phoneNumber}
                            buttonStyle={{ display: 'none' }}
                            inputStyle={{
                                width: '100%',
                                border: 'none',
                                padding: 0,
                                cursor: 'pointer',
                                color: textColor.secondary,
                                textDecoration: 'none',
                                pointerEvents: 'none',
                                fontSize: fontSize.sm,
                                backgroundColor: 'inherit',
                                ...(mobileDevicesBreakpoint ? textSmall : textStandard),
                            }}
                            containerStyle={{ width: '165px' }}
                        />
                    </a>
                );
            },
        },
        {
            title: t('fields.status'),
            dataIndex: 'status',
            key: 'status',
            width: mobileDevicesBreakpoint ? 120 : 146,
            filters:
                !mobileDevicesBreakpoint &&
                selectedTab === t('general:statuses.active') &&
                accountStatus
                    .filter(status => status.value !== 'Inactive' && status.value !== 'Deleted')
                    .map(status => ({
                        text: status.label,
                        value: status.value,
                    })),
            filteredValue: filteredInfo.status || null,
            render: status => {
                const userStatus = accountStatus.find(accountStatus => accountStatus.value === status);
                return (
                    <Tag
                        style={{
                            color: userStatus.color,
                            backgroundColor: userStatus.backgroundColor,
                            border: `1px solid ${userStatus.borderColor}`,
                            paddingInline: spacing[3],
                            paddingBlock: spacing[0.5],
                            fontWeight: 500,
                            fontSize: mobileDevicesBreakpoint ? fontSize.xxs : 'inherit',
                        }}
                    >
                        {userStatus.label}
                    </Tag>
                );
            },
            filterDropdown: ({ filters, close }) =>
                !mobileDevicesBreakpoint &&
                selectedTab === t('general:statuses.active') && (
                    <TableFilterDropdown
                        filters={filters}
                        close={close}
                        filterKey="status"
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        setFilteredInfo={setFilteredInfo}
                    />
                ),
        },
        {
            title: t('fields.role'),
            dataIndex: 'appRole',
            key: 'appRole',
            width: 220,
            showSorterTooltip: { target: 'full-header' },
            filters:
                !mobileDevicesBreakpoint &&
                AppRoles.filter(role => role.value !== 'SuperAdmin').map(role => ({
                    text: role.label,
                    value: role.value,
                })),
            filteredValue: filteredInfo.roles || null,
            render: (_, { roleNames }) => (
                <>
                    {roleNames
                        ?.sort((a, b) => a.localeCompare(b))
                        .map(role => {
                            const userRole = AppRoles.find(appRole => appRole.value === role);

                            return (
                                <Tag
                                    style={{
                                        backgroundColor: userRole.backgroundColor,
                                        borderColor: userRole.borderColor,
                                        color: userRole.color,
                                        paddingInline: spacing[3],
                                        paddingBlock: spacing[0.5],
                                        fontWeight: 500,
                                        fontSize: mobileDevicesBreakpoint ? fontSize.xxs : 'inherit',
                                    }}
                                >
                                    {userRole.label}
                                </Tag>
                            );
                        })}
                </>
            ),
            filterDropdown: ({ filters, close }) =>
                !mobileDevicesBreakpoint && (
                    <TableFilterDropdown
                        filters={filters}
                        close={close}
                        filterKey="roles"
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        setFilteredInfo={setFilteredInfo}
                    />
                ),
        },
        {
            title: t('fields.actions'),
            dataIndex: 'actions',
            key: 'actions',
            width: '10%',
            fixed: 'right',
            render: (text, record) => {
                return (
                    currentUser.email !== record.email && (
                        <Flex justify="center" onClick={e => e.stopPropagation()}>
                            <Dropdown
                                placement="bottom"
                                menu={{
                                    items: ActionsMenu({
                                        record,
                                        handleEdit: () => handleOpenManageMembershipDrawer(record),
                                        handleDelete: () => onClickDelete([record.id]),
                                        handleArchive:
                                            record.status === 'Pending'
                                                ? null
                                                : () => {
                                                      if (record.isDeleted) {
                                                          onClickRestore([record.id]);
                                                      } else {
                                                          onClickArchive([record.id]);
                                                      }
                                                  },
                                        t,
                                    }),
                                }}
                            >
                                <span onClick={e => e.preventDefault()} style={{ cursor: 'pointer' }}>
                                    <i
                                        className={`fi fi-rr-menu-dots-vertical ${icon.md}`}
                                        style={{ color: textColor.subtle }}
                                    />
                                </span>
                            </Dropdown>
                        </Flex>
                    )
                );
            },
        },
    ];

    const handleSubmitEditUser = async values => {
        try {
            await userService.updateUser({
                id: editUser.id,
                roleIds: values.roleIds ?? [],
                status: values.status,
            });
            fetchUsersData();
        } catch (error) {
            const errorCode = error.response.data?.code;

            openNotification({
                type: notificationType.ERROR,
                message: t(`apiResponses:${errorCode}.message`),
                description: t(`apiResponses:${errorCode}.description`),
                placement: 'top',
            });
        }
    };

    const onTableParamsChange = (pagination, filters, sorter) => {
        setSearchParams(prevValue => ({
            ...prevValue,
            currentPage: pagination.current,
            pageSize: pagination.pageSize,
            status: filters.status || '',
            roles: filters.teamRoles || '',
        }));

        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const clearAll = () => {
        setFilteredInfo({});
        setSelectedFilters({
            status: [],
            roles: [],
        });
    };

    const paginationConfig = {
        pageSize: searchParams.limit,
        current: searchParams.offset,
        total: count,
    };

    return (
        <PageContainer
            style={{
                backgroundColor: mobileDevicesBreakpoint ? primitiveColors.gray25 : primitiveColors.gray0,
                padding: mobileDevicesBreakpoint ? spacing[1] : spacing[4],
            }}
        >
            {contextHolder}
            <Flex vertical>
                <Flex
                    vertical={mobileDevicesBreakpoint}
                    gap={mobileDevicesBreakpoint ? spacing[1] : spacing[3]}
                    align="center"
                    justify="space-between"
                    style={{
                        marginBottom: mobileDevicesBreakpoint ? spacing[3] : spacing[4],
                    }}
                >
                    <Flex
                        gap={spacing[3]}
                        align="center"
                        justify={mobileDevicesBreakpoint ? 'space-between' : 'start'}
                        style={{
                            width: mobileDevicesBreakpoint && '100%',
                            maxHeight: mobileDevicesBreakpoint && 60,
                        }}
                    >
                        <h1 style={{ ...pageHeading, fontSize: mobileDevicesBreakpoint && fontSize.H4, margin: 0 }}>
                            {t('plural')}
                        </h1>
                        <StatusControl
                            value={selectedTab}
                            onChange={value => {
                                setSelectedTab(value);
                                setSelectedUsers([]);
                            }}
                        />
                    </Flex>

                    <Flex
                        gap={spacing[3]}
                        align="center"
                        justify={mobileDevicesBreakpoint ? 'space-between' : 'end'}
                        style={{ width: mobileDevicesBreakpoint ? '100%' : 'auto' }}
                    >
                        {mobileDevicesBreakpoint && (
                            <>
                                <Button
                                    icon={<i className={`fi fi-rr-settings-sliders`} />}
                                    style={{
                                        ...buttonStyle.small,
                                        color: textColor.subtle,
                                        borderColor: textColor.subtle,
                                        backgroundColor: 'inherit',
                                        width: 'clamp(123px, 20vw, 140px)',
                                    }}
                                    onClick={() => setOpenMobileFilters(true)}
                                >
                                    {t('actions.filters')}
                                </Button>

                                <MobileFiltersDrawer
                                    open={openMobileFilters}
                                    closeDrawer={() => setOpenMobileFilters(false)}
                                    filteredInfo={filteredInfo}
                                    filterOptions={filterOptions}
                                    selectedValue={selectedFilterValue}
                                    setSelectedValue={setSelectedFilterValue}
                                    setSelectedFilters={setSelectedFilters}
                                    setFilteredInfo={setFilteredInfo}
                                />
                            </>
                        )}
                        {!mobileDevicesBreakpoint && (
                            <SearchInput
                                placeholder={t('general:search')}
                                value={searchText}
                                style={{ width: isMediumDevice ? 220 : 250 }}
                                onChange={event => setSearchText(event.target.value)}
                                onPressEnter={() => {
                                    setSearchParams(params => {
                                        return {
                                            ...params,
                                            globalSearch: searchText,
                                        };
                                    });
                                }}
                                prefix={
                                    <SearchIcon
                                        onClick={() =>
                                            setSearchParams(params => {
                                                return {
                                                    ...params,
                                                    globalSearch: searchText,
                                                };
                                            })
                                        }
                                    />
                                }
                                suffix={
                                    searchText && (
                                        <ClearInputIcon
                                            setSearchText={setSearchText}
                                            handleReset={() =>
                                                setSearchParams(params => {
                                                    return {
                                                        ...params,
                                                        globalSearch: '',
                                                    };
                                                })
                                            }
                                        />
                                    )
                                }
                            />
                        )}
                    </Flex>

                    <Flex gap={spacing[4]} align={'center'} style={{ width: '100%' }} justify="flex-end">
                        {selectedUsers.length > 0 && mobileDevicesBreakpoint && (
                            <Flex
                                gap={spacing[0.5]}
                                align="center"
                                justify="end"
                                style={{ width: mobileDevicesBreakpoint && '100%' }}
                            >
                                <TableHeadingButtons
                                    items={selectedUsers}
                                    handleDelete={() => onClickDelete(selectedUsers)}
                                    handleArchive={() => onClickArchive(selectedUsers)}
                                    handleRestore={() => onClickRestore(selectedUsers)}
                                    isMobile
                                    style={{
                                        ...buttonStyle.small,
                                    }}
                                    selectedTab={selectedTab}
                                />
                            </Flex>
                        )}
                    </Flex>
                </Flex>

                {!mobileDevicesBreakpoint && (
                    <Flex
                        align="center"
                        justify={selectedUsers.length > 0 ? 'end' : 'space-between'}
                        style={{ marginBottom: spacing[1], height: 38 }}
                    >
                        {filtersRenderCondition && !selectedUsers.length && (
                            <Flex gap={spacing[2]} align="center">
                                <FiltersHeading />
                                {Object.keys(filteredInfo).map((key, index) => (
                                    <RenderFilter
                                        key={key}
                                        index={index}
                                        filteredKey={key}
                                        filteredInfo={filteredInfo}
                                        filterOptions={filterOptions}
                                        selectedValue={selectedFilterValue}
                                        setSelectedValue={setSelectedFilterValue}
                                        setSelectedFilters={setSelectedFilters}
                                        setFilteredInfo={setFilteredInfo}
                                    />
                                ))}
                                <Button
                                    type="link"
                                    onClick={() => {
                                        clearAll();
                                    }}
                                    size="small"
                                    style={{
                                        color: textColor.subtle,
                                    }}
                                >
                                    {t('actions.reset')}
                                </Button>
                            </Flex>
                        )}
                        {selectedUsers.length > 0 && (
                            <Flex
                                gap={spacing[0.5]}
                                align="center"
                                justify="end"
                                style={{ width: mobileDevicesBreakpoint && '100%' }}
                            >
                                <TableHeadingButtons
                                    items={selectedUsers}
                                    handleDelete={() => onClickDelete(selectedUsers)}
                                    handleArchive={() => onClickArchive(selectedUsers)}
                                    handleRestore={() => onClickRestore(selectedUsers)}
                                    isMobile
                                    style={{
                                        ...buttonStyle.small,
                                    }}
                                    selectedTab={selectedTab}
                                />
                            </Flex>
                        )}
                    </Flex>
                )}
            </Flex>
            <Table
                columns={[
                    ...columns.map(column => {
                        const isStatusOnArchived =
                            selectedTab === t('general:statuses.archived') && column.dataIndex === 'status';
                        return {
                            ...column,
                            title: column.title.toUpperCase(),
                            filterIcon: filtered =>
                                !mobileDevicesBreakpoint && !isStatusOnArchived && FilterIcon({ filtered }),
                            sortIcon: () => SorterIcon({ sortOrder: sortedInfo, column: column.dataIndex }),
                        };
                    }),
                ]}
                dataSource={users}
                onChange={onTableParamsChange}
                loading={isUsersLoading}
                rowSelection={{
                    ...rowSelection,
                    type: 'checkbox',
                    getCheckboxProps: record => {
                        return {
                            disabled: !hasPermissionToOperate(currentUser, record),
                        };
                    },
                    filterIcon: filtered => !mobileDevicesBreakpoint && FilterIcon({ filtered }),
                }}
                footer={() => (
                    <CustomPagination
                        currentPage={paginationConfig.current}
                        handlePageChange={value => {
                            setSearchParams(prev => ({ ...prev, offset: value }));
                        }}
                        perPage={paginationConfig.pageSize}
                        handlePerPageChange={value => {
                            setSearchParams(prev => ({ ...prev, limit: value, offset: 1 }));
                        }}
                        total={paginationConfig.total}
                        title={t('total-users')}
                    />
                )}
                pagination={false}
                scroll={{
                    x: 'max-content',
                }}
                bordered={false}
                size="small"
                defaultSortOrder="descend"
                rowClassName={(record, index) =>
                    classNames({
                        'even-table-cell': index % 2 === 0,
                        'odd-table-cell': index % 2 !== 0,
                    })
                }
                locale={{
                    emptyText: <Empty description={t('general:no-data')} />,
                    triggerDesc: t('general:actions.simple.sort-desc'),
                    triggerAsc: t('general:actions.simple.sort-asc'),
                    cancelSort: t('general:actions.simple.cancel-sort'),
                }}
            />

            <SideDrawer title={t('actions.manage-user')} open={isDrawerOpen} closeDrawer={handleDrawerClose}>
                {editUser && (
                    <UserToogleMembershipForm
                        editUser={editUser}
                        archiveUsermemberships={handleArchiveMemberships}
                        handleSubmitEditUser={handleSubmitEditUser}
                        userRoles={userRoles}
                        restoreUsermemberships={handleRestoreMemberships}
                        closeDrawer={handleDrawerClose}
                    />
                )}
            </SideDrawer>
        </PageContainer>
    );
}

export default UsersList;
