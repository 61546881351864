export const auditLogActions = {
    CREATE_BUSINESS: 1,
    UPDATE_BUSINESS: 2,
    DELETE_BUSINESS: 3,
    ARCHIVE_BUSINESS: 4,
    RESTORE_BUSINESS: 5,
    EDIT_BUSINESS_COMMENT: 6,
    DELETE_BUSINESS_COMMENT: 7,
    ASSIGN_LEAD_TO_BUSINESS: 8,
    UNASSIGN_LEAD_TO_BUSINESS: 16,
    CREATE_LEAD: 9,
    UPDATE_LEAD: 10,
    DELETE_LEAD: 11,
    ARCHIVE_LEAD: 12,
    RESTORE_LEAD: 13,
    EDIT_LEAD_COMMENT: 14,
    DELETE_LEAD_COMMENT: 15,
};
