import { useEffect, useState } from 'react';
import PageContainer from './../../common/components/PageContainer';
import { useAuth } from '../../context/AuthContext';
import classes from './style.module.scss';
import globalNotification from '../../common/hooks/globalNotification';
import { notificationType } from '../../common/constants/notificationType';
import ButtonIcon from '../../common/components/buttonIcon/ButtonIcon';
import SideDrawer from './../../common/components/sideDrawer/SideDrawer';
import { useTranslation } from 'react-i18next';
import ProfileForm from '../../components/profile/form';
import ProfileDetails from '../../components/profile/details';
import { useUserService } from '../../services/userService';
import { useMedia } from '../../hooks/useMediaQuery';
import { useTeamService } from '../../services/teamService';
import { notification, Spin } from 'antd';
import { OwnerTransferForm } from '../../components/profile/OwnerTransferForm';
import { notifications } from '../../common/constants/notifications';

export default function Profile() {
    const { currentUser, fetchUser, logout } = useAuth();
    const { updateProfile } = useUserService();

    const [api, contextHolder] = notification.useNotification();

    const openNotification = ({ type = 'error', message = '', description = '', placement = 'top' }) => {
        api[type]({
            message: message,
            description: description,
            placement: placement,
        });
    };

    const { leaveTeam } = useTeamService();

    const [formAction, setFormAction] = useState('');
    const [open, setOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const { isSmallDevice } = useMedia();
    const { t } = useTranslation(['profile', 'confirmModal']);

    const [userMemberships, setUserMemberships] = useState([]);
    const [membershipsLoading, setMembershipsLoading] = useState(false);

    const [clickedTeam, setClickedTeam] = useState(null);

    const { getUserMemberships, deleteTeam } = useTeamService();

    const fetchUserMemberships = async () => {
        setMembershipsLoading(true);

        try {
            const response = await getUserMemberships();

            setUserMemberships(response.data);
        } catch (error) {
            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.TEAMS,
                action: notifications.action.FETCH,
                plural: true,
            });
        } finally {
            setMembershipsLoading(false);
        }
    };

    useEffect(() => {
        fetchUserMemberships();
    }, []);

    const fullName = `${currentUser?.firstName} ${currentUser?.lastName}`;

    const handleOpenDrawer = () => {
        setOpen(prev => !prev);
    };

    const handleSubmit = async values => {
        const valuesToSend = {
            ...values,
        };
        delete valuesToSend.photo;

        setLoading(true);
        try {
            await updateProfile(valuesToSend);

            globalNotification.open({
                type: notificationType.SUCCESS,
                message: t('notification.save-success.message'),
                description: t('notification.save-success.description'),
                placement: 'top',
            });

            fetchUser();
            handleOpenDrawer();
        } catch (err) {
            const errorCode = err.response.data?.code;

            openNotification({
                type: notificationType.ERROR,
                message: t(`apiResponses:${errorCode}.message`),
                description: t(`apiResponses:${errorCode}.description`),
                placement: 'top',
            });
        } finally {
            setLoading(false);
        }
    };

    const handleOpenLeaveDrawer = teamId => {
        setClickedTeam(teamId);
        setFormAction('leave');
        handleOpenDrawer();
    };

    const handleOpenUpdateDrawer = () => {
        setFormAction('update');
        handleOpenDrawer();
    };

    return (
        <PageContainer
            style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                backgroundColor: isSmallDevice && 'transparent',
            }}
        >
            {contextHolder}
            <ButtonIcon
                icon="fi fi-rr-menu-dots"
                style={{
                    position: 'absolute',
                    right: 'clamp(4px, 6%, 30px)',
                    top: 'clamp(4px, 12%, 85px)',
                }}
                onClick={handleOpenUpdateDrawer}
            />
            {membershipsLoading ? (
                <Spin />
            ) : (
                <ProfileDetails
                    currentUser={currentUser}
                    userMemberships={userMemberships}
                    openLeaveDrawer={handleOpenLeaveDrawer}
                    classes={classes}
                    leaveTeam={leaveTeam}
                    fullName={fullName}
                    logout={logout}
                    openNotification={openNotification}
                    fetchUserMemberships={fetchUserMemberships}
                    fetchUser={fetchUser}
                    deleteTeam={deleteTeam}
                    t={t}
                />
            )}
            <SideDrawer
                open={open}
                closeDrawer={handleOpenDrawer}
                title={formAction === 'update' ? t('form.update-title') : t('form.leave-title')}
                children={
                    formAction === 'update' ? (
                        <ProfileForm
                            currentUser={currentUser}
                            fullName={fullName}
                            handler={handleSubmit}
                            loading={loading}
                            t={t}
                        />
                    ) : (
                        <OwnerTransferForm
                            leaveTeam={leaveTeam}
                            fetchUser={fetchUser}
                            fetchUserMemberships={fetchUserMemberships}
                            logout={logout}
                            openNotification={openNotification}
                            closeDrawer={handleOpenDrawer}
                            userMemberships={userMemberships}
                            currentUser={currentUser}
                            teamId={clickedTeam}
                        />
                    )
                }
            />
        </PageContainer>
    );
}
