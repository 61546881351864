import { Button, Card, Divider, Flex, Skeleton, Typography } from 'antd';
import { primitiveColors, spacing } from '../../theming';
import { PreviewMainInfo } from '../../common/components/preview/PreviewMainInfo';
import { PreviewAdditionalInfo } from '../../common/components/preview/PreviewAdditionalInfo';
import { PreviewCardInfo } from '../../common/components/preview/PreviewCardInfo';
import { LeadStatusTimeline } from './LeadStatusTimeline';
import { useEffect, useRef, useState } from 'react';
import { useLeadService } from '../../services/leadService';
import { CommentsList } from '../../common/components/commentsList/CommentsList';
import { LogsList } from '../../common/components/logsList/LogsList';
import { Journal } from '../../common/components/Journal';
import { PreviewTabs } from '../../common/components/previewTabs';
import { useTranslation } from 'react-i18next';
import globalNotification from '../../common/hooks/globalNotification';
import { notifications } from '../../common/constants/notifications';
import InfiniteScroll from 'react-infinite-scroll-component';

const RECORD_LIMIT_PER_REQUEST = 10;

export const LeadPreview = ({ lead, openEditMode, statuses }) => {
    const { t } = useTranslation(['leadPreview', 'leads', 'general']);

    const {
        id,
        name,
        email,
        state,
        business,
        source,
        location,
        photo,
        status,
        phoneNumber,
        socialMedia,
        description,
        createdAt,
        updatedAt,
        placeOfJob,
        position,
    } = lead;

    const [selectedTab, setSelectedTab] = useState('1');

    const [leadComments, setLeadComments] = useState([]);
    const [isCommentInputCollapsed, setIsCommentInputCollapsed] = useState(false);

    const [leadLogs, setLeadLogs] = useState([]);

    const [leadLogsAndComment, setLeadLogsAndComments] = useState({
        logsAndComments: [],
        logsCount: 0,
        commentsCount: 0,
    });

    const [loading, setLoading] = useState(false);
    const [hasMore, setHasMore] = useState(true);

    const infiniteScrollRef = useRef(null);

    const {
        getLeadComments,
        leaveLeadComment,
        deleteLeadComment,
        editLeadComment,
        getLeadLogs,
        getLeadLogsAndComments,
    } = useLeadService();

    const fetchComments = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            const response = await getLeadComments(id, leadComments.length, RECORD_LIMIT_PER_REQUEST);

            if (response.comments.length === 0 || response.comments.length < RECORD_LIMIT_PER_REQUEST) {
                setHasMore(false);
            }

            setLeadComments(prevComments => [...prevComments, ...response.comments]);
        } catch {
            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.COMMENTS,
                action: notifications.action.FETCH,
                plural: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchLogs = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            const response = await getLeadLogs(id, leadLogs.length, RECORD_LIMIT_PER_REQUEST);

            if (response.logs.length === 0 || response.logs.length < RECORD_LIMIT_PER_REQUEST) {
                setHasMore(false);
            }

            setLeadLogs(prevLogs => [...prevLogs, ...response.logs]);
        } catch {
            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.LOGS,
                action: notifications.action.FETCH,
                plural: true,
            });
        } finally {
            setLoading(false);
        }
    };

    const fetchLogsAndComments = async () => {
        if (loading) {
            return;
        }

        setLoading(true);
        try {
            const response = await getLeadLogsAndComments(
                id,
                leadLogsAndComment.logsCount,
                RECORD_LIMIT_PER_REQUEST,
                leadLogsAndComment.commentsCount,
                RECORD_LIMIT_PER_REQUEST
            );

            if (response.logsAndComments.length === 0 || response.logsAndComments.length < RECORD_LIMIT_PER_REQUEST) {
                setHasMore(false);
            }

            const { logsAndComments, logsCount, commentsCount } = response;

            setLeadLogsAndComments(prevLogsAndComments => {
                return {
                    logsAndComments: [...prevLogsAndComments.logsAndComments, ...logsAndComments],
                    logsCount: prevLogsAndComments.logsCount + logsCount,
                    commentsCount: prevLogsAndComments.commentsCount + commentsCount,
                };
            });
        } catch {
            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.ACTIVITY,
                action: notifications.action.FETCH,
                plural: true,
            });
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        setHasMore(true);
        setLeadLogs([]);
        setLeadComments([]);
        setLeadLogsAndComments({
            logsAndComments: [],
            logsCount: 0,
            commentsCount: 0,
        });

        switch (selectedTab) {
            case '3':
                fetchLogs();
                break;
            case '2':
                fetchComments();
                break;
            default:
                fetchLogsAndComments();
                break;
        }
    }, [id, selectedTab]);

    const handleScroll = event => {
        if (event.target.scrollTop > 50) {
            setIsCommentInputCollapsed(true);
        } else if (event.target.scrollTop === 0) {
            setIsCommentInputCollapsed(false);
        }
    };

    const handleScrollToTop = () => {
        infiniteScrollRef.current.el.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Card style={{ boxShadow: 'none' }} styles={{ body: { padding: 0 } }} bordered={false}>
            <Flex vertical>
                <Flex justify="space-between" align="center">
                    <Typography.Title style={{ margin: 0 }}>{t('title')}</Typography.Title>

                    <Button onClick={() => openEditMode(lead)}>{t('edit-info')}</Button>
                </Flex>

                <Divider />

                <PreviewMainInfo
                    id={id}
                    name={name}
                    photo={photo}
                    email={email}
                    phone={phoneNumber[0]}
                    socialMedia={socialMedia}
                    state={state}
                />

                <Flex gap={spacing[4]} style={{ marginTop: spacing[4] }} justify="strech">
                    <PreviewCardInfo
                        title={t('leads:fields.business')}
                        items={business.map(business => business.name)}
                    />
                    <PreviewCardInfo title={t('leads:fields.source')} items={[source]} />
                    <PreviewCardInfo title={t('leads:fields.location')} items={[location]} />
                </Flex>

                <Flex align="center" style={{ marginTop: spacing[4] }} gap={spacing[10]}>
                    <Flex gap={spacing[1]}>
                        <Typography.Text style={{ fontWeight: 500, color: primitiveColors.gray400 }}>
                            {`${t('leads:fields.place-of-job')}:`}
                        </Typography.Text>
                        <Typography.Text style={{ fontWeight: 500, color: primitiveColors.gray700 }}>
                            {placeOfJob ? placeOfJob : t('leads:no-company')}
                        </Typography.Text>
                    </Flex>

                    <Flex gap={spacing[1]}>
                        <Typography.Text style={{ fontWeight: 500, color: primitiveColors.gray400 }}>
                            {`${t('leads:fields.position')}:`}
                        </Typography.Text>
                        <Typography.Text style={{ fontWeight: 500, color: primitiveColors.gray700 }}>
                            {position ? position : t('leads:no-info')}
                        </Typography.Text>
                    </Flex>
                </Flex>

                <LeadStatusTimeline currentStatus={status.name} marginTop={spacing[4]} statuses={statuses} />

                <Flex align="center" justify="space-between" style={{ marginTop: spacing[4] }}>
                    <Flex gap={spacing[1]}>
                        <Typography.Text style={{ fontWeight: 500, color: primitiveColors.gray400 }}>
                            {`${t('leads:fields.created')}:`}
                        </Typography.Text>
                        <Typography.Text style={{ fontWeight: 500, color: primitiveColors.gray700 }}>
                            {createdAt}
                        </Typography.Text>
                    </Flex>

                    <Flex gap={spacing[1]}>
                        <Typography.Text style={{ fontWeight: 500, color: primitiveColors.gray400 }}>
                            {`${t('leads:fields.updated')}:`}
                        </Typography.Text>
                        <Typography.Text style={{ fontWeight: 500, color: primitiveColors.gray700 }}>
                            {updatedAt}
                        </Typography.Text>
                    </Flex>
                </Flex>

                <Divider />

                <PreviewAdditionalInfo description={description} phones={phoneNumber} />

                {(description || phoneNumber.length > 1) && <Divider />}

                <Flex gap={spacing[1]} vertical>
                    <Typography.Text>{t('activity')}</Typography.Text>
                    <PreviewTabs selectedTab={selectedTab} onChange={setSelectedTab} />
                </Flex>

                <div
                    id="scrollableDiv"
                    style={{
                        height: 408,
                        overflow: 'auto',
                    }}
                >
                    <InfiniteScroll
                        ref={infiniteScrollRef}
                        dataLength={
                            selectedTab === '2'
                                ? leadComments.length
                                : selectedTab === '3'
                                  ? leadLogs.length
                                  : leadLogsAndComment.logsAndComments.length
                        }
                        next={
                            selectedTab === '2' ? fetchComments : selectedTab === '3' ? fetchLogs : fetchLogsAndComments
                        }
                        hasMore={hasMore}
                        height={408}
                        loader={
                            <Skeleton
                                avatar
                                paragraph={{
                                    rows: 1,
                                }}
                                active
                            />
                        }
                        onScroll={handleScroll}
                        style={{ scrollbarWidth: 'thin', scrollbarColor: '#888 #f1f1f1' }}
                    >
                        {selectedTab === '2' ? (
                            <CommentsList
                                comments={leadComments}
                                itemId={id}
                                leaveComment={leaveLeadComment}
                                editComment={editLeadComment}
                                deleteComment={deleteLeadComment}
                                setComments={setLeadComments}
                                isInputCollapsed={isCommentInputCollapsed}
                                scrollToTop={handleScrollToTop}
                                componentLoading={loading}
                            />
                        ) : selectedTab === '3' ? (
                            <LogsList logs={leadLogs} entityName={'leads'} loading={loading} />
                        ) : (
                            <Journal
                                logsAndComments={leadLogsAndComment.logsAndComments}
                                entityName={'leads'}
                                loading={loading}
                            />
                        )}
                    </InfiniteScroll>
                </div>
            </Flex>
        </Card>
    );
};
