import React from 'react';
import { Flex, Typography } from 'antd';
import { textColor, textStandard, spacing } from '../../theming';
import { useTranslation } from 'react-i18next';

const FiltersHeading = () => {
    const { t } = useTranslation('leads');
    return (
        <Flex align="center" gap={spacing[1]}>
            <i className="fi fi-rr-settings-sliders" style={{ marginTop: '3px', color: textColor.subtle }}></i>
            <Typography.Text style={{ ...textStandard, color: textColor.subtle }}>
                {t('actions.filters')}:
            </Typography.Text>
        </Flex>
    );
};

export default FiltersHeading;
