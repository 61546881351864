import { primitiveColors } from '../../theming/colors';

export const leadState = [
    {
        value: 'Active',
        label: 'Active',
        color: primitiveColors.blue500,
        backgroundColor: primitiveColors.blue50,
    },
    {
        value: 'Done',
        label: 'Done',
        color: primitiveColors.green600,
        backgroundColor: primitiveColors.green50,
    },
];
