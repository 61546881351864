import { Table, Button, Flex, Tag, List, Modal, Typography, Dropdown, Empty } from 'antd';
import { useState, useEffect } from 'react';
import { leadState } from '../../common/constants/leadState';
import globalNotification from '../../common/hooks/globalNotification';
import dayjs from 'dayjs';
import lodash from 'lodash';
import Avatar from '../../common/components/Avatar';
import PageContainer from '../../common/components/PageContainer';
import SearchInput from '../../common/components/GlobalSearch/SearchInput';
import { CustomPagination } from '../../common/components/pagination/Pagination';
import { socialMediaPatterns } from '../../common/constants/socialMediaPatterns';
import { SearchIcon, SorterIcon, FilterIcon, ClearInputIcon } from '../../common/components/icons/';
import ActionsMenu from '../../common/components/ActionsMenu/ActionsMenu';
import TableFilterDropdown from '../../common/components/TableFilterDropdown';
import RenderFilter from '../../components/leads/renderFilter';
import SideDrawer from '../../common/components/sideDrawer/SideDrawer';
import { LeadForm } from '../../components/leads/leadForm';
import TableRowDescription from '../../common/components/TableRowDescription';
import StatusControl from '../../common/components/StatusSegmentController';
import FiltersHeading from '../../common/components/FiltersHeading';
import TableHeadingButtons from '../../common/components/TableHeadingButtons';
import AddButton from '../../common/components/AddButton';
import MobileFiltersDrawer from '../../components/leads/mobileFiltersDrawer';
import { useMediaQuery } from '@uidotdev/usehooks';
import { notifications } from '../../common/constants/notifications';
import { notificationType } from '../../common/constants/notificationType';

// #region Utils and Services imports
import PhoneInput from 'react-phone-input-2';
import classNames from 'classnames';
import { BusinessesService } from '../../services/businessesService';
import { useLeadService } from '../../services/leadService';
import getSocialMediaIcon from '../../utils/getSocialMediaIcon';
import { sortDates } from '../../utils/sortDates';
import { useMedia } from '../../hooks/useMediaQuery';
import capitalize from '../../utils/capitalize';
import 'dayjs/locale/en';
import 'dayjs/locale/uk';
import { datesFormat } from '../../common/constants/datesFormat';
// #endregion

// #region Styles and Theming imports
import {
    tagStyle,
    tagIcon,
    primitiveColors,
    textColor,
    fontSize,
    textStandard,
    pageHeading,
    spacing,
    buttonStyle,
    textSmall,
} from '../../theming';
import { useTranslation } from 'react-i18next';
import icon from '../../common/styles/Icon.module.scss';
import './Leads.scss';
import classes from './Leads.module.scss';
import { LeadPreview } from '../../components/leads/LeadPreview';
import { motion } from 'framer-motion';
// #endregion

function Leads() {
    const { t, i18n } = useTranslation(['leads', 'general', 'confirmModal', 'notification', 'apiResponses']);
    const currentLanguage = i18n.language;
    const { isExtraSmallDevice, isSmallDevice, isMediumDevice } = useMedia();
    const { getAllLeads, createLead, updateLead, deleteLead, archive, restore, getStatuses, changeLeadsStatus } =
        useLeadService();
    const { getAllActiveBusinesses } = BusinessesService();

    const mobileDevicesBreakpoint = useMediaQuery('only screen and (max-width : 840px)');

    // #region States and Constants
    // Data:
    const [leads, setLeads] = useState([]);
    const [leadsCount, setLeadsCount] = useState(0);
    const [businesses, setBusinesses] = useState([]);
    const [statuses, setStatuses] = useState([]);

    const [loading, setLoading] = useState({
        tableLoading: false,
        addLoading: false,
        editLoading: false,
        deleteLoading: false,
        archiveLoading: false,
    });
    // Drawer states:
    const [open, setOpen] = useState(false);
    const [openMobileFilters, setOpenMobileFilters] = useState(false);
    const [formMode, setFormMode] = useState('add');
    const [leadToEdit, setLeadToEdit] = useState(null);
    // filtration, sorters, search states:
    const [filteredInfo, setFilteredInfo] = useState({});
    const [selectedFilterValue, setSelectedFilterValue] = useState({});
    const [sortedInfo, setSortedInfo] = useState({});
    const [searchParams, setSearchParams] = useState({
        pageSize: 10,
        currentPage: 1,
        isDeleted: false,
    });
    const [searchText, setSearchText] = useState('');
    // Table control states:
    const [expandedRowKeys, setExpandedRowKeys] = useState([]);
    const [selectedLeads, setSelectedLeads] = useState([]);
    const [selectedFilters, setSelectedFilters] = useState({
        states: [],
        statuses: [],
        businesses: [],
    });
    const [isHovered, setIsHovered] = useState(null);

    const filterOptions = {
        state: leadState.map(state => ({
            text: state.label,
            value: state.value,
        })),
        status: statuses.map(status => ({
            text: status.name,
            value: status.id,
        })),
        business: [
            {
                text: 'No business',
                value: 'No business',
            },
            ...businesses.map(business => ({
                text: business.name,
                value: business.name,
            })),
        ],
    };

    const filtersRenderCondition =
        Object.keys(filteredInfo).length > 0 &&
        Object.values(filteredInfo).some(value => (Array.isArray(value) ? value.length > 0 : value !== null));

    const [isClickFromView, setIsClickFromView] = useState(false);

    useEffect(() => {
        setSelectedFilterValue(filteredInfo);
    }, [filteredInfo]); // this is used to update the selectedValue state when filteredInfo changes. This was made due to behavior of antd select component
    // #endregion

    // #region Fetch Leads Data
    const fetchData = async () => {
        setLoading(prevLoading => ({
            ...prevLoading,
            tableLoading: true,
        }));
        try {
            const params = { ...searchParams, search: searchText };

            // Filter params
            params.state = filteredInfo.state || undefined;
            params.status = filteredInfo.status || undefined;
            params.business = filteredInfo.business || undefined;

            // Sorting params
            switch (sortedInfo.column?.dataIndex) {
                case 'name':
                    params.sortField = 'firstName';
                    break;
                case undefined:
                    params.sortField = 'createdAt';
                    break;
                default:
                    params.sortField = sortedInfo.column?.dataIndex;
                    break;
            }
            params.sortOrder = sortedInfo.order === 'ascend' ? 'ASC' : 'DESC';

            const { data } = await getAllLeads(params);
            const businessData = await getAllActiveBusinesses();
            const statusesData = await getStatuses();
            const { count, leads } = data;

            setLeadsCount(count);

            setStatuses(statusesData.data.statuses.sort((a, b) => a.statusOrder - b.statusOrder));
            setLeads(
                leads.map(lead => ({
                    ...lead,
                    key: lead.id,
                    name: `${lead.firstName} ${lead.lastName}`,
                    placeOfJob: lead.company,
                    position: lead.position,
                    createdAt: capitalize(
                        dayjs(lead.createdAt)
                            .locale(currentLanguage)
                            .format(currentLanguage === 'uk' ? datesFormat.uk.long : datesFormat.en.short),
                        currentLanguage === 'uk' ? 3 : 0
                    ),
                    updatedAt: capitalize(
                        dayjs(lead.updatedAt)
                            .locale(currentLanguage)
                            .format(currentLanguage === 'uk' ? datesFormat.uk.long : datesFormat.en.short),
                        currentLanguage === 'uk' ? 3 : 0
                    ),
                }))
            );
            setBusinesses(
                businessData.businesses.map(business => ({
                    name: business.name,
                    key: business.id,
                }))
            );
        } finally {
            setLoading(prevLoading => ({
                ...prevLoading,
                tableLoading: false,
            }));
        }
    };

    useEffect(() => {
        fetchData();
    }, [searchParams, filteredInfo, i18n.language]);
    // #endregion

    // #region Handlers
    const handleAddLead = (values, formReset) => {
        setLoading(prevLoading => ({
            ...prevLoading,
            addLoading: true,
        }));
        return createLead(values)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.LEADS,
                    action: notifications.action.ADD,
                    plural: false,
                });

                setTimeout(() => {
                    formReset();
                }, 1000);
                fetchData();
                closeDrawer();
            })
            .catch(error => {
                const errCode = error.response?.data?.code;
                globalNotification.open({
                    type: notificationType.ERROR,
                    message: errCode ? t(`apiResponses:${errCode}.message`) : error.response?.data?.message,
                    description: errCode ? t(`apiResponses:${errCode}.description`) : error.response?.data?.description,
                });
            })
            .finally(() => {
                setLoading(prevLoading => ({
                    ...prevLoading,
                    addLoading: false,
                }));
            });
    };

    const handleEditLead = async (id, values) => {
        setLoading(prevLoading => ({
            ...prevLoading,
            editLoading: true,
        }));

        try {
            const updatedLead = await updateLead(id, values);
            globalNotification.request({
                type: notifications.type.SUCCESS,
                resource: notifications.resource.LEADS,
                action: notifications.action.UPDATE,
                plural: false,
            });
            fetchData();
            closeDrawer();

            return { ...updatedLead, name: `${updatedLead.firstName} ${updatedLead.lastName}` };
        } catch (error) {
            globalNotification.request({
                type: notifications.type.ERROR,
                resource: notifications.resource.LEADS,
                action: notifications.action.UPDATE,
                plural: false,
            });
            throw error;
        } finally {
            setLoading(prevLoading => ({
                ...prevLoading,
                editLoading: false,
            }));
        }
    };

    const handleStatusChange = async (leadId, statusId) => {
        const ids = (selectedLeads.length && selectedLeads.map(lead => lead.id)) || leadId;

        await changeLeadsStatus(ids, statusId)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.LEADS,
                    action: notifications.action.UPDATE,
                    plural: true,
                });

                fetchData();
            })
            .catch(() => {
                globalNotification.request({
                    type: notifications.type.ERROR,
                    resource: notifications.resource.LEADS,
                    action: notifications.action.UPDATE,
                    plural: true,
                });
            })
            .finally(() => {
                setSelectedLeads([]);
            });
    };

    // #region Delete Lead
    const onClickDeleteLeads = async ids => {
        Modal.confirm({
            title: `${t('confirmModal:actions.delete')} ${ids.length > 1 ? t('leads:confirm.plural') : t('leads:confirm.singular')}?`,
            icon: null,
            width: 'fit-content',
            styles: { body: { padding: spacing[4] } },
            okText: `${t('confirmModal:buttons.yes')}`,
            cancelText: `${t('confirmModal:buttons.no')}`,
            okButtonProps: { danger: true },
            onOk: async () => {
                await handleDeleteLeads(ids);
            },
        });
    };

    const handleDeleteLeads = async ids => {
        setLoading(prevLoading => ({
            ...prevLoading,
            deleteLoading: true,
        }));
        await deleteLead(ids)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.LEADS,
                    action: notifications.action.DELETE,
                    plural: ids.length > 1,
                });
            })
            .catch(() => {
                globalNotification.request({
                    type: notifications.type.ERROR,
                    resource: notifications.resource.LEADS,
                    action: notifications.action.DELETE,
                    plural: ids.length > 1,
                });
            })
            .finally(() => {
                fetchData();
                setLoading(prevLoading => ({
                    ...prevLoading,
                    deleteLoading: false,
                }));
                setSelectedLeads([]);
            });
    };
    // #endregion

    // #region CRUD Drawer
    const openDrawer = () => {
        setOpen(true);
    };
    const closeDrawer = () => {
        setOpen(false);

        setTimeout(() => {
            setFormMode('');
        }, 500);
    };

    const openAddDrawer = () => {
        setIsClickFromView(false);
        setFormMode('add');
        openDrawer();
    };

    const openEditDrawer = (lead, isClickFromView = false) => {
        if (!isClickFromView) {
            setIsClickFromView(false);
        }

        setLeadToEdit(lead);
        setFormMode('edit');
        openDrawer();
    };

    const openEditMode = lead => {
        closeDrawer();
        setIsClickFromView(true);

        setTimeout(() => {
            openEditDrawer(lead, true);
        }, 500);
    };

    const handleOpenPreviewDrawer = lead => {
        setFormMode('preview');
        setLeadToEdit(lead);
        openDrawer();
    };
    // #endregion

    // #region Search
    const handleSearchLead = lodash.debounce(searchText => {
        setSearchText(searchText);
        setSearchParams(prevValue => ({
            ...prevValue,
            currentPage: 1,
        }));
    }, 500);

    const handleResetSearch = () => {
        setSearchText('');
        setSearchParams(prevValue => ({
            ...prevValue,
            currentPage: 1,
        }));
    };
    // #endregion

    const handlePageChange = page => {
        setSearchParams(prevValue => ({
            ...prevValue,
            currentPage: page,
        }));
    };

    const handleExpandRow = (expanded, record) => {
        setExpandedRowKeys(expanded ? [record.key] : []);
    };

    // #region Selection and archivation
    const onClickArchive = async ids => {
        Modal.confirm({
            title: `${t('confirmModal:actions.archive')} ${t(`leads:confirm.${ids.length > 1 ? 'plural' : 'singular'}`)}?`,
            icon: null,
            styles: { body: { padding: spacing[4] } },
            width: 'fit-content',
            okText: t('confirmModal:buttons.yes'),
            cancelText: t('confirmModal:buttons.no'),
            okButtonProps: { danger: true },
            onOk: () => handleArchiveLeads(ids),
        });
    };

    const onClickUnarchive = async ids => {
        Modal.confirm({
            title: `${t('confirmModal:actions.restore')} ${t(`leads:confirm.${ids.length > 1 ? 'plural' : 'singular'}`)}?`,
            icon: null,
            styles: { body: { padding: spacing[4] } },
            width: 'fit-content',
            okText: t('confirmModal:buttons.yes'),
            cancelText: t('confirmModal:buttons.no'),
            okButtonProps: { danger: true },
            onOk: () => handleUnarchiveLeads(ids),
        });
    };

    const handleArchiveLeads = async ids => {
        setLoading(prevLoading => ({
            ...prevLoading,
            archiveLoading: true,
        }));
        await archive(ids)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.LEADS,
                    action: notifications.action.ARCHIVE,
                    plural: ids.length > 1,
                });
            })
            .catch(() => {
                globalNotification.request({
                    type: notifications.type.ERROR,
                    resource: notifications.resource.LEADS,
                    action: notifications.action.ARCHIVE,
                    plural: ids.length > 1,
                });
            })
            .finally(() => {
                setSearchParams(prevValue => {
                    const prevPage = parseInt(prevValue.currentPage, 10);
                    return {
                        ...prevValue,
                        currentPage: prevPage > 1 ? prevPage - 1 : 1,
                    };
                });
                fetchData();
                setSelectedLeads([]);
                setLoading(prevLoading => ({
                    ...prevLoading,
                    archiveLoading: false,
                }));
            });
    };

    const handleUnarchiveLeads = async ids => {
        setLoading(prevLoading => ({
            ...prevLoading,
            archiveLoading: true,
        }));
        await restore(ids)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.LEADS,
                    action: notifications.action.RESTORE,
                    plural: ids.length > 1,
                });
            })
            .catch(() => {
                globalNotification.request({
                    type: notifications.type.ERROR,
                    resource: notifications.resource.LEADS,
                    action: notifications.action.RESTORE,
                    plural: ids.length > 1,
                });
            })
            .finally(() => {
                setSearchParams(prevValue => {
                    const prevPage = parseInt(prevValue.currentPage, 10);
                    return {
                        ...prevValue,
                        currentPage: prevPage > 1 ? prevPage - 1 : 1,
                    };
                });
                fetchData();
                setSelectedLeads([]);
                setLoading(prevLoading => ({
                    ...prevLoading,
                    archiveLoading: false,
                }));
            });
    };

    const onSelectChange = newSelectedRowKeys => {
        setSelectedLeads(newSelectedRowKeys);
    };

    const rowSelection = {
        fixed: true,
        selectedRowKeys: selectedLeads.map(lead => lead.id),
        onChange: (_, selectedRows) => {
            onSelectChange(
                selectedRows.map(row => {
                    return {
                        id: row.id,
                        isDeleted: row.isDeleted,
                    };
                })
            );
        },
        onSelect: (record, selected) => {
            if (selected) {
                setSelectedLeads(prevSelected => [
                    ...prevSelected,
                    {
                        id: record.id,
                        isDeleted: record.isDeleted,
                    },
                ]);
            } else {
                setSelectedLeads(prevSelected => prevSelected.filter(item => item.id !== record.key));
            }
        },
        onSelectAll: (selected, _, changeRows) => {
            const selectedKeys = changeRows.map(row => row.key);
            if (selected) {
                setSelectedLeads(prevSelected => [...prevSelected, ...selectedKeys]);
            } else {
                setSelectedLeads(prevSelected => prevSelected.filter(id => !selectedKeys.includes(id)));
            }
        },

        getCheckboxProps: record => ({
            name: record.name,
        }),
    };

    const statusHandler = value => {
        setSearchParams(prevValue => ({
            ...prevValue,
            currentPage: 1,
            isDeleted: value === t('general:statuses.archived'),
        }));
        setSelectedLeads([]);
    };
    // #endregion
    // #endregion

    // #region Table Config
    const onTableParamsChange = (pagination, filters, sorter) => {
        setSearchParams(prevValue => ({
            ...prevValue,
            currentPage: pagination.current || 1,
            pageSize: pagination.pageSize || 10,
        }));
        setFilteredInfo(filters);
        setSortedInfo(sorter);
    };

    const handleRowClick = record => {
        handleOpenPreviewDrawer(record);
    };

    const columns = [
        {
            title: t('fields.name'),
            dataIndex: 'name',
            key: 'name',
            fixed: mobileDevicesBreakpoint ? false : 'left',
            width: mobileDevicesBreakpoint ? 280 : 300,
            sorter: () => {}, // empty because we are sorting on backend
            render: (name, record) => {
                return (
                    <Flex
                        gap={mobileDevicesBreakpoint ? spacing[1] : spacing[3]}
                        justify="start"
                        align="center"
                        style={{
                            cursor: 'pointer',
                        }}
                        onClick={() => handleRowClick(record)}
                    >
                        <Avatar
                            key={record.id}
                            size={isSmallDevice || isExtraSmallDevice ? 32 : 36}
                            photoName={record.photo}
                            firstName={record.firstName}
                            lastName={record.lastName}
                            controller={'leads'}
                            style={{ flexShrink: 0 }}
                        />
                        <Flex vertical>
                            <Typography.Text style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard) }}>
                                {name}
                            </Typography.Text>

                            <Typography.Text
                                style={{
                                    color: textColor.subtle,
                                    ...textStandard,
                                }}
                            >
                                {record.email}
                            </Typography.Text>
                        </Flex>
                    </Flex>
                );
            },
        },
        {
            title: t('fields.state'),
            dataIndex: 'state',
            key: 'state',
            width: mobileDevicesBreakpoint ? 110 : 140,
            filters:
                !mobileDevicesBreakpoint &&
                leadState.map(state => ({
                    text: state.label,
                    value: state.value,
                })),
            onFilter: (value, record) => record.state.indexOf(value) === 0,
            filteredValue: filteredInfo.state || null,
            sorter: () => {}, // empty because we are sorting on backend
            defaultSortOrder: 'descend',
            sorterOrder: sortedInfo.columnKey === 'state' ? sortedInfo.order : null,
            render: state => {
                const stateName = leadState.find(s => s.value === state);
                return (
                    <Tag
                        style={{
                            ...tagStyle,
                            gap: spacing[1],
                            width: 'fit-content',
                            height: '28px',
                            color: stateName?.color,
                            backgroundColor: stateName?.backgroundColor,
                            fontSize: mobileDevicesBreakpoint ? fontSize.xxs : 'inherit',
                        }}
                        bordered={false}
                        icon={
                            <span
                                style={{
                                    backgroundColor: stateName?.color,
                                    ...tagIcon,
                                }}
                            ></span>
                        }
                    >
                        {stateName?.label}
                    </Tag>
                );
            },
            filterDropdown: ({ filters, close }) =>
                !mobileDevicesBreakpoint && (
                    <TableFilterDropdown
                        filters={filters}
                        close={close}
                        filterKey="state"
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        setFilteredInfo={setFilteredInfo}
                    />
                ),
        },
        {
            title: t('fields.status'),
            dataIndex: 'status',
            key: 'status',
            width: mobileDevicesBreakpoint ? 120 : 146,
            filters: !mobileDevicesBreakpoint && [
                ...statuses.map(status => ({
                    text: status.name,
                    value: status.id,
                })),
            ],
            onFilter: (value, record) => {
                return record.status?.id === value;
            },
            filteredValue: filteredInfo.status || null,
            sorter: () => {}, // empty because we are sorting on backend
            sorterOrder: sortedInfo.columnKey === 'status' ? sortedInfo.order : null,
            render: (status, record) => {
                return (
                    <Dropdown
                        placement="bottom"
                        menu={{
                            items: statuses.map(status => ({
                                key: status.id,
                                label: status.name,
                                onClick: () => {
                                    handleStatusChange([record.id], status.id);
                                },
                            })),
                        }}
                        overlayClassName={classes['actions-menu']}
                        trigger="click"
                    >
                        <motion.div
                            initial="hidden"
                            animate={isHovered === record.id ? 'visible' : 'hidden'}
                            onHoverStart={() => setIsHovered(record.id)}
                            onHoverEnd={() => setIsHovered(null)}
                            style={{ position: 'relative', cursor: 'pointer' }}
                        >
                            <Tag
                                style={{
                                    ...tagStyle,
                                    width: 'fit-content',
                                    color: status?.primaryColor,
                                    backgroundColor: status?.secondaryColor,
                                    height: '28px',
                                    fontSize: mobileDevicesBreakpoint ? fontSize.xxs : 'inherit',
                                    zIndex: 1,
                                }}
                                bordered={false}
                            >
                                {status?.name}
                            </Tag>
                            <motion.div
                                variants={{
                                    hidden: { opacity: 0 },
                                    visible: { opacity: 1 },
                                }}
                                style={{
                                    position: 'absolute',
                                    top: -6,
                                    right: 20,
                                    zIndex: 2,
                                    width: 16,
                                    height: 16,
                                    color: primitiveColors.blue200,
                                    pointerEvents: 'none',
                                }}
                            >
                                <i className="fi fi-rr-edit" />
                            </motion.div>
                        </motion.div>
                    </Dropdown>
                );
            },
            filterDropdown: ({ filters, close }) =>
                !mobileDevicesBreakpoint && (
                    <TableFilterDropdown
                        filters={filters}
                        close={close}
                        filterKey="status"
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        setFilteredInfo={setFilteredInfo}
                    />
                ),
        },
        {
            title: t('fields.business'),
            dataIndex: 'business',
            key: 'business',
            width: mobileDevicesBreakpoint ? 150 : 200,
            render: business => {
                return business.length === 0 ? (
                    <List.Item style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard) }}>
                        {t('no-business')}
                    </List.Item>
                ) : (
                    <Flex vertical gap={spacing[0.5]} style={{ margin: `${spacing[0.5]} 0` }}>
                        {business.slice(0, mobileDevicesBreakpoint ? 1 : 2).map(business => (
                            <Typography.Text
                                key={business.id}
                                style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard) }}
                            >
                                {business.name}
                            </Typography.Text>
                        ))}
                    </Flex>
                );
            },
            filters: !mobileDevicesBreakpoint && [
                {
                    text: t('no-business'),
                    value: 'No business',
                },
                ...businesses.map(business => ({ text: business.name, value: business.name })),
            ],
            onFilter: (value, record) => {
                if (value === 'No business') {
                    return !record.business || record.business.length === 0;
                }
                return record.business.map(business => business.name).includes(value);
            },
            filteredValue: filteredInfo.business || null,
            filterDropdown: ({ filters, close }) =>
                !mobileDevicesBreakpoint && (
                    <TableFilterDropdown
                        filters={filters}
                        close={close}
                        filterKey="business"
                        selectedFilters={selectedFilters}
                        setSelectedFilters={setSelectedFilters}
                        setFilteredInfo={setFilteredInfo}
                    />
                ),
        },
        {
            title: t('fields.phone'),
            dataIndex: 'phoneNumber',
            key: 'phoneNumber',
            width: mobileDevicesBreakpoint ? 165 : 200,
            render: phoneNumber => (
                <List
                    dataSource={mobileDevicesBreakpoint ? phoneNumber.slice(0, 1) : phoneNumber.slice(0, 2)}
                    renderItem={(item, index) => (
                        <a
                            key={item}
                            href={`tel:${item}`}
                            onClick={e => e.stopPropagation()}
                            style={{
                                display: 'block',
                                width: '165px',
                                textDecoration: 'none',
                            }}
                        >
                            <PhoneInput
                                value={item}
                                buttonStyle={{ display: 'none' }}
                                inputStyle={{
                                    width: '100%',
                                    border: 'none',
                                    padding: 0,
                                    cursor: 'pointer',
                                    color: index === 0 ? textColor.secondary : textColor.secondaryAlt,
                                    textDecoration: 'none',
                                    pointerEvents: 'none',
                                    backgroundColor: 'inherit',
                                    ...(mobileDevicesBreakpoint ? textSmall : textStandard),
                                }}
                                containerStyle={{ width: '165px' }}
                            />
                        </a>
                    )}
                />
            ),
        },
        {
            title: t('fields.source'),
            dataIndex: 'source',
            key: 'source',
            width: 120,
            render: source => (
                <Typography.Text style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard) }}>
                    {source}
                </Typography.Text>
            ),
        },
        {
            title: t('fields.location'),
            dataIndex: 'location',
            key: 'location',
            width: 200,
            render: location => (
                <Typography.Text style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard) }}>
                    {location}
                </Typography.Text>
            ),
        },
        {
            title: t('fields.socials'),
            dataIndex: 'socialMedia',
            key: 'socialMedia',
            width: 182,
            render: socialMedia => (
                <Flex gap={spacing[1]}>
                    {Object.values(socialMedia).map((item, index) => {
                        return (
                            item &&
                            socialMediaPatterns.some(pattern => new RegExp(pattern).test(item)) && (
                                <a
                                    key={index}
                                    href={item.startsWith('http') ? item : `https://${item}`}
                                    target="_blank"
                                    rel="noreferrer"
                                    style={{ textDecoration: 'none', color: textColor.secondary }}
                                >
                                    {getSocialMediaIcon(item)}
                                </a>
                            )
                        );
                    })}
                    {Object.values(socialMedia).every(item => item === '') && (
                        <Typography.Text style={{ ...textStandard }}>{t('no-socials')}</Typography.Text>
                    )}
                </Flex>
            ),
        },
        {
            title: t('fields.place-of-job'),
            dataIndex: 'placeOfJob',
            key: 'placeOfJob',
            width: mobileDevicesBreakpoint ? 165 : 200,
            render: placeOfJob => (
                <Typography.Text style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard) }}>
                    {placeOfJob ? placeOfJob : t('no-company')}
                </Typography.Text>
            ),
        },
        {
            title: t('fields.position'),
            dataIndex: 'position',
            key: 'position',
            width: mobileDevicesBreakpoint ? 165 : 200,
            render: position => (
                <Typography.Text style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard) }}>
                    {position ? position : t('leads:no-info')}
                </Typography.Text>
            ),
        },
        {
            title: t('fields.created'),
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: 170,
            sorter: (a, b) => sortDates(a.createdAt, b.createdAt),
            render: createdAt => (
                <Typography.Text style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard) }}>
                    {createdAt}
                </Typography.Text>
            ),
        },
        {
            title: t('fields.updated'),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: 170,
            sorter: (a, b) => sortDates(a.updatedAt, b.updatedAt),
            render: updatedAt => (
                <Typography.Text style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard) }}>
                    {updatedAt}
                </Typography.Text>
            ),
        },
        {
            title: '',
            dataIndex: 'actions',
            key: 'actions',
            width: 40,
            fixed: 'right',
            render: (_, record) => (
                <Flex justify="center" onClick={e => e.stopPropagation()}>
                    <Dropdown
                        placement="bottom"
                        menu={{
                            items: ActionsMenu({
                                record,
                                handleEdit: openEditDrawer,
                                handleDelete: onClickDeleteLeads,
                                handleArchive: record.isDeleted ? onClickUnarchive : onClickArchive,
                                handleView: () => handleOpenPreviewDrawer(record),
                                t,
                            }),
                        }}
                        overlayClassName={classes['actions-menu']}
                    >
                        <span onClick={e => e.preventDefault()} style={{ cursor: 'pointer' }}>
                            <i
                                className={`fi fi-rr-menu-dots-vertical ${icon.md}`}
                                style={{ color: textColor.placeholder }}
                            />
                        </span>
                    </Dropdown>
                </Flex>
            ),
        },
    ];

    const clearAll = () => {
        setFilteredInfo({});
        setSortedInfo({});
        setSelectedFilters({
            states: [],
            statuses: [],
            businesses: [],
        });
    };
    // #endregion

    return (
        <PageContainer
            style={{
                backgroundColor: mobileDevicesBreakpoint ? primitiveColors.gray25 : primitiveColors.gray0,
                padding: mobileDevicesBreakpoint ? spacing[1] : spacing[4],
            }}
        >
            <Flex vertical>
                <Flex
                    vertical={mobileDevicesBreakpoint}
                    gap={mobileDevicesBreakpoint ? spacing[1] : spacing[3]}
                    align="center"
                    justify="space-between"
                    style={{
                        marginBottom: mobileDevicesBreakpoint ? spacing[3] : spacing[4],
                    }}
                >
                    <Flex
                        gap={spacing[3]}
                        align="center"
                        justify={mobileDevicesBreakpoint ? 'space-between' : 'start'}
                        style={{
                            width: mobileDevicesBreakpoint && '100%',
                            maxHeight: mobileDevicesBreakpoint && 60,
                        }}
                    >
                        <h1 style={{ ...pageHeading, fontSize: mobileDevicesBreakpoint && fontSize.H4, margin: 0 }}>
                            {t('plural')}
                        </h1>
                        <StatusControl onChange={statusHandler} />
                    </Flex>
                    <Flex
                        gap={spacing[3]}
                        align="center"
                        justify={mobileDevicesBreakpoint ? 'space-between' : 'end'}
                        style={{ width: mobileDevicesBreakpoint && '100%' }}
                    >
                        {mobileDevicesBreakpoint && (
                            <>
                                <Button
                                    icon={<i className={`fi fi-rr-settings-sliders`} />}
                                    style={{
                                        ...buttonStyle.small,
                                        color: textColor.subtle,
                                        borderColor: textColor.subtle,
                                        backgroundColor: 'inherit',
                                        width: 'clamp(123px, 20vw, 140px)',
                                    }}
                                    onClick={() => setOpenMobileFilters(true)}
                                >
                                    {t('actions.filters')}
                                </Button>
                                <MobileFiltersDrawer
                                    open={openMobileFilters}
                                    closeDrawer={() => setOpenMobileFilters(false)}
                                    filteredInfo={filteredInfo}
                                    filterOptions={filterOptions}
                                    selectedValue={selectedFilterValue}
                                    setSelectedValue={setSelectedFilterValue}
                                    setSelectedFilters={setSelectedFilters}
                                    setFilteredInfo={setFilteredInfo}
                                />
                            </>
                        )}
                        {!mobileDevicesBreakpoint && (
                            <SearchInput
                                placeholder={t('search')}
                                value={searchText}
                                style={{ width: isMediumDevice ? 220 : 250 }}
                                onChange={e => {
                                    setSearchText(e.target.value);
                                    if (e.target.value === '') {
                                        handleResetSearch();
                                    }
                                }}
                                onPressEnter={e => {
                                    e.preventDefault();
                                    handleSearchLead(searchText);
                                }}
                                prefix={<SearchIcon onClick={() => handleSearchLead(searchText)} />}
                                suffix={
                                    searchText && (
                                        <ClearInputIcon setSearchText={setSearchText} handleReset={handleResetSearch} />
                                    )
                                }
                            />
                        )}
                        <AddButton
                            size={isMediumDevice && mobileDevicesBreakpoint && 'default'}
                            style={{
                                ...(mobileDevicesBreakpoint ? buttonStyle.small : buttonStyle.large),
                            }}
                            iconSize={icon.sm}
                            text={t('actions.add')}
                            onClick={openAddDrawer}
                        />
                    </Flex>
                    {selectedLeads.length > 0 && mobileDevicesBreakpoint && (
                        <Flex
                            gap={spacing[0.5]}
                            align="center"
                            justify="end"
                            style={{ width: mobileDevicesBreakpoint && '100%' }}
                        >
                            <TableHeadingButtons
                                items={selectedLeads}
                                handleDelete={onClickDeleteLeads}
                                handleArchive={onClickArchive}
                                handleRestore={onClickUnarchive}
                                isMobile
                                style={{
                                    ...buttonStyle.small,
                                }}
                            />
                        </Flex>
                    )}
                </Flex>
                {!mobileDevicesBreakpoint && (
                    <Flex
                        align="center"
                        justify={selectedLeads.length > 0 ? 'end' : 'space-between'}
                        style={{ marginBottom: spacing[1], height: 38 }}
                    >
                        {filtersRenderCondition && !selectedLeads.length && (
                            <Flex gap={spacing[2]} align="center">
                                <FiltersHeading />
                                {Object.keys(filteredInfo).map((key, index) => (
                                    <RenderFilter
                                        key={key}
                                        index={index}
                                        filteredKey={key}
                                        filteredInfo={filteredInfo}
                                        filterOptions={filterOptions}
                                        selectedValue={selectedFilterValue}
                                        setSelectedValue={setSelectedFilterValue}
                                        setSelectedFilters={setSelectedFilters}
                                        setFilteredInfo={setFilteredInfo}
                                    />
                                ))}
                                <Button
                                    type="link"
                                    onClick={() => {
                                        clearAll();
                                    }}
                                    size="small"
                                    style={{
                                        color: textColor.subtle,
                                    }}
                                >
                                    {t('actions.reset')}
                                </Button>
                            </Flex>
                        )}
                        {selectedLeads.length > 0 && (
                            <Flex
                                gap={spacing[0.5]}
                                align="center"
                                justify="end"
                                style={{ width: mobileDevicesBreakpoint && '100%' }}
                            >
                                <TableHeadingButtons
                                    items={selectedLeads}
                                    handleDelete={onClickDeleteLeads}
                                    handleArchive={onClickArchive}
                                    handleRestore={onClickUnarchive}
                                    isMobile
                                    style={{
                                        ...buttonStyle.small,
                                    }}
                                />
                            </Flex>
                        )}
                    </Flex>
                )}
                <SideDrawer
                    open={open}
                    closeDrawer={closeDrawer}
                    title={formMode === 'add' ? t('actions.add') : formMode === 'preview' ? '' : t('actions.edit')}
                    width={formMode === 'preview' && 700}
                    padding={formMode === 'preview' ? `${spacing[10]} ${spacing[6]}` : ''}
                    children={
                        formMode === 'preview' ? (
                            <LeadPreview lead={leadToEdit} openEditMode={openEditMode} statuses={statuses} />
                        ) : (
                            <LeadForm
                                mode={formMode}
                                lead={leadToEdit}
                                loading={formMode === 'add' ? loading.addLoading : loading.editLoading}
                                handleAdd={handleAddLead}
                                handleEdit={handleEditLead}
                                isOpened={open}
                                openPreviewDrawer={handleOpenPreviewDrawer}
                                isClickFromView={isClickFromView}
                                statuses={statuses}
                            />
                        )
                    }
                />
            </Flex>

            <Table
                columns={[
                    Table.SELECTION_COLUMN,
                    Table.EXPAND_COLUMN,
                    ...columns.map(column => ({
                        ...column,
                        sortOrder: sortedInfo?.columnKey === column.dataIndex && sortedInfo?.order,
                        title: column.title.toUpperCase(),
                        filterIcon: filtered => !mobileDevicesBreakpoint && FilterIcon({ filtered }),
                        sortIcon: () => SorterIcon({ sortOrder: sortedInfo, column: column.dataIndex }),
                    })),
                ]}
                dataSource={leads}
                onChange={onTableParamsChange}
                pagination={false}
                loading={loading.tableLoading}
                scroll={{
                    x: 'max-content',
                }}
                size="small"
                selection
                bordered={false}
                rowSelection={{
                    ...rowSelection,
                    type: 'checkbox',
                }}
                locale={{
                    triggerAsc: `${t('general:actions.simple.sort-asc')}`,
                    triggerDesc: t('general:actions.simple.sort-desc'),
                    cancelSort: t('general:actions.simple.cancel-sort'),
                    emptyText: <Empty description={t('general:no-data')} />,
                }}
                expandable={{
                    fixed: true,
                    onExpand: handleExpandRow,
                    expandedRowKeys: expandedRowKeys,
                    expandedRowClassName: () => 'expanded-row',
                    expandedRowRender: record => <TableRowDescription record={record} />,
                    expandIcon: ({ expanded, record, onExpand }) => {
                        return expanded ? (
                            <i
                                className={`fi fi-rr-angle-small-down ${icon.md}`}
                                onClick={e => {
                                    e.stopPropagation();
                                    onExpand(record, e);
                                    setExpandedRowKeys(prev => [...prev.filter(key => key !== record.key)]);
                                }}
                                style={{
                                    color: primitiveColors.blue500,
                                    cursor: 'pointer',
                                    display: 'flex',
                                }}
                            />
                        ) : (
                            <i
                                className={`fi fi-rr-angle-small-right ${icon.md}`}
                                onClick={e => {
                                    e.stopPropagation();
                                    onExpand(record, e);
                                    setExpandedRowKeys(prev => [...prev, record.key]);
                                }}
                                style={{
                                    color: primitiveColors.gray300,
                                    cursor: 'pointer',
                                    display: 'flex',
                                    alignItems: 'center',
                                    marginTop: 0,
                                }}
                            />
                        );
                    },
                }}
                rowClassName={(record, index) =>
                    classNames({
                        'even-table-cell': index % 2 === 0,
                        'odd-table-cell': index % 2 !== 0,
                        'expanded-cell': expandedRowKeys.includes(record.key),
                    })
                }
                defaultSortOrder="descend"
                footer={() => (
                    <CustomPagination
                        currentPage={searchParams.currentPage}
                        handlePageChange={handlePageChange}
                        perPage={searchParams.pageSize}
                        handlePerPageChange={perPage => {
                            setSearchParams(prevValue => ({
                                ...prevValue,
                                pageSize: perPage,
                                currentPage: 1,
                            })); // reset page to 1 when changing perPage
                        }}
                        total={leadsCount}
                        title={t('total')}
                    />
                )}
            />
        </PageContainer>
    );
}

export default Leads;
