import { Typography } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import classes from './style.module.scss';

const { Text } = Typography;

export const NotFindingTeam = ({ memberships, logout, t }) => {
    const navigate = useNavigate();

    const handleLogout = () => {
        logout();
        navigate({ replace: true });
    };

    return (
        <Text className={classes['end-text']}>
            {memberships && memberships.length > 0 && t('join-footer')}
            <Link onClick={handleLogout}> {t('buttons.diff')}</Link>
        </Text>
    );
};

export const getFullName = (firstName, lastName, t) => {
    if (!firstName && !lastName) {
        return t('teamCreation:pending');
    }
    return `${firstName} ${lastName}`;
};
