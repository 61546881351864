import { Modal } from 'antd';

export default function ModalWarning({ title, text, okText, cancelText, props }) {
    return Modal.warning({
        title: title,
        content: (
            <div>
                <p>{text}</p>
            </div>
        ),
        centered: true,
        okText: okText,
        cancelText: cancelText,
        maskClosable: true,
        ...props,
    });
}
