/**
 * Processes a URL to extract and format the hostname.
 *
 * @param {string} url - The URL to process.
 * @returns {string} The formatted hostname, or the original URL if it cannot be processed.
 *
 * @example
 * processUrl('https://www.google.com');
 * returns "Google"
 */
export default function processUrl(url) {
    try {
        const urlObj = new URL(url);
        const hostname = urlObj.hostname
            .replace('www.', '')
            .replace('.com', '')
            .replace('.net', '')
            .replace('.org', '');
        return hostname.charAt(0).toUpperCase() + hostname.slice(1);
    } catch (e) {
        return url;
    }
}
