import { spacing } from './spacing';
import { radius } from './radius';
import { borderColor } from './themeColors';

export const iconContainerStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    border: `1px solid ${borderColor.strong}`,
    borderRadius: radius.huge,
    padding: spacing[1],
    width: '24px',
    height: '24px',
    maxWidth: '100%',
    maxHeight: '100%',
    padding: 0,
};
