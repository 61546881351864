import { useEffect, useState } from 'react';
import { Flex, Divider, Typography, Card, List, Avatar, Button, Tooltip } from 'antd';
import CustomAvatar from './../../common/components/Avatar';
import { spacing, buttonStyle } from './../../theming';
import { useAuth } from './../../context/AuthContext';
import { useTeamService } from './../../services/teamService';
import { useNavigate } from 'react-router-dom';
import classes from './style.module.scss';
import { routesPath } from './../../common/constants/routesPath';
import globalNotification from './../../common/hooks/globalNotification';
import { notificationType } from './../../common/constants/notificationType';
import { NotFindingTeam, getFullName } from './helpers';

const { Title, Text } = Typography;

function JoinTeam({ t }) {
    const [memberships, setMemberships] = useState([]);
    const { currentUser, fetchUser, changeTeam, logout } = useAuth();
    const { getUserMemberships } = useTeamService();
    const navigate = useNavigate();

    useEffect(() => {
        if (!currentUser) {
            fetchUser();
        }
        getUserMemberships(false).then(({ data }) => setMemberships(data));
    }, []);

    const handleJoinTeam = async teamId => {
        try {
            await changeTeam(teamId);
            navigate(routesPath.ROOT.DASHBOARD);
        } catch (err) {
            globalNotification.open({
                type: notificationType.ERROR,
                message: err.response.data.message,
                description: err.response.data.description,
            });
        }
    };

    return (
        <Flex vertical className={classes['join-team-container']}>
            {memberships && memberships.length > 0 ? (
                <>
                    <Divider>{t('general:or')}</Divider>
                    <Title className={classes.title}>{t('join-title')}</Title>
                    <Card className={classes['join-team-card']}>
                        <Text className={classes['card-title']}>
                            {t('join-for')}: <span>{currentUser?.email}</span>
                        </Text>
                        <Divider />
                        <List>
                            {memberships.map(({ team }) => (
                                <List.Item key={team.id}>
                                    <Flex
                                        vertical
                                        style={{
                                            width: 'clamp(120px, 75vw, 150px)',
                                        }}
                                    >
                                        <List.Item.Meta title={team.name} />
                                        <Flex gap={spacing[4]} align="center">
                                            <Avatar.Group
                                                size={24}
                                                max={{
                                                    count: 4,
                                                }}
                                                style={{ width: 90 }}
                                            >
                                                {team.memberships.map(member => (
                                                    <Tooltip
                                                        title={getFullName(
                                                            member.user.firstName,
                                                            member.user.lastName,
                                                            t
                                                        )}
                                                        key={member.id}
                                                    >
                                                        <CustomAvatar
                                                            size={24}
                                                            photoName={member.user.photo}
                                                            firstName={member.user.firstName}
                                                            lastName={member.user.lastName}
                                                            style={{ cursor: 'pointer' }}
                                                            key={member.id}
                                                        />
                                                    </Tooltip>
                                                ))}
                                            </Avatar.Group>
                                            <Text className={classes['memberships-count']}>
                                                {team.memberships.length}
                                            </Text>
                                        </Flex>
                                    </Flex>
                                    <Button
                                        type="primary"
                                        style={{ ...buttonStyle.small, padding: `${spacing[0.5]} ${spacing[2]}` }}
                                        onClick={() => handleJoinTeam(team.id)}
                                    >
                                        {t('buttons.join')}
                                    </Button>
                                </List.Item>
                            ))}
                        </List>
                    </Card>
                    <NotFindingTeam memberships={memberships} logout={logout} t={t} />
                </>
            ) : (
                <NotFindingTeam memberships={memberships} logout={logout} t={t} />
            )}
        </Flex>
    );
}

export default JoinTeam;
