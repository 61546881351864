import { primitiveColors } from '../../theming';

export const AppRoles = [
    {
        label: 'Super Admin',
        value: 'SuperAdmin',
        color: primitiveColors.geekblue500,
        backgroundColor: primitiveColors.geekblue50,
        borderColor: primitiveColors.geekblue100,
    },
    {
        label: 'Admin',
        value: 'Admin',
        color: primitiveColors.ocean500,
        backgroundColor: primitiveColors.ocean50,
        borderColor: primitiveColors.ocean100,
    },
    {
        label: 'User',
        value: 'User',
        color: primitiveColors.cyan500,
        backgroundColor: primitiveColors.cyan50,
        borderColor: primitiveColors.cyan500,
    },
];
