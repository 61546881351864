import { Avatar, Card, Flex, Typography } from 'antd';
import { SparkLine } from '../../../common/components/icons/SparkLine';
import { fontSize, primitiveColors, radius, spacing, textStandard } from '../../../theming';
import { sortTypes } from '../../../common/constants/sortTypes';
import classes from './style.module.scss';

// N.B. Some params and imports here are left for the future implementation with different icons and colors

export const LeadAmountDiffenceWidget = ({
    title,
    leadAmount,
    percentageDifference,
    icon,
    sparkLineColor,
    iconBgColor,
}) => {
    const type =
        percentageDifference > 0 ? sortTypes.ascent : percentageDifference < 0 ? sortTypes.descent : sortTypes.none;

    return (
        <Card
            className={classes['lead-amount-card']}
            styles={{
                body: { padding: spacing[3], height: '96px' },
            }}
        >
            <Flex align="center" justify="space-between">
                <Flex gap={spacing[1]} vertical>
                    <Flex gap={spacing[1]}>
                        {/* <Avatar
                            size={24}
                            style={{ backgroundColor: iconBgColor, borderRadius: radius.sm, padding: spacing[0.5] }}
                            icon={icon}
                        /> */}
                        <Typography.Text style={{ ...textStandard, fontWeight: 500, textWrap: 'nowrap' }}>
                            {title.charAt(0).toUpperCase() + title.slice(1)}
                        </Typography.Text>
                    </Flex>

                    <Flex gap={spacing[1]}>
                        <Typography.Text
                            style={{ fontSize: fontSize.H4, fontWeight: 600, color: primitiveColors.gray900 }}
                        >
                            {leadAmount}
                        </Typography.Text>
                        <Typography.Text
                            style={{
                                color: type === sortTypes.descent ? primitiveColors.red500 : primitiveColors.blue500,
                                fontSize: fontSize.xxs,
                                fontWeight: 500,
                            }}
                        >{`${type === sortTypes.ascent ? '+' : ''}${percentageDifference}%`}</Typography.Text>
                    </Flex>
                </Flex>

                <SparkLine type={type} color={sparkLineColor} />
            </Flex>
        </Card>
    );
};
