import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Tag, Table, Divider } from 'antd';
import { DollarOutlined } from '@ant-design/icons';
import { useOrderService } from '../../services/orderService';
import { orderStatus } from '../../common/constants/orderStatus';
import dayjs from 'dayjs';

function Orders() {
    const { Column } = Table;

    const orderService = useOrderService();
    const navigate = useNavigate();

    const [orders, setOrders] = useState([]);

    useEffect(() => {
        getAllUserOrders();
    }, []);

    async function getAllUserOrders() {
        const { data } = await orderService.getAllOrders();

        setOrders(data.map(order => ({ ...order, date: dayjs(order.date).format('DD-MMM-YYYY') })));
    }

    function handleProceedToPayments(orderData) {
        navigate('/payments', { state: { orderData: orderData } });
    }

    function handleTagColor(status) {
        let color = 'green';
        if (status === orderStatus.AWAITING) {
            color = 'geekblue';
        } else if (status === orderStatus.FAILED) {
            color = 'volcano';
        } else if (status === orderStatus.CANCELLED || status === orderStatus.DECLINED) {
            color = 'red';
        }
        return color;
    }

    return (
        <>
            <Divider orientation="left">
                <b>Orders Demo</b>
            </Divider>
            <p>The Orders page is designed to demonstrate how Stripe Webhook Endpoints work.</p>
            <p>
                You can configure webhook endpoints via the API to be notified about events that happen in your Stripe
                account or connected accounts.
            </p>
            <p>
                In our demo we subscribe to payments events and this way we can find out when a user has made a payment
                or when a payment has failed, and change the status of an order.
            </p>
            <p>
                For more information visit{' '}
                <a href="https://docs.stripe.com/api/webhook_endpoints" target="blank">
                    Stripe API Webhook Endpoints Docs
                </a>
            </p>

            <Table
                dataSource={orders}
                rowKey="id"
                expandable={{
                    expandedRowRender: record => {
                        const products = record.products.map(product => ({ ...product }));

                        return (
                            <Table dataSource={products} pagination={false} rowKey="id">
                                <Column
                                    title=""
                                    dataIndex="image"
                                    key="image"
                                    render={(image, product) => (
                                        <img src={image} alt={product.title} width="50" height="50" />
                                    )}
                                />
                                <Column title="Title" dataIndex="title" key="title" />
                                <Column title="Quantity" dataIndex="quantity" key="quantity" />
                                <Column
                                    title="Price"
                                    dataIndex="priceInCents"
                                    key="priceInCents"
                                    render={priceInCents => <p>{priceInCents / 100} $</p>}
                                />
                                <Column
                                    title="Cost"
                                    dataIndex="cost"
                                    key="cost"
                                    render={(_, product) => <p>{(product.priceInCents / 100) * product.quantity} $</p>}
                                />
                            </Table>
                        );
                    },
                    rowExpandable: record => record.name !== 'Not Expandable',
                }}
            >
                <Column title="Order Number" dataIndex="orderNumber" key="orderNumber" render={text => <a>{text}</a>} />
                <Column title="Date" dataIndex="date" key="date" />
                <Column
                    title="Status"
                    dataIndex="status"
                    key="status"
                    render={status => {
                        const tagColor = handleTagColor(status);
                        return <Tag color={tagColor}>{status.toUpperCase()}</Tag>;
                    }}
                />
                <Column
                    title="Cost"
                    dataIndex="amountInCents"
                    key="amountInCents"
                    render={amountInCents => <p>{amountInCents / 100} $</p>}
                />
                <Column
                    title="Action"
                    key="action"
                    render={(_, order) => {
                        if (order.status === orderStatus.AWAITING || order.status === orderStatus.FAILED) {
                            return (
                                <Button onClick={() => handleProceedToPayments(order)} icon={<DollarOutlined />}>
                                    Pay
                                </Button>
                            );
                        }
                    }}
                />
            </Table>
        </>
    );
}

export default Orders;
