import { Flex } from 'antd';
import FacebookSelectIcon from '../../assets/facebook_select_icon.svg';
import InstagramSelectIcon from '../../assets/insta_select_icon.svg';
import TwitterSelectIcon from '../../assets/x_select_icon.svg';
import YoutubeSelectIcon from '../../assets/youtube_select_icon.svg';
import { spacing } from './../../theming/spacing';

const socialIconSelectStyle = { width: 28, marginRight: spacing[1] };

export const defaultSocialOptions = [
    {
        value: 'facebook',
        label: (
            <Flex align="center" justify="center">
                <img style={{ ...socialIconSelectStyle }} src={FacebookSelectIcon} alt="Facebook" />
            </Flex>
        ),
        disabled: true,
    },
    {
        value: 'instagram',
        label: (
            <Flex align="center" justify="center">
                <img style={{ ...socialIconSelectStyle }} src={InstagramSelectIcon} alt="Instagram" />
            </Flex>
        ),
    },
    {
        value: 'twitter',
        label: (
            <Flex align="center" justify="center">
                <img style={{ ...socialIconSelectStyle }} src={TwitterSelectIcon} alt="Twitter" />
            </Flex>
        ),
    },
    {
        value: 'youtube',
        label: (
            <Flex align="center" justify="center">
                <img style={{ ...socialIconSelectStyle }} src={YoutubeSelectIcon} alt="Youtube" />
            </Flex>
        ),
    },
];
