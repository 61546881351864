import { fontSize, lineHeight } from './typography';

export const tagStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 500,
    fontSize: fontSize.xs,
    lineHeight: lineHeight.md,
};

export const tagIcon = {
    borderRadius: '2px',
    width: '5px',
    height: '5px',
};
