import { useNavigate, useParams } from 'react-router-dom';
import globalNotification from '../../common/hooks/globalNotification';
import { notificationType } from '../../common/constants/notificationType';
import classes from '../../common/styles/FormStyles.module.scss';
import { Button, Form, Input, Row, Typography } from 'antd';
import { useAuthService } from '../../services/authService';
import getInputRules, { inputType, validateFullName } from '../../utils/getInputRules';
import { iconColor } from '../../theming';
import logo from '../../assets/Main_logo.png';
import { useTranslation } from 'react-i18next';

const { Text, Title } = Typography;

function Invitation() {
    const navigate = useNavigate();
    const params = useParams();

    const { finishInvitation } = useAuthService();
    const { t } = useTranslation(['invitations', 'form', 'apiResponses']);

    const onFormSubmit = async form => {
        try {
            await finishInvitation({
                id: params.id,
                token: params.token,
                newPassword: form.password,
                firstName: form.fullName.split(' ')[0],
                lastName: form.fullName.split(' ')[1],
            }).then(() => {
                navigate('/auth/sign-in');
            });
        } catch (error) {
            const errorCode = error.response?.data?.code;
            globalNotification.open({
                type: notificationType.ERROR,
                message: t(`apiResponses:${errorCode}.message`),
                description: t(`apiResponses:${errorCode}.message`),
                placement: 'top',
            });
        }
    };

    return (
        <div className={classes['auth-wrapper']}>
            <img src={logo} alt="InterCode_logo" className={classes.logo} />
            <div className={classes.content}>
                <Title
                    level={2}
                    style={{
                        margin: 0,
                    }}
                >
                    {t('create-account.title')}
                </Title>
                <Text>{t('create-account.sub')}</Text>
                <Form onFinish={onFormSubmit} style={{ width: 'clamp(300px, 85vw, 400px)' }}>
                    <Form.Item
                        name="fullName"
                        rules={[
                            {
                                validator: validateFullName,
                            },
                        ]}
                    >
                        <Input
                            prefix={
                                <i className="fi-rr-user" style={{ margin: '3px 6px 0 0', color: iconColor.primary }} />
                            }
                            size="large"
                            placeholder={t('create-account.form.placeholder.fullname')}
                        />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        rules={getInputRules(t, inputType.PASSWORD, t('create-account.form.errors.password'))}
                        hasFeedback
                    >
                        <Input.Password
                            prefix={
                                <i className="fi-rr-lock" style={{ margin: '3px 6px 0 0', color: iconColor.primary }} />
                            }
                            size="large"
                            placeholder={t('create-account.form.placeholder.password')}
                        />
                    </Form.Item>

                    <Form.Item
                        name="confirm"
                        dependencies={['password']}
                        hasFeedback
                        rules={[
                            {
                                required: true,
                                message: t('create-account.form.errors.confirm'),
                            },
                            ({ getFieldValue }) => ({
                                validator(_, value) {
                                    if (!value || getFieldValue('password') === value) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error(t('create-account.form.errors.confirm-match')));
                                },
                            }),
                        ]}
                    >
                        <Input.Password
                            prefix={
                                <i className="fi-rr-lock" style={{ margin: '3px 6px 0 0', color: iconColor.primary }} />
                            }
                            size="large"
                            placeholder={t('create-account.form.placeholder.confirm')}
                        />
                    </Form.Item>
                    <Row justify="center" align="center">
                        <Button htmlType="submit" style={{ width: '100%' }} type="primary" size="large">
                            {t('actions.confirm')}
                        </Button>
                    </Row>
                </Form>
            </div>
        </div>
    );
}

export default Invitation;
