import { useEffect } from 'react';
import { Form, Input, Button, Flex, Spin } from 'antd';
import 'react-phone-input-2/lib/bootstrap.css';
import classes from '../../common/styles/FormStyles.module.scss';
import { spacing, inputStyle } from '../../theming';
import { useMedia } from '../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import getInputRules, { inputType } from '../../utils/getInputRules';

export const DemoUserForm = ({ mode, user, loading, handleAdd, handleEdit }) => {
    const [form] = Form.useForm();
    const { t } = useTranslation(['form', 'users', 'general']);
    const { isSmallDevice } = useMedia();

    const handleSubmit = values => {
        if (mode === 'edit') {
            handleEdit(user.id, values).then(() => {
                setTimeout(() => {
                    form.resetFields();
                }, 1000);
            });
        } else {
            handleAdd(values).then(() => {
                form.resetFields();
            });
        }
    };

    useEffect(() => {
        if (mode === 'edit' && user) {
            form.resetFields();

            form.setFieldsValue({
                ...user,
            });
        } else if (mode === 'add') {
            form.resetFields();
        }
    }, [user, form, mode]);

    return (
        <Spin spinning={loading} tip="Populating demo account">
            <Form
                disabled={loading}
                name="basic"
                layout="vertical"
                autoComplete="off"
                form={form}
                onFinish={values => {
                    handleSubmit(values);
                }}
            >
                <Flex vertical>
                    <Flex
                        vertical={isSmallDevice}
                        justify="space-between"
                        align="start"
                        gap={isSmallDevice ? 0 : spacing[5]}
                        style={{ width: '100%' }}
                    >
                        <Form.Item
                            name="firstName"
                            label={t('first-name')}
                            rules={getInputRules(t, inputType.FIRST_NAME, t('errors.required'), t('errors.string'))}
                            style={{ width: '100%' }}
                        >
                            <Input
                                placeholder={t('placeholder.first-name')}
                                allowClear
                                style={{ ...inputStyle, width: '100%' }}
                            />
                        </Form.Item>
                        <Form.Item
                            name="lastName"
                            label={t('last-name')}
                            rules={getInputRules(t, inputType.LAST_NAME, t('errors.required'), t('errors.string'))}
                            style={{ width: '100%' }}
                        >
                            <Input
                                placeholder={t('placeholder.last-name')}
                                allowClear
                                style={{ ...inputStyle, width: '100%' }}
                            />
                        </Form.Item>
                    </Flex>
                    <Form.Item
                        name="email"
                        label={t('email')}
                        rules={getInputRules(t, inputType.EMAIL, t('errors.required'), t('errors.email'))}
                    >
                        <Input placeholder="example@email.com" allowClear />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label={t('placeholder.password')}
                        rules={getInputRules(
                            t,
                            inputType.PASSWORD,
                            t('form:errors.required'),
                            t('form:errors.required'),
                            mode === 'add'
                        )}
                    >
                        <Input.Password placeholder={t('placeholder.password')} allowClear />
                    </Form.Item>

                    <Form.Item>
                        <Button className={classes['submit-button']} type="primary" htmlType="submit" loading={loading}>
                            {mode === 'add' ? t('users:actions.add') : t('general:actions.simple.save')}
                        </Button>
                    </Form.Item>
                </Flex>
            </Form>
        </Spin>
    );
};
