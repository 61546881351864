import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button, Form, Input, Row } from 'antd';
import { useAuthService } from '../../services/authService';
import { useAuth } from '../../context/AuthContext';
import { notificationType } from '../../common/constants/notificationType';
import { routesPath } from '../../common/constants/routesPath';
import { iconColor, spacing } from '../../theming';
import getInputRules, { validateFullName, inputType } from '../../utils/getInputRules';

const SignUpForm = ({ openNotification, setActiveTabKey, t }) => {
    const navigate = useNavigate();
    const { setAuth } = useAuth();
    const [loading, setLoading] = useState(false);
    const [form] = Form.useForm();
    const [formValid, setFormValid] = useState(true);
    const { signUpEmailPassword } = useAuthService();
    const isInitialMount = useRef(true); // to track initial render and avoid validation on first render

    useEffect(() => {
        if (isInitialMount.current) {
            isInitialMount.current = false;
            return;
        }

        form.validateFields()
            .then(() => setFormValid(true))
            .catch(() => setFormValid(false));
    }, [formValid]);

    const onFormSubmit = async event => {
        const isFormValid = await form
            .validateFields()
            .then(() => true)
            .catch(() => false);

        if (!isFormValid) {
            setFormValid(false);
            openNotification({
                type: notificationType.ERROR,
                message: t('notification.register-form-validation-error.message'),
                description: t('notification.register-form-validation-error.description'),
                placement: 'top',
            });
            return;
        }

        try {
            setLoading(true);
            const userTokens = await signUpEmailPassword({
                firstName: event.fullName.split(' ')[0],
                lastName: event.fullName.split(' ')[1],
                email: event.email,
                password: event.password,
            });

            if (userTokens?.data) {
                const { accessToken, refreshToken, isTwoFactorEnable } = userTokens.data;

                if (isTwoFactorEnable) {
                    setAuth(accessToken, null);
                    navigate(`${routesPath.AUTH.PATH}/${routesPath.AUTH.TWO_FACTOR_AUTH}`);
                } else {
                    setAuth(accessToken, refreshToken);
                    navigate(routesPath.ROOT.CREATE_TEAM_AUTH);
                }
            }

            setLoading(false);
        } catch (error) {
            const errorCode = error.response.data?.code;

            openNotification({
                type: notificationType.ERROR,
                message: t(`apiResponses:${errorCode}.message`),
                description: t(`apiResponses:${errorCode}.description`),
                placement: 'top',
            });
            setLoading(false);
            setActiveTabKey(error.response.data?.redirectToSignUp ? '1' : '0');
        }
    };

    return (
        <Form form={form} onFinish={onFormSubmit} style={{ width: 'clamp(300px, 85vw, 400px)' }} autoComplete="off">
            <Form.Item
                name="email"
                rules={getInputRules(t, inputType.EMAIL, t('form:errors.required'), t('form:errors.email'))}
            >
                <Input
                    prefix={
                        <i className="fi-rr-envelope" style={{ margin: '3px 6px 0 0', color: iconColor.primary }} />
                    }
                    placeholder="office@company.com"
                    size="large"
                />
            </Form.Item>
            <Form.Item
                name="fullName"
                rules={[
                    {
                        validator: validateFullName,
                    },
                ]}
            >
                <Input
                    prefix={<i className="fi-rr-user" style={{ margin: '3px 6px 0 0', color: iconColor.primary }} />}
                    size="large"
                    placeholder={t('placeholder.full-name')}
                />
            </Form.Item>

            <Form.Item
                name="password"
                rules={getInputRules(t, inputType.PASSWORD, t('form-error.password-required'))}
                hasFeedback
            >
                <Input.Password
                    prefix={<i className="fi-rr-lock" style={{ margin: '3px 6px 0 0', color: iconColor.primary }} />}
                    size="large"
                    placeholder={t('placeholder.password')}
                />
            </Form.Item>

            <Form.Item
                name="confirm"
                dependencies={['password']}
                hasFeedback
                rules={[
                    {
                        required: true,
                        message: t('form-error.password-confirm'),
                    },
                    ({ getFieldValue }) => ({
                        validator(_, value) {
                            if (!value || getFieldValue('password') === value) {
                                return Promise.resolve();
                            }
                            return Promise.reject(new Error(t('form-error.password-match')));
                        },
                    }),
                ]}
            >
                <Input.Password
                    prefix={<i className="fi-rr-lock" style={{ margin: '3px 6px 0 0', color: iconColor.primary }} />}
                    size="large"
                    placeholder={t('placeholder.password-confirm')}
                />
            </Form.Item>

            <Row justify="center" align="center">
                <Button
                    loading={loading}
                    htmlType="submit"
                    style={{
                        width: '100%',
                        margin: `${spacing[1]} 0 ${spacing[3]} 0`,
                    }}
                    type="primary"
                    size="large"
                    disabled={!formValid}
                >
                    {t('buttons.register')}
                </Button>
            </Row>
        </Form>
    );
};

export default SignUpForm;
