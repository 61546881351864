import globalNotification from '../../common/hooks/globalNotification';
import { notificationType } from '../../common/constants/notificationType';
import { Upload } from 'antd';

export const beforeUpload = (file, acceptFileTypes = [], maxFileSizeMb = null, t) => {
    const isValidType = acceptFileTypes.length === 0 || acceptFileTypes.some(type => file.type === type);

    if (!isValidType) {
        globalNotification.open({
            type: notificationType.WARNING,
            message: 'Warning',
            description: (
                <p>
                    {t('form:notifications.wrong-extension')}
                    <strong> {acceptFileTypes.join(', ')}</strong>
                </p>
            ),
        });
    }

    const fileSizeInMB = file.size / (1024 * 1024);
    const isValidSize = maxFileSizeMb ? fileSizeInMB < maxFileSizeMb : true;
    if (!isValidSize) {
        globalNotification.open({
            type: notificationType.WARNING,
            message: 'Warning',
            // description: `File size is too large! Please upload file below ${maxFileSizeMb}MB`,
            description: (
                <p>
                    {t('form:notifications.fize-size')} <strong>{maxFileSizeMb}MB</strong>.{' '}
                    {t('form:notifications.select-another-file')}.
                </p>
            ),
        });
    }

    // You can reuse this method in different components by adding validation rules in parameters, like was added type and size.

    return (isValidType && isValidSize) || Upload.LIST_IGNORE;
};

export const handleFileUpload = async (path, { file, onSuccess, onProgress }, fileService, fieldName, id) => {
    // We are using different field names from property 'fieldName' to separate access and specify required options on the back-end endpoint.
    try {
        const data = {
            [fieldName]: file,
        };
        if (id) {
            data.id = id;
        }

        const response = await fileService.uploadFile(path, data, {
            onUploadProgress: progressEvent => {
                const percent = Math.round((progressEvent.loaded * 100) / progressEvent.total);
                // Update progress using onProgress callback
                onProgress({ percent });
            },
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        });
        // Call onSuccess callback when upload is successful
        onSuccess(response.data, file);
    } catch (error) {
        console.log(error);
    }
};

export const handleChange = (info, t) => {
    const { status, name, response } = info.file;
    if (status === 'done' && response) {
        globalNotification.open({
            type: notificationType.SUCCESS,
            message: 'Success',
            description: (
                <p>
                    <strong>{name}</strong> - {t('form:notifications.upload-succesfully')}.
                </p>
            ),
        });
    } // we don't handle 'error' status here because there is interceptor that does this and calls error notification
};

export const handleRemove = async (file, path, fileService, t) => {
    if (file) {
        await fileService.deleteFile(path, file.response || file.name);

        if (file.status === 'done') {
            globalNotification.open({
                type: notificationType.SUCCESS,
                message: 'Success',
                description: (
                    <p>
                        <strong>{file.name}</strong> - {t('form:notifications.removed-succesfully')}.
                    </p>
                ),
            });
        }
    }
};
