import { colorMap } from '../common/constants/colorMap';
import { primitiveColors } from '../theming';

/**
 * Calculates the average color of the colors associated with the first and last initials.
 *
 * @param {string} firstInitial - The initial of the first name.
 * @param {string} lastInitial - The initial of the last name.
 * @returns {string} The hexadecimal representation of the average color calculated from initials.
 *
 * @example
 * getInitialsColor('A', 'B');
 * returns "#808080"
 */
export const getInitialsColor = (firstInitial, lastInitial) => {
    const firstColor = colorMap[firstInitial.toUpperCase()] || primitiveColors.gray100;
    const lastColor = colorMap[lastInitial.toUpperCase()] || primitiveColors.gray100;

    const rgbToHex = rgb => {
        const [r, g, b] = rgb;
        return `#${((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1)}`;
    };

    const hexToRgb = hex => {
        const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
        return result ? [parseInt(result[1], 16), parseInt(result[2], 16), parseInt(result[3], 16)] : null;
    };

    const firstRgb = hexToRgb(firstColor);
    const lastRgb = hexToRgb(lastColor);

    const avgRgb = [
        Math.round((firstRgb[0] + lastRgb[0]) / 2),
        Math.round((firstRgb[1] + lastRgb[1]) / 2),
        Math.round((firstRgb[2] + lastRgb[2]) / 2),
    ];

    const avgColor = rgbToHex(avgRgb);

    return avgColor;
};
