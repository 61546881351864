import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Button, Form, Input, Row } from 'antd';
import { useAuthService } from '../../services/authService';
import { useAuth } from '../../context/AuthContext';
import { notificationType } from '../../common/constants/notificationType';
import { routesPath } from '../../common/constants/routesPath';
import { iconColor, textColor, spacing, coreBrandColors } from '../../theming';
import getInputRules, { inputType } from '../../utils/getInputRules';

const SignInForm = ({ preview, openNotification, setActiveTabKey, t }) => {
    const [form] = Form.useForm();
    const navigate = useNavigate();
    const { setAuth } = useAuth();
    const [loading, setLoading] = useState(false);
    const { signInEmailPassword, decryptPreviewLink } = useAuthService();

    useEffect(() => {
        if (!!preview) {
            decryptPreviewLink(preview).then(res => {
                const { data } = res;
                form.setFieldValue('email', data.split(';')[0]);
                form.setFieldValue('password', data.split(';')[1]);
            });
        }
    }, []);

    const onFormSubmit = async ({ email, password }) => {
        try {
            setLoading(true);
            const userTokens = await signInEmailPassword({ email, password });

            if (userTokens?.data) {
                const { accessToken, refreshToken, isTwoFactorEnable } = userTokens.data;

                if (isTwoFactorEnable) {
                    setAuth(accessToken, null);
                    navigate(`${routesPath.AUTH.PATH}/${routesPath.AUTH.TWO_FACTOR_AUTH}`);
                } else {
                    setAuth(accessToken, refreshToken);
                    navigate(routesPath.ROOT.CREATE_TEAM_AUTH);
                }
            }

            setLoading(false);
        } catch (error) {
            const errorCode = error.response.data?.code;

            setLoading(false);
            openNotification({
                type: notificationType.ERROR,
                message: t(`apiResponses:${errorCode}.message`),
                description: t(`apiResponses:${errorCode}.description`),
                placement: 'top',
            });

            setActiveTabKey(error.response.data?.redirectToSignUp ? '1' : '0');
        }
    };

    return (
        <Form form={form} onFinish={onFormSubmit} style={{ width: 'clamp(300px, 85vw, 400px)' }}>
            <Form.Item
                name="email"
                rules={getInputRules(t, inputType.EMAIL, t('form:errors.required'), t('form:errors.email'))}
            >
                <Input
                    prefix={
                        <i className="fi-rr-envelope" style={{ margin: '3px 6px 0 0', color: iconColor.primary }} />
                    }
                    placeholder="office@company.com"
                    size="large"
                />
            </Form.Item>
            <Form.Item
                style={{ marginBottom: '1rem' }}
                name="password"
                rules={getInputRules(t, inputType.PASSWORD, t('form:errors.required'))}
            >
                <Input.Password
                    prefix={<i className="fi-rr-lock" style={{ margin: '3px 6px 0 0', color: iconColor.primary }} />}
                    placeholder={t('form:placeholder.password')}
                    size="large"
                />
            </Form.Item>
            <Row style={{ height: '2rem' }} justify="end" align="center">
                <Link to={'/auth/forgot-password'} style={{ color: textColor.placeholder }}>
                    {t('auth:forgot')}
                </Link>
            </Row>
            <Row justify="center" align="center">
                <Button
                    loading={loading}
                    htmlType="submit"
                    style={{
                        width: '100%',
                        margin: `${spacing[1]} 0 ${spacing[3]} 0`,
                        backgroundColor: coreBrandColors.blueRibbon,
                    }}
                    type="primary"
                    size="large"
                >
                    {t('auth:buttons.login')}
                </Button>
            </Row>
        </Form>
    );
};

export default SignInForm;
