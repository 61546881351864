import React from 'react';
import { UploadOutlined } from '@ant-design/icons';
import { Upload, Button } from 'antd';
import { useFileService } from '../../services/fileService';
import { handleChange, handleFileUpload, handleRemove } from './UploadHelper';
import { useTranslation } from 'react-i18next';

function ClickToUploadFileButton({ title, fieldName, isMultiple, path, fileType }) {
    const { t } = useTranslation(['form']);

    const fileService = useFileService();

    const props = {
        accept: fileType, // This parameter will prevent the selection of files of different types in the OS window. For uploading any types use empty string ''.
        multiple: isMultiple, // If set "false" - will prevent multiple selection in the OS window.
    };

    return (
        <Upload
            {...props}
            customRequest={fileInfo => handleFileUpload(path, fileInfo, fileService, fieldName)}
            onChange={fileInfo => handleChange(fileInfo, t)}
            onRemove={fileInfo => handleRemove(fileInfo, path, fileService, t)}
        >
            <Button icon={<UploadOutlined />}>Click to Upload {title}</Button>
        </Upload>
    );
}

export default ClickToUploadFileButton;
