export const radius = {
    none: '0px',
    sm: '4px',
    md: '8px',
    lg: '16px',
    exs: '20px',
    xl: '24px',
    _2xl: '32px',
    _3xl: '40px',
    _4xl: '48px',
    huge: '9999px',
};
