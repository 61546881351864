import { primitiveColors } from '../../theming/colors';

export const leadStatus = [
    {
        value: 'Lead',
        label: 'Lead',
        color: primitiveColors.volcano500,
        backgroundColor: primitiveColors.volcano50,
    },
    {
        value: 'Prospect',
        label: 'Prospect',
        color: primitiveColors.magenta500,
        backgroundColor: primitiveColors.magenta50,
    },
    {
        value: 'Opportunity',
        label: 'Opportunity',
        color: primitiveColors.purple500,
        backgroundColor: primitiveColors.purple50,
    },
    {
        value: 'Client',
        label: 'Client',
        color: primitiveColors.vitalize500,
        backgroundColor: primitiveColors.vitalize50,
    },
];
