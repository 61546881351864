import { iconColor, spacing } from '../../../theming';

export default function FilterIcon({ filtered }) {
    return (
        <i
            className="fi fi-sr-filter"
            style={{
                color: filtered ? iconColor.active : iconColor.secondary,
                width: spacing[2],
                height: spacing[3],
            }}
        ></i>
    );
}
