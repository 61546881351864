import memoize from 'memoize';

import { getToken } from './tokenService';
import { axiosRefTokenInst } from './axiosService';
import { updateToken } from './tokenService';
import { REFRESH_TOKEN, TOKEN } from '../common/constants/localStorage';

const refreshTokenFn = async () => {
    const response = await axiosRefTokenInst.post('/auth/refresh-token', {
        refreshToken: getToken(REFRESH_TOKEN),
    });

    if (response) {
        updateToken(TOKEN, response.data.accessToken);
        updateToken(REFRESH_TOKEN, response.data.refreshToken);
    }
    return response?.data;
};

const maxAge = 10000;

export const memoizedRefreshToken = memoize(refreshTokenFn, {
    maxAge,
});
