export const regexPatterns = {
    latin: /^[a-zA-Z\s]*$/,
    latinNumbers: /^[a-zA-Z0-9\s]*$/,
    latinSpecial: /^[a-zA-Z\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
    latinNumbersSpecials: /^[a-zA-Z0-9\s!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
    latinWithSpace: /^[a-zA-Z\s]*$/,
    latinAddress: /^[a-zA-Z0-9\s,.'\-]*$/,
    cyrillic: /^[a-zA-Z\s\u0400-\u04FF]*$/,
    cyrillicNumbers: /^[a-zA-Z0-9\s\u0400-\u04FF]*$/,
    cyrillicSpecial: /^[a-zA-Z\s\u0400-\u04FF!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
    cyrillicNumbersSpecials: /^[a-zA-Z0-9\s\u0400-\u04FF!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
    cyrillicWithSpace: /^[a-zA-Z\s\u0400-\u04FF]*$/,
    cyrillicAddress: /^[a-zA-Z0-9\s\u0400-\u04FF,.'\-]*$/,
    numbers: /^[0-9]*$/,
    numbersSpecials: /^[0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]*$/,
    email: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
    zipCode: /^[A-Za-z0-9\s\-]{3,10}$/,
    cyrillicFullName: /^[a-z\u0400-\u04FF]+([-']?[a-z\u0400-\u04FF]+)*$/i,
    latinFullName: /^[a-z]+([-']?[a-z]+)*$/i,
};

export default function getRegexPattern(patternType) {
    return regexPatterns[patternType] || null;
}
