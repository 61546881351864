import { coreBrandColors, primitiveColors } from './colors';

export const backgroundColor = {
    page: primitiveColors.gray50,
    primary: primitiveColors.gray50,
    secondary: primitiveColors.gray100,
    secondaryAlt: primitiveColors.gray200,
    white: primitiveColors.gray0,
    subtle: primitiveColors.gray200,
    Action: primitiveColors.blue500,
    ActionHover: primitiveColors.blue600,
    ActionActive: primitiveColors.blue700,
    ActionSecondary: primitiveColors.mint500,
    ActionSecondaryHover: primitiveColors.mint600,
    ActionSecondaryActive: primitiveColors.mint700,
    error: primitiveColors.red500,
    errorHover: primitiveColors.red600,
    errorActive: primitiveColors.red700,
    magenta: primitiveColors.magenta50,
    ocean: primitiveColors.ocean50,
    cyan: primitiveColors.cyan50,
    geekblue: primitiveColors.geekblue50,
    gold: primitiveColors.gold50,
    vitalize: primitiveColors.vitalize50,
    lime: primitiveColors.lime50,
    nectarine: primitiveColors.nectarine50,
    purple: primitiveColors.purple50,
    ruby: primitiveColors.ruby50,
    volcano: primitiveColors.volcano50,
};

export const textColor = {
    primary: primitiveColors.gray900,
    secondary: primitiveColors.gray700,
    secondaryAlt: primitiveColors.gray500,
    subtle: primitiveColors.gray400,
    white: primitiveColors.gray0,
    inactive: primitiveColors.gray200,
    placeholder: primitiveColors.gray300,
    brandPrimary: coreBrandColors.blueRibbon,
    brandSecondary: coreBrandColors.mintMorning,
    link: primitiveColors.blue500,
    linkHover: primitiveColors.blue600,
    linkActive: primitiveColors.blue700,
    success: primitiveColors.green500,
    warning: primitiveColors.orange500,
    error: primitiveColors.red500,
    magenta: primitiveColors.magenta500,
    ocean: primitiveColors.ocean500,
    cyan: primitiveColors.cyan500,
    geekblue: primitiveColors.geekblue500,
    gold: primitiveColors.gold500,
    vitalize: primitiveColors.vitalize500,
    lime: primitiveColors.lime500,
    nectarine: primitiveColors.nectarine500,
    purple: primitiveColors.purple500,
    ruby: primitiveColors.ruby500,
    volcano: primitiveColors.volcano500,
};

export const borderColor = {
    primary: primitiveColors.gray100,
    secondary: primitiveColors.gray200,
    secondaryAlt: primitiveColors.gray400,
    strong: primitiveColors.gray700,
    white: primitiveColors.gray0,
    actionPrimary: primitiveColors.blue400,
    actionPrimaryHover: primitiveColors.blue500,
    actionSecondary: primitiveColors.blue200,
    actionSecondaryHover: primitiveColors.blue300,
    success: primitiveColors.green500,
    warning: primitiveColors.orange500,
    error: primitiveColors.red500,
    magenta: primitiveColors.magenta100,
    ocean: primitiveColors.ocean100,
    cyan: primitiveColors.cyan100,
    geekblue: primitiveColors.geekblue100,
    gold: primitiveColors.gold100,
    vitalize: primitiveColors.vitalize100,
    lime: primitiveColors.lime100,
    nectarine: primitiveColors.nectarine100,
    purple: primitiveColors.purple100,
    ruby: primitiveColors.ruby100,
    volcano: primitiveColors.volcano100,
};

export const iconColor = {
    primary: primitiveColors.gray400,
    secondary: primitiveColors.gray200,
    secondaryAlt: primitiveColors.gray500,
    white: primitiveColors.gray0,
    dark: primitiveColors.gray700,
    active: primitiveColors.blue500,
    inactive: primitiveColors.gray100,
    brandPrimary: coreBrandColors.blueRibbon,
    brandSecondary: coreBrandColors.mintMorning,
    link: primitiveColors.blue500,
    success: primitiveColors.green500,
    warning: primitiveColors.orange500,
    error: primitiveColors.red500,
};
