import { Modal, Space, Typography, Tag, List, Flex } from 'antd';
import dayjs from 'dayjs';
import processUrl from '../../../utils/processUrl';
import { leadState } from '../../constants/leadState';
import classes from './SearchModal.module.scss';
import { spacing } from './../../../theming';
import { useTranslation } from 'react-i18next';

function SearchModal({ isModalOpen, modalHandler, selectedItem }) {
    const { t } = useTranslation(['globalSearch']);
    const colors = ['blue', 'red'];

    const getStateColor = state => {
        const foundState = leadState.find(s => s.value === state);
        return foundState ? foundState.color : leadState[1].color;
    };

    const renderName = () => {
        if (selectedItem.name) {
            return (
                <Space key={selectedItem.name}>
                    <Typography.Text className={classes.bold}>{t('modal.name')}: </Typography.Text>
                    <Typography.Text>{selectedItem.name}</Typography.Text>
                </Space>
            );
        }
        if (selectedItem.firstName) {
            return (
                <Space>
                    <Typography.Text className={classes.bold}>{t('modal.name')}: </Typography.Text>
                    <Flex gap={spacing[1]}>
                        <Typography.Text key={selectedItem.firstName + selectedItem.lastName}>
                            {selectedItem.firstName} {selectedItem.lastName}
                        </Typography.Text>
                        {selectedItem.status && (
                            <Tag
                                color={selectedItem.status.primaryColor}
                                style={{ marginRight: 0 }}
                                key={selectedItem.status.id}
                            >
                                {selectedItem.status.name}
                            </Tag>
                        )}
                        {selectedItem.state && (
                            <Tag
                                color={getStateColor(selectedItem.state)}
                                style={{ marginRight: 0 }}
                                key={selectedItem.state}
                            >
                                {selectedItem.state}
                            </Tag>
                        )}
                        {selectedItem.userRoles &&
                            selectedItem.userRoles.map((userRole, index) => {
                                return (
                                    userRole.role?.name && (
                                        <Tag
                                            color={colors[index % colors.length]}
                                            style={{ marginRight: 0 }}
                                            key={userRole.role.name}
                                        >
                                            {userRole.role.name}
                                        </Tag>
                                    )
                                );
                            })}
                    </Flex>
                </Space>
            );
        }
        return null;
    };

    const renderPhoneNumbers = () => {
        if (selectedItem.phones) {
            return (
                <>
                    <Typography.Text className={classes.bold}>{t('modal.phone')}:</Typography.Text>
                    <List style={{ gap: spacing[0.5] }} split={false}>
                        {selectedItem.phones.map((phone, index) => {
                            return (
                                <List.Item key={phone.id} style={{ padding: `${spacing[0.5]} 0` }}>
                                    <Typography.Text>
                                        {`#${index + 1}`}&nbsp;
                                        <a href={`tel:${phone}`} target="_blank" rel="noreferrer">
                                            {phone}
                                        </a>
                                    </Typography.Text>
                                </List.Item>
                            );
                        })}
                    </List>
                </>
            );
        }
        if (selectedItem.phoneNumber) {
            return (
                <>
                    <Typography.Text className={classes.bold}>{t('modal.phone')}:</Typography.Text>
                    <List style={{ gap: spacing[0.5] }} split={false}>
                        {selectedItem.phoneNumber.map((phone, index) => {
                            return (
                                <List.Item key={phone.id} style={{ padding: `${spacing[0.5]} 0` }}>
                                    <Typography.Text>
                                        {`#${index + 1}`}&nbsp;
                                        <a href={`tel:${phone}`} target="_blank" rel="noreferrer">
                                            {phone}
                                        </a>
                                    </Typography.Text>
                                </List.Item>
                            );
                        })}
                    </List>
                </>
            );
        }
        return null;
    };

    const renderBusiness = () => {
        if (selectedItem.business) {
            return (
                <>
                    <Typography.Text className={classes.bold}>{t('modal.businesses')}: </Typography.Text>
                    <List style={{ gap: spacing[0.5] }}>
                        {selectedItem.business.map((business, index) => {
                            return (
                                <List.Item key={business.id} style={{ padding: `${spacing[0.5]} 0` }}>
                                    <Typography.Text>{`#${index + 1} ${business.name}`}</Typography.Text>
                                </List.Item>
                            );
                        })}
                    </List>
                </>
            );
        }
        return null;
    };

    const renderSocialMedia = () => {
        if (selectedItem.socialMedia) {
            if (Array.isArray(selectedItem.socialMedia) && selectedItem.socialMedia.filter(link => link).length > 0) {
                return (
                    <>
                        <Typography.Text className={classes.bold}>{t('modal.social-media')}:</Typography.Text>
                        <List style={{ gap: spacing[0.5] }} split={false}>
                            {selectedItem.socialMedia
                                .filter(link => link)
                                .map((link, index) => {
                                    return (
                                        <List.Item key={index} style={{ padding: `${spacing[0.5]} 0` }}>
                                            <Typography.Text>
                                                {`#${index + 1}`}&nbsp;
                                                <a href={link} target="_blank" rel="noreferrer">
                                                    {processUrl(link)}
                                                </a>
                                            </Typography.Text>
                                        </List.Item>
                                    );
                                })}
                        </List>
                    </>
                );
            }
            if (
                !Array.isArray(selectedItem.socialMedia) &&
                Object.entries(selectedItem.socialMedia).filter(([_, link]) => link).length > 0
            ) {
                return (
                    <>
                        <Typography.Text className={classes.bold}>{t('modal.social-media')}:</Typography.Text>
                        <List style={{ gap: spacing[0.5] }} split={false}>
                            {Object.entries(selectedItem.socialMedia)
                                .filter(([_, link]) => link)
                                .map(([name, link], index) => {
                                    return (
                                        <List.Item key={name} style={{ padding: `${spacing[0.5]} 0` }}>
                                            <Typography.Text>
                                                {`#${index + 1}`}&nbsp;
                                                <a href={link} target="_blank" rel="noreferrer">
                                                    {processUrl(name)}
                                                </a>
                                            </Typography.Text>
                                        </List.Item>
                                    );
                                })}
                        </List>
                    </>
                );
            }
        }
        return null;
    };

    return (
        <Modal
            title={t('modal.details')}
            open={isModalOpen}
            centered
            closable={false}
            cancelButtonProps={{
                style: { display: 'none' },
            }}
            onOk={modalHandler}
            okText={t('modal.close')}
            className={classes.modal}
        >
            <Flex vertical gap={8}>
                {renderName()}
                {selectedItem.position && (
                    <Space key={selectedItem.position}>
                        <Typography.Text className={classes.bold}>{t('modal.position')}: </Typography.Text>
                        <Typography.Text>{selectedItem.position}</Typography.Text>
                    </Space>
                )}
                {selectedItem.email && (
                    <Space key={selectedItem.email}>
                        <Typography.Text className={classes.bold}>{t('modal.email')}: </Typography.Text>
                        <Typography.Text>
                            <a href={`mailto:${selectedItem.email}`} target="_blank" rel="noreferrer">
                                {selectedItem.email}
                            </a>
                        </Typography.Text>
                    </Space>
                )}
                {renderPhoneNumbers()}
                {selectedItem.address && (
                    <Flex>
                        <Flex vertical>
                            {selectedItem.address.addressOne && (
                                <Space key={selectedItem.address.addressOne}>
                                    <Typography.Text className={classes.bold}>
                                        {t('modal.address-one')}:&nbsp;
                                    </Typography.Text>
                                    <span>{selectedItem.address.addressOne}</span>
                                </Space>
                            )}
                            {selectedItem.address.addressTwo && (
                                <Space key={selectedItem.address.addressTwo}>
                                    <Typography.Text className={classes.bold}>
                                        {t('modal.address-two')}:&nbsp;
                                    </Typography.Text>
                                    <span>{selectedItem.address.addressTwo}</span>
                                </Space>
                            )}
                            <Space key={selectedItem.address.country}>
                                <Typography.Text className={classes.bold}>{t('modal.country')}:&nbsp;</Typography.Text>
                                <span>{selectedItem.address.country}</span>
                            </Space>

                            <Space key={selectedItem.address.state}>
                                <Typography.Text className={classes.bold}>{t('modal.state')}:&nbsp;</Typography.Text>
                                <span>{selectedItem.address.state}</span>
                            </Space>
                            <Space key={selectedItem.address.city}>
                                <Typography.Text className={classes.bold}>{t('modal.city')}:&nbsp;</Typography.Text>
                                <span>{selectedItem.address.city}</span>
                            </Space>
                            <Space key={selectedItem.address.zipCode}>
                                <Typography.Text className={classes.bold}>{t('modal.zip')}:&nbsp;</Typography.Text>
                                <span>{selectedItem.address.zipCode}</span>
                            </Space>
                        </Flex>
                    </Flex>
                )}
                {selectedItem.location && (
                    <Space key={selectedItem.location}>
                        <Typography.Text className={`${classes.bold} ${classes.nowrap}`}>
                            {t('modal.location')}:
                        </Typography.Text>
                        <Typography.Text>{selectedItem.location}</Typography.Text>
                    </Space>
                )}
                {renderBusiness()}
                {renderSocialMedia()}
                {selectedItem.source && (
                    <Space key={selectedItem.source}>
                        <Typography.Text className={classes.bold}>{t('modal.source')}: </Typography.Text>
                        <Typography.Text>{selectedItem.source}</Typography.Text>
                    </Space>
                )}
                {selectedItem.description && (
                    <Flex gap={spacing[1]} key={selectedItem.description}>
                        <Typography.Text className={`${classes.bold} ${classes.nowrap}`}>
                            {t('modal.description')}:
                        </Typography.Text>
                        <Typography.Text> {selectedItem.description}</Typography.Text>
                    </Flex>
                )}
                {selectedItem.createdAt && (
                    <Space key={selectedItem.createdAt}>
                        <Typography.Text className={classes.bold}>{t('modal.created')}: </Typography.Text>
                        <Typography.Text>{dayjs(selectedItem.createdAt).format('YYYY-MM-DD')}</Typography.Text>
                    </Space>
                )}
                {selectedItem.updatedAt && (
                    <Space key={selectedItem.updatedAt}>
                        <Typography.Text className={classes.bold}>{t('modal.updated')}: </Typography.Text>
                        <Typography.Text>{dayjs(selectedItem.updatedAt).format('YYYY-MM-DD')}</Typography.Text>
                    </Space>
                )}
            </Flex>
        </Modal>
    );
}

export default SearchModal;
