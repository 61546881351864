import { primitiveColors } from '../../theming';

export const teamStatus = [
    {
        label: 'Active',
        value: 'Active',
        color: primitiveColors.vitalize500,
        backgroundColor: primitiveColors.vitalize50,
        borderColor: primitiveColors.vitalize,
    },
    {
        label: 'Inactive',
        value: 'Inactive',
        color: primitiveColors.gray500,
        backgroundColor: primitiveColors.gray50,
        borderColor: primitiveColors.gray100,
    },
    {
        label: 'Pending',
        value: 'Pending',
        color: primitiveColors.magenta500,
        backgroundColor: primitiveColors.magenta50,
        borderColor: primitiveColors.magenta100,
    },
];
