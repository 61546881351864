import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, notification, Typography, Flex } from 'antd';
import { useAuthService } from '../../services/authService';
import { useAuth } from '../../context/AuthContext';
import { notificationType } from '../../common/constants/notificationType';
import { routesPath } from '../../common/constants/routesPath';
import classes from './TwoFactorAuth.module.scss';
import TwoFactorAuthForm from '../../components/auth/TwoFactorAuthForm.js';
import { useTranslation } from 'react-i18next';
import PageContainer from './../../common/components/PageContainer';
import { useMediaQuery } from '@uidotdev/usehooks';
import { primitiveColors, spacing } from './../../theming';

export default function TwoFactorAuth() {
    const [api, contextHolder] = notification.useNotification();
    const navigate = useNavigate();
    const { t } = useTranslation(['settings', 'apiResponses']);
    const mobileDevicesBreakpoint = useMediaQuery('only screen and (max-width : 840px)');

    const { twoFactorAuth } = useAuthService();
    const { setAuth } = useAuth();
    const [loading, setLoading] = useState(false);

    const openNotification = ({ type = 'error', message = '', description = '', placement = 'bottomRight' }) => {
        api[type]({
            message: message,
            description: description,
            placement: placement,
        });
    };

    const onBackClick = () => {
        setAuth(null, null);
        navigate(routesPath.AUTH.PATH);
    };

    const onFormSubmit = async form => {
        try {
            setLoading(true);
            const twoFactorTokens = await twoFactorAuth(form.code);

            if (twoFactorTokens?.data) {
                const { accessToken, refreshToken } = twoFactorTokens.data;
                setAuth(accessToken, refreshToken);
                navigate(routesPath.ROOT.PATH);
            }
            setLoading(false);
        } catch (error) {
            setLoading(false);
            openNotification({
                type: notificationType.ERROR,
                message: error.response?.data?.message ?? error.response.status,
                description: error.response?.data?.description ?? error.response.statusText,
            });
        }
    };

    return (
        <PageContainer
            style={{
                backgroundColor: mobileDevicesBreakpoint ? 'transparent' : primitiveColors.gray0,
                padding: mobileDevicesBreakpoint ? spacing[1] : spacing[4],
            }}
        >
            {contextHolder}
            <Flex vertical align="center" justify="center">
                <Card title={t('settings:2FA-title')} bordered={false} style={{ width: 'clamp(280px, 85vw, 470px)' }}>
                    <div className={classes.content}>
                        <Typography.Text>{t('settings:2FA.page.description')}</Typography.Text>
                        <TwoFactorAuthForm
                            loading={loading}
                            showCancel={true}
                            submitDisabled={false}
                            onBackClick={onBackClick}
                            onFormSubmit={onFormSubmit}
                            t={t}
                        />
                    </div>
                </Card>
            </Flex>
        </PageContainer>
    );
}
