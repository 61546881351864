export const getStatusModalTitle = (isCanceled, language, t) => {
    const baseTitle = t('status-modal.title');
    const statusTitle = isCanceled ? t('status-modal.title-is-cancelled') : t('status-modal.title-is-not-cancelled');

    if (language === 'uk') {
        return `${statusTitle} ${baseTitle}`;
    }

    return `${baseTitle} ${statusTitle}`;
};

export const getButtonText = (isCanceled, userPriority, subscription, t) => {
    if (isCanceled) {
        return 'Renew Plan';
    }

    if (userPriority === subscription?.priority) {
        return 'Selected Plan';
    }

    return 'Select Plan';
};

export const extractFeatureName = featureString => {
    const match = featureString.match(/"name":"([^"]+)"/);
    return match ? match[1] : null;
};
