import React, { useEffect, useState } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { Layout, ConfigProvider, Flex, Button, Badge } from 'antd';
import UserIconDropdown from './common/components/userIconDropdown/UserIconDropdown';
import LeftMenu from './common/components/LeftMenu/LeftMenu';
import { useAuth } from './context/AuthContext';
import AppLoader from './common/components/AppLoader';
import { routesPath } from './common/constants/routesPath';
import globalRouter from './routes/GlobalRouter';
import GlobalSearch from './common/components/GlobalSearch/GlobalSearch';
import { spacing, fontSize, backgroundColor, iconColor, primitiveColors } from './theming';
import { useMedia } from './hooks/useMediaQuery';
import MobileMenu from './common/components/MobileMenu/MobileMenu';
import logo from './assets/Big_logo_for_light_Theme.png';
import icon from './common/styles/Icon.module.scss';
import ButtonIcon from './common/components/buttonIcon/ButtonIcon';
import { AnimatePresence, motion } from 'framer-motion';
import { globalSearchVariants, headerVariants, contentVariants } from './common/animationVariants';
import { themeConfig } from './theming/themeConfig';
import './App.scss';
import { useSSR, useTranslation } from 'react-i18next';
import { TeamsProvider } from './context/TeamsContext';

const { Header, Content, Sider } = Layout;

function App() {
    const { i18n } = useTranslation();

    useSSR(window.initialI18nStore, i18n.language);

    const [collapsed, setCollapsed] = useState(false);
    const [openMobileMenu, setOpenMobileMenu] = useState(false);
    const [showSearchInput, setShowSearchInput] = useState(false);

    const { isSmallDevice } = useMedia();
    const { fetchUser, isUserLoading, currentUser } = useAuth();

    const currentUserTeam = currentUser?.memberships.find(memb => memb.value === currentUser.teamId);

    const navigate = useNavigate();
    const location = useLocation();
    globalRouter.navigate = navigate;

    useEffect(() => {
        fetchUser();
    }, [fetchUser]);

    useEffect(() => {
        if (location.pathname === routesPath.ROOT.PATH) {
            navigate(routesPath.ROOT.DASHBOARD);
        }
    }, [location, navigate]);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth <= 1085) {
                setCollapsed(true);
            } else {
                setCollapsed(false);
            }
        };

        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    return !isUserLoading ? (
        <ConfigProvider theme={themeConfig}>
            <TeamsProvider>
                <Layout className="main-wrapper">
                    {!isSmallDevice && currentUserTeam && (
                        <Sider
                            collapsedWidth={isSmallDevice ? 0 : 80}
                            collapsible
                            collapsed={collapsed}
                            width={isSmallDevice ? '100%' : 250}
                            trigger={null}
                            theme={isSmallDevice ? 'light' : 'dark'}
                            style={{
                                padding: collapsed ? `${spacing[2]} ${spacing[1]}` : `${spacing[4]} ${spacing[2]}`,
                                height: '100%',
                            }}
                        >
                            <LeftMenu
                                isCollapsed={collapsed}
                                collapseTrigger={setCollapsed}
                                theme={isSmallDevice ? 'light' : 'dark'}
                            />
                        </Sider>
                    )}
                    <Layout>
                        <motion.div
                            variants={headerVariants}
                            initial="collapsed"
                            exit="collapsed"
                            animate={showSearchInput && isSmallDevice ? 'expanded' : 'collapsed'}
                            transition={{ duration: 0.3 }}
                            style={{
                                backgroundColor: currentUser.teamId ? primitiveColors.gray25 : primitiveColors.blue25,
                            }}
                        >
                            {currentUserTeam && (
                                <Header className="header">
                                    <div className="notifications-user">
                                        {isSmallDevice && (
                                            <Flex gap={spacing[3]}>
                                                <UserIconDropdown />
                                                <Button
                                                    type="link"
                                                    size="large"
                                                    shape="circle"
                                                    onClick={() => {
                                                        setShowSearchInput(!showSearchInput);
                                                    }}
                                                >
                                                    {showSearchInput ? (
                                                        <i className="fi fi-rr-cross" />
                                                    ) : (
                                                        <i className="fi fi-rr-search" />
                                                    )}
                                                </Button>
                                            </Flex>
                                        )}

                                        {!isSmallDevice && (
                                            <div style={{ position: 'relative' }}>
                                                <Button
                                                    size="small"
                                                    type="primary"
                                                    style={{
                                                        position: 'absolute',
                                                        left: `-46px`,
                                                        top: '10px',
                                                        zIndex: 2,
                                                        outline: '3px solid white',
                                                        fontSize: fontSize.md,
                                                    }}
                                                    shape="circle"
                                                    onClick={() => {
                                                        setCollapsed(!collapsed);
                                                    }}
                                                >
                                                    {collapsed ? (
                                                        <i
                                                            className={`fi fi-rr-angle-small-right ${icon.sm}`}
                                                            style={{
                                                                marginBottom: '2px',
                                                            }}
                                                        />
                                                    ) : (
                                                        <i
                                                            className={`fi fi-rr-angle-small-left ${icon.sm}`}
                                                            style={{
                                                                marginBottom: '2px',
                                                            }}
                                                        />
                                                    )}
                                                </Button>
                                                <GlobalSearch />
                                            </div>
                                        )}

                                        {isSmallDevice && (
                                            <Flex align="center">
                                                <img
                                                    alt={'Intercode logo'}
                                                    src={logo}
                                                    style={{
                                                        width: 'fit-content',
                                                        maxWidth: '100%',
                                                    }}
                                                />
                                            </Flex>
                                        )}

                                        <Flex gap={spacing[3]} align="center">
                                            {/* <Badge dot style={{ padding: 6 }}>
                                                <ButtonIcon
                                                    type="link"
                                                    style={{
                                                        position: 'relative',
                                                        backgroundColor: backgroundColor.page,
                                                        padding: spacing[0.5],
                                                        top: 0,
                                                        right: 0,
                                                    }}
                                                    icon="fi fi-sr-bell"
                                                />
                                            </Badge> */}

                                            {!isSmallDevice && <UserIconDropdown />}

                                            {isSmallDevice && (
                                                <>
                                                    <Button
                                                        type="link"
                                                        size="large"
                                                        onClick={() => setOpenMobileMenu(!openMobileMenu)}
                                                    >
                                                        <i
                                                            className={`fi fi-rr-bars-staggered ${icon.md}`}
                                                            style={{ marginTop: 0, color: iconColor.primary }}
                                                        ></i>
                                                    </Button>
                                                    <MobileMenu
                                                        isMobileMenuOpen={openMobileMenu}
                                                        toggleMenu={setOpenMobileMenu}
                                                    />
                                                </>
                                            )}
                                        </Flex>
                                    </div>
                                </Header>
                            )}
                            <AnimatePresence>
                                {showSearchInput && isSmallDevice && (
                                    <motion.div
                                        initial="hidden"
                                        animate="visible"
                                        exit="hidden"
                                        variants={globalSearchVariants}
                                        transition={{ duration: 0.3 }}
                                        style={{
                                            padding: spacing[2],
                                            backgroundColor: backgroundColor.white,
                                        }}
                                    >
                                        <GlobalSearch />
                                    </motion.div>
                                )}
                            </AnimatePresence>
                        </motion.div>

                        <Content
                            className="content"
                            style={{
                                backgroundColor: currentUser.teamId ? primitiveColors.gray25 : primitiveColors.blue25,
                            }}
                        >
                            <motion.div
                                variants={contentVariants}
                                initial={isSmallDevice && 'hidden'}
                                animate="visible"
                                exit="hidden"
                            >
                                <Outlet />
                            </motion.div>
                        </Content>
                    </Layout>
                </Layout>
            </TeamsProvider>
        </ConfigProvider>
    ) : (
        <AppLoader />
    );
}

export default App;
