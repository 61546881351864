import { Button, Dropdown, Flex, Typography } from 'antd';
import { fontSize, menuActionText, primitiveColors, spacing, textColor, textStandard } from '../../../theming';
import CustomAvatar from '../Avatar';
import dayjs from 'dayjs';
import TextArea from 'antd/es/input/TextArea';
import icon from '../../styles/Icon.module.scss';
import classes from './CommentsSection.module.scss';
import 'dayjs/locale/en';
import 'dayjs/locale/uk';
import { useTranslation } from 'react-i18next';
import { assetsConstants } from '../../constants/assetsConstants';

export const Comment = ({
    comment,
    editKey,
    setEditKey,
    editLoading,
    editMessage,
    setEditMessage,
    onClickDelete,
    handleEditComment,
    currentUser,
}) => {
    const { t, i18n } = useTranslation(['comments', 'general']);

    return (
        <Flex vertical gap={spacing[1]} key={comment.id} style={{ width: '100%' }}>
            <Flex justify="space-between" align="center">
                <Flex gap={spacing[1]} align="center">
                    <CustomAvatar
                        key={comment.id}
                        size={32}
                        photoName={comment.user?.photo}
                        firstName={comment.user?.firstName || ''}
                        lastName={comment.user?.lastName || ''}
                        controller={'users'}
                    />

                    <Flex vertical>
                        <Flex gap={spacing[1]} align="center">
                            <Typography.Text
                                style={{
                                    fontSize: fontSize.xs,
                                    fontWeight: 500,
                                    color: primitiveColors.gray700,
                                }}
                            >
                                {`${comment.user ? comment.user.firstName : comment.userEmail} ${comment.user ? comment.user.lastName : ''}`}
                            </Typography.Text>
                            {!comment.user && (
                                <Typography.Text
                                    style={{ ...textStandard, fontSize: fontSize.xxs, color: primitiveColors.gray200 }}
                                >
                                    {`(${t('deleted')})`}
                                </Typography.Text>
                            )}
                        </Flex>
                        <Typography.Text
                            style={{
                                color: primitiveColors.gray400,
                                fontSize: fontSize.xxs,
                                fontWeight: 500,
                            }}
                        >
                            {dayjs(comment.createdAt)
                                .locale(i18n.language)
                                .format(`MMM D YYYY [${t('general:prepositions.at')}] HH:mm`)}
                        </Typography.Text>
                    </Flex>
                </Flex>

                {handleEditComment &&
                    onClickDelete &&
                    (currentUser.email === comment.userEmail ||
                        currentUser.userRoleNames.some(role => assetsConstants.ADMIN_ROLES.includes(role))) && (
                        <Dropdown
                            placement="bottom"
                            menu={{
                                items: [
                                    currentUser.email === comment.userEmail && {
                                        key: 1,
                                        icon: (
                                            <i
                                                className={`fi fi-rr-edit ${icon.md}`}
                                                style={{ color: textColor.subtle }}
                                            />
                                        ),
                                        label: (
                                            <span
                                                gap={spacing[1]}
                                                align="center"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    setEditKey(comment.id);
                                                    setEditMessage(comment.content);
                                                }}
                                                style={{
                                                    ...menuActionText,
                                                }}
                                            >
                                                {t('general:actions.simple.edit')}
                                            </span>
                                        ),
                                    },
                                    {
                                        key: 2,
                                        icon: (
                                            <i
                                                className={`fi fi-rr-trash ${icon.md}`}
                                                style={{ color: textColor.subtle }}
                                            />
                                        ),
                                        label: (
                                            <span
                                                gap="8px"
                                                align="center"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    onClickDelete(comment.id);
                                                }}
                                                style={{
                                                    ...menuActionText,
                                                }}
                                            >
                                                {t('general:actions.simple.delete')}
                                            </span>
                                        ),
                                    },
                                ],
                            }}
                        >
                            <span onClick={e => e.preventDefault()} style={{ cursor: 'pointer' }}>
                                <i className={`fi fi-rr-menu-dots ${icon.md}`} style={{ color: textColor.subtle }} />
                            </span>
                        </Dropdown>
                    )}
            </Flex>

            {editKey === comment.id ? (
                <div style={{ position: 'relative' }}>
                    <TextArea
                        value={editMessage}
                        rows={1}
                        onChange={e => setEditMessage(e.target.value)}
                        placeholder={t('left-comment')}
                        className={classes['text-area']}
                        style={{ minHeight: 98, paddingBottom: 60 }}
                        disabled={editLoading}
                        maxLength={400}
                        autoSize
                    />

                    <Flex className={classes['buttons-wrapper']} gap={spacing[3]}>
                        <Button
                            className={classes['cancel-button']}
                            onClick={() => {
                                setEditMessage('');
                                setEditKey(null);
                            }}
                            disabled={editLoading}
                        >
                            {t('cancel')}
                        </Button>
                        <Button
                            className={classes['button-save']}
                            type="primary"
                            onClick={() => handleEditComment(comment.id)}
                            disabled={editLoading || !editMessage.trim()}
                        >
                            {t('save')}
                        </Button>
                    </Flex>
                </div>
            ) : (
                <>
                    <Typography.Text
                        style={{
                            ...textStandard,
                            fontSize: fontSize.xs,
                            color: primitiveColors.gray700,
                        }}
                    >
                        {comment.content}

                        {comment.createdAt !== comment.updatedAt && (
                            <Typography.Text
                                style={{
                                    ...textStandard,
                                    fontSize: fontSize.xxs,
                                    color: primitiveColors.gray200,
                                    marginLeft: spacing[1],
                                }}
                            >
                                {`(${t('edited')})`}
                            </Typography.Text>
                        )}
                    </Typography.Text>
                </>
            )}
        </Flex>
    );
};
