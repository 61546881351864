import { Flex, Drawer } from 'antd';
import SideMenu from '../sideMenu/SideMenu';
import TeamSelector from '../TeamSelector/TeamSelector';
import { spacing, primitiveColors } from './../../../theming';
import ButtonIcon from '../buttonIcon/ButtonIcon';
import { useAuth } from '../../../context/AuthContext';

const MobileMenu = ({ isMobileMenuOpen, toggleMenu }) => {
    const { currentUser } = useAuth();

    const currentUserTeam = currentUser.memberships.find(memb => memb.value === currentUser.teamId);

    return (
        <Drawer
            open={isMobileMenuOpen}
            placement="top"
            size="100%"
            height="100%"
            closable={false}
            styles={{
                wrapper: {
                    height: '100%',
                    width: '100vw',
                },
                body: {
                    height: '100%',
                    padding: 0,
                    backgroundColor: primitiveColors.gray25,
                    overflow: 'scroll',
                },
                content: {
                    borderRadius: 0,
                },
            }}
        >
            <Flex vertical align="center" style={{ paddingTop: 10 }}>
                <div style={{ width: '100%', textAlign: 'end', padding: 20 }}>
                    <ButtonIcon
                        onClick={() => toggleMenu(!isMobileMenuOpen)}
                        icon="fi-rr-cross"
                        style={{ top: 14, right: 22 }}
                    />
                </div>
                {currentUserTeam && (
                    <Flex vertical justify="center" align="start" style={{ width: '100%', marginTop: spacing[1] }}>
                        <TeamSelector isCollapsed={false} toggleMenu={toggleMenu} isMobileMenuOpen={isMobileMenuOpen} />
                        <SideMenu closeMobileMenu={toggleMenu} />
                    </Flex>
                )}
            </Flex>
        </Drawer>
    );
};

export default MobileMenu;
