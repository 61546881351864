import { useLocation } from 'react-router-dom';
import { Navigate } from 'react-router-dom';
import { routesPath } from '../../common/constants/routesPath';
import { TOKEN, REFRESH_TOKEN } from '../../common/constants/localStorage';

function AuthGuard({ children }) {
    const location = useLocation();
    const isAuth = location.pathname.split('/')[1] === 'auth';
    if (isAuth) {
        return getToken(TOKEN) && getToken(REFRESH_TOKEN) ? <Navigate to={routesPath.ROOT.DASHBOARD} /> : children;
    } else {
        return getToken(TOKEN) && getToken(REFRESH_TOKEN) ? children : <Navigate to={'auth/sign-in'} />;
    }
}

const getToken = tokenName => {
    const token = localStorage.getItem(tokenName);

    if (token === null || token === 'undefined') {
        return null;
    }
    return JSON.parse(localStorage.getItem(tokenName));
};

export default AuthGuard;
