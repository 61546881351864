import { useAxios } from './axiosService';

export const useDemoService = () => {
    const { axios } = useAxios();

    const getAllDemoAccounts = async ({ limit, offset }) => {
        const response = await axios.get(`/demo?limit=${limit}&offset=${offset}`);

        return response.data;
    };

    const createDemoAccount = async body => {
        const response = await axios.post('/demo/account', body);

        return response.data;
    };

    const editDemoAccount = async (id, body) => {
        const response = await axios.patch(`/demo/account/${id}`, body);

        return response.data;
    };

    const deleteDemoAccount = async id => {
        const response = await axios.delete(`/demo/account/${id}`);

        return response.data;
    };

    const resetDemoAccount = async id => {
        const response = await axios.post(`/demo/account/reset/${id}`);

        return response.data;
    };

    return {
        getAllDemoAccounts,
        createDemoAccount,
        deleteDemoAccount,
        resetDemoAccount,
        editDemoAccount,
    };
};
