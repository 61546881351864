import { fontSize, primitiveColors } from '../../theming';

const iconStyles = {
    fontSize: fontSize.sm,
    maxHeight: '16px',
    lineHeight: '10px',
};

export const leadAmountWidgetIcons = {
    lead: <i className="fi-sr-magnet-user" style={{ ...iconStyles, color: primitiveColors.volcano500 }} />,
    opportunity: <i className="fi-sr-mode-portrait" style={{ ...iconStyles, color: primitiveColors.geekblue500 }} />,
    prospect: <i className="fi-rr-Revenue" style={{ ...iconStyles, color: primitiveColors.magenta500 }} />,
    client: <i className="fi-sr-review" style={{ ...iconStyles, color: primitiveColors.vitalize500 }} />,
};
