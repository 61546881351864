import { Flex, Tag, Card, List, Button, Typography, Modal, Tooltip } from 'antd';
import Avatar from '../../common/components/Avatar';
import { spacing } from './../../theming/spacing';
import globalNotification from './../../common/hooks/globalNotification';
import { notificationType } from './../../common/constants/notificationType';
import { useState } from 'react';
import { useMedia } from '../../hooks/useMediaQuery';
import { fontSize } from '../../theming';
import CustomAvatar from '../../common/components/Avatar';
import { teamRoles } from '../../common/constants/teamRoles';
import { teamRole } from '../../common/constants/teamRole';

export default function ProfileDetails({
    currentUser,
    userMemberships,
    deleteTeam,
    leaveTeam,
    logout,
    openNotification,
    openLeaveDrawer,
    classes,
    fullName,
    fetchUser,
    fetchUserMemberships,
    t,
}) {
    const [loading, setLoading] = useState(false);
    const { isSmallDevice } = useMedia();
    const ownTeams = userMemberships.filter(membership => membership.role === teamRole.OWNER);

    // #region handlers
    const copyEmail = () => {
        navigator.clipboard.writeText(currentUser?.email);
        globalNotification.open({
            type: notificationType.SUCCESS,
            message: t('notification.copy.message'),
            description: t('notification.copy.description'),
            placement: 'top',
        });
    };

    const handleDeleteTeam = async teamId => {
        setLoading(true);

        try {
            await deleteTeam(teamId);

            globalNotification.open({
                type: notificationType.SUCCESS,
                message: t('notification.delete-success.message'),
                description: t('notification.delete-success.description'),
                placement: 'top',
            });

            fetchUser();
        } catch (err) {
            const errorCode = err.response.data?.code;

            openNotification({
                type: notificationType.ERROR,
                message: t(`apiResponses:${errorCode}.message`),
                description: t(`apiResponses:${errorCode}.description`),
                placement: 'top',
            });
        } finally {
            setLoading(false);
        }
    };

    const openDeleteModal = teamId => {
        Modal.confirm({
            title:
                ownTeams.length === 1
                    ? t('notification.delete-last-team.title')
                    : t('notification.delete-confirm.title'),
            icon: null,
            content: (
                <p>
                    {ownTeams.length === 1
                        ? t('notification.delete-last-team.description')
                        : t('notification.delete-confirm.description')}
                </p>
            ),
            onOk: () => handleDeleteTeam(teamId),
            styles: { body: { padding: spacing[4] } },
            width: 'clamp(300px, 85vw, 500px)',
            okButtonProps: { danger: true },
            okText: t('confirmModal:buttons.yes'),
            cancelText: t('confirmModal:buttons.cancel'),
        });
    };

    const handleLeaveClick = (userTeamRole, teamId) => {
        if (userTeamRole === teamRole.OWNER) {
            openLeaveDrawer(teamId);
        } else {
            Modal.confirm({
                title:
                    userMemberships.length === 1
                        ? t('notification.leave-last-team.title')
                        : t('notification.leave-confirm.title'),
                icon: null,
                content: (
                    <p>
                        {userMemberships.length === 1
                            ? t('notification.leave-last-team.description')
                            : t('notification.leave-confirm.description')}
                    </p>
                ),
                onOk: () => {
                    leaveTeam(teamId);

                    if (teamId === currentUser.teamId) {
                        logout();

                        globalNotification.open({
                            type: notificationType.SUCCESS,
                            message: t('notification.leave-success.message'),
                            description: t('notification.leave-success.description'),
                            placement: 'top',
                        });

                        return;
                    } else {
                        fetchUserMemberships();
                        fetchUser();
                    }

                    globalNotification.open({
                        type: notificationType.SUCCESS,
                        message: t('notification.leave-success.message'),
                        description: t('notification.leave-success.description'),
                        placement: 'top',
                    });
                },
                styles: { body: { padding: spacing[4] } },
                width: 'fit-content',
                okButtonProps: { danger: true },
                okText: t('confirmModal:buttons.yes'),
                cancelText: t('confirmModal:buttons.cancel'),
            });
        }
    };
    // #endregion

    return (
        <Flex vertical align="center" className={classes.container}>
            <Avatar
                firstName={currentUser?.firstName}
                lastName={currentUser?.lastName}
                photoName={currentUser?.photo}
                controller="users"
                size={100}
                style={{ fontSize: fontSize.H1 }}
            />
            <h1
                className={classes.title}
                style={{
                    fontSize: isSmallDevice && fontSize.H3,
                }}
            >
                {fullName}
            </h1>
            <Flex>
                {currentUser?.userRoleNames.map(role => (
                    <Tag key={role} color="blue">
                        {role}
                    </Tag>
                ))}
            </Flex>
            <Flex align="center" gap={spacing[1]}>
                <p>{currentUser?.email}</p>
                <i className={`fi fi-rr-copy-alt ${classes.icon}`} onClick={copyEmail} />
            </Flex>
            <Card title={t('teams')} className={classes.card} style={{ width: isSmallDevice && '100%' }}>
                <List bordered={false}>
                    {userMemberships.map(membership => {
                        const userRole = teamRoles.find(role => role.value === membership.role);

                        return (
                            <Tooltip
                                title={membership.isDeleted ? t('tooltip.archived') : ''}
                                visible={membership.isDeleted ? undefined : false} // we need exactly undefined there for correct display
                            >
                                <List.Item
                                    key={membership.id}
                                    style={{
                                        flexDirection: isSmallDevice ? 'column' : 'row',
                                        position: 'relative',
                                    }}
                                >
                                    {membership.isDeleted && (
                                        <div
                                            style={{
                                                width: '100%',
                                                position: 'absolute',
                                                top: 0,
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                filter: 'blur(1px)',
                                                backgroundColor: '#f0f0f0',
                                                opacity: 0.6,
                                            }}
                                        ></div>
                                    )}
                                    <Flex
                                        gap={spacing[1]}
                                        style={{ width: '100%' }}
                                        vertical={isSmallDevice}
                                        align="center"
                                    >
                                        <div
                                            style={{
                                                width: isSmallDevice ? '100%' : '15%',
                                                textAlign: isSmallDevice ? 'center' : 'left',
                                            }}
                                        >
                                            <strong>{membership.team.name}</strong>
                                        </div>

                                        <Flex
                                            style={{
                                                width: isSmallDevice ? '100%' : '50%',
                                            }}
                                            vertical={isSmallDevice}
                                            align="center"
                                            gap={spacing[1]}
                                        >
                                            <CustomAvatar
                                                firstName={currentUser?.firstName}
                                                lastName={currentUser?.lastName}
                                                photoName={currentUser?.photo}
                                                controller="users"
                                                size={36}
                                            />

                                            <Typography.Text style={{ textWrap: 'nowrap' }}>
                                                {`${membership.firstName || currentUser.firstName} ${membership.lastName || currentUser.lastName}`}
                                            </Typography.Text>

                                            <Tag
                                                style={{
                                                    backgroundColor: userRole.backgroundColor,
                                                    borderColor: userRole.borderColor,
                                                    color: userRole.color,
                                                    paddingInline: spacing[3],
                                                    paddingBlock: spacing[0.5],
                                                    fontWeight: 500,
                                                    fontSize: 'inherit',
                                                    marginInlineEnd: 0,
                                                }}
                                            >
                                                {userRole.label}
                                            </Tag>
                                        </Flex>

                                        <Flex
                                            style={{ width: isSmallDevice ? '100%' : '35%' }}
                                            justify={isSmallDevice ? 'center' : 'flex-end'}
                                            gap={spacing[1]}
                                        >
                                            <Button
                                                type="primary"
                                                onClick={() => handleLeaveClick(membership.role, membership.team.id)}
                                                loading={loading}
                                                disabled={membership.team.memberships.length === 1}
                                                style={{
                                                    flexBasis: '50%',
                                                    flexGrow: 1,
                                                    position: 'relative',
                                                    zIndex: 3,
                                                }}
                                            >
                                                {t('button.leave')}
                                            </Button>

                                            <Button
                                                type="primary"
                                                danger
                                                onClick={() => openDeleteModal(membership.team.id)}
                                                loading={loading}
                                                disabled={membership.role !== teamRole.OWNER}
                                                style={{ flexBasis: '50%', flexGrow: 1 }}
                                            >
                                                {t('button.delete')}
                                            </Button>
                                        </Flex>
                                    </Flex>
                                </List.Item>
                            </Tooltip>
                        );
                    })}
                </List>
            </Card>
        </Flex>
    );
}
