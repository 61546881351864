import { useState, useEffect } from 'react';
import { Popover, Flex, Row, Col } from 'antd';
import { useGlobalSearchService } from '../../../services/globalSearchService';
import { isEmpty, debounce } from 'lodash';
import { spacing, backgroundColor } from '../../../theming';
import SearchModal from './SearchModal';
import SearchOptions from './SearchOptions';
import SearchInput from './SearchInput';
import SearchResultsList from './SearchResultsList';
import PopoverTitle from '../PopoverTitle';
import { useMedia } from '../../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';

export default function GlobalSearch() {
    const { t } = useTranslation(['globalSearch']);
    const { isSmallDevice } = useMedia();

    // #region States
    const [loading, setLoading] = useState(false);
    const [searchText, setSearchText] = useState('');
    const [openAllResults, setOpenAllResults] = useState(false);
    const [results, setResults] = useState({
        leads: [],
        businesses: [],
        users: [],
    });
    const [options, setOptions] = useState([
        { label: t('leads'), value: 'leads', checked: false },
        { label: t('businesses'), value: 'businesses', checked: false },
        { label: t('users'), value: 'users', checked: false },
    ]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});
    const { globalSearch } = useGlobalSearchService();
    // #endregion

    useEffect(() => {
        setOptions([
            { label: t('leads'), value: 'leads', checked: false },
            { label: t('businesses'), value: 'businesses', checked: false },
            { label: t('users'), value: 'users', checked: false },
        ]);
    }, [t]);

    // #region Search handlers
    const noResults = isEmpty(results.leads) && isEmpty(results.businesses) && isEmpty(results.users);

    const onSearch = searchText => {
        setLoading(true);
        debounce(
            () =>
                globalSearch(searchText)
                    .then(response => {
                        setResults({ leads: response.leads, businesses: response.businesses, users: response.users });
                    })
                    .finally(() => {
                        setLoading(false);
                        setOpenAllResults(true);
                    }),
            500
        )();
    };

    const hideAllResults = () => {
        setOpenAllResults(false);
    };

    useEffect(() => {
        if (searchText === '') {
            setOpenAllResults(false);
        }
    }, [searchText]);
    // #endregion

    // #region Modal Handlers
    const showModal = item => {
        setSelectedItem(item);
        setIsModalOpen(true);
        setOpenAllResults(false);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    // #endregion

    return (
        <Flex align="center" justify="center">
            <SearchInput
                loading={loading}
                onSearch={onSearch}
                searchText={searchText}
                setSearchText={setSearchText}
                style={{
                    width: isSmallDevice ? '100%' : '300px',
                    backgroundColor: isSmallDevice ? backgroundColor.page : 'inherit',
                }}
            />
            <Popover
                title={<PopoverTitle title={t('results')} onClickClose={hideAllResults} />}
                trigger="click"
                id="globalSearchWrapper"
                open={openAllResults}
                onOpenChange={visible => setOpenAllResults(visible)}
                placement="bottom"
                arrow={false}
                content={
                    <Row>
                        <Flex justify="space-between" gap={spacing[1]}>
                            <Col span={14}>
                                <SearchResultsList
                                    options={options}
                                    results={results}
                                    noResults={noResults}
                                    showModal={showModal}
                                    t={t}
                                />
                            </Col>
                            <Col span={10} style={{ marginTop: '1em' }}>
                                <SearchOptions options={options} setOptions={setOptions} />
                            </Col>
                        </Flex>
                    </Row>
                }
                overlayStyle={{
                    width: 'clamp(325px, 80vw, 500px)',
                    top: isSmallDevice ? 130 : 60,
                    left: isSmallDevice ? 10 : 120,
                    margin: '0 auto',
                }}
            />
            {selectedItem && (
                <SearchModal selectedItem={selectedItem} isModalOpen={isModalOpen} modalHandler={handleCloseModal} />
            )}
        </Flex>
    );
}
