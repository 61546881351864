import { Tag } from 'antd';
import { useMediaQuery } from '@uidotdev/usehooks';
import { tagStyle, fontSize } from './../../theming';

export default function StatusTag({ status, style, props }) {
    const mobileDevicesBreakpoint = useMediaQuery();

    return (
        <Tag
            style={{
                ...tagStyle,
                width: 'fit-content',
                color: status?.primaryColor,
                backgroundColor: status?.secondaryColor,
                height: '28px',
                fontSize: mobileDevicesBreakpoint ? fontSize.xxs : 'inherit',
                ...style,
            }}
            bordered={false}
            {...props}
        >
            {status.name}
        </Tag>
    );
}
