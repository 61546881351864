import React from 'react';
import { Menu } from 'antd';
import { routesPath } from '../../constants/routesPath';
import { useTranslation } from 'react-i18next';
import { useAuth } from '../../../context/AuthContext';
import { teamRole } from '../../constants/teamRole';
import { useLocation, useNavigate } from 'react-router-dom';
import { coreBrandColors, primitiveColors, spacing } from '../../../theming';
import classes from './SideMenu.module.scss';
import { checkForSuperAdmin, checkTeamRole } from '../../../utils/checkRole';

const SideMenu = ({ theme, isCollapsed, closeMobileMenu }) => {
    const { t } = useTranslation(['sidebar', 'leads', 'users', 'businesses', 'teams']);
    const { currentUser } = useAuth();
    const location = useLocation();
    const navigate = useNavigate();

    const leftMenu = [
        checkTeamRole(currentUser, teamRole.VIEWER) && {
            key: routesPath.ROOT.DASHBOARD,
            icon: (
                <span>
                    <i className="fi fi-rr-dashboard-monitor" />
                </span>
            ),
            label: t('sidebar:dashboard'),
        },
        checkForSuperAdmin(currentUser) && {
            key: '/demo',
            icon: (
                <span>
                    <i className="fi fi-rr-preview" />
                </span>
            ),
            label: t('demo'),
        },
        {
            key: routesPath.ROOT.LEADS,
            icon: (
                <span>
                    <i className="fi fi-rr-users" />
                </span>
            ),
            label: t('leads:plural'),
        },
        {
            key: routesPath.ROOT.BUSINESSES,
            icon: (
                <span>
                    <i className="fi fi-rr-briefcase" />
                </span>
            ),
            label: t('businesses:plural'),
        },
        {
            key: routesPath.ROOT.TEAM,
            icon: (
                <span>
                    <i className="fi fi-rr-users-alt" />
                </span>
            ),
            label: t('teams:singular'),
        },
        checkTeamRole(currentUser, teamRole.OWNER) && {
            key: 'payments',
            icon: (
                <span>
                    <i className="fi fi-rr-wallet" />
                </span>
            ),
            label: t('sidebar:payments'),
            children: [
                checkTeamRole(currentUser, teamRole.OWNER) && {
                    key: routesPath.ROOT.SUBSCRIPTIONS,
                    icon: (
                        <span>
                            <i className="fi fi-rr-star" />
                        </span>
                    ),
                    label: t('sidebar:subscriptions'),
                },
                checkForSuperAdmin(currentUser) && {
                    key: routesPath.ROOT.ORDERS,
                    icon: (
                        <span>
                            <i className="fi fi-rr-list" />
                        </span>
                    ),
                    label: t('sidebar:order-list'),
                },
                checkForSuperAdmin(currentUser) && {
                    key: routesPath.ROOT.CART,
                    icon: (
                        <span>
                            <i className="fi fi-rr-shopping-cart" />
                        </span>
                    ),
                    label: t('sidebar:cart'),
                },
            ],
        },

        checkForSuperAdmin(currentUser) && {
            key: routesPath.ROOT.FILE_UPLOAD,
            icon: (
                <span>
                    <i className="fi fi-rr-cloud-upload-alt" />
                </span>
            ),
            label: t('sidebar:file-actions'),
        },
        checkForSuperAdmin(currentUser) && {
            key: routesPath.ROOT.RECENT_UPDATES,
            icon: (
                <span>
                    <i className="fi fi-rr-apps" />
                </span>
            ),
            label: t('sidebar:recent-updates'),
        },

        checkForSuperAdmin(currentUser) && {
            key: routesPath.ROOT.EXPORT_PDF,
            icon: (
                <span>
                    <i className="fi fi-rr-file-pdf" />
                </span>
            ),
            label: t('sidebar:export-pdf'),
        },
        checkTeamRole(currentUser, teamRole.OWNER) && {
            key: routesPath.ROOT.SETTINGS,
            icon: (
                <span>
                    <i className="fi fi-rr-settings" />
                </span>
            ),
            label: t('sidebar:settings'),
        },
    ];

    const onMenuClick = ({ key }) => {
        navigate(key);
        if (closeMobileMenu) {
            closeMobileMenu();
        }
    };

    return (
        <Menu
            onClick={onMenuClick}
            mode="inline"
            theme={theme}
            defaultSelectedKeys={['1']}
            selectedKeys={[location.pathname]}
            defaultOpenKeys={['sub1']}
            className={isCollapsed && classes.menu}
            style={{
                height: '100%',
                borderRight: 0,
                paddingTop: `${spacing[3]}`,
                backgroundColor: 'inherit',
                color:
                    theme === 'dark' ? `${primitiveColors.gray0} !important` : `${primitiveColors.gray900} !important`,
            }}
            overlayinnertyle={{
                backgroundColor: theme === 'dark' ? coreBrandColors.blueRibbon : primitiveColors.gray50,
            }}
            items={leftMenu}
            expandIcon={({ isSubMenu, isOpen }) => {
                switch (true) {
                    case isSubMenu && isOpen:
                        return <i className="fi fi-rr-angle-small-up" />;
                    case isSubMenu && !isOpen:
                        return <i className="fi fi-rr-angle-small-down" />;
                    default:
                        return null;
                }
            }}
        />
    );
};

export default SideMenu;
