import React, { useState, useEffect } from 'react';
import { Upload, Modal, Typography, Flex, Space } from 'antd';
import ImgCrop from 'antd-img-crop';
import { useFileService } from '../../../services/fileService';
import { handleFileUpload, handleRemove, beforeUpload } from '../../../components/fileUploadButtons/UploadHelper';
import getBase64 from '../../../utils/getBase64';
import { UploadButton } from '../UploadButton/UploadButton';
import { DeleteButton } from '../DeleteButton/DeleteButton';
import { textStandard, spacing, radius } from '../../../theming';
import PreviewButton from '../PreviewButton/PreviewButton';
import { useMedia } from '../../../hooks/useMediaQuery';
import { useTranslation } from 'react-i18next';
import classes from './style.module.scss';

function FormImageUpload({ fileQuality, fieldName, path, maxCount = 1, id, file, withPreviewModal = false, title }) {
    const { t } = useTranslation(['form']);

    const fileService = useFileService();
    const [fileList, setFileList] = useState([]);

    const [previewOpen, setPreviewOpen] = useState(false);
    const [previewImage, setPreviewImage] = useState('');
    const [previewTitle, setPreviewTitle] = useState('');
    const onChange = ({ fileList: newFileList }) => {
        setFileList(newFileList);
    };

    const { isSmallDevice } = useMedia();

    const handlePreviewModal = async file => {
        if (!file.url) {
            file.preview = await getBase64(file.originFileObj);
        }
        setPreviewImage(file.url || file.preview);
        setPreviewOpen(true);
        setPreviewTitle(
            file.name || file.url?.substring(file.url.lastIndexOf('/') + 1) || file.substring(file.lastIndexOf('/') + 1)
        );
    };

    const onPreview = async file => {
        let src = file.url;
        if (!src) {
            src = await new Promise(resolve => {
                const reader = new FileReader();
                reader.readAsDataURL(file.originFileObj);
                reader.onload = () => resolve(reader.result);
            });
        }
        const image = new Image();
        image.src = src;
        const imgWindow = window.open(src);
        imgWindow?.document.write(image.outerHTML);
    };

    const handlePhotoDelete = async () => {
        await handleRemove(fileList[0], path.delete || path, fileService, t);
        setFileList([]);
    };

    useEffect(() => {
        if (file) {
            setFileList([
                {
                    uid: '-1',
                    name: file.name || file.substring(file.lastIndexOf('/') + 1),
                    status: 'done',
                    url: file.url || file,
                },
            ]);
        }
        if (file === null) {
            setFileList([]); // reset the fileList if user have no image
        }
    }, [file]);

    return (
        <>
            <Flex vertical align="center" justify="center">
                <Typography.Text
                    style={{ maxWidth: '200px', ...textStandard, placeSelf: 'start', marginBottom: spacing[1] }}
                >
                    {title}
                </Typography.Text>
                <Space gap={spacing[4]}>
                    <ImgCrop
                        rotationSlider
                        quality={fileQuality}
                        modalProps={{
                            okText: t('form:buttons.crop'),
                            cancelText: t('form:buttons.cancel'),
                        }}
                        modalTitle={t('form:edit-image')}
                        modalClassName={classes.modal}
                    >
                        <Upload
                            beforeUpload={fileInfo =>
                                beforeUpload(fileInfo, ['image/jpeg', 'image/png', 'image/jpg'], 5, t)
                            }
                            customRequest={fileInfo =>
                                handleFileUpload(path.upload || path, fileInfo, fileService, fieldName, id)
                            }
                            listType="picture-card"
                            fileList={fileList}
                            onChange={onChange}
                            onPreview={withPreviewModal ? handlePreviewModal : onPreview}
                            onRemove={fileInfo => handleRemove(fileInfo, path.delete || path, fileService, t)}
                            maxCount={maxCount}
                            style={{
                                position: 'relative',
                                borderRadius: radius[1],
                                width: isSmallDevice && spacing[10],
                            }}
                            itemRender={(_, file, currFileList) => {
                                return (
                                    file &&
                                    currFileList.length > 0 && (
                                        <PreviewButton
                                            file={file}
                                            url={currFileList[0].url}
                                            handler={() => handlePreviewModal(file)}
                                            path={path.get}
                                        />
                                    )
                                );
                            }}
                        >
                            {fileList.length < maxCount && <UploadButton />}
                        </Upload>
                    </ImgCrop>
                    {fileList.length >= maxCount && <DeleteButton onDelete={handlePhotoDelete} />}
                </Space>
                {fileList.length < maxCount && (
                    <Typography.Text style={{ maxWidth: '200px', marginTop: spacing[1] }}>
                        {t('form:add-image')}
                    </Typography.Text>
                )}
            </Flex>
            <Modal
                open={previewOpen}
                title={previewTitle}
                footer={null}
                onCancel={() => setPreviewOpen(false)}
                className={classes.modal}
            >
                <img
                    alt="example"
                    style={{
                        width: '100%',
                    }}
                    src={previewImage}
                />
            </Modal>
        </>
    );
}
export default FormImageUpload;
