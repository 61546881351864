import { iconColor } from '../../../theming';
import { Flex } from 'antd';

export default function SorterIcon({ sortOrder, column }) {
    const { order, columnKey } = sortOrder;
    return (
        <Flex vertical align="end" justify="center">
            <i
                className="fi-sr-sort-down up"
                style={{
                    color: order === 'descend' && columnKey === column ? iconColor.active : iconColor.secondary,
                    transform: 'rotate(180deg)',
                }}
            ></i>
            <i
                className="fi-sr-sort-down down"
                style={{
                    color: order === 'ascend' && columnKey === column ? iconColor.active : iconColor.secondary,
                }}
            ></i>
        </Flex>
    );
}
