import React from 'react';
import { Divider, Flex, Menu } from 'antd';
import { coreBrandColors, primitiveColors, spacing } from '../../../theming';
import classes from './LeftMenu.module.scss';
import { routesPath } from '../../constants/routesPath';
import '../../../App.scss';
import TeamSelector from '../TeamSelector/TeamSelector';
import SideMenu from '../sideMenu/SideMenu';
import { useAuth } from '../../../context/AuthContext';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { checkForSuperAdmin } from '../../../utils/checkRole';

function LeftMenu({ isCollapsed, theme }) {
    const { currentUser } = useAuth();

    const location = useLocation();

    const navigate = useNavigate();

    const { t } = useTranslation(['sidebar']);

    const currentUserTeam = currentUser.memberships.find(memb => memb.value === currentUser.teamId);

    const onMenuClick = ({ key }) => {
        navigate(key);
    };

    const adminMenu = [
        {
            key: 'admin',
            icon: (
                <span>
                    <i className="fi fi-rr-user-crown" />
                </span>
            ),
            label: t('admin'),
            children: [
                {
                    icon: (
                        <span>
                            <i className="fi fi-rr-list" />
                        </span>
                    ),
                    key: routesPath.ROOT.USERS_LIST,
                    label: t('sidebar:users-list'),
                },
            ],
        },
    ];

    return (
        <Flex
            vertical
            style={{ backgroundColor: coreBrandColors.blueRibbon, height: '100%' }}
            className={classes['left-menu']}
        >
            {currentUserTeam && (
                <Flex justify="center">
                    <TeamSelector isCollapsed={isCollapsed} theme={theme} />
                </Flex>
            )}
            <SideMenu theme={theme} isCollapsed={isCollapsed} />
            {checkForSuperAdmin(currentUser) && (
                <Flex vertical style={{ marginTop: 'auto' }} gap={spacing[3]}>
                    <Divider
                        style={{
                            backgroundColor: primitiveColors.gray100,
                            height: 1,
                            margin: 0,
                            minWidth: 'auto',
                            maxWidth: 216,
                        }}
                    />
                    <Menu
                        onClick={onMenuClick}
                        mode="inline"
                        theme={theme}
                        defaultSelectedKeys={['1']}
                        selectedKeys={[location.pathname]}
                        defaultOpenKeys={['sub1']}
                        className={isCollapsed && classes.menu}
                        style={{
                            height: '100%',
                            borderRight: 0,
                            backgroundColor: 'inherit',
                            color:
                                theme === 'dark'
                                    ? `${primitiveColors.gray0} !important`
                                    : `${primitiveColors.gray900} !important`,
                        }}
                        overlayinnertyle={{
                            backgroundColor: theme === 'dark' ? coreBrandColors.blueRibbon : primitiveColors.gray50,
                        }}
                        items={adminMenu}
                        expandIcon={({ isSubMenu, isOpen }) => {
                            switch (true) {
                                case isSubMenu && isOpen:
                                    return <i className="fi fi-rr-angle-small-up" />;
                                case isSubMenu && !isOpen:
                                    return <i className="fi fi-rr-angle-small-down" />;
                                default:
                                    return null;
                            }
                        }}
                    />
                </Flex>
            )}
        </Flex>
    );
}

export default LeftMenu;
