import { useAxios } from './axiosService';

export const useAuthService = () => {
    const { axios } = useAxios();

    const signInEmailPassword = async credentials => {
        return await axios.post('/auth/sign-in', { credentials });
    };

    const signUpEmailPassword = async credentials => {
        return await axios.post('/auth/sign-up', { credentials });
    };

    const googleAuth = async (path, code) => {
        return await axios.post(`/auth/google/${path}`, { code });
    };

    const facebookAuth = async (path, code) => {
        return await axios.post(`/auth/facebook/${path}`, { code });
    };

    const getGeneratedQr = async () => {
        return await axios.get('/2fa/generate-qr');
    };

    const enableTwoFactorAuth = async code => {
        return await axios.post('/2fa/enable-two-factor-auth', { code });
    };

    const disableTwoFactorAuth = async () => {
        return await axios.post('/2fa/disable-two-factor-auth');
    };

    const twoFactorAuth = async code => {
        return await axios.post('/2fa/authenticate', { code });
    };

    const changeTeam = async teamId => {
        return await axios.post(`/auth/change-team`, { teamId });
    };

    const validateInvitationToken = async token => {
        return await axios.post(`/auth/validate-invitation-token`, {
            token: token,
        });
    };

    const validateRestoreToken = async token => {
        return await axios.post(`/auth/validate-restore-token`, {
            token: token,
        });
    };

    const finishInvitation = async data => {
        return await axios.post(`/auth/finish-invitation`, data);
    };

    const forgotPassword = async email => {
        return await axios.post(`/auth/forgot-password`, {
            email: email,
        });
    };

    const decryptPreviewLink = async hash => {
        return await axios.post(`/demo/preview`, { hash });
    };

    return {
        signInEmailPassword,
        signUpEmailPassword,
        googleAuth,
        facebookAuth,
        getGeneratedQr,
        enableTwoFactorAuth,
        disableTwoFactorAuth,
        twoFactorAuth,
        changeTeam,
        finishInvitation,
        validateInvitationToken,
        validateRestoreToken,
        forgotPassword,
        decryptPreviewLink,
    };
};
