import { Form, Input, Button, Flex } from 'antd';
import { spacing, iconColor, coreBrandColors, textStandard, fontSize } from '../../theming';
import getInputRules, { inputType } from '../../utils/getInputRules';

function MainTab({ handler, handleBack, classes, t }) {
    return (
        <>
            <h3
                style={{
                    marginBottom: spacing[5],
                    fontFamily: "'Inter', sans-serif",
                }}
            >
                {t('title')}
            </h3>
            <div className={classes.content}>
                <p>{t('sub')}</p>
                <Form onFinish={handler}>
                    <Form.Item
                        name="email"
                        rules={getInputRules(
                            t,
                            inputType.EMAIL,
                            t('form.errors.email'),
                            t('form.errors.email-invalid')
                        )}
                    >
                        <Input
                            prefix={
                                <i
                                    className="fi-rr-envelope"
                                    style={{ margin: '5px 10px 0 0', color: iconColor.primary }}
                                />
                            }
                            placeholder="office@company.com"
                            size="large"
                            style={{
                                padding: `${spacing[1]} ${spacing[3]}`,
                            }}
                        />
                    </Form.Item>
                    <Flex vertical justify="center" align="center" gap={spacing[6]}>
                        <Button
                            htmlType="submit"
                            style={{ width: '100%', backgroundColor: coreBrandColors.blueRibbon }}
                            type="primary"
                            size="large"
                        >
                            {t('buttons.send')}
                        </Button>
                        <Button
                            htmlType="button"
                            style={{
                                width: 'fit-content',
                                ...textStandard,
                            }}
                            type="link"
                            size="middle"
                            onClick={handleBack}
                            icon={
                                <i
                                    className="fi-rr-arrow-left"
                                    style={{ position: 'relative', top: 2, right: 5, fontSize: fontSize.lg }}
                                />
                            }
                        >
                            {t('buttons.back')}
                        </Button>
                    </Flex>
                </Form>
            </div>
        </>
    );
}

export default MainTab;
