import { Tabs } from 'antd';
import { useTranslation } from 'react-i18next';

export const PreviewTabs = ({ selectedTab, onChange, ...props }) => {
    const { t } = useTranslation(['previewTabs']);

    const tabsItems = [
        {
            key: '1',
            label: t('all'),
        },
        {
            key: '2',
            label: t('comments'),
        },
        {
            key: '3',
            label: t('logs'),
        },
    ];

    return <Tabs items={tabsItems} activeKey={selectedTab} onChange={onChange} {...props} />;
};
