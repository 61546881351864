import { Table, Tag, Flex, Typography, Spin, Card } from 'antd';
import { leadStatus } from '../../../common/constants/leadStatus';
import { useEffect } from 'react';
import { useState } from 'react';
import globalNotification from '../../../common/hooks/globalNotification';
import { notificationType } from '../../../common/constants/notificationType';
import dayjs from 'dayjs';
import { useLeadService } from '../../../services/leadService';
import CustomAvatar from '../../../common/components/Avatar';
import { WidgetReload } from './WidgetReload';
import { fontSize, lineHeight, radius, spacing, tagIcon, tagStyle, textColor, textStandard } from '../../../theming';
import { useTranslation } from 'react-i18next';
import { leadState } from '../../../common/constants/leadState';
import 'dayjs/locale/en';
import 'dayjs/locale/uk';
import capitalize from './../../../utils/capitalize';
import { datesFormat } from './../../../common/constants/datesFormat';

export const RecentLeadsTableWidget = ({ selectedDates, selectedBusinesses }) => {
    const { t, i18n } = useTranslation('dashboard');
    const currentLanguage = i18n.language;

    const [tableData, setTableData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const { getFiveRecentLeads } = useLeadService();

    const fetchFiveRecentLeads = async () => {
        setError('');
        setLoading(true);

        try {
            const leads = await getFiveRecentLeads(selectedDates[0], selectedDates[1], selectedBusinesses);

            setTableData(
                leads.map(lead => ({
                    ...lead,
                    name: `${lead.firstName} ${lead.lastName}`,
                    createdAt: capitalize(
                        dayjs(lead.createdAt)
                            .locale(currentLanguage)
                            .format(currentLanguage === 'uk' ? datesFormat.uk.long : datesFormat.en.short),
                        currentLanguage === 'uk' ? 3 : 0
                    ),
                    updatedAt: capitalize(
                        dayjs(lead.updatedAt)
                            .locale(currentLanguage)
                            .format(currentLanguage === 'uk' ? datesFormat.uk.long : datesFormat.en.short),
                        currentLanguage === 'uk' ? 3 : 0
                    ),
                }))
            );
        } catch (err) {
            console.error(err);
            globalNotification.open({
                type: notificationType.ERROR,
                message: err.response.data.message,
                description: err.response.data.description,
            });

            setError(err.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if (i18n.language === 'uk') {
            dayjs.locale('uk');
        } else {
            dayjs.locale('en');
        }
    }, [i18n.language]);

    useEffect(() => {
        if ((selectedDates.length > 0, selectedBusinesses.length > 0)) {
            fetchFiveRecentLeads();
        }
    }, [selectedDates, selectedBusinesses, i18n.language]);

    const columns = [
        {
            title: t('recent-leads-table-widget.fullname').toUpperCase(),
            dataIndex: 'name',
            key: 'name',
            width: '300px',
            render: (name, record) => {
                return (
                    <Flex gap={spacing[1]} justify="start" align="center">
                        <CustomAvatar
                            key={record.id}
                            size={32}
                            photoName={record.photo}
                            firstName={record.firstName}
                            lastName={record.lastName}
                            controller={'leads'}
                        />
                        <Flex vertical>
                            <Typography.Text
                                style={{ fontWeight: 500, fontSize: fontSize.sm, lineHeight: lineHeight.md }}
                            >
                                {name}
                            </Typography.Text>
                            <a
                                href={`mailto:${record.email}`}
                                target="_blank"
                                style={{
                                    color: textColor.subtle,
                                    fontWeight: 400,
                                    fontSize: fontSize.sm,
                                    lineHeight: lineHeight.md,
                                }}
                            >
                                {record.email}
                            </a>
                        </Flex>
                    </Flex>
                );
            },
        },
        {
            title: t('recent-leads-table-widget.state').toUpperCase(),
            dataIndex: 'state',
            key: 'state',
            width: '100.5px',
            render: state => {
                const stateName = leadState.find(s => s.value === state);
                return (
                    <Tag
                        style={{
                            ...tagStyle,
                            gap: spacing[1],
                            width: '73px',
                            height: '28px',
                            color: stateName?.color,
                            backgroundColor: stateName?.backgroundColor,
                        }}
                        bordered={false}
                        icon={
                            <span
                                style={{
                                    backgroundColor: stateName?.color,
                                    ...tagIcon,
                                }}
                            ></span>
                        }
                    >
                        {stateName?.label}
                    </Tag>
                );
            },
        },
        {
            title: t('recent-leads-table-widget.status').toUpperCase(),
            dataIndex: 'status',
            key: 'status',
            width: '150.5px',

            render: status => {
                return (
                    <Tag
                        style={{
                            ...tagStyle,
                            width: 'fit-content',
                            padding: `${spacing[0.5]} ${spacing[3]}`,
                            color: status?.primaryColor,
                            backgroundColor: status?.secondaryColor,
                            height: '28px',
                        }}
                        bordered={false}
                    >
                        {status?.name}
                    </Tag>
                );
            },
        },
        {
            title: t('recent-leads-table-widget.date-added').toUpperCase(),
            dataIndex: 'createdAt',
            key: 'createdAt',
            width: '170px',
            render: createdAt => <Typography.Text style={{ ...textStandard }}>{createdAt}</Typography.Text>,
        },
        {
            title: t('recent-leads-table-widget.update-date').toUpperCase(),
            dataIndex: 'updatedAt',
            key: 'updatedAt',
            width: '170px',
            render: updatedAt => <Typography.Text style={{ ...textStandard }}>{updatedAt}</Typography.Text>,
        },
    ];

    return loading || error || tableData.length === 0 ? (
        <Card
            style={{
                border: '1px solid #E9EBED',
                borderRadius: radius.lg,
                height: '100%',
            }}
            styles={{
                body: {
                    padding: spacing[4],
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }}
        >
            {loading ? (
                <Spin size="large" />
            ) : (
                <WidgetReload
                    errorMessage={error ? error : t('widget-reload.no-data')}
                    reloadFunction={error ? fetchFiveRecentLeads : null}
                />
            )}
        </Card>
    ) : (
        <div>
            <Typography.Title style={{ margin: 0, marginBottom: spacing[3], fontSize: fontSize.lg, fontWeight: 700 }}>
                {t('recent-leads-table-widget.title')}
            </Typography.Title>

            <Table
                columns={columns}
                dataSource={tableData}
                loading={loading}
                pagination={false}
                scroll={{
                    x: 'max-content',
                }}
                size="middle"
                bordered={false}
                rowClassName={'even-table-cell'}
                style={{
                    scrollbarWidth: 'none',
                    overflowX: 'scroll',
                }}
            />
        </div>
    );
};
