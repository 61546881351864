import { Button, Form, Input, Flex } from 'antd';
import { buttonStyle } from '../../theming';

export default function TwoFactorAuthForm({ loading, showCancel, submitDisabled, onBackClick, onFormSubmit, t }) {
    return (
        <Form onFinish={onFormSubmit} layout="vertical" requiredMark={false}>
            <Form.Item
                label={t('settings:2FA.form.label')}
                name="code"
                hasFeedback
                validateDebounce={1000}
                rules={[
                    {
                        min: 6,
                        max: 6,
                        message: t('settings:2FA.form.minmax'),
                    },
                    {
                        required: true,
                        message: t('settings:2FA.form.required'),
                    },
                ]}
            >
                <Input
                    size="large"
                    placeholder={t('settings:2FA.form.placeholder')}
                    disabled={submitDisabled}
                    autoComplete="off"
                />
            </Form.Item>
            <Flex justify={showCancel ? 'space-around' : 'start'} align="center" style={{ marginTop: 30 }}>
                {showCancel && (
                    <Button
                        htmlType="button"
                        type="default"
                        size={'middle'}
                        onClick={onBackClick}
                        style={{ ...buttonStyle.medium }}
                    >
                        {t('settings:2FA.form.cancel')}
                    </Button>
                )}
                <Button
                    htmlType="submit"
                    type="primary"
                    size={'middle'}
                    loading={loading}
                    disabled={submitDisabled}
                    style={{ ...buttonStyle.medium }}
                >
                    {t('settings:2FA.form.submit')}
                </Button>
            </Flex>
        </Form>
    );
}
