import { Card, Spin, Carousel } from 'antd';
import { useEffect, useState } from 'react';
import { useLeadService } from '../../../services/leadService';
import globalNotification from '../../../common/hooks/globalNotification';
import { notificationType } from '../../../common/constants/notificationType';
import { WidgetReload } from './WidgetReload';
import { LeadAmountDiffenceWidget } from './LeadAmountDiffenceWidget';
import { leadAmountWidgetIcons } from '../../../common/constants/leadAmountWidgetConstants';
import { radius, spacing } from '../../../theming';
import { useTranslation } from 'react-i18next';
import { chunkArray } from '../helpers';
import classes from './style.module.scss';

export const LeadCountOverPeriodWidget = ({ selectedDates, selectedBusinesses }) => {
    const { t } = useTranslation(['dashboard']);

    const [leadAmount, setLeadAmount] = useState({});
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState('');

    const { countLeadsOverPeriod } = useLeadService();

    const fetchLeadsCountOverPeriod = async () => {
        setError('');
        setLoading(true);

        try {
            const result = await countLeadsOverPeriod(selectedDates[0], selectedDates[1], selectedBusinesses);

            setLeadAmount(result);
        } catch (err) {
            console.error(err);
            globalNotification.open({
                type: notificationType.ERROR,
                message: err.response.data.message,
                description: err.response.data.description,
            });

            setError(err.response.data.message);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        if ((selectedDates.length > 0, selectedBusinesses.length > 0)) {
            fetchLeadsCountOverPeriod();
        }
    }, [selectedDates, selectedBusinesses]);

    const chunkedLeadAmount = chunkArray(leadAmount, 4);

    return loading ||
        error ||
        Object.values(leadAmount).every(value => value.current === 0 && value.percentageChange === 0) ? (
        <Card
            style={{
                border: '1px solid #E9EBED',
                borderRadius: radius.lg,
                height: '100%',
            }}
            styles={{
                body: {
                    padding: spacing[4],
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                },
            }}
        >
            {loading ? (
                <Spin size="large" />
            ) : (
                <WidgetReload
                    errorMessage={error ? error : t('widget-reload.no-data')}
                    reloadFunction={error ? fetchLeadsCountOverPeriod : null}
                />
            )}
        </Card>
    ) : (
        leadAmount && (
            <Carousel infinite={false} draggable className={classes.carousel}>
                {chunkedLeadAmount.map((chunk, index) => (
                    <div key={index} className={classes.chunk}>
                        {chunk.map((item, idx) => (
                            <LeadAmountDiffenceWidget
                                key={idx}
                                title={item.name}
                                icon={leadAmountWidgetIcons[item.name]}
                                iconBgColor={item.backgroundColor}
                                sparkLineColor={item.color}
                                leadAmount={item.current}
                                percentageDifference={item.percentageChange}
                            />
                        ))}
                    </div>
                ))}
            </Carousel>
        )
    );
};
