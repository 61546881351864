import { sortTypes } from '../../constants/sortTypes';

export const SparkLine = ({ type, color }) => {
    const gradientId = `gradient_${type}_${color.replace('#', '')}`; // Unique gradient ID based on type and color

    if (type === sortTypes.ascent) {
        return (
            <svg width="134" height="64" viewBox="0 0 67 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M15.173 20.5956L1 31H66V1L50.8327 9.85036C45.8547 12.7551 40.1946 14.2857 34.4311 14.2857C27.5038 14.2857 20.7572 16.4962 15.173 20.5956Z"
                    fill={`url(#${gradientId})`}
                    fillOpacity="0.3"
                />
                <path
                    d="M1 31L15.173 20.5956C20.7572 16.4962 27.5038 14.2857 34.4311 14.2857V14.2857C40.1946 14.2857 45.8547 12.7551 50.8327 9.85036L66 1"
                    stroke={color}
                    strokeLinecap="round"
                />
                <defs>
                    <linearGradient id={gradientId} x1="33.5" y1="1" x2="33.5" y2="31" gradientUnits="userSpaceOnUse">
                        <stop stopColor={color} />
                        <stop offset="1" stopColor={color} stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }

    if (type === sortTypes.descent) {
        return (
            <svg width="134" height="64" viewBox="0 0 67 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M51.827 20.5956L66 31H1V1L16.1673 9.85036C21.1453 12.7551 26.8054 14.2857 32.5689 14.2857C39.4962 14.2857 46.2428 16.4962 51.827 20.5956Z"
                    fill={`url(#${gradientId})`}
                    fillOpacity="0.3"
                />
                <path
                    d="M66 31L51.827 20.5956C46.2428 16.4962 39.4962 14.2857 32.5689 14.2857V14.2857C26.8054 14.2857 21.1453 12.7551 16.1673 9.85036L1 1"
                    stroke={color}
                    strokeLinecap="round"
                />
                <defs>
                    <linearGradient
                        id={gradientId}
                        x1="21.5833"
                        y1="-3.5"
                        x2="21.5833"
                        y2="35.5"
                        gradientUnits="userSpaceOnUse"
                    >
                        <stop stopColor={color} />
                        <stop offset="1" stopColor={color} stopOpacity="0" />
                    </linearGradient>
                </defs>
            </svg>
        );
    }

    return (
        <svg width="134" height="64" viewBox="0 0 67 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
                d="M1 1L10.3568 3.07621C19.0218 4.99893 28.0157 4.87566 36.6248 2.71619C41.1688 1.57639 45.8357 1 50.5205 1H66V31H1V1Z"
                fill={`url(#${gradientId})`}
                fillOpacity="0.2"
            />
            <path
                d="M66 1H50.1987C45.5724 1 40.9645 1.58241 36.4837 2.7335V2.7335C28.1237 4.88113 19.3762 5.03548 10.9457 3.1841L0.999999 1"
                stroke={color}
                strokeLinecap="round"
            />
            <defs>
                <linearGradient id={gradientId} x1="29.6" y1="-25.625" x2="29.6" y2="31" gradientUnits="userSpaceOnUse">
                    <stop stopColor={color} />
                    <stop offset="1" stopColor={color} stopOpacity="0" />
                </linearGradient>
            </defs>
        </svg>
    );
};
