import { spacing } from './spacing';
import { radius } from './radius';
import { fontSize, lineHeight, fontWeight } from './typography';

export const small = {
    display: 'flex',
    padding: `${spacing[1]} ${spacing[3]}`,
    borderRadius: radius.md,
    width: 'fit-content',
    height: '36px',
    gap: spacing[1],
    alignItems: 'center',
    fontSize: fontSize.xs,
    fontWeight: fontWeight.sm,
    lineHeight: lineHeight.sm,
};

export const medium = {
    display: 'flex',
    padding: `${spacing[1]} ${spacing[3]}`,
    borderRadius: radius.md,
    width: 'fit-content',
    height: '40px',
    gap: spacing[2],
    alignItems: 'center',
    fontSize: fontSize.sm,
    fontWeight: fontWeight.md,
    lineHeight: lineHeight.md,
};

export const large = {
    display: 'flex',
    padding: `${spacing[1]} ${spacing[3]}`,
    borderRadius: radius.md,
    width: 'fit-content',
    height: '44px',
    gap: spacing[2],
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: fontSize.md,
    fontWeight: fontWeight.lg,
    lineHeight: lineHeight.md,
};

export const extraLarge = {
    display: 'flex',
    padding: `${spacing[2]} ${spacing[3]}`,
    borderRadius: radius.md,
    width: 'fit-content',
    height: '56px',
    gap: spacing[2],
    alignItems: 'center',
    fontSize: fontSize.H4,
    fontWeight: fontWeight.xl,
    lineHeight: lineHeight.H5,
};

export const buttonStyle = {
    small,
    medium,
    large,
    extraLarge,
};
