import { Doughnut } from 'react-chartjs-2';
import ChartDataLabels from 'chartjs-plugin-datalabels';
import { colors, fontSize, primitiveColors } from '../../theming';
import { useMedia } from '../../hooks/useMediaQuery';
import { useMediaQuery } from '@uidotdev/usehooks';

export const DoughnutChart = ({ chartData, title }) => {
    const { isExtraSmallDevice } = useMedia();

    const mobileDevicesBreakpoint = useMediaQuery('(max-width : 1680px)');

    const doughnutLabel = {
        id: 'dounghnutLabel',
        afterDatasetsDraw(chart, args, plugins) {
            const { ctx } = chart;

            const centerX = chart.getDatasetMeta(0).data[0].x;
            const centerY = chart.getDatasetMeta(0).data[0].y;

            ctx.save();
            ctx.font = `500 ${fontSize.lg} Inter`;
            ctx.fillStyle = primitiveColors.gray900;
            ctx.textAlign = 'center';
            ctx.textBaseline = 'middle';
            ctx.fillText(title, centerX, centerY);
        },
    };

    return (
        <div
            className="chart-container"
            style={{
                maxWidth: '100%',
                width: '100%',
                height: mobileDevicesBreakpoint ? '400px' : '100%',
                display: 'flex',
                justifyContent: 'center',
            }}
        >
            <Doughnut
                key={title}
                data={chartData}
                // style={{                 marginInline: mobileDevicesBreakpoint ? 'auto' : 'none',}}
                height="100%"
                width="100%"
                plugins={[doughnutLabel, ChartDataLabels]}
                options={{
                    cutout: '70%',
                    hover: {
                        mode: null,
                    },
                    layout: {
                        padding: {
                            top: 10
                        },
                    },
                    plugins: {
                        tooltip: {
                            enabled: false,
                        },
                        datalabels: {
                            display: true,
                            backgroundColor: function (context) {
                                return primitiveColors.gray0;
                            },
                            backgroundShadowColor: colors.black,
                            borderColor: primitiveColors.gray100,
                            borderRadius: 8,
                            borderWidth: 1,
                            color: colors.black,
                            font: {
                                family: 'Inter',
                                weight: 500,
                                size: 16,
                            },
                            padding: {
                                top: 2,
                                bottom: 2,
                                left: 8,
                                right: 8,
                            },
                            shadowOffsetX: 0,
                            shadowOffsetY: 3,
                            shadowBlur: 5,
                            shadowColor: colors.black,
                        },
                        legend: {
                            display: true,
                            position: 'bottom',
                            align: isExtraSmallDevice ? 'start' : 'center',
                            labels: {
                                usePointStyle: true,
                                pointStyle: 'rectRounded',
                                boxHeight: 2,
                                pointStyleWidth: 16,
                                padding: 24,
                                color: primitiveColors.gray500,
                                font: {
                                    family: 'Inter',
                                    size: 12,
                                    weight: 400,
                                },
                            },
                        },
                    },
                }}
            />
        </div>
    );
};
