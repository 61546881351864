import { useEffect, useState } from 'react';
import { Dropdown, Empty, Flex, Table, Typography } from 'antd';
import globalNotification from '../../common/hooks/globalNotification';
import { notificationType } from '../../common/constants/notificationType';
import { useTranslation } from 'react-i18next';
import SideDrawer from '../../common/components/sideDrawer/SideDrawer';
import { CustomPagination } from '../../common/components/pagination/Pagination';
import classNames from 'classnames';
import {
    buttonStyle,
    fontSize,
    menuActionText,
    pageHeading,
    primitiveColors,
    spacing,
    textColor,
    textSmall,
    textStandard,
} from '../../theming';
import CustomAvatar from '../../common/components/Avatar';
import PageContainer from '../../common/components/PageContainer';
import icon from '../../common/styles/Icon.module.scss';
import { useMedia } from '../../hooks/useMediaQuery';
import { useMediaQuery } from '@uidotdev/usehooks';
import AddButton from '../../common/components/AddButton';
import { useDemoService } from '../../services/demoService';
import { DemoUserForm } from '../../components/demo/demoUserForm';
import { constants } from '../../common/constants/constants';
import { notifications } from '../../common/constants/notifications';

export const Demo = () => {
    const { t } = useTranslation(['users', 'invitations', 'general', 'confirmModal', 'apiResponses']);

    const { getAllDemoAccounts, createDemoAccount, deleteDemoAccount, resetDemoAccount, editDemoAccount } =
        useDemoService();

    const { isExtraSmallDevice, isSmallDevice, isMediumDevice } = useMedia();

    const mobileDevicesBreakpoint = useMediaQuery('only screen and (max-width : 992px)');

    const [users, setUsers] = useState([]);
    const [totalUsers, setTotalUsers] = useState(0);
    const [loading, setLoading] = useState(true);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [formAction, setFormAction] = useState('create');
    const [defaultFormValues, setDefaultFormValues] = useState(null);

    const [searchParams, setSearchParams] = useState({
        limit: 10,
        offset: 1,
    });

    const [formMode, setFormMode] = useState('add');

    useEffect(() => {
        fetchDemoUsers();
    }, [searchParams]);

    const copyPreviewLink = record => {
        navigator.clipboard.writeText(`${constants.BASE_URL}/auth/sign-in?preview=${record.previewLink}`).then(() => {
            globalNotification.open({
                type: notificationType.SUCCESS,
                message: t('notifications.copied'),
            });
        });
    };

    const handleSubmitUserForm = async data => {
        setLoading(true);

        await createDemoAccount(data)
            .then(() => {
                fetchDemoUsers();
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.USERS,
                    action: notifications.action.CREATE,
                });
            })
            .catch(error => {
                const errCode = error.response?.data?.code;
                globalNotification.open({
                    type: notificationType.ERROR,
                    message: errCode ? t(`apiResponses:${errCode}.message`) : error.response?.data?.message,
                    description: errCode ? t(`apiResponses:${errCode}.description`) : error.response?.data?.description,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const handleSubmitUserEditForm = async (id, data) => {
        setLoading(true);

        await editDemoAccount(id, data)
            .then(() => {
                fetchDemoUsers();
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.USERS,
                    action: notifications.action.UPDATE,
                });
            })
            .catch(() =>
                globalNotification.request({
                    type: notifications.type.ERROR,
                    resource: notifications.resource.USERS,
                    action: notifications.action.UPDATE,
                })
            )
            .finally(() => {
                setLoading(false);
            });
    };

    const handleOpenEditDrawer = record => {
        setFormMode('edit');
        setDefaultFormValues(record);
        setDrawerOpen(true);
    };

    const handleDeleteDemoAccount = async id => {
        setLoading(true);
        await deleteDemoAccount(id)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.USERS,
                    action: notifications.action.DELETE,
                });
            })
            .catch(() => {
                globalNotification.request({
                    type: notifications.type.ERROR,
                    resource: notifications.resource.USERS,
                    action: notifications.action.DELETE,
                });
            })
            .finally(() => {
                setLoading(false);
            });
        await fetchDemoUsers();
    };

    const handleResetDemoAccount = async id => {
        setLoading(true);
        await resetDemoAccount(id)
            .then(() => {
                globalNotification.request({
                    type: notifications.type.SUCCESS,
                    resource: notifications.resource.USERS,
                    action: notifications.action.RESET,
                });
            })
            .catch(() => {
                globalNotification.request({
                    type: notifications.type.ERROR,
                    resource: notifications.resource.USERS,
                    action: notifications.action.RESET,
                });
            })
            .finally(() => {
                setLoading(false);
            });
        await fetchDemoUsers();
    };

    const fetchDemoUsers = async () => {
        await getAllDemoAccounts({
            limit: searchParams.limit,
            offset: (searchParams.offset - 1) * searchParams.limit,
        })
            .then(res => {
                setUsers(res[0]);
                setTotalUsers(res[1]);
            })
            .catch(err => {
                globalNotification.open({
                    type: notificationType.ERROR,
                    message: err.response?.data?.message,
                    description: err.response?.data?.description,
                });
            })
            .finally(() => {
                setLoading(false);
            });
    };

    const actionItems = record => [
        {
            key: 1,
            icon: <i className={`fi fi-rr-copy ${icon.md}`} style={{ color: textColor.subtle }} />,
            label: (
                <span
                    style={{
                        ...menuActionText,
                    }}
                >
                    {t('actions.copy')}
                </span>
            ),
            onClick: () => copyPreviewLink(record),
        },
        {
            key: 2,
            icon: <i className={`fi fi-rr-edit ${icon.md}`} style={{ color: textColor.subtle }} />,
            label: (
                <span
                    style={{
                        ...menuActionText,
                    }}
                >
                    {t('general:actions.simple.edit')}
                </span>
            ),
            onClick: () => {
                handleOpenEditDrawer(record);
            },
        },
        {
            key: 3,
            icon: <i className={`fi fi-rr-trash ${icon.md}`} style={{ color: textColor.subtle }} />,
            label: (
                <span
                    style={{
                        ...menuActionText,
                    }}
                >
                    {t('general:actions.simple.delete')}
                </span>
            ),
            onClick: async () => {
                await handleDeleteDemoAccount(record.id);
            },
        },
        {
            key: 4,
            icon: <i className={`fi fi-rr-refresh ${icon.md}`} style={{ color: textColor.subtle }} />,
            label: (
                <span
                    style={{
                        ...menuActionText,
                    }}
                >
                    {t('actions.reset')}
                </span>
            ),
            onClick: async () => {
                await handleResetDemoAccount(record.id);
            },
        },
    ];

    const columns = [
        {
            title: t('fields.name'),
            dataIndex: 'name',
            key: 'name',
            width: mobileDevicesBreakpoint ? 280 : 300,
            render: (name, record) => {
                return (
                    <Flex gap={mobileDevicesBreakpoint ? spacing[1] : spacing[3]} justify="start" align="center">
                        <CustomAvatar
                            key={record.id}
                            size={isSmallDevice || isExtraSmallDevice ? 32 : 36}
                            photoName={record.photo}
                            firstName={record.firstName}
                            lastName={record.lastName}
                            controller={'businesses'}
                        />
                        <Flex vertical>
                            <Typography.Text
                                style={{ ...(mobileDevicesBreakpoint ? textSmall : textStandard), fontWeight: 500 }}
                            >
                                {`${record.firstName} ${record.lastName}`}
                            </Typography.Text>

                            <a
                                href={`mailto:${record.email}`}
                                target="_blank"
                                onClick={e => e.stopPropagation()}
                                style={{
                                    color: textColor.subtle,
                                    ...textStandard,
                                }}
                            >
                                {record.email}
                            </a>
                        </Flex>
                    </Flex>
                );
            },
        },
        {
            title: t('fields.actions'),
            dataIndex: 'actions',
            key: 'actions',
            width: 40,
            fixed: 'right',
            render: (_, record) => (
                <Flex justify="center" onClick={e => e.stopPropagation()}>
                    <Dropdown
                        placement="bottom"
                        menu={{
                            items: actionItems(record),
                        }}
                    >
                        <span onClick={e => e.preventDefault()} style={{ cursor: 'pointer' }}>
                            <i
                                className={`fi fi-rr-menu-dots-vertical ${icon.md}`}
                                style={{ color: textColor.subtle }}
                            />
                        </span>
                    </Dropdown>
                </Flex>
            ),
        },
    ];

    const paginationConfig = {
        pageSize: searchParams.limit,
        current: searchParams.offset,
        total: totalUsers,
    };

    const onTableParamsChange = pagination => {
        setSearchParams(prevValue => ({
            ...prevValue,
            currentPage: pagination.current,
            pageSize: pagination.pageSize,
        }));
    };

    const handleAddMember = () => {
        setFormAction('add');
        setDrawerOpen(true);
    };

    const handleDrawerClose = () => {
        setFormMode('add');
        setDrawerOpen(false);
        setDefaultFormValues(null);
    };

    return (
        <PageContainer
            style={{
                backgroundColor: mobileDevicesBreakpoint ? primitiveColors.gray25 : primitiveColors.gray0,
                padding: mobileDevicesBreakpoint ? spacing[1] : spacing[4],
            }}
        >
            <Flex vertical>
                <Flex
                    vertical={mobileDevicesBreakpoint}
                    gap={mobileDevicesBreakpoint ? spacing[1] : spacing[3]}
                    align="center"
                    justify="space-between"
                    style={{
                        marginBottom: mobileDevicesBreakpoint ? spacing[3] : spacing[4],
                    }}
                >
                    <Flex
                        gap={spacing[3]}
                        align="center"
                        justify={mobileDevicesBreakpoint ? 'space-between' : 'start'}
                        style={{
                            width: mobileDevicesBreakpoint && '100%',
                            maxHeight: mobileDevicesBreakpoint && 60,
                        }}
                    >
                        <h1
                            style={{
                                ...pageHeading,
                                fontSize: mobileDevicesBreakpoint && fontSize.H4,
                                margin: 0,
                            }}
                        >
                            {t('plural')}
                        </h1>
                    </Flex>

                    <Flex
                        gap={spacing[3]}
                        align="center"
                        justify={mobileDevicesBreakpoint ? 'space-between' : 'end'}
                        style={{ width: mobileDevicesBreakpoint ? '100%' : 'auto' }}
                    >
                        <AddButton
                            size={isMediumDevice && mobileDevicesBreakpoint && 'default'}
                            style={{
                                ...(mobileDevicesBreakpoint ? buttonStyle.small : buttonStyle.large),
                            }}
                            iconSize={icon.sm}
                            text={t('actions.add')}
                            onClick={handleAddMember}
                        />
                    </Flex>
                </Flex>
            </Flex>
            <Table
                columns={columns}
                dataSource={users}
                onChange={onTableParamsChange}
                loading={loading}
                footer={() => (
                    <CustomPagination
                        currentPage={paginationConfig.current}
                        handlePageChange={value => {
                            setSearchParams(prev => ({ ...prev, offset: value }));
                        }}
                        perPage={paginationConfig.pageSize}
                        handlePerPageChange={value => {
                            setSearchParams(prev => ({ ...prev, limit: value, offset: 1 }));
                        }}
                        total={paginationConfig.total}
                        title={t('total-users')}
                    />
                )}
                pagination={false}
                scroll={{
                    x: 'max-content',
                }}
                bordered={false}
                size="small"
                defaultSortOrder="descend"
                rowClassName={(record, index) =>
                    classNames({
                        'even-table-cell': index % 2 === 0,
                        'odd-table-cell': index % 2 !== 0,
                    })
                }
                locale={{
                    emptyText: <Empty description={t('general:no-data')} />,
                    triggerDesc: t('general:actions.simple.sort-desc'),
                    triggerAsc: t('general:actions.simple.sort-asc'),
                    cancelSort: t('general:actions.simple.cancel-sort'),
                }}
            />
            <SideDrawer
                title={formAction === 'add' ? t('actions.add') : t('actions.edit')}
                open={drawerOpen}
                closeDrawer={handleDrawerClose}
            >
                <DemoUserForm
                    mode={formMode}
                    user={defaultFormValues}
                    loading={loading}
                    handleAdd={handleSubmitUserForm}
                    handleEdit={handleSubmitUserEditForm}
                />
            </SideDrawer>
        </PageContainer>
    );
};
