import { Input, Spin } from 'antd';
import { useTranslation } from 'react-i18next';
import { SearchIcon, ClearInputIcon } from '../icons/';

export default function SearchInput({ loading, onSearch, searchText, setSearchText, placeholder, ...props }) {
    const { t } = useTranslation(['globalSearch']);

    const handleSearch = e => {
        e.preventDefault();
        if (searchText !== '') {
            onSearch(searchText);
        }
    };

    return (
        <Input
            placeholder={placeholder || t('search') + '...'}
            size="large"
            addonAfter={false}
            onChange={e => setSearchText(e.target.value)}
            onPressEnter={e => {
                handleSearch(e);
            }}
            style={{ ...props.style }}
            value={searchText}
            prefix={<SearchIcon onClick={e => handleSearch(e)} />}
            suffix={loading ? <Spin size="small" /> : searchText && <ClearInputIcon setSearchText={setSearchText} />}
            {...props}
        />
    );
}
