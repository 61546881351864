import { useAuth } from '../context/AuthContext';
import { useAxios } from './axiosService';
import { constants } from '../common/constants/constants';

export const useSubscriptionService = () => {
    const { currentUser } = useAuth();
    const { axios } = useAxios();

    const checkSubscriptionPermissions = async () => {
        try {
            const userSubscription = await getUserSubscription(currentUser.teamId);

            return !!currentUser?.subscriptionTier === userSubscription?.data?.priority;
        } catch (err) {
            console.log(err?.data);
            return false;
        }
    };

    const getUserSubscription = async teamId => {
        return await axios.get(`${constants.API_BASE_URL}/subscriptions/me`, {
            params: {
                teamId,
            },
        });
    };

    const getAllSubscriptions = async () => {
        return await axios.get(`${constants.API_BASE_URL}/subscriptions`);
    };

    const registerSubscription = async (priceId, quantity, teamId, isTeamCreation) => {
        return await axios.post(`${constants.API_BASE_URL}/subscriptions/register`, {
            priceId: priceId,
            quantity: quantity,
            teamId: teamId,
            isTeamCreation: isTeamCreation,
        });
    };

    const cancelSubscription = async subscription => {
        return await axios.post(`${constants.API_BASE_URL}/subscriptions/cancel`, { subscription: subscription });
    };

    const cancelSubscriptionImmediately = async subscription => {
        return await axios.post(`${constants.API_BASE_URL}/subscriptions/cancel-immediately`, {
            subscription: subscription,
        });
    };

    const renewSubscription = async subscription => {
        return await axios.post(`${constants.API_BASE_URL}/subscriptions/renew`, { subscription: subscription });
    };

    return {
        checkSubscriptionPermissions,
        getUserSubscription,
        getAllSubscriptions,
        registerSubscription,
        cancelSubscription,
        renewSubscription,
        cancelSubscriptionImmediately,
    };
};
