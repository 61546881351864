import classes from './PreviewButton.module.scss';
import { EyeOutlined } from '@ant-design/icons';
import { constants } from '../../constants/constants';
import { Spin } from 'antd';

export default function PreviewButton({ handler, url, file, path }) {
    const { API_BASE_URL } = constants;
    const fileUrl = `${API_BASE_URL}${path}/${file.response}`; // this is for fallback if url is not provided

    return (
        <div
            className={classes.wrapper}
            onClick={e => {
                e.stopPropagation();
                handler(file);
            }}
        >
            <p className={classes.icon} type="button">
                <EyeOutlined />
            </p>
            {url || file.response ? <img src={url || fileUrl} alt="avatar" className={classes.image} /> : <Spin />}
        </div>
    );
}
