import { useEffect, useState } from 'react';
import { Col, Row, FloatButton } from 'antd';
import { useSubscriptionService } from '../../services/subscriptionService';
import SubscriptionCard from '../../components/subscriptions/subscription-card';
import { spacing } from '../../theming';
import classes from './Subscriptions.module.scss';
import icon from '../../common/styles/Icon.module.scss';

export const Subscriptions = () => {
    const [subscriptions, setSubscriptions] = useState([]);
    const [userSubscription, setUseSubscription] = useState();
    const [userSubscriptionLoading, setUserSubscriptionLoading] = useState(true);

    const { getAllSubscriptions, getUserSubscription } = useSubscriptionService();

    const handleSubscriptionChange = () => {
        getUserSubscription().then(res => {
            setUseSubscription(res.data);
            setUserSubscriptionLoading(false);
        });
        getAllSubscriptions().then(res => {
            setSubscriptions(res.data);
        });
    };

    useEffect(() => {
        try {
            const fetchData = async () => {
                const response = await getUserSubscription();
                setUseSubscription(response.data);
                setUserSubscriptionLoading(false);

                const subscriptionsResponse = await getAllSubscriptions();
                setSubscriptions(subscriptionsResponse.data);
            };

            fetchData();
        } catch (error) {
            console.error(error);
        }
    }, []);

    return (
        <div className={classes.wrapper}>
            <FloatButton
                style={{
                    position: 'fixed',
                    width: 50,
                    height: 50,
                }}
                tooltip="All subscriptions that you see are going from stripe dashboard and it cannot be translated. If you want to change the text, you can change it from the stripe dashboard."
                icon={<i className={`fi fi-rr-comment-info ${icon.lg}`} />}
                type="primary"
            />
            <Row
                gutter={{
                    xs: 8,
                    sm: 16,
                    md: 24,
                    lg: 32,
                }}
                justify="center"
                align="center"
                style={{
                    flexDirection: 'row-reverse',
                    justifyContent: 'center',
                    alignItems: 'center',
                    margin: `${spacing[4]} 0 ${spacing[6]} 0`,
                    height: '100%',
                    minHeight: 760,
                    gap: spacing[2],
                }}
            >
                {subscriptions.map(subscription => (
                    <Col span={spacing[6]} key={subscription.subscriptionId}>
                        <SubscriptionCard
                            key={subscription.subscriptionId}
                            subscription={subscription}
                            userPriority={userSubscription}
                            loading={userSubscriptionLoading}
                            setLoading={setUserSubscriptionLoading}
                            handleSubscriptionChange={handleSubscriptionChange}
                            isTeamCreation={false}
                        />
                    </Col>
                ))}
            </Row>
        </div>
    );
};
