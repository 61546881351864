import { Flex, Typography, Button, Form, Input } from 'antd';
import { useState, useEffect, useCallback } from 'react';
import { useTeamService } from './../../services/teamService';
import { spacing, buttonStyle, textSmall, textColor, primitiveColors, fontSize } from './../../theming';
import logo from '../../assets/Main_logo.png';
import globalNotification from './../../common/hooks/globalNotification';
import { notificationType } from './../../common/constants/notificationType';
import classes from './style.module.scss';
import { regexPatterns } from './../../common/constants/regexPatterns';
import UpgradePlanModal from './upgrade-plan-modal';
import SkipInvitationModal from './skip-invitation-modal';
import { useSubscriptionService } from '../../services/subscriptionService';
import { useMedia } from '../../hooks/useMediaQuery';

const { Text, Title } = Typography;

function Invitation({ activeTabKey, setActiveTabKey, t, currentUser }) {
    const [form] = Form.useForm();
    const [formValid, setFormValid] = useState(true);
    const [isUpgradeModalOpen, setIsUpgradeModalOpen] = useState(false);
    const [isSkipModalOpen, setIsSkipModalOpen] = useState(false);
    const [usersSubscription, setUsersSubscription] = useState();
    const [usersLimit, setUsersLimit] = useState(5);
    const [loading, setLoading] = useState(false);
    const [hasInteracted, setHasInteracted] = useState(false);
    const { isSmallDevice } = useMedia();

    const flexStyle = {
        width: 'clamp(300px, 85vw, 400px)',
        margin: `${spacing[2]} 0`,
    };

    const { sendInvitation } = useTeamService();
    const { getUserSubscription } = useSubscriptionService();

    const handleNavigate = () => {
        setActiveTabKey('0');
        // clearing local storage
        localStorage.removeItem('emails');
        localStorage.removeItem('teamId');
        localStorage.removeItem('isSubscriptionChanged');
    };

    useEffect(() => {
        const emails = localStorage.getItem('emails');
        form.setFieldsValue({ emails: emails || '' });

        const fetchUserSubscription = async () => {
            try {
                const res = await getUserSubscription(localStorage.getItem('teamId'));
                setUsersSubscription(res.data);
                setUsersLimit(res.data.usersLimit);
            } catch (error) {
                console.error('Error getting user subscription', error); // just to handle errors, no need in notification
            }
        };
        fetchUserSubscription();

        setHasInteracted(false);
    }, [activeTabKey]);

    const upgradeModalHandler = useCallback(() => {
        setIsUpgradeModalOpen(prev => !prev);
        localStorage.setItem('emails', form.getFieldValue('emails'));
    }, [form]);

    const skipInvitationModalHandler = () => {
        setIsSkipModalOpen(prev => !prev);
    };

    const validator = async (_, value) => {
        if (!hasInteracted) {
            return Promise.resolve();
        }

        if (value && value.trim()) {
            const emails = value.split(/[\s,]+/);

            if (emails.some(email => !email.match(regexPatterns.email))) {
                return Promise.reject(`${emails.length > 1 ? t('form.email-plural') : t('form.email-singular')}`);
            }
            if (emails.some((email, index) => emails.indexOf(email) !== index)) {
                return Promise.reject(t('form.emails-unique'));
            }
        } else {
            return Promise.reject(t('form.email-required'));
        }

        return Promise.resolve();
    };

    const handleFormSubmit = async () => {
        const emails = form.getFieldValue('emails').split(/[\s,]+/);

        if (usersSubscription?.usersLimit && emails.length >= usersSubscription.usersLimit) {
            upgradeModalHandler();
            return;
        }

        try {
            setLoading(true);
            for (const email of emails) {
                await sendInvitation({ email, teamId: localStorage.getItem('teamId') || currentUser.teamId });
            }
            globalNotification.open({
                type: notificationType.SUCCESS,
                message: t('general:success'),
                description: t('notification.inv-success'),
            });
            handleNavigate();
        } catch (error) {
            const errorCode = error.response.data?.code;
            globalNotification.open({
                type: notificationType.ERROR,
                message: t(`apiResponses:${errorCode}.message`),
                description: t(`apiResponses:${errorCode}.description`),
            });
        } finally {
            setLoading(false);
        }
    };

    const handleFormChange = async () => {
        if (!hasInteracted) {
            setHasInteracted(true);
        }
        try {
            await form.validateFields();
            setFormValid(true);
        } catch {
            setFormValid(false);
        }
    };

    return (
        <Flex vertical gap={isSmallDevice ? spacing[2] : spacing[4]} style={{ ...flexStyle }}>
            {!currentUser?.teamId && (
                <img
                    src={logo}
                    alt="logo"
                    style={{
                        width: 60,
                        placeSelf: 'center',
                    }}
                />
            )}
            <Flex vertical gap={spacing[3]} style={{ ...flexStyle }}>
                <Flex vertical gap={spacing[1]}>
                    <Text style={{ ...textSmall, color: primitiveColors.gray400 }}>{t('step-2')}</Text>
                    <Title
                        level={isSmallDevice ? 3 : 2}
                        style={{
                            margin: 0,
                        }}
                    >
                        {t('invite-title')}
                    </Title>
                    <Text style={{ ...textSmall, color: textColor.secondary, fontSize: isSmallDevice && fontSize.xs }}>
                        {t('invite-sub')}
                    </Text>
                </Flex>
                <Form
                    form={form}
                    onFinish={event => handleFormSubmit(event)}
                    onChange={() => handleFormChange()}
                    className={classes['invitation-form']}
                >
                    <Text className={classes.title}>{t('invite-add')}</Text>
                    {usersLimit <= 5 && <Text className={classes.subtitle}>{t('invite-warning')}</Text>}
                    <Form.Item style={{ width: '100%' }} name="emails" rules={[{ validator: validator }]}>
                        <Input.TextArea
                            placeholder={t('invite-example')}
                            className={classes.textarea}
                            autoSize={{
                                minRows: 2,
                                maxRows: 8,
                            }}
                        />
                    </Form.Item>
                    <Flex align="center" gap={spacing[2]}>
                        <Button
                            type="primary"
                            style={{ ...buttonStyle.large, minWidth: 105, border: 0 }}
                            disabled={!formValid}
                            htmlType="submit"
                            loading={loading}
                        >
                            {t('buttons.next')}
                        </Button>
                        <Button
                            type="text"
                            style={{
                                ...buttonStyle.large,
                                minWidth: 105,
                                color: textColor.subtle,
                                fontSize: fontSize.sm,
                            }}
                            onClick={skipInvitationModalHandler}
                        >
                            {t('buttons.skip')}
                        </Button>
                    </Flex>
                </Form>
            </Flex>
            <UpgradePlanModal
                isModalOpen={isUpgradeModalOpen}
                modalHandler={upgradeModalHandler}
                setActiveTabKey={setActiveTabKey}
                t={t}
            />
            <SkipInvitationModal
                isModalOpen={isSkipModalOpen}
                modalHandler={skipInvitationModalHandler}
                navigationHandler={handleNavigate}
                t={t}
            />
        </Flex>
    );
}

export default Invitation;
