import { Checkbox } from 'antd';

function SearchOptions({ options, setOptions }) {
    return (
        <>
            {options.map(option => (
                <Checkbox
                    key={option.value}
                    checked={option.checked}
                    onChange={() => {
                        setOptions(
                            options.map(item => {
                                if (item.value === option.value) {
                                    item.checked = !item.checked;
                                }
                                return item;
                            })
                        );
                    }}
                    style={{ width: '100%' }}
                >
                    {option.label}
                </Checkbox>
            ))}
        </>
    );
}

export default SearchOptions;
