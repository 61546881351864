import { Link, useNavigate } from 'react-router-dom';
import { Dropdown, Flex, Select, Typography } from 'antd';
import { useAuth } from '../../../context/AuthContext';
import { routesPath } from '../../constants/routesPath';
import React from 'react';
import Avatar from '../Avatar';
import { useTranslation } from 'react-i18next';
import { languages } from '../../constants/languages';
import classes from './UserIconDropdown.module.scss';
import icon from '../../styles/Icon.module.scss';

function UserIconDropdown() {
    const navigate = useNavigate();
    const { i18n, t } = useTranslation(['menu']);

    const { logout, currentUser } = useAuth();

    const onClick = ({ key }) => {
        switch (key) {
            case 'profile':
                navigate(routesPath.ROOT.PROFILE);
                break;
            case 'settings':
                navigate(routesPath.ROOT.USER_SETTINGS);
                break;
            case 'logout':
                logout();
                break;
            default:
                break;
        }
    };

    const items = [
        {
            key: 'currentUsersEmail',
            label: <Typography.Text style={{ wordBreak: 'keep-all !important' }}>{currentUser?.email}</Typography.Text>,
        },
        { type: 'divider', key: 'divider1' },
        {
            key: 'language',
            label: (
                <Flex justify="space-between" align="center">
                    <Typography.Text>{t('language')}:</Typography.Text>
                    <Select
                        defaultValue={i18n.resolvedLanguage}
                        onClick={e => e.stopPropagation()}
                        onChange={value => i18n.changeLanguage(value)}
                        variant="filled"
                        dropdownStyle={{ minWidth: 100, width: 'fit-content' }}
                    >
                        {Object.values(languages).map(lng => (
                            <Select.Option key={lng} value={lng.value} disabled={i18n.resolvedLanguage === lng.value}>
                                {lng.label}
                            </Select.Option>
                        ))}
                    </Select>
                </Flex>
            ),
        },
        { type: 'divider', key: 'divider2' },
        {
            key: 'menu',
            label: t('menu'),
            disabled: true,
        },
        {
            key: 'profile',
            label: (
                <Flex align="center">
                    <i className={`fi fi-sr-user ${icon.md}`} />
                    <span className="ml-1">{t('profile')}</span>
                </Flex>
            ),
        },
        {
            key: 'settings',
            label: (
                <Flex align="center">
                    <i className={`fi fi-sr-settings ${icon.md}`} />
                    <span className="ml-1">{t('settings')}</span>
                </Flex>
            ),
        },
        { type: 'divider', key: 'divider3' },
        {
            key: 'logout',
            label: (
                <Flex align="center">
                    <i className={`fi fi-sr-exit ${icon.md}`} />
                    <span className="ml-1">{t('log-out')}</span>
                </Flex>
            ),
        },
    ];

    return (
        <Dropdown
            menu={{ items, onClick }}
            placement="bottomRight"
            overlayClassName={classes['user-icon-dropdown-menu']}
            trigger={['click']}
        >
            <Avatar
                firstName={currentUser?.firstName}
                lastName={currentUser?.lastName}
                photoName={currentUser?.photo}
                controller={'users'}
                size={37}
                style={{
                    cursor: 'pointer',
                }}
            />
        </Dropdown>
    );
}

export default UserIconDropdown;
