export const spacing = {
    none: '0px',
    0.5: '4px',
    1: '8px',
    2: '12px',
    3: '16px',
    4: '24px',
    5: '32px',
    6: '40px',
    7: '48px',
    8: '56px',
    9: '64px',
    10: '72px',
    11: '80px',
    12: '88px',
    13: '96px',
    14: '104px',
    15: '208px',
    huge: '9999px',
};
