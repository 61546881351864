import { Modal, Flex, Button, Typography } from 'antd';
import ButtonIcon from '../../common/components/buttonIcon/ButtonIcon';
import upgradeLogo from '../../assets/upgrade_plan_team_creation.png';
import { spacing, buttonStyle } from './../../theming';
import classes from './style.module.scss';
import classNames from 'classnames';
import { useState, useEffect } from 'react';

const { Title, Text } = Typography;

function UpgradePlanModal({ isModalOpen, modalHandler, setActiveTabKey, t }) {
    const [isLoaded, setIsLoaded] = useState(false);

    useEffect(() => {
        const img = new Image();
        img.src = upgradeLogo;
        img.onload = () => setIsLoaded(true);
    }, []); // this is to handle image loading and to prevent the modal from flickering

    return (
        <Modal
            open={isModalOpen}
            closable={false}
            okButtonProps={{
                style: { display: 'none' },
            }}
            cancelButtonProps={{
                style: { display: 'none' },
            }}
            centered
            styles={{
                content: {
                    margin: spacing[2],
                },
                body: {
                    padding: `${spacing[5]} ${spacing[4]}`,
                },
            }}
            className={classes.modal}
        >
            <Flex vertical gap={spacing[2]} align="center">
                <ButtonIcon
                    icon="fi-rr-cross"
                    onClick={modalHandler}
                    style={{
                        top: spacing[4],
                        right: spacing[4],
                    }}
                />
                {isLoaded && (
                    <>
                        <img
                            src={upgradeLogo}
                            alt="Upgrade Plan"
                            style={{
                                width: 'clamp(280px, 75vw, 450px)',
                            }}
                        />
                        <Title className={classNames(classes['modal-title'], classes['text-align-center'])}>
                            {t('upgrade-modal-title')}
                        </Title>
                        <Text className={classNames(classes['modal-text'], classes['text-align-center'])}>
                            {t('upgrade-modal-sub')}
                        </Text>
                    </>
                )}
                <Flex gap={spacing[2]}>
                    <Button
                        type="text"
                        danger
                        onClick={modalHandler}
                        style={{
                            ...buttonStyle.medium,
                        }}
                    >
                        {t('buttons.cancel')}
                    </Button>
                    <Button
                        type="primary"
                        onClick={() => {
                            modalHandler();
                            setActiveTabKey('3');
                        }}
                        style={{
                            ...buttonStyle.medium,
                        }}
                    >
                        {t('buttons.plans')}
                    </Button>
                </Flex>
            </Flex>
        </Modal>
    );
}

export default UpgradePlanModal;
